import {useEffect, useState, useMemo, useContext} from 'react';
import {
	Grid,
	TextField,
	FormControl,
	InputLabel,
	Select,
	MenuItem,
	Button,
	ListItem,
	ListItemText,
	ListItemIcon,
	CircularProgress,
	Alert,
} from '@mui/material';
import {AddOutlined, SaveAltOutlined, EditOutlined} from '@mui/icons-material';
import {useListLevel, useCreate as createGroup} from '../../../services/group';
import {useCreate as createPrivilege} from '../../../services/privileges';
import {GroupSelection} from '../../../components/root';
import {ModuleSelection} from '../../../components/root';
import {OptionPrivilegeCreate} from '../../../components/spam/optionPrivilegesCreate';
import {GroupContext} from '../../../utils/group';
import {ModuleContext} from '../../../utils/modules';

export const GroupCreate = () => {
	const {onProcess, levels} = useListLevel(),
		{loading, onProcess: processCreateGroup} = createGroup(),
		{onProcess: processCreatePrivilege} = createPrivilege(),
		[res, setRes] = useState({status: '', message: ''}),
		[groupLevelSelect, setGroupLevelSelect] = useState('others'),
		[groupLevel, setGroupLevel] = useState(1),
		[groupName, setGroupName] = useState(''),
		{data, updateAll: allGroup} = useContext(GroupContext),
		{data: modules, updateOne, updateAll: allModule} = useContext(ModuleContext),
		[showListGroup, setShowListGroup] = useState(false),
		[showListModule, setShowListModule] = useState(false),
		[privileges, setPrivileges] = useState([]),
		getData = useMemo(() => {
			const d = data.filter(el => el.isChecked);
			if(d.length === 0)
				return null;
			return d[0];
		}, [data]),
		getModules = useMemo(() => {
			if(modules.length === 0)
				return [];
			return modules.filter(el => el.isChecked);
		}, [modules]),
		loadPrivileges = useMemo(() => {
			if(privileges.length === 0)
				return false;
			return privileges.filter(el => el.loading).length > 0;
		}, [privileges])

	const updatePrivileges = ({module_id, payload = undefined, key, value}) => {

		if(module_id === 'all')
			return setPrivileges(prev => prev.map(el => ({...el, [key]: value})));
		setPrivileges(prev => prev.map(el => {
			if(module_id === el.module_id) {
				if(payload !== undefined)
					return {...el, ...payload};
				return {...el, [key]: value};
			}
			return el;
		}))
	}

	function addOthers () {
		setGroupName('');
		setGroupLevel('1');
		setGroupLevelSelect('others');
		allGroup({key: 'isChecked', value: false});
		allModule({key: 'isChecked', value: false});
	}

	const onCreateData = () => {
		processCreateGroup({name: groupName, level: parseInt(groupLevel || '1', 10), parentGroupId: getData && getData.id ? getData.id : 0}, async (err, _respGroup) => {
			if(err)
				return setRes({status: 'error', message: err});

			const id = _respGroup.id;

			if(!id)
				return setRes({status: 'error', message: `invalid respon from server`});

			if(privileges.length > 0) {
				await updatePrivileges({module_id: 'all', key: 'loading', value: true});

				for(const privilege of privileges) {
					const payload = {
						group_id: id,
						module_id: privilege.module_id,
						create: privilege.create,
						read: privilege.read,
						update: privilege.update,
						delete: privilege.delete,
					};
					await processCreatePrivilege(payload, async (err, _) => {
						if(err) {
							await updatePrivileges({module_id: payload.module_id, payload: {loading: false, message: err}})
							return;
						}

						await updatePrivileges({module_id: payload.module_id, key: 'loading', value: false});
					})
				}

				setRes({status: 'ok', message: `Success create Group, and success create privileges`});
				allGroup({key: 'isChecked', value: false});
				return
			}
			setRes({status: 'ok', message: 'Success create group'});
			allGroup({key: 'isChecked', value: false});

		})
	}

	useEffect(() => {
		onProcess();
	}, [onProcess]);

	useEffect(() => {
		if(getModules.length > 0)
			setPrivileges(getModules.map(el => ({module_id: el.id, module_name: el.identity, group_id: null, create: true, read: true, update: true, delete: true, loading: false, message: ''})));
		else
			setPrivileges([]);
	}, [getModules])

	return (
		<Grid container flexDirection='column' spacing={2} marginTop={1}>
			{showListGroup
				?	<GroupSelection currentLevel={parseInt(groupLevel, 10)} single open={showListGroup} close={() => setShowListGroup(false)}/>
				:	null}
			{showListModule
				?	<ModuleSelection open={showListModule} close={() => setShowListModule(false)}/>
				:	null}
			<Grid item>
				<TextField
					label='Group Name'
					placeholder='eg: master'
					value={groupName}
					variant='standard'
					size='small'
					onChange={({target: {value}}) => setGroupName(value)}
					name='name'/>

			</Grid>
			<Grid item>
				{groupLevelSelect === 'others'
					?	<TextField
							label='Other Level'
							placeholder='eg: 10'
							value={groupLevel}
							onChange={({target: {value}}) => {
								if(!value)
									return setGroupLevel('');
								if(!isNaN(Number(value)))
									setGroupLevel(value)
							}}
							variant='standard'
							size='small'
							style={{width: 100}}/> : null}
				{levels.length > 0
					?	<FormControl variant='standard' style={{marginLeft: groupLevelSelect !== 'others' ? 0 : 10, minWidth: 200}}>
							<InputLabel id='level-variable'>Choose an existing level</InputLabel>
							<Select
								labelId='level-variable'
								value={groupLevelSelect}
								onChange={({target: {value}}) => {
									setGroupLevelSelect(value);
									if(value !== 'others')
										setGroupLevel(parseInt(value, 10));
								}}
								label='Level'>
								{levels.length > 0
									?	levels.map((level, index) => (
										<MenuItem value={level} key={index}>{level}</MenuItem>
									)) : null}
								<MenuItem value='others'>Other</MenuItem>
							</Select>
						</FormControl>
					:	null}

			</Grid>

			<Grid item>
				<ListItem button onClick={() => setShowListGroup(true)}>
					<ListItemText primary='Superior Name' secondary={getData && getData.id ? `${getData.name} - Level ${getData.level}` : 'Root (Default)'}/>
					<ListItemIcon>
						<EditOutlined color='primary'/>
					</ListItemIcon>
				</ListItem>
			</Grid>

			<Grid item>
				<ListItem button onClick={() => setShowListModule(true)}>
					<ListItemText
						primary='Target Module'
						secondary={privileges.length === 0 ? 'Optional' : `${privileges.length} modules`}/>
					<ListItemIcon>
						<EditOutlined color='primary'/>
					</ListItemIcon>
				</ListItem>
				
			</Grid>

			{privileges.length > 0
				?	<OptionPrivilegeCreate
						privileges={privileges}
						groupName={groupName}
						updatePrivileges={({privilege, key, value}) => updatePrivileges({module_id: privilege.module_id, key, value})}
						deleteOne={privilege => updateOne(privilege.module_id, {key: 'isChecked', value: false})}/>
				:	null}


			<Grid item>
				{res.status === 'ok'
					?	<Button
							variant='outlined'
							size='small'
							color='success'
							startIcon={<AddOutlined/>}
							onClick={addOthers}>Add Other</Button>
					:	loading || loadPrivileges
						?	<CircularProgress size={25}/>
						:	<Button
								size='small'
								variant='outlined'
								color='primary'
								onClick={onCreateData}
								startIcon={<SaveAltOutlined/>}>Save Group</Button>}

			</Grid>

			{res.message
				?	<Grid item>
						<Alert severity={res.status === 'ok' ? 'success' : 'error'} variant='standard'>{res.message}</Alert>
					</Grid>
				: null}
		</Grid>
	)
}

