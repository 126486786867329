import {useState, useContext, useCallback, useMemo} from 'react';
import x from 'axios';
import gtw from '../apis/gtw';
import {AuthContext} from '../utils/auth';
import {GroupContext} from '../utils/group';
import {handleError, useCancelRequest} from './helpers';
import {convertDataToQuery} from '../helpers/custom';

export function useCreate () {
	const [loading, setLoading] = useState(false),
		[request, setRequest] = useState(null),
		{headers} = useContext(AuthContext),
		{addOne} = useContext(GroupContext),
		onProcess = async function (data, callback = (err, dataGroup) => null) {
			setLoading(true);
			const cancel = await (async function () {
				try {
					const _req = x.CancelToken.source();
					setRequest(_req);
					const {data: _data} = await gtw({method: 'post', url: `/root/group`, data, headers, cancelToken: _req.token});

					if(_data && _data.id) {
						addOne(_data);
						return callback(null, _data);
					}

					callback('server si not responding', null);
				}catch(e) {
					const message = handleError(e);
					if(message.cancel)
						return true;
					callback(message, null);
				}
			})();
			if(cancel)
				return;
			setLoading(false);
		};
	useCancelRequest(request);
	return {onProcess, loading};
}

export function useListLevel () {
	const [loading, setLoading] = useState(false),
		[request, setRequest] = useState(null),
		{headers} = useContext(AuthContext),
		[levels, setLevels] = useState([]),
		onProcess = useCallback(async function () {
			setLoading(true);
			const cancel = await (async () => {
				try {
					const _req = x.CancelToken.source();
					setRequest(_req);
					const {data} = await gtw({method: 'get', url: '/root/group/level', headers, cancelToken: _req.token});
					if(data && data.length)
						setLevels(data);
				}catch(e) {}
			})();
			if(cancel)
				return;
				setLoading(false);
		}, [headers]);

	useCancelRequest(request);

	return {loading, levels, onProcess};
}

export const useGroupList = () => {
	const [loading, setLoading] = useState(false),
		[request, setRequest] = useState(null),
		{headers} = useContext(AuthContext),
		[query, setQuery] = useState({offset: 0, orderBy: 'create_date', sortBy: 'desc'}),
		{count, offset: _stateOffset, data, setData, updateOne, updateAll} = useContext(GroupContext),
		[res, setRes] = useState({status: '', message: ''}),
		onProcess = useCallback(async function () {
			setLoading(true);
			setRes({status: '', message: ''});
			const cancel = await (async function () {
				if(data.length > 0 && query.offset <= _stateOffset)
					return;

				try {
					const _req = x.CancelToken.source();
					setRequest(_req);
					let _query = {...query};
					delete _query.offset
					const {data: _data} = await gtw({method: 'get', url: `/root/group/${query.offset}/5${convertDataToQuery(_query)}`, headers, cancelToken: _req.token});

					if(_data.data.length > 0)
						return setData({..._data, offset: query.offset, first: query.offset === 0, data: _data.data.map(el => ({...el, isChecked: false}))});
				}catch(e) {
					const message = handleError(e);
					if(message.cancel)
						return true;
					setRes({status: 'error', message});
				}
			})();
			if(cancel)
				return;
			setLoading(false);
		}, [headers, query, _stateOffset, data.length, setData]),
		hasMore = useMemo(() => {
			const _count = Math.floor(count / 5);
			return query.offset < _count;
		}, [query.offset, count]),
		selectItems = useMemo(() => {
			if(data.length === 0)
				return [];
			return data.filter(el => el.isChecked);
		}, [data]);

	useCancelRequest(request);
	return {onProcess, loading, hasMore, query, selectItems, setQuery, res, data, updateOne, updateAll}
}