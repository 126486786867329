import {useState} from 'react';
import {TextField, IconButton, Alert, CircularProgress, InputAdornment, Typography, Divider, Button} from '@mui/material';
import {VisibilityOff, Visibility} from '@mui/icons-material';
import {useLogin} from '../services/auth';

const LoginPage = () => {
	const [showPass, setShowPass] = useState(false),
		{loading, onProcess, res, data, onChange} = useLogin();

	return (
		<div className='login-container'>
			<form className='form-content' onSubmit={onProcess}>
				<Typography variant='h6' letterSpacing={1}>Login Internal Dashboard</Typography>
				<Divider style={{width: '100%', margin: 10}}/>
				<TextField
					value={data.request}
					onChange={onChange}
					name='request'
					variant='standard'
					className='input-textfield'
					label='Username / Email'
					placeholder='eg: bigdelivery'/>
				<TextField
					value={data.password}
					onChange={onChange}
					name='password'
					className='input-textfield'
					variant='standard'
					label='Password'
					InputProps={{
						endAdornment: (
							<InputAdornment position="start">
								<IconButton onClick={() => setShowPass(prev => !prev)}>
									{!showPass
										?	<Visibility/> : <VisibilityOff/>}
								</IconButton>
							</InputAdornment>
						)
					}}
					placeholder='eg: bigdel'
					type={showPass ? 'text' : 'password'}/>

				<Button type='submit' variant='outlined' color='primary' fullWidth>
					{loading
						?	<CircularProgress size={20}/>
						:	'LOGIN'}
				</Button>

				{res.message
					?	<Alert severity='error' style={{marginTop: 10}}>{res.message}</Alert>
					:	null}
			</form>
		</div>
	)
}

export {LoginPage}