import { useCallback, useContext, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { AuthContext } from "../utils/auth";
import gtw from "../apis/gtw";
import axios from "axios";
import { handleError, useCancelRequest } from "./helpers";
import { convertDataToQuery, getQueryUrl } from "../helpers/custom";

export const getAllDataBagging = async (search, headers) => {
  const { data } = await gtw({
    method: "get",
    url: `/scan/bagging${search}`,
    headers,
  });
  return data.data;
};

export const getSelectDOP = async (headers) => {
  const { data } = await gtw({
    method: "get",
    url: `/scan/select-dop`,
    headers,
  });
  return data.data;
};

export const updateBaggingStatus = async (id, headers) => {
  const { data } = await gtw({
    method: "patch",
    url: `/scan/bagging/${id}`,
    headers,
  });
  return data.data;
};

export const useListDataScan = () => {
  const [loading, setLoading] = useState(false);
  const [request, setRequest] = useState(null);
  const [res, setRes] = useState({ status: "", message: "" });
  const { headers } = useContext(AuthContext);
  const navigate = useNavigate();
  const [data, setData] = useState({ count: 0, data: [] });
  const { search } = useLocation();

  const onProcess = useCallback(async () => {
    setLoading(true);
    setRes({ status: "", message: "" });
    setData((prev) => ({ ...prev, data: [] }));
    const cancel = await (async () => {
      try {
        const _req = axios.CancelToken.source();
        setRequest(_req);
        const { data: _data } = await gtw({
          method: "get",
          url: `/scan${search}`,
          headers,
          cancelToken: _req.token,
        });
        setData(_data);
      } catch (e) {
        const message = handleError(e);
        if (message.cancel) return true;
        setRes({ status: "error", message });
      }
    })();
    if (cancel) return;
    setLoading(false);
  }, [search, headers]);

  const push = (payload) =>
    navigate(`/easy-drop${convertDataToQuery(payload)}`);

  const query = useMemo(() => {
    if (!search) return null;
    return getQueryUrl(search);
  }, [search]);

  useCancelRequest(request);
  return { onProcess, loading, query, res, data, push };
};

export const useListDataLog = () => {
  const [loading, setLoading] = useState(false);
  const [request, setRequest] = useState(null);
  const [res, setRes] = useState({ status: "", message: "" });
  const { headers } = useContext(AuthContext);
  const navigate = useNavigate();
  const [data, setData] = useState({ count: 0, data: [] });
  const { search } = useLocation();

  const onProcess = useCallback(async () => {
    setLoading(true);
    setRes({ status: "", message: "" });
    setData((prev) => ({ ...prev, data: [] }));
    const cancel = await (async () => {
      try {
        const _req = axios.CancelToken.source();
        setRequest(_req);
        const { data: _data } = await gtw({
          method: "get",
          url: `/access/log/dop${search}`,
          headers,
          cancelToken: _req.token,
        });
        setData(_data);
      } catch (e) {
        const message = handleError(e);
        if (message.cancel) return true;
        setRes({ status: "error", message });
      }
    })();
    if (cancel) return;
    setLoading(false);
  }, [search, headers]);

  const push = (payload) =>
    navigate(`/easy-drop/logging${convertDataToQuery(payload)}`);

  const query = useMemo(() => {
    if (!search) return null;
    return getQueryUrl(search);
  }, [search]);

  useCancelRequest(request);
  return { onProcess, loading, query, res, data, push };
};

export const useManipulationData = () => {
  const [loadingM, setLoadingM] = useState(false);
  const { headers } = useContext(AuthContext);
  const [request, setRequest] = useState(null);
  const [res, setRes] = useState({ status: "", message: "" });
  // const navigate = useNavigate();

  const onDelete = useCallback(
    async (id) => {
      setLoadingM(true);
      setRes({ status: "", message: "" });
      const cancel = await (async () => {
        try {
          const _req = axios.CancelToken.source();
          setRequest(_req);
          await gtw({
            method: "delete",
            url: `/scan/${id}`,
            headers,
            cancelToken: _req.token,
          });
          await new Promise((resolve) => setTimeout(resolve, 2000));
        } catch (e) {
          const message = handleError(e);
          if (message.cancel) return true;
          setRes({ status: "error", message });
        }
      })();
      if (cancel) return;
      setLoadingM(false);
      window.location.reload();
    },
    [headers]
  );

  const onUpdate = useCallback(
    async (payload) => {
      setLoadingM(true);
      setRes({ status: "", message: "" });
      const cancel = await (async () => {
        try {
          const _req = axios.CancelToken.source();
          setRequest(_req);
          await gtw({
            method: "put",
            url: `/scan`,
            headers,
            data: payload,
            cancelToken: _req.token,
          });
          await new Promise((resolve) => setTimeout(resolve, 2000));
        } catch (e) {
          const message = handleError(e);
          if (message.cancel) return true;
          setRes({ status: "error", message });
        }
      })();
      if (cancel) return;
      setLoadingM(false);
      window.location.reload();
    },
    [headers]
  );

  useCancelRequest(request);
  return {
    onDelete,
    onUpdate,
    loadingM,
    res,
  };
};

export const uploadData = async (payload) => {
  const { data: _data } = await gtw({
    method: "put",
    url: `/scan/upload`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    data: payload,
  });
  return _data;
};

export const uploadDataJNE = async (payload) => {
  const { data: _data } = await gtw({
    method: "put",
    url: `/scan/upload-jne`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    data: payload,
  });
  return _data;
};

export const downloadData = async ({ search, payload }) => {
  const { data: _data } = await gtw({
    method: "post",
    url: `/scan/download/data${search ?? ""}`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    data: payload,
  });
  return _data.data;
};

export const getTotalDownload = async ({ payload }) => {
  const { data: _data } = await gtw({
    method: "post",
    url: `/scan/download/total`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    data: payload,
  });
  return _data.count;
};

export const getAllDOP = async () => {
  const { data } = await gtw({
    method: "GET",
    url: `/scan/dop`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
  return data;
};
