import {
  Alert,
  Box,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import { getSelectDOP, useListDataLog } from "../../services/scan";
import { useContext, useEffect, useMemo, useState } from "react";
import moment from "moment";
import { TablePaginationActions } from "../../components/spam/pagination";
import { AuthContext } from "../../utils/auth";

export default function LoggingDOPPage() {
  const { loading, onProcess, data, push, query } = useListDataLog();

  const { headers } = useContext(AuthContext);

  const [selectDOP, setSelectDOP] = useState(218);
  const [allDOP, setAllDOP] = useState([]);

  const listData = data.data;

  // console.log(listData);

  const getAllDOPSelection = async () => {
    try {
      const data = await getSelectDOP(headers);
      setAllDOP(data);
    } catch (error) {
      console.log(error.message);
    }
  };

  useMemo(() => {
    getAllDOPSelection();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    onProcess();
  }, [onProcess]);

  return (
    <>
      <Grid item width="100%" justifyContent="space-between" display="flex">
        <Grid
          item
          display="flex"
          sx={{
            width: "60%",
            margin: "0 auto 2rem",
            justifyContent: "center",
          }}
        >
          <FormControl variant="standard" sx={{ m: 1, minWidth: 220 }}>
            <InputLabel id="demo-simple-select-standard-label">
              Nama DOP
            </InputLabel>
            <Select
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              value={selectDOP}
              size="small"
              margin="none"
              onChange={(event) => {
                push({ ...query, page: 0, ShopId: event.target?.value });
                setSelectDOP(event.target?.value);
              }}
              label="Nama DOP"
            >
              <MenuItem value="" disabled>
                <em>Please select DOP</em>
              </MenuItem>
              {allDOP.length > 0 &&
                allDOP.map((item) => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.DOP}
                  </MenuItem>
                ))}
              {/* <MenuItem value={2}>DOP DHI</MenuItem> */}
            </Select>
          </FormControl>
        </Grid>
      </Grid>

      {/* lists table log */}
      <TableContainer
        style={{
          minHeight: "45vh",
          maxHeight: "69vh",
          width: "60%",
          margin: "0 auto",
        }}
      >
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>No.</TableCell>
              <TableCell sx={{ minWidth: 100 }}>Nama DOP</TableCell>
              <TableCell sx={{ minWidth: 100 }}>Nama User</TableCell>
              <TableCell>Aktivitas</TableCell>
              <TableCell>Waktu</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell colSpan={4}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      textAlign: "center",
                    }}
                  >
                    <CircularProgress size={25} />
                  </Box>
                </TableCell>
              </TableRow>
            ) : (
              listData.length > 0 &&
              listData.map((item, index) => (
                <TableRow key={item.id} hover>
                  <TableCell>{index + 1}.</TableCell>
                  <TableCell>{item.User.Shop.shopName}</TableCell>
                  <TableCell>{item.User.username}</TableCell>
                  <TableCell>{item.access.split("/")[1]}</TableCell>
                  <TableCell sx={{ display: "flex", flexDirection: "column" }}>
                    <div>{moment(item.createdAt).format("YYYY-MM-DD")}</div>
                    <div>{moment(item.createdAt).format("HH:mm:ss")}</div>
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
        {listData.length === 0 ? (
          <Alert variant="standard" severity="warning">
            No Data
          </Alert>
        ) : null}
      </TableContainer>

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "end",
          height: "3rem",
          width: "60%",
          margin: "0 auto",
        }}
      >
        <TablePagination
          rowsPerPageOptions={[5, 10, 15, 25]}
          component="div"
          count={data.count}
          rowsPerPage={parseInt(query?.perPage || "10", 10)}
          page={data.count === 0 ? 0 : parseInt(query?.page || "0", 10)}
          onPageChange={(_, newPage) =>
            push(
              query
                ? { ...query, page: newPage }
                : { page: newPage, perPage: "10" }
            )
          }
          onRowsPerPageChange={({ target: { value = "10" } }) =>
            push(
              query
                ? { ...query, page: 0, perPage: parseInt(value, 10) }
                : { page: 0, perPage: parseInt(value, 10) }
            )
          }
          ActionsComponent={TablePaginationActions}
        />
      </Box>
    </>
  );
}
