import {useState, useContext} from 'react';
import x from 'axios';
import gtw from '../apis/gtw';
import {AuthContext} from '../utils/auth';
import {handleError, useCancelRequest} from './helpers';

export function useCreate () {
	const [loading, setLoading] = useState(false),
		[request, setRequest] = useState(null),
		{headers} = useContext(AuthContext),
		onProcess = async function (data, callback = (err, dataPrivileges) => null) {
			setLoading(true);
			const cancel = await (async function () {
				try {
					const _req = x.CancelToken.source();
					setRequest(_req);
					const {data: _data} = await gtw({method: 'post', url: `/root/privileges`, data, headers, cancelToken: _req.token});

					if(_data && _data.id)
						return callback(null, _data);

					callback('server si not responding', null);
				}catch(e) {
					const message = handleError(e);
					if(message.cancel)
						return true;
					callback(message, null);
				}
			})();
			if(cancel)
				return;
			setLoading(false);
		};
	useCancelRequest(request);
	return {onProcess, loading};
}
