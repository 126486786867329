import {useState, useContext, useMemo} from 'react';
import {GroupContext} from '../../../utils/group';
import {AdminInputValue} from '../../../components/root';
import {useCreate} from '../../../services/auth';

export const AdminCreate = () => {
	const {data: dataGroups, updateOne} = useContext(GroupContext),
		[payload, setPayload] = useState({full_name: '', username: '', email: '', password: ''}),
		{loading, onProcess} = useCreate(),
		[res, setRes] = useState({status: '', message: ''}),
		getGroupSelection = useMemo(() => {
			if(dataGroups.length === 0)
				return null;
			const filtering = dataGroups.filter(el => el.isChecked);
			return filtering.length > 0 ? filtering[0] : null;
		}, [dataGroups]),
		onCreateAdmin = () => {
			if(!getGroupSelection)
				return setRes({status: 'error', message: 'please select the group'});

			setRes({status: '', message: ''});
			const dataPayload = {
				...payload,
				group_id: getGroupSelection.id
			};

			onProcess(dataPayload, (err, _) => {
				if(err)
				 	return setRes({status: 'error', message: err});

				setPayload({full_name: '', username: '', email: '', password: ''});
				updateOne(dataPayload.group_id, {key: 'isChecked', value: false});
				setRes({status: 'ok', message: 'create admin successfully'});
			})
		},
		onChange = ({target: {name, value}}) =>
			setPayload(prev => ({...prev, [name]: value}));

	return (
		<AdminInputValue
			res={res}
			loading={loading}
			payload={payload}
			onAction={onCreateAdmin}
			onChange={onChange}/>
	)
}
