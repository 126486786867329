import {
  Alert,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
} from "@mui/material";
import { SearchHead } from "../../components/spam/inputs";
import {
  // AddLinkTwoTone,
  // AttachMoneyOutlined,
  UploadFileOutlined,
  // ViewComfyAltOutlined,
  FindInPage,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useListReportDelivery } from "../../services/delivery";
import { useEffect, useState } from "react";
import { TablePaginationActions } from "../../components/spam/pagination";
import { formatDate } from "../../helpers/custom";

export default function DeliveryPage() {
  const [values, setValues] = useState("");
  const navigate = useNavigate();
  const { loading, onProcess, res, data, push, query } =
    useListReportDelivery();

  console.log(data.data);

  useEffect(() => {
    onProcess();
  }, [onProcess]);

  useEffect(() => {
    if (query && query.search) setValues(query.search);
    else setValues("");
  }, [query]);

  return (
    <>
      <Grid item width="100%" justifyContent="space-between" display="flex">
        <Grid item display="flex">
          <Tooltip title="Upload report secara massal">
            <Button
              startIcon={<UploadFileOutlined />}
              size="small"
              onClick={() =>
                navigate(`/delivery/upload`, {
                  state: { from: true },
                })
              }
              variant="outlined"
              color="success"
            >
              Upload report sprinter
            </Button>
          </Tooltip>
          <Grid />
        </Grid>

        <SearchHead
          values={values}
          placeholder="Search Sprinter here"
          onEnter={(variable) => push({ ...query, page: 0, search: variable })}
        />
      </Grid>

      <TableContainer style={{ minHeight: "50vh", maxHeight: "80vh" }}>
        <Table style={{ width: "100%" }} stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>No</TableCell>
              <TableCell>Tanggal Delivery</TableCell>
              <TableCell>No. Airwaybill</TableCell>
              <TableCell>Sprinter Name</TableCell>
              <TableCell>Tanggal TTD</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.data.length > 0
              ? data.data.map((item, index) => (
                  <TableRow key={index} hover>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>
                      {formatDate(item?.delivery_date) || "-"}
                    </TableCell>
                    <TableCell>{item?.awb || "-"}</TableCell>
                    <TableCell>{item?.sprinter?.name || "-"}</TableCell>
                    <TableCell>
                      {item?.ttds[0]?.ttd_date
                        ? formatDate(item?.ttds[0]?.ttd_date)
                        : "-" || "-"}
                    </TableCell>
                    <TableCell>
                      <IconButton
                        title="View Detail"
                        style={{ marginLeft: 5 }}
                        size="small"
                        color="primary"
                        // onClick={() =>
                        //   navigate(`/seller/${seller.id}`, {
                        //     state: { from: true },
                        //   })
                        // }
                      >
                        <FindInPage />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))
              : null}
          </TableBody>
        </Table>
        {loading ? (
          <div className="center">
            <CircularProgress size={25} />
          </div>
        ) : res.message ? (
          <Alert variant="standard" severity="error">
            {res.message}
          </Alert>
        ) : data.data.length === 0 ? (
          <Alert variant="standard" severity="warning">
            No Data
          </Alert>
        ) : null}
      </TableContainer>

      <TablePagination
        rowsPerPageOptions={[5, 10, 15, 25]}
        component="div"
        count={data.count}
        rowsPerPage={parseInt(query?.perPage || "10", 10)}
        page={data.count === 0 ? 0 : parseInt(query?.page || "0", 10)}
        onPageChange={(_, newPage) =>
          push(
            query
              ? { ...query, page: newPage }
              : { page: newPage, perPage: "10" }
          )
        }
        onRowsPerPageChange={({ target: { value = "10" } }) =>
          push(
            query
              ? { ...query, page: 0, perPage: parseInt(value, 10) }
              : { page: 0, perPage: parseInt(value, 10) }
          )
        }
        ActionsComponent={TablePaginationActions}
      />
    </>
  );
}
