import dayjs from "dayjs";

export const formatDate = (date, format = "YYYY/MM/DD HH:mm:ss") => {
  return dayjs(date).format(format);
};

export const getFirstLastDateAMonth = (date) => {
  if (date) {
    const current = new Date(date);
    const first = new Date(current.getFullYear(), current.getMonth(), 1);
    const last = new Date(current.getFullYear(), current.getMonth() + 1, 0);
    return `Periode : (${dayjs(first).format("YYYY/MM/DD")} - ${dayjs(
      last
    ).format("YYYY/MM/DD")})`;
  }

  const today = new Date();
  const first = new Date(today.getFullYear(), today.getMonth(), 1);
  const last = new Date(today.getFullYear(), today.getMonth() + 1, 0);
  return `${dayjs(first).format("YYYY/MM/DD")} - ${dayjs(last).format(
    "YYYY/MM/DD"
  )}`;
};

export const serialDate = (serial) => {
  const utc_days = Math.floor(serial - 25569);
  const utc_value = utc_days * 86400;
  const date_info = new Date(utc_value * 1000);

  const fractional_day = serial - Math.floor(serial) + 0.0000001;

  let total_seconds = Math.floor(86400 * fractional_day);

  const seconds = total_seconds % 60;

  total_seconds -= seconds;

  const hours = Math.floor(total_seconds / (60 * 60));
  const minutes = Math.floor(total_seconds / 60) % 60;

  return new Date(
    date_info.getFullYear(),
    date_info.getMonth(),
    date_info.getDate(),
    hours,
    minutes,
    seconds
  );
};

export function getQueryUrl(query) {
  try {
    if (/[&?=]/.test(String(query).toLowerCase())) {
      let result = {};
      query
        .split("?")[1]
        .split("&")
        .forEach((el, i) => (result[el.split("=")[0]] = el.split("=")[1]));
      return result;
    }
  } catch (e) {}
  return false;
}

export const convertDate = (date) => {
  const _date = new Date(typeof date === "number" ? date * 1000 : date),
    options = { timeZone: "Asia/Jakarta" };
  return {
    date: _date.toLocaleDateString("en-US", options),
    time: _date.toLocaleTimeString("en-US", options),
  };
};

export const convertPrice = (price, point = false) => {
  const number_string = price.toString(),
    remainder = number_string.length % 3;
  let money = number_string.substr(0, remainder);
  const thousand = number_string.substr(remainder).match(/\d{3}/g);
  if (thousand) {
    const separator = remainder ? "." : "";
    money += separator + thousand.join(".");
  }
  return point ? money : `Rp. ${money}`;
};

export const convertDataToQuery = (data) => {
  if (data) {
    const obj = Object.keys(data);
    let query = ``;
    for (let i = 0; i < obj.length; i++) {
      if (query.length === 0) {
        query += `?${obj[i]}=${data[obj[i]]}`;
        continue;
      }
      query += `&${obj[i]}=${data[obj[i]]}`;
    }

    return query;
  }
  return "";
};

export const convertCurrency = (value) => {
  return new Intl.NumberFormat("id-ID", {
    style: "currency",
    currency: "IDR",
    maximumFractionDigits: 0,
  }).format(value);
};

export const yesterday = (value) => {
  let date = new Date();
  date.setDate(date.getDate() - 1);
  return date.toLocaleDateString().replaceAll("/", "-");
};

export const ThisMonth = () => {
  let today = new Date();

  let month = today.getMonth() + 1;
  let year = today.getFullYear();
  //day -1
  let yesterday = new Date(today);
  yesterday.setDate(today.getDate() - 1);
  let dateNow = yesterday.getDate();
  let pastmonth = yesterday.getMonth() + 1;

  // if(today.getDate())
  let lastdate = new Date(year, month, 0).getDate();
  let obj = {
    startDate: `${month}-01-${year}`,
    endDate: `${month}-${lastdate}-${year}`,
    thisDate: `${pastmonth}-${dateNow}-${year}`,
  };
  return obj;
};
