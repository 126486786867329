// import { useEffect, Fragment, useState, useMemo, useContext } from "react";
import { useEffect, Fragment, useState, useMemo } from "react";

import { useNavigate, useLocation } from "react-router-dom";
import {
  Grid,
  Button,
  List,
  InputLabel,
  ListItem,
  ListItemText,
  Typography,
  Alert,
  TableContainer,
  TextareaAutosize,
  Table,
  ListSubheader,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from "@mui/material";
import {
  ArrowLeftOutlined,
  DoneOutlineOutlined,
  ErrorOutlineOutlined,
  UpdateOutlined,
} from "@mui/icons-material";
import { ItemSellerCashback } from "../cashback";
import {
  useDetailSellerCashback,
  useUpdateDccbSeller,
  useResponDccb,
} from "../../services/cashback";
import { useGetAdminId } from "../../services/auth";
import { BasicModal } from "../../components/spam/modal";
// import { AuthContext } from "../../utils/auth";

export const SellerCashbackDetailPage = () => {
  const { onProcess, data, loading, res, updateState } =
      useDetailSellerCashback(),
    {
      data: _data,
      loading: _loading,
      onProcess: _process,
      res: _res,
    } = useGetAdminId(),
    [firstAmount, setFirstAmount] = useState(true),
    [showUpdate, setShowUpdate] = useState(false),
    // { auth } = useContext(AuthContext),
    navigate = useNavigate(),
    { state = null } = useLocation(),
    onChangeValue =
      (index) =>
      ({ target: { name, value } }) => {
        let _value = value;
        if (!value || isNaN(Number(value))) _value = "";

        updateState({ index, key: name, value: _value });
      },
    isFinal = useMemo(() => {
      // if (!data) return false;
      // return data.status === "pending approval";
      return false;
    }, []),
    // }, [data]),

    possibleEdit = useMemo(() => {
      // if (
      //   _data &&
      //   (_data.group_level < auth.group_level ||
      //     (_data.group_level === auth.group_level &&
      //       data &&
      //       data.status === "pending approval"))
      // )
      //   return false;

      // if (_res.status === "error") return false;
      return true;
    }, []);
  // }, [_data, auth.group_level, _res.status, data]);

  useEffect(() => {
    onProcess();
  }, [onProcess]);

  useEffect(() => {
    if (data && data.internalId && firstAmount) {
      _process(data.internalId);
      setFirstAmount(false);
    }
  }, [data, _process, firstAmount]);

  useEffect(() => {
    document.title = "BD - Detail Cashback";
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Fragment>
      {showUpdate ? (
        !isFinal ? (
          <PrepareCheck
            open={showUpdate}
            close={setShowUpdate}
            sellerId={data?.sellerId || "-1"}
            refresh={onProcess}
            dataAwb={data?.dataAwb || []}
          />
        ) : (
          <FinalCheck
            open={showUpdate}
            close={setShowUpdate}
            requestId={data?.requestId || ""}
            refresh={onProcess}
          />
        )
      ) : null}
      <Grid
        item
        width="100%"
        alignItems="center"
        justifyContent="flex-start"
        display="flex"
      >
        <Button
          startIcon={<ArrowLeftOutlined />}
          onClick={() =>
            navigate(state?.from ? -1 : `/s-cshback?page=0&perPage=10`)
          }
          size="small"
          variant="outlined"
          color="info"
        >
          Go Back
        </Button>

        {!possibleEdit ? (
          <Alert style={{ marginLeft: 25 }} severity="warning">
            Your level may not be able to update data
          </Alert>
        ) : null}
      </Grid>

      {loading ? (
        <div className="center">
          <Typography variant="caption" textAlign="center">
            Loading...
          </Typography>
        </div>
      ) : data ? (
        <Fragment>
          <Grid container display="flex" justifyContent="space-between">
            <List style={{ width: "33%" }}>
              <ListItem>
                <ListItemText
                  primary="Seller Name"
                  secondary={data?.User?.name || "-"}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="Status Cashback"
                  secondary={data?.status || "-"}
                />
              </ListItem>
            </List>
            <List style={{ width: "33%" }}>
              <ListItem>
                <ListItemText
                  primary="Seller Email"
                  secondary={data?.User?.email || "-"}
                />
              </ListItem>
              <ListItem>
                {_loading ? (
                  <Typography variant="caption">Loading...</Typography>
                ) : (
                  <ListItemText
                    secondaryTypographyProps={{
                      color: _res.status === "error" ? "red" : "black",
                    }}
                    primary="PIC Internal"
                    secondary={
                      _data
                        ? `${_data.full_name || "-"} - ${
                            _data.group_name || "-"
                          } - Lvl ${_data.group_level || "-"}`
                        : _res.message
                        ? _res.message
                        : "-"
                    }
                  />
                )}
              </ListItem>
            </List>
            <List
              sx={{
                width: "33%",
                position: "relative",
                overflow: "auto",
                height: 150,
                "& ul": { padding: 0 },
              }}
              disablePadding
              subheader={<li />}
            >
              <li>
                <ul>
                  <ListSubheader>Histories</ListSubheader>
                  {data.histories.length > 0
                    ? data.histories.map((history, _index) => (
                        <HistoryItemList
                          history={history}
                          availableFetchInternal={
                            typeof history === "string"
                              ? false
                              : !history?.internalId
                              ? false
                              : history?.internalId !== _data?.id
                          }
                          key={_index}
                        />
                      ))
                    : null}
                </ul>
              </li>
            </List>
          </Grid>

          <ListItem>
            <Typography variant="h5">Data AWB</Typography>
          </ListItem>

          <TableContainer style={{ minHeight: "35vh", maxHeight: "50vh" }}>
            <Table size="small" style={{ width: "100%" }} stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>No</TableCell>
                  <TableCell>Expedisi Name</TableCell>
                  <TableCell>Expedisi Service</TableCell>
                  <TableCell>Awb Code</TableCell>
                  <TableCell>Note</TableCell>
                  <TableCell>Fix</TableCell>
                  <TableCell>Rate</TableCell>
                  {/* 
                  {!isFinal && data.status !== "approved" ? (
                    <TableCell>Action</TableCell>
                  ) : null} */}
                </TableRow>
              </TableHead>
              <TableBody>
                {(isFinal
                  ? data &&
                    data.temp &&
                    typeof data.temp !== "string" &&
                    data.temp.length
                    ? data.temp
                    : []
                  : data.dataAwb
                ).length > 0
                  ? (isFinal
                      ? data &&
                        data.temp &&
                        typeof data.temp !== "string" &&
                        data.temp.length
                        ? data.temp
                        : []
                      : data.dataAwb
                    ).map((item, index) => (
                      <ItemSellerCashback
                        onChange={onChangeValue}
                        key={index}
                        index={index}
                        item={item}
                        readOnly={isFinal}
                        // actionComponent={
                        //   <TableCell>
                        //     <IconButton
                        //       title="Remove Cashback"
                        //       variant="standard"
                        //       onClick={() => deleteOneState(index)}
                        //     >
                        //       <DeleteOutlined color="error" />
                        //     </IconButton>
                        //   </TableCell>
                        //   // !isFinal && data.status !== "approved" ? (
                        //   //   <TableCell>
                        //   //     <IconButton
                        //   //       title="Remove Cashback"
                        //   //       variant="standard"
                        //   //       onClick={() => deleteOneState(index)}
                        //   //     >
                        //   //       <DeleteOutlined color="error" />
                        //   //     </IconButton>
                        //   //   </TableCell>
                        //   // ) : undefined
                        // }
                      />
                    ))
                  : null}
              </TableBody>
            </Table>
            {(isFinal
              ? data &&
                data.temp &&
                typeof data.temp !== "string" &&
                data.temp.length
                ? data.temp
                : []
              : data.dataAwb
            ).length === 0 ? (
              <Alert severity="warning">No Data</Alert>
            ) : null}
          </TableContainer>

          <Grid
            margin={2}
            container
            display="flex"
            flexDirection="column"
            alignItems="flex-end"
          >
            {/* {data.status === "approved" ? (
              <Button
                disabled
                startIcon={<DoneOutlineOutlined />}
                onClick={() => null}
                title="All Done"
              >
                All Done
              </Button>
            ) : (
              <Button
                startIcon={<UpdateOutlined />}
                onClick={() => setShowUpdate(true)}
                title="Update Data"
              >
                Update
              </Button>
            )} */}
            <Button
              startIcon={<UpdateOutlined />}
              onClick={() => setShowUpdate(true)}
              title="Update Data"
            >
              Update
            </Button>
          </Grid>
        </Fragment>
      ) : res.message ? (
        <ListItem>
          <Alert severity="error">{res.message}</Alert>
        </ListItem>
      ) : (
        <Alert severity="error">No Data</Alert>
      )}
    </Fragment>
  );
};

const HistoryItemList = ({ history, availableFetchInternal = true }) => {
  const { data, loading, onProcess } = useGetAdminId();

  useEffect(() => {
    if (
      history &&
      typeof history !== "string" &&
      history?.internalId &&
      availableFetchInternal
    )
      onProcess(history.internalId);
  }, [onProcess, availableFetchInternal, history]);

  return (
    <ListItem>
      {loading ? (
        <ListItemText primary="Loading..." />
      ) : typeof history === "string" ? (
        <ListItemText primary={history} />
      ) : (
        <Fragment>
          <ListItemText
            primary={history.time || "-"}
            secondary={`${history.status || "-"} - ${
              history.reason || "-"
            } - by ${data?.full_name || "-"} - level ${
              data?.group_level || "-"
            }`}
          />
        </Fragment>
      )}
    </ListItem>
  );
};

const FinalCheck = ({ open, close, requestId, refresh = () => null }) => {
  const { res, loading, onProcess } = useResponDccb(),
    [reason, setReason] = useState("");

  return (
    <BasicModal title="Are you sure ?" open={open} onClose={close}>
      <Grid
        container
        spacing={3}
        style={{ width: 450, maxWidth: 500 }}
        display="flex"
        flexDirection="column"
      >
        <Grid item>
          <InputLabel>Note / Reason</InputLabel>
          <TextareaAutosize
            placeholder="eg: no tidak aktif"
            value={reason}
            onChange={({ target: { value } }) => setReason(value)}
            style={{ width: "100%", resize: "none", outline: "none" }}
            minRows={3}
            maxRows={8}
          />
        </Grid>

        {loading ? (
          <div className="center">
            <Typography variant="caption">Loading...</Typography>
          </div>
        ) : (
          <Grid display="flex" justifyContent="space-between" item>
            <Button
              variant="outlined"
              size="small"
              color="info"
              onClick={() => close(false)}
            >
              Cancel
            </Button>
            <Button
              variant="outlined"
              size="small"
              color="error"
              endIcon={<ErrorOutlineOutlined />}
              onClick={() => onProcess({ accept: false, reason, requestId })}
            >
              Reject
            </Button>
            <Button
              variant="outlined"
              size="small"
              color="success"
              endIcon={<DoneOutlineOutlined />}
              onClick={() =>
                onProcess({ accept: true, reason, requestId }, () => {
                  refresh();
                  close(false);
                })
              }
            >
              Approve
            </Button>
          </Grid>
        )}

        {res.message ? (
          <Grid item>
            <Alert severity={res.status === "ok" ? "success" : "error"}>
              {res.message}
            </Alert>
          </Grid>
        ) : null}
      </Grid>
    </BasicModal>
  );
};

const PrepareCheck = ({
  open,
  dataAwb,
  close = () => null,
  sellerId,
  refresh = () => null,
}) => {
  const { onProcess, loading, res } = useUpdateDccbSeller();

  return (
    <BasicModal title="Please check before update" open={open} onClose={close}>
      <TableContainer
        style={{ minHeight: "35vh", maxHeight: "50vh", width: 1300 }}
      >
        <Table size="small" style={{ width: "100%" }} stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>No</TableCell>
              <TableCell>Expedisi Name</TableCell>

              <TableCell>Expedisi Service</TableCell>
              <TableCell>Awb Code</TableCell>
              <TableCell>Note</TableCell>
              <TableCell>Fix</TableCell>
              <TableCell>Rate</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dataAwb.length > 0
              ? dataAwb.map((item, index) => (
                  <ItemSellerCashback
                    key={index}
                    index={index}
                    item={item}
                    readOnly
                  />
                ))
              : null}
          </TableBody>
        </Table>
      </TableContainer>

      <Grid
        container
        display="flex"
        flexDirection="column"
        alignItems="flex-end"
      >
        <Grid item display="flex" margin={3}>
          {loading ? (
            <Typography variant="caption">Loading...</Typography>
          ) : (
            <Fragment>
              <Button
                title="Cancel"
                variant="outlined"
                size="small"
                color="error"
                onClick={() => close(false)}
                style={{ marginRight: 10 }}
              >
                Cancel
              </Button>

              <Button
                title="Update"
                variant="outlined"
                size="small"
                color="primary"
                onClick={() =>
                  onProcess(
                    sellerId,
                    dataAwb.map((el) => ({
                      ...el,
                      target: parseFloat(el.target || "0"),
                      Fix: parseFloat(el.Fix || "0"),
                      Rate: parseFloat(el.Rate || "0"),
                    })),
                    () => {
                      refresh();
                      close(false);
                    }
                  )
                }
              >
                Process Data
              </Button>
            </Fragment>
          )}
        </Grid>

        {res.message ? (
          <Alert severity={res.status === "ok" ? "success" : "error"}>
            {res.message}
          </Alert>
        ) : null}
      </Grid>
    </BasicModal>
  );
};
