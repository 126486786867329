import { useState, Fragment, useEffect } from "react";
import { useSearchParams } from "react-router-dom";

import {
  Box,
  Button,
  Grid,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Badge,
  Chip,
  Divider,
  IconButton,
  TextField,
  Modal,
  Typography,
  Card,
  CardContent,
  TablePagination,
  Zoom,
  Pagination,
  Stack,
  CircularProgress,
  Alert,
} from "@mui/material";
import {
  EditOutlined,
  DeleteOutlined,
  Clear,
  Done,
  HistoryEduOutlined,
  Close,
  Login,
  RemoveCircleOutlineOutlined,
} from "@mui/icons-material";
import { SearchHead } from "../../components/spam/inputs";
import { RangeDateTimePicker } from "../../components/spam/dateTime";
import { useComparation } from "../../services/financial";
import { TablePaginationActions } from "../../components/spam/pagination";
// import { useListMonitoring } from "../../services/transactions";

export const FinancialComparationPage = () => {
  const [showDateFilter, setShowDateFilter] = useState(false);
  const [statusCompare, setStatusCompare] = useState("sudah_compare");
  const [isEdit, setIsEdit] = useState(-1);
  const [updateById, setUpdateById] = useState({});
  const [page, setPage] = useState(1);
  const [dateView, setDateView] = useState({ minDate: "", maxDate: "" });
  const [searchParams, setSearchParams] = useSearchParams();
  const params = Object.fromEntries([...searchParams]);
  // const [openModal, setOpenModal] = useState(false);
  // const [editList, setEditList] = useState([]);

  // const [berat, setBerat] = useState(1);
  const [data, setData] = useState([]);
  // const [tempDataEdit, setTempDataEdit] = useState([]);
  const [addData, setAddData] = useState([
    {
      "NAMA SELLER": "",
      "Reference Number": "",
      "Shipper Name": "",
      "Reciver Name": "",
      "Reciver City": "",
      "Reciver Phone": "",
      "Goods Name / Desc": "",
      Weigth: "",
      threePl: "",
      Ammount: "",
      Airwaybill: "",
    },
  ]);
  const {
    downloadTemplate,
    fileHandler,
    getList,
    dataCompare,
    listData,
    query,
    pushCompare,
    openModal,
    setOpenModal,
    editValueCompare,
    sellerListError,
    setOpenModalError,
    openModalError,
    openModal2,
    setOpenModal2,
    totalPage,
    loading,
    setDataCompare,
    loadingCompare,
    deleteOrder,
  } = useComparation();

  const newObj = {
    "NAMA SELLER": "",
    "Reference Number": "",
    "Shipper Name": "",
    "Reciver Name": "",
    "Reciver City": "",
    "Reciver Phone": "",
    "Goods Name / Desc": "",
    Weigth: "",
    threePl: "",
    Ammount: "",
    Service: "",
    Airwaybill: "",
  };

  const addValueCreate = (value, type, i) => {
    let temp = dataCompare.create;
    temp[i][type] = value;
    setAddData(temp);
  };

  useEffect(() => {
    getList();
    setStatusCompare(params.status);
    if (params.minDate && params.maxDate) {
      setDateView({ minDate: params.minDate, maxDate: params.maxDate });
    }
  }, [query]);

  // useEffect(() => {
  // const newDataUpdate = _data.update.map((d) => {
  //   return { id: d.id, ongkir: d.newOngkir };
  // });
  // setTempDataEdit(newDataUpdate);
  // }, [dataCompare]);

  return (
    <Fragment>
      <Modal
        open={openModalError}
        sx={{
          width: "100%",
          justifyContent: "center",
          display: "flex",
          height: "70%",
          mt: 10,
        }}
      >
        <Grid>
          <Grid
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              backgroundColor: "white",
              height: 50,
            }}
          >
            <Button
              size="small"
              onClick={() => {
                setOpenModalError(false);
              }}
            >
              <Close />
            </Button>
          </Grid>
          <Card
            sx={{
              width: 300,
              overflow: "auto",
              height: "100%",
              borderRadius: 0,
            }}
          >
            <CardContent>
              {sellerListError.length
                ? sellerListError.map((e, i) => (
                    <Typography variant="body2" sx={{ mb: 5 }} key={i}>{`${
                      i + 1
                    }. seller ${e} tidak terdaftar`}</Typography>
                  ))
                : null}
            </CardContent>
          </Card>
        </Grid>
      </Modal>
      <Modal
        open={openModal}
        sx={{
          width: "100%",
          justifyContent: "center",
          display: "flex",
          height: "80%",
          mt: 5,
        }}
      >
        <Zoom in={openModal}>
          <Grid>
            <Grid
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                backgroundColor: "white",
                height: 50,
              }}
            >
              <Button
                size="small"
                onClick={() => {
                  setOpenModal(false);
                }}
              >
                <Close />
              </Button>
            </Grid>
            <Card
              sx={{
                width: 600,
                overflow: "auto",
                height: "100%",
                borderRadius: 0,
              }}
            >
              <CardContent sx={{ height: "100%" }}>
                {!loadingCompare ? (
                  <>
                    <Grid sx={{ height: "80%" }}>
                      <Grid sx={{ height: "90%" }}>
                        <Typography
                          sx={{
                            mb: 2,
                            fontWeight: "bold",
                            textAlign: "center",
                          }}
                        >
                          Data Selisih
                        </Typography>
                        {dataCompare.update.length ? (
                          <TableContainer style={{ maxHeight: "80%" }}>
                            <Table
                              size="small"
                              style={{ width: "100%" }}
                              stickyHeader
                            >
                              <TableHead>
                                <TableRow>
                                  <TableCell>Awb</TableCell>
                                  <TableCell align="center">
                                    Ongkir Lama
                                  </TableCell>
                                  <TableCell align="center">
                                    Ongkir Baru
                                  </TableCell>
                                  <TableCell align="center">
                                    Ongkir yang dipakai
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {dataCompare.update
                                  .slice((page - 1) * 100, 100 * page)
                                  .map((data, i) => (
                                    <TableRow key={i}>
                                      <TableCell>{data.awb}</TableCell>
                                      <TableCell align="center">
                                        <Typography>
                                          {data.ongkirDatabase}
                                        </Typography>
                                      </TableCell>
                                      <TableCell align="center">
                                        <Typography>
                                          {data.ongkir3PL}
                                        </Typography>
                                      </TableCell>
                                      <TableCell
                                        align="center"
                                        // sx={{ backgroundColor: "#e3ffd6" }}
                                        // sx={{ backgroundColor: "#ebebeb" }}
                                      >
                                        <TextField
                                          defaultValue={data.newOngkir}
                                          key={data.newOngkir}
                                          variant="outlined"
                                          onChange={(e) => {
                                            let temp = dataCompare;
                                            let index = 0;
                                            if (page === 1) {
                                              index = i;
                                            } else {
                                              index = (page - 1) * 100 + i;
                                            }

                                            temp.update[index].newOngkir =
                                              e.target.value;

                                            setDataCompare(temp);
                                          }}
                                          sx={{ width: 100 }}
                                          inputProps={{
                                            sx: { textAlign: "center" },
                                          }}
                                        />
                                        {/* {errorValue[i] ? (
                                      <Typography>{errorValue[i]}</Typography>
                                    ) : null} */}
                                      </TableCell>
                                    </TableRow>
                                  ))}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        ) : null}
                        <Grid
                          sx={{
                            mt: 3,
                          }}
                        >
                          <Divider />

                          {+totalPage > 1 ? (
                            <Grid
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alightItem: "center",
                                width: "100%",
                              }}
                            >
                              <Stack spacing={2} sty>
                                <Pagination
                                  count={+totalPage}
                                  color="primary"
                                  onChange={(e, v) => {
                                    setPage(v);
                                  }}
                                />
                              </Stack>
                            </Grid>
                          ) : null}
                        </Grid>

                        <Divider />
                      </Grid>

                      {dataCompare.create.length ? (
                        <Grid
                          sx={{
                            height: "10%",
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            flexDirection: "column",
                            mt: 1,
                          }}
                        >
                          {/* <Typography
                        sx={{ mb: 2, fontWeight: "bold", textAlign: "center" }}
                      >
                        Data Baru dari ThreePL
                      </Typography> */}
                          <Button
                            variant="outlined"
                            color="warning"
                            sx={{ textAlign: "center", mt: 1 }}
                            onClick={() => setOpenModal2(true)}
                          >
                            ada {dataCompare.create.length} data baru Lihat
                            Detail
                          </Button>
                        </Grid>
                      ) : null}
                    </Grid>
                    <Grid
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        px: 10,
                        height: "20%",
                      }}
                    >
                      <Button
                        variant="outlined"
                        color="error"
                        onClick={() => setOpenModal(false)}
                      >
                        Cancel
                      </Button>
                      <Button
                        variant="outlined"
                        color="success"
                        onClick={() =>
                          editValueCompare({
                            update: dataCompare.update,
                            create: [],
                            updateById: [],
                          })
                        }
                      >
                        Simpan
                      </Button>
                    </Grid>
                  </>
                ) : (
                  <div className="center">
                    <CircularProgress size={25} />
                  </div>
                )}
              </CardContent>
            </Card>
          </Grid>
        </Zoom>
      </Modal>

      <Modal
        open={openModal2}
        sx={{
          width: "100%",
          justifyContent: "center",
          display: "flex",
          height: "80%",
          mt: 5,
        }}
      >
        <Zoom in={openModal2}>
          <Grid>
            <Grid
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                backgroundColor: "white",
                height: 50,
              }}
            >
              <Button
                size="small"
                onClick={() => {
                  setOpenModal2(false);
                }}
              >
                <Close />
              </Button>
            </Grid>
            <Card
              sx={{
                width: "90vw",
                overflow: "auto",
                height: "100%",
                borderRadius: 0,
              }}
            >
              <CardContent sx={{ height: "100%" }}>
                <Grid sx={{ height: "80%" }}>
                  <Typography
                    sx={{ mb: 2, fontWeight: "bold", textAlign: "center" }}
                  >
                    Data Baru yang ingin Ditambahkan
                  </Typography>
                  <TableContainer style={{ maxHeight: "90%" }}>
                    <Table size="small" style={{ width: "100%" }} stickyHeader>
                      <TableHead>
                        <TableRow>
                          <TableCell>NAMA SELLER</TableCell>
                          <TableCell align="center">Reference Number</TableCell>
                          <TableCell align="center">Shipper Name</TableCell>
                          <TableCell align="center">Reciver Name</TableCell>
                          <TableCell align="center">Reciver City</TableCell>
                          <TableCell align="center">Reciver Phone</TableCell>
                          <TableCell align="center">
                            Goods Name / Desc
                          </TableCell>
                          <TableCell align="center">Weigth</TableCell>
                          <TableCell align="center">Service</TableCell>

                          <TableCell align="center">threePl</TableCell>
                          <TableCell align="center">Ammount</TableCell>
                          <TableCell align="center">Airwaybill</TableCell>
                          <TableCell align="center">Action</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {dataCompare.create.map((e, index) => (
                          <TableRow key={index}>
                            <TableCell>
                              <TextField
                                // value={ongkir}
                                variant="outlined"
                                onChange={(e) => {
                                  // let temp = createList;

                                  // temp[i];
                                  addValueCreate(
                                    e.target.value,
                                    "NAMA SELLER",
                                    index
                                  );
                                }}
                                sx={{ width: 200 }}
                              />
                            </TableCell>
                            <TableCell align="center">
                              <TextField
                                // value={ongkir}
                                variant="outlined"
                                onChange={(e) =>
                                  addValueCreate(
                                    e.target.value,
                                    "Reference Number",
                                    index
                                  )
                                }
                                sx={{ width: 200 }}
                              />
                            </TableCell>
                            <TableCell align="center">
                              <TextField
                                // value={ongkir}
                                variant="outlined"
                                onChange={(e) =>
                                  addValueCreate(
                                    e.target.value,
                                    "Shipper Name",
                                    index
                                  )
                                }
                                sx={{ width: 200 }}
                              />
                            </TableCell>
                            <TableCell align="center">
                              <TextField
                                // value={ongkir}
                                variant="outlined"
                                onChange={(e) =>
                                  addValueCreate(
                                    e.target.value,
                                    "Reciver Name",
                                    index
                                  )
                                }
                                sx={{ width: 200 }}
                              />
                            </TableCell>
                            <TableCell align="center">
                              <TextField
                                // value={ongkir}
                                variant="outlined"
                                onChange={(e) =>
                                  addValueCreate(
                                    e.target.value,
                                    "Reciver City",
                                    index
                                  )
                                }
                                sx={{ width: 200 }}
                              />
                            </TableCell>
                            <TableCell align="center">
                              <TextField
                                // value={ongkir}
                                variant="outlined"
                                onChange={(e) =>
                                  addValueCreate(
                                    e.target.value,
                                    "Reciver Phone",
                                    index
                                  )
                                }
                                sx={{ width: 200 }}
                              />
                            </TableCell>
                            <TableCell align="center">
                              <TextField
                                // value={e.Airwaybill}
                                variant="outlined"
                                onChange={(e) =>
                                  addValueCreate(
                                    e.target.value,
                                    "Goods Name / Desc",
                                    index
                                  )
                                }
                                sx={{ width: 200 }}
                              />
                            </TableCell>
                            <TableCell align="center">
                              <TextField
                                // value={ongkir}
                                variant="outlined"
                                onChange={(e) =>
                                  addValueCreate(
                                    e.target.value,
                                    "Weigth",
                                    index
                                  )
                                }
                                sx={{ width: 200 }}
                              />
                            </TableCell>
                            <TableCell align="center">
                              <TextField
                                // value={ongkir}
                                variant="outlined"
                                onChange={(e) =>
                                  addValueCreate(
                                    e.target.value,
                                    "Service",
                                    index
                                  )
                                }
                                sx={{ width: 200 }}
                              />
                            </TableCell>

                            <TableCell align="center">
                              <TextField
                                // value={ongkir}
                                variant="outlined"
                                onChange={(e) =>
                                  addValueCreate(
                                    e.target.value,
                                    "threePl",
                                    index
                                  )
                                }
                                sx={{ width: 200 }}
                              />
                            </TableCell>
                            <TableCell align="center">
                              <TextField
                                value={e.Ammount}
                                variant="outlined"
                                onChange={(e) =>
                                  addValueCreate(
                                    e.target.value,
                                    "Ammount",
                                    index
                                  )
                                }
                                sx={{ width: 200 }}
                              />
                            </TableCell>
                            <TableCell align="center">
                              <TextField
                                value={e.Airwaybill}
                                variant="outlined"
                                // onChange={(e) =>
                                //   addValueCreate(
                                //     e.target.value,
                                //     "Airwaybill",
                                //     index
                                //   )
                                // }
                                sx={{ width: 200 }}
                              />
                            </TableCell>

                            <TableCell align="center">
                              <IconButton
                                color="error"
                                onClick={() => setIsEdit(-1)}
                              >
                                <Clear />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <Button
                    variant="outlined"
                    color="success"
                    sx={{ width: "100%", mt: 2 }}
                    onClick={() =>
                      setAddData((current) => [...current, newObj])
                    }
                  >
                    +
                  </Button>
                </Grid>

                <Grid
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    px: 10,
                    height: "20%",
                  }}
                >
                  <Button
                    variant="outlined"
                    color="error"
                    onClick={() => setOpenModal2(false)}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="outlined"
                    color="success"
                    onClick={() =>
                      editValueCompare({
                        create: addData,
                        update: [],
                        updateById: [],
                      })
                    }
                  >
                    Simpan
                  </Button>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Zoom>
      </Modal>

      <Grid
        item
        width="100%"
        justifyContent="space-between"
        display="flex"
        flexDirection="column"
      >
        <Box
          sx={{
            mb: 2,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box>
            {showDateFilter ? (
              <RangeDateTimePicker
                onFilter={({ startDate, endDate }) =>
                  (_) => {
                    pushCompare({
                      ...query,
                      page: 1,
                      minDate: startDate,
                      maxDate: endDate,
                    });
                    setShowDateFilter(false);
                  }}
                open
                close={setShowDateFilter}
              />
            ) : null}

            {dateView.minDate && dateView.maxDate ? (
              <Button
                size="small"
                endIcon={<RemoveCircleOutlineOutlined />}
                variant="outlined"
                sx={{ mr: 2 }}
                color="error"
                onClick={() => {
                  delete query.minDate;
                  delete query.maxDate;
                  setDateView({ minDate: "", maxDate: "" });
                  pushCompare({
                    ...query,
                    page: 1,
                  });
                }}
                title="Remove Filter Date"
              >
                {new Date(dateView.minDate).toLocaleDateString("en-US", {
                  timeZone: "Asia/Jakarta",
                })}{" "}
                -{" "}
                {new Date(dateView.maxDate).toLocaleDateString("en-US", {
                  timeZone: "Asia/Jakarta",
                })}
              </Button>
            ) : (
              <Button
                size="small"
                variant="outlined"
                sx={{ mr: 2 }}
                onClick={() => setShowDateFilter(true)}
                title="Filter date"
              >
                Choose Date Range
              </Button>
            )}

            <Button
              component="label"
              // startIcon={<Update />}
              variant="outlined"
              sx={{ mr: 2 }}
              color="success"
              size="small"
            >
              Upload Comparation File
              <input type="file" hidden onChange={fileHandler} />
            </Button>
            <Button
              component="label"
              // startIcon={<Update />}
              variant="outlined"
              size="small"
              onClick={() => downloadTemplate()}
              // onClick={() => setOpenModal(true)}
            >
              Download Template
            </Button>
          </Box>
          <Box>
            <SearchHead
              values={params.search}
              placeholder="Search seller / awb"
              onEnter={(variable) => {
                if (variable) {
                  pushCompare({
                    ...query,
                    page: 1,
                    search: variable,
                  });
                } else {
                  delete query.minDate;
                  delete query.maxDate;
                  delete query.search;

                  pushCompare({
                    ...query,
                    page: 1,
                  });
                }
              }}
            />
          </Box>
        </Box>
        <Divider sx={{ ml: -3 }} />
        <Box sx={{ mb: 3, mt: 2 }}>
          <Chip
            label="Sudah di Compare"
            variant={
              statusCompare === "sudah_compare" ? "contained" : "outlined"
            }
            color="success"
            onClick={() => {
              setStatusCompare("sudah_compare");
              pushCompare({ ...query, status: "sudah_compare" });
            }}
          />
          <Chip
            label="Belum di Compare"
            variant={
              statusCompare === "belum_compare" ? "contained" : "outlined"
            }
            color="primary"
            onClick={() => {
              setStatusCompare("belum_compare");
              pushCompare({ ...query, status: "belum_compare" });
            }}
            sx={{ ml: 2 }}
          />
        </Box>
      </Grid>
      <TableContainer style={{ minHeight: "50vh", maxHeight: "80vh" }}>
        <Table size="small" style={{ width: "100%" }} stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>No</TableCell>
              <TableCell>Shipping Date</TableCell>
              <TableCell>Expedisi Name</TableCell>
              <TableCell>Expedisi Service</TableCell>
              <TableCell>Awb</TableCell>
              <TableCell>Store Name</TableCell>
              <TableCell align="center">Shipping Fee</TableCell>
              <TableCell align="center">Weight</TableCell>
              <TableCell>Status Compare</TableCell>
              <TableCell align="center">Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {listData.data.length
              ? listData.data.map((order, index) => (
                  <TableRow
                    key={index}
                    hover
                    sx={{
                      backgroundColor: isEdit === index ? "#f5f5f5" : "#ffffff",
                    }}
                  >
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{order?.tanggal_pengiriman || "-"}</TableCell>
                    <TableCell>{order?.three_PL || "-"}</TableCell>
                    <TableCell>{order?.service || "-"}</TableCell>
                    <TableCell>{order?.awb || "-"}</TableCell>
                    <TableCell>{order?.nama_pengirim || "-"}</TableCell>
                    <TableCell align="center">
                      {isEdit === index ? (
                        <TextField
                          value={updateById.ongkir}
                          variant="standard"
                          onChange={(e) =>
                            setUpdateById({
                              id: order.id,
                              ongkir: e.target.value,
                            })
                          }
                          sx={{ width: 100 }}
                          inputProps={{ sx: { textAlign: "center" } }}
                        />
                      ) : (
                        order?.ongkir || "-"
                      )}
                    </TableCell>
                    <TableCell align="center">
                      {/* {isEdit === index ? (
                        <TextField
                          value={berat}
                          variant="standard"
                          onChange={(e) => setBerat(e.target.value)}
                          sx={{ width: 50 }}
                          inputProps={{ sx: { textAlign: "center" } }}
                        />
                      ) : (
                        order?.berat || "-"
                      )} */}
                      {order?.berat || "-"}
                    </TableCell>
                    <TableCell>
                      {order?.status_komparasi
                        ? "sudah dicompare"
                        : "belum dicompare" || "-"}
                    </TableCell>
                    <TableCell align="center">
                      {isEdit === index ? (
                        <>
                          <IconButton
                            color="success"
                            onClick={() => {
                              editValueCompare({
                                updateById: [updateById],
                                update: [],
                                create: [],
                              });
                              setIsEdit(-1);
                            }}
                          >
                            <Done />
                          </IconButton>
                          <IconButton
                            color="error"
                            onClick={() => setIsEdit(-1)}
                          >
                            <Clear />
                          </IconButton>
                          <IconButton
                            color="error"
                            onClick={() => {
                              deleteOrder(order.id);
                              setIsEdit(-1);
                            }}
                          >
                            <DeleteOutlined />
                          </IconButton>
                        </>
                      ) : (
                        <>
                          <IconButton
                            onClick={() => {
                              setIsEdit(index);
                              if (order.ongkir) {
                                setUpdateById({
                                  id: order.id,
                                  ongkir: order.ongkir,
                                });
                              }
                              // if (order.berat) {
                              //   setBerat(order.berat);
                              // }
                            }}
                          >
                            <EditOutlined />
                          </IconButton>
                          <IconButton color="primary">
                            <HistoryEduOutlined />
                          </IconButton>
                        </>
                      )}
                    </TableCell>
                  </TableRow>
                ))
              : null}
          </TableBody>
        </Table>
        {loading ? (
          <div className="center">
            <CircularProgress size={25} />
          </div>
        ) : listData.data.length < 1 ? (
          <Alert variant="standard" severity="warning">
            No Data
          </Alert>
        ) : null}
      </TableContainer>

      <TablePagination
        rowsPerPageOptions={[5, 10, 15, 25, 50, 100, 200, 500]}
        component="div"
        count={listData.count}
        rowsPerPage={parseInt(query?.perPage || "10", 10)}
        page={data.count === 0 ? 0 : parseInt(+query?.page - 1 || "0", 10)}
        onPageChange={(_, newPage) =>
          pushCompare(
            query
              ? { ...query, page: +newPage + 1 }
              : { page: +newPage + 1, perPage: "10" }
          )
        }
        onRowsPerPageChange={({ target: { value = "10" } }) =>
          pushCompare(
            query
              ? { ...query, page: 0, perPage: parseInt(value, 10) }
              : { page: 0, perPage: parseInt(value, 10) }
          )
        }
        ActionsComponent={TablePaginationActions}
      />
    </Fragment>
  );
};
