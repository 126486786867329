import { useState, useContext, useCallback, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import x from "axios";
import gtw from "../apis/gtw";
import { AuthContext } from "../utils/auth";
import { RequestContext } from "../utils/requests";
import { handleError, useCancelRequest } from "./helpers";
import { getQueryUrl, convertDataToQuery } from "../helpers/custom";
import { exportFileExcelNew } from "../helpers/excel";
import { decompress } from "compress-json";

// export const useList = () => {
//   const [loading, setLoading] = useState(false),
//     [request, setRequest] = useState(null),
//     [res, setRes] = useState({ status: "", message: "" }),
//     navigate = useNavigate(),
//     [lastId, setLastId] = useState(0),
//     [nextPage, setNextPage] = useState(true),
//     { headers } = useContext(AuthContext),
//     [data, setData] = useState({ count: 0, data: [] }),
//     { search } = useLocation(),
//     onProcess = useCallback(async () => {
//       setLoading(true);
//       setRes({ status: "", message: "" });
//       setData((prev) => ({ ...prev, count: 0, data: [] }));
//       const cancel = await (async () => {
//         try {
//           const _req = x.CancelToken.source();
//           setRequest(_req);
//           const { data: _data } = await gtw({
//             method: "get",
//             url: `/transactions${search}${lastId ? `&lastId=${lastId}` : ""}${
//               nextPage ? `&nextPage=${nextPage}` : ""
//             }`,
//             headers,
//             cancelToken: _req.token,
//           });

//           // setLastId(_data.data[+query.perPage - 1].id);
//           setData(_data);
//         } catch (e) {
//           const message = handleError(e);
//           if (message.cancel) return true;
//           setRes({ status: "error", message });
//         }
//       })();
//       if (cancel) return;
//       setLoading(false);
//     }, [headers, search]),
//     push = (payload) => navigate(`/transactions${convertDataToQuery(payload)}`),
//     query = useMemo(() => {
//       if (!search) return null;
//       return getQueryUrl(search);
//     }, [search]);

//   useCancelRequest(request);
//   return { onProcess, loading, query, res, data, push, setNextPage };
// };

export const useList = () => {
  const [loading, setLoading] = useState(false),
    [request, setRequest] = useState(null),
    [res, setRes] = useState({ status: "", message: "" }),
    navigate = useNavigate(),
    { headers } = useContext(AuthContext),
    [data, setData] = useState({ count: 0, data: [] }),
    { search } = useLocation(),
    onProcess = useCallback(async () => {
      setLoading(true);
      setRes({ status: "", message: "" });
      setData((prev) => ({ ...prev, count: 0, data: [] }));
      const cancel = await (async () => {
        try {
          const _req = x.CancelToken.source();
          setRequest(_req);
          const { data: _data } = await gtw({
            method: "get",
            url: `/transactions${search}`,
            headers,
            cancelToken: _req.token,
          });
          setData(_data);
        } catch (e) {
          const message = handleError(e);
          if (message.cancel) return true;
          setRes({ status: "error", message });
        }
      })();
      if (cancel) return;
      setLoading(false);
    }, [headers, search]),
    push = (payload) => navigate(`/transactions${convertDataToQuery(payload)}`),
    query = useMemo(() => {
      if (!search) return null;
      return getQueryUrl(search);
    }, [search]);

  useCancelRequest(request);
  return { onProcess, loading, query, res, data, push };
};

export const useCountReport = () => {
  const [request, setRequest] = useState(null),
    { headers } = useContext(AuthContext),
    onProcess = (query = {}) => {
      return new Promise(async (resolve) => {
        try {
          const _req = x.CancelToken.source();
          setRequest(_req);
          const { data } = await gtw({
            method: "get",
            url: `/transactions${convertDataToQuery(query)}`,
            headers,
            cancelToken: _req.token,
          });
          if (data && data.count) return resolve(data.count);
          resolve(0);
        } catch (e) {
          resolve(0);
        }
      });
    };
  useCancelRequest(request);
  return { onProcess };
};

export const useTransactionUpload = () => {
  const { headers } = useContext(AuthContext),
    [request, setRequest] = useState(null),
    onProcess = (data) => {
      return new Promise((resolve, reject) => {
        setTimeout(async () => {
          try {
            const _req = x.CancelToken.source();
            setRequest(_req);
            const { data: _data } = await gtw({
              method: "post",
              url: `/transactions/upload`,
              data,
              headers,
              cancelToken: _req.token,
            });

            if (_data) return resolve(_data);

            reject("server is not responding");
          } catch (e) {
            const message = handleError(e);
            if (message.cancel) return;

            reject(message);
          }
        }, 500);
      });
    };
  useCancelRequest(request);
  return { onProcess };
};
export const useTransactionUploadError = () => {
  const { headers } = useContext(AuthContext),
    [request, setRequest] = useState(null),
    onProcessError = (data, message) => {
      return new Promise((resolve, reject) => {
        setTimeout(async () => {
          try {
            const _req = x.CancelToken.source();
            setRequest(_req);
            const { data: _data } = await gtw({
              method: "post",
              url: `/transactions/upload/error${
                message ? `?message=${message}` : ""
              }`,
              data: {
                orderList: data.orderList,
                request_id: data.request_id,
              },
              headers,
              cancelToken: _req.token,
            });

            if (_data) return resolve(_data);

            reject("server is not responding");
          } catch (e) {
            const message = handleError(e);
            if (message.cancel) return;

            reject(message);
          }
        }, 500);
      });
    };
  useCancelRequest(request);
  return { onProcessError };
};

// export const useExportTransactions = () => {
//   const [loading, setLoading] = useState(false),
//     [request, setRequest] = useState(null),
//     [progress, setProgress] = useState(0),
//     [res, setRes] = useState({ status: "", message: "" }),
//     { headers } = useContext(AuthContext),
//     onProcess = async (_query = {}, extension = ".csv", totalData = 10000) => {
//       setLoading(true);
//       setProgress(0);
//       setRes({ status: "", message: "" });
//       let query = { ..._query },
//         hasMore = true;
//       query.page = 0;
//       query.perPage = Math.ceil(totalData / 10);
//       let items = [];

//       async function hits() {
//         return new Promise(async (resolve, reject) => {
//           try {
//             const _req = x.CancelToken.source();
//             setRequest(_req);
//             const { data: _data } = await gtw({
//                 method: "get",
//                 url: `/transactions${convertDataToQuery(query)}`,
//                 headers,
//                 cancelToken: _req.token,
//               }),
//               totalData = _data.count;

//             if (totalData !== 0 && items.length > 0) {
//               const calculate = Math.floor((items.length / totalData) * 100);
//               setProgress(calculate);
//             }

//             items.push(..._data.data);
//             if (_data.data.length < query.perPage) hasMore = false;
//             query.page++;
//             resolve(true);
//           } catch (e) {
//             reject(e);
//           }
//         });
//       }

//       try {
//         while (hasMore) {
//           await hits();
//         }

//         setProgress(100);
//         // exportFileExcel(items, "ReportTransaction", extension);
//         exportFileExcelNew(items, "ReportTransaction", extension);
//         setLoading(false);
//         setRes({
//           status: "ok",
//           message: `Success download ${items.length} transactions`,
//         });
//       } catch (e) {
//         const message = handleError(e);
//         if (message.cancel)
//           setRes({ status: "error", message: "You cancel the request" });
//         else {
//           setRes({ status: "error", message });
//           setLoading(false);
//         }
//       }
//     };

//   useCancelRequest(request);
//   return { onProcess, loading, progress, res, setRes };
// };

export const useExportTransactions = () => {
  const [loading, setLoading] = useState(false),
    // [request, setRequest] = useState(null),
    [progress, setProgress] = useState(0),
    [res, setRes] = useState({ status: "", message: "" }),
    { headers } = useContext(AuthContext);

  // const onProcess = async (_query = {}, extension, totalData = 10000) => {
  //   try {
  //     setLoading(true);
  //     setProgress(0);
  //     setRes({ status: "", message: "" });
  //     // let hasMore = true;
  //     let items = [];
  //     let query = { ..._query };
  //     let pageOffset = 0;
  //     let progress = 0;
  //     let limit = Math.floor(totalData / 10);
  //     let lastId = 0;
  //     while (items.length !== totalData) {
  //       const { data: _data } = await gtw({
  //         method: "get",
  //         url: `/transactions/export/transactions${convertDataToQuery(
  //           query
  //         )}&limit=${limit}&pageOffset=${pageOffset}${
  //           lastId ? `&lastId=${lastId}` : ""
  //         }`,
  //         headers,
  //         // cancelToken: _req.token,
  //       });

  //       let newData = decompress(_data.data);

  //       if (newData[+limit - 1]) lastId = newData[+limit - 1].id;

  //       items = items.concat(newData);
  //       if (progress < 100) {
  //         progress += 10;
  //         setProgress(progress);
  //       }

  //       pageOffset++;
  //     }

  //     await exportFileExcelNew(items, "ReportTransaction", extension);
  //     setLoading(false);
  //     setRes({
  //       status: "ok",
  //       message: `Success download ${items.length} transactions`,
  //     });
  //   } catch (error) {
  //     const message = handleError(error);
  //     if (message.cancel)
  //       setRes({ status: "error", message: "You cancel the request" });
  //     else {
  //       setRes({ status: "error", message });
  //       setLoading(false);
  //     }
  //   }
  // };
  const onProcess = async (_query = {}, extension, totalData = 10000) => {
    try {
      setLoading(true);
      setProgress(0);
      setRes({ status: "", message: "" });
      // let hasMore = true;
      let items = [];
      let query = { ..._query };
      let pageOffset = 0;
      let progress = 0;
      let limit = Math.floor(totalData / 10);

      while (items.length !== totalData) {
        const { data: _data } = await gtw({
          method: "get",
          url: `/transactions/export/transactions${convertDataToQuery(
            query
          )}&limit=${limit}&pageOffset=${pageOffset}`,
          headers,
          // cancelToken: _req.token,
        });
        let newData = decompress(_data.data);
        items = items.concat(newData);
        if (progress < 100) {
          progress += 10;
          setProgress(progress);
        }

        pageOffset++;
      }

      await exportFileExcelNew(items, "ReportTransaction", extension);
      setLoading(false);
      setRes({
        status: "ok",
        message: `Success download ${items.length} transactions`,
      });
    } catch (error) {
      const message = handleError(error);
      if (message.cancel)
        setRes({ status: "error", message: "You cancel the request" });
      else {
        setRes({ status: "error", message });
        setLoading(false);
      }
    }
  };

  return { onProcess, loading, progress, res, setRes };
};
export const useGetRequestById = () => {
  const [loading, setLoading] = useState(false),
    [request, setRequest] = useState(null),
    [res, setRes] = useState({ status: "", message: "" }),
    { add, requestId, data, clear } = useContext(RequestContext),
    { headers } = useContext(AuthContext),
    onProcess = useCallback(
      async (id) => {
        if (data && requestId && requestId === id) return;
        setLoading(true);
        setRes({ status: "", message: "" });
        const cancel = await (async () => {
          try {
            const _req = x.CancelToken.source();
            setRequest(_req);
            const { data: _data } = await gtw({
              method: "get",
              url: `/requests/${id}`,
              headers,
              cancelToken: _req.token,
            });
            add({
              data: { ..._data, histories: JSON.parse(_data.histories) },
              requestId: id,
            });
          } catch (e) {
            const message = handleError(e);
            if (message.cancel) return true;
            clear();
            setRes({ status: "error", message });
          }
        })();
        if (cancel) return;
        setLoading(false);
      },
      [headers, add, clear, data, requestId]
    );

  useCancelRequest(request);
  return { onProcess, loading, res, data };
};

export const useListMonitoring = () => {
  const [loading, setLoading] = useState(false),
    [request, setRequest] = useState(null),
    [res, setRes] = useState({ status: "", message: "" }),
    navigate = useNavigate(),
    { headers } = useContext(AuthContext),
    [data, setData] = useState({}),
    [operationals, setOperationals] = useState({}),
    { search } = useLocation(),
    onProcess = useCallback(async () => {
      setLoading(true);
      setRes({ status: "", message: "" });
      setData({});
      const cancel = await (async () => {
        try {
          const _req = x.CancelToken.source();
          setRequest(_req);
          const { data: _data } = await gtw({
            method: "get",
            url: `/transactions/monitoring${search}`,
            headers,
            cancelToken: _req.token,
          });
          // await getOperationnal();
          let obj = {};
          _data.operational.forEach((e) => {
            if (!obj[e.name]) obj[e.name] = 0;
            obj[e.name] = e.total;
          });
          setOperationals(obj);

          setData(_data.obj);
        } catch (e) {
          const message = handleError(e);
          if (message.cancel) return true;
          setRes({ status: "error", message });
        }
      })();
      if (cancel) return;
      setLoading(false);
    }, [headers, search]),
    updateOperationnal = useCallback(
      async (operational) => {
        const cancel = await (async () => {
          try {
            const _req = x.CancelToken.source();
            setRequest(_req);
            await gtw({
              method: "patch",
              url: `/user/update/operational`,
              headers,
              cancelToken: _req.token,
              data: operational,
            });
          } catch (e) {
            const message = handleError(e);
            if (message.cancel) return true;
            setRes({ status: "error", message });
          }
        })();
        if (cancel) return;
        setLoading(false);
      },
      [headers]
    ),
    push = (payload) => navigate(`/dashboard${convertDataToQuery(payload)}`),
    query = useMemo(() => {
      if (!search) return null;
      return getQueryUrl(search);
    }, [search]);

  useCancelRequest(request);
  return {
    onProcess,
    updateOperationnal,
    loading,
    query,
    res,
    data,
    push,
    operationals,
    setOperationals,
  };
};

export const useListFinancial = () => {
  const [loading, setLoading] = useState(false),
    [request, setRequest] = useState(null),
    [res, setRes] = useState({ status: "", message: "" }),
    navigate = useNavigate(),
    { headers } = useContext(AuthContext),
    [data, setData] = useState({}),
    { search } = useLocation(),
    onProcess = useCallback(
      async (state, statusSearch) => {
        setLoading(true);
        setRes({ status: "", message: "" });
        setData({});
        const cancel = await (async () => {
          try {
            const _req = x.CancelToken.source();
            setRequest(_req);
            const { data: _data } = await gtw({
              method: "get",
              url: `/transactions/financial?startDate=${state.startDate}&endDate=${state.endDate}&statusSearch=${statusSearch}`,
              headers,
              cancelToken: _req.token,
            });
            setData(_data);
          } catch (e) {
            const message = handleError(e);
            if (message.cancel) return true;
            setRes({ status: "error", message });
          }
        })();
        if (cancel) return;
        setLoading(false);
      },
      [headers]
    ),
    push = (payload) => navigate(`/finance${convertDataToQuery(payload)}`),
    query = useMemo(() => {
      if (!search) return null;
      return getQueryUrl(search);
    }, [search]);

  useCancelRequest(request);
  return { onProcess, loading, query, res, data, push };
};

export const useListDifference = () => {
  const [newData, setNewData] = useState({});

  const { headers } = useContext(AuthContext);
  const [data, setData] = useState({});

  const [res, setRes] = useState({ status: "success", message: "" });
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const { search } = useLocation();
  const push = (payload) => {
    navigate(`/difference${convertDataToQuery(payload)}`);
  };

  const query = useMemo(() => {
    if (!search) return null;
    return getQueryUrl(search);
  }, [search]);

  const getList = async () => {
    try {
      setLoading(true);
      setNewData({});
      setData({});

      const { data } = await gtw({
        method: "get",
        url: `/sync/difference/order${search}`,
        headers,
      });
      setData(data);
      setLoading(false);
    } catch (err) {
      const message = handleError(err);
      setRes({
        status: "error",
        message,
      });
      setLoading(false);
    }
  };

  return { getList, data, res, push, query, loading, newData, setNewData };
};
