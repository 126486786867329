import { useEffect, Fragment, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import {
  Grid,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  IconButton,
  TablePagination,
  CircularProgress,
  Button,
  Tooltip,
  TextField,
  Alert,
  // Typography,
  // InputLabel,
  // FormControl,
} from "@mui/material";
import {
  ViewComfyAltOutlined,
  CopyAllOutlined,
  AddLinkTwoTone,
  DoneAllOutlined,
  AttachMoneyOutlined,
  // Send,
} from "@mui/icons-material";
// import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { useListUserSeller } from "../../services/user-seller";
import env from "../../config/env";
import { TablePaginationActions } from "../../components/spam/pagination";
import { SearchHead } from "../../components/spam/inputs";
// import gtw from "../../apis/gtw";
import { AuthContext } from "../../utils/auth";
// import { BasicModal } from "../../components/spam/modal";
// import { LocalizationProvider } from "@mui/x-date-pickers";
// import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
// import Select from "@mui/material/Select";
// import MenuItem from "@mui/material/MenuItem";

export const UserSellerPage = () => {
  const { loading, onProcess, res, data, push, query } = useListUserSeller(),
    [values, setValues] = useState(""),
    // { auth, headers } = useContext(AuthContext),
    { auth } = useContext(AuthContext),
    [copy, setCopy] = useState(false),
    // [notif, setNotif] = useState({ status: "", message: "" }),
    // [open, setOpen] = useState(false),
    // [date, setDate] = useState(""),
    // [threePl, setThreePl] = useState(""),
    // [id, setId] = useState(0),
    // [name, setName] = useState(""),
    navigate = useNavigate();

  function copyData() {
    var copyText = document.getElementById("myInput");

    copyText.select();
    copyText.setSelectionRange(0, 99999); /* For mobile devices */

    navigator.clipboard.writeText(copyText.value);

    setCopy(true);
  }

  // async function sendReport() {
  //   try {
  //     if (!date) {
  //       setNotif({
  //         status: "error",
  //         message: "Tolong isi tanggal",
  //         type: "date",
  //       });
  //     } else if (!threePl) {
  //       setNotif({
  //         status: "error",
  //         message: "tolong isi jenis expedisi",
  //         type: "expedisi",
  //       });
  //     } else if (date && threePl && id) {
  //       await gtw({
  //         method: "post",
  //         url: `/whatsapp/send_report?id=${id}&threepl=${threePl}&date=${date}&name=${name}&status=single`,
  //         headers,
  //       });
  //       setOpen(false);
  //       setNotif("success");
  //       setTimeout(() => setNotif(""), 5000);
  //     }
  //   } catch (err) {
  //     if (err.response.data.message === "Tidak ada data transaction") {
  //       setNotif("Tidak ada data transaksi");
  //     } else {
  //       setNotif("error");
  //     }
  //     setOpen(false);
  //     setTimeout(() => setNotif(""), 5000);
  //   }
  // }

  useEffect(() => {
    document.title = "BD - Seller";
  }, []);

  useEffect(() => {
    onProcess();
  }, [onProcess]);

  useEffect(() => {
    if (query && query.search) setValues(query.search);
    else setValues("");
  }, [query]);

  return (
    <>
      {/* <BasicModal open={open} onClose={() => null}>
        <Grid
          container
          display="flex"
          width="100%"
          justifyContent="center"
          flexDirection="column"
          alignItems="center"
        >
          <Typography variant="h4" component="h2" style={{ marginBottom: 20 }}>
            Send Report
          </Typography>
          <Fragment>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DesktopDatePicker
                label="Choose Date"
                inputFormat="dd/MM/yyyy"
                value={date}
                onChange={(e) => {
                  setDate(e);
                  setNotif("");
                }}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
            {notif.type === "date" ? (
              <Grid>
                <Alert severity={notif.status === "ok" ? "success" : "error"}>
                  {notif.message}{" "}
                </Alert>
              </Grid>
            ) : null}
            <FormControl sx={{ m: 1, minWidth: 120, marginTop: 5 }}>
              <InputLabel id="demo-simple-select-helper-label">
                Expedisi
              </InputLabel>
              <Select
                labelId="demo-simple-select-helper-label"
                id="demo-simple-select-helper"
                value={threePl}
                label="Expedisi"
                onChange={(e) => {
                  setThreePl(e.target.value);
                  setNotif("");
                }}
              >
                <MenuItem value={"jnt"}>JNT</MenuItem>
                <MenuItem value={"jne"}>JNE</MenuItem>
                <MenuItem value={"ninja express"}>Ninja Express</MenuItem>
                <MenuItem value={"anteraja"}>Anteraja</MenuItem>
                <MenuItem value={"shopee"}>Shopee</MenuItem>
                <MenuItem value={"lex"}>Lex</MenuItem>
                <MenuItem value={"id express"}>Id Expres</MenuItem>
                <MenuItem value={"sicepat"}>SiCepat</MenuItem>
                <MenuItem value={"other"}>other</MenuItem>
              </Select>
            </FormControl>
            {notif.type === "expedisi" ? (
              <Grid>
                <Alert severity={notif.status === "ok" ? "success" : "error"}>
                  {notif.message}{" "}
                </Alert>
              </Grid>
            ) : null}
          </Fragment>

          <Fragment>
            <Grid
              container
              display="flex"
              width="100%"
              justifyContent="space-between"
              alignItems="center"
              paddingX={5}
              marginTop={5}
            >
              <Button
                variant="outlined"
                color="error"
                size="small"
                title="Delete"
                onClick={() => {
                  setDate("");
                  setThreePl("");
                  setId(0);
                  setNotif("");
                  setOpen(false);
                }}
              >
                cancel
              </Button>

              <Button
                color="info"
                variant="outlined"
                size="small"
                title="Cancel Delete"
                onClick={() => sendReport()}
              >
                Confirm
              </Button>
            </Grid>
          </Fragment>
          <Grid item />
        </Grid>
      </BasicModal> */}

      <Fragment>
        {/* {notif === "success" ? (
          <Alert sx={{ width: "100%" }} severity="success">
            report telah dikirim via whatsapp
          </Alert>
        ) : notif === "error" ? (
          <Alert sx={{ width: "100%" }} severity="error">
            report gagal dikirim
          </Alert>
        ) : notif === "Tidak ada data transaksi" ? (
          <Alert sx={{ width: "100%" }} severity="error">
            {notif}
          </Alert>
        ) : (
          ""
        )} */}
        <Grid item width="100%" justifyContent="space-between" display="flex">
          <Grid item display="flex">
            <Tooltip title="Register seller secara massal">
              <Button
                size="small"
                onClick={() =>
                  navigate("/seller/register/massal", { state: { from: true } })
                }
                variant="outlined"
                color="success"
              >
                Register Seller Massal
              </Button>
            </Tooltip>
            {/* <Tooltip title="Register seller secara massal" sx={{ ml: 2 }}>
              <Button
                size="small"
                onClick={() => sendReport()}
                variant="outlined"
                color="primary"
              >
                Kirim Report
              </Button>
            </Tooltip> */}
            {auth.group_name !== "root" ? (
              <Fragment>
                <Tooltip title="Link Referensi anda, undang seller register dengan link ini">
                  <Button
                    color={copy ? "success" : "info"}
                    style={{ marginLeft: 5 }}
                    variant="text"
                    endIcon={copy ? <DoneAllOutlined /> : <CopyAllOutlined />}
                    onClick={copyData}
                  >
                    Copy Register Link
                  </Button>
                </Tooltip>
                <TextField
                  hidden
                  style={{ width: 350, opacity: 0 }}
                  variant="standard"
                  size="small"
                  type="text"
                  disabled
                  value={`${env.sasFeUrl}/register?salesRefID=${auth.id}`}
                  id="myInput"
                />
              </Fragment>
            ) : null}
            <Grid />
          </Grid>

          <SearchHead
            values={values}
            placeholder="Search Seller here"
            onEnter={(variable) =>
              push({ ...query, page: 0, search: variable })
            }
          />
        </Grid>

        <TableContainer style={{ minHeight: "50vh", maxHeight: "80vh" }}>
          <Table style={{ width: "100%" }} stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>No</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Username</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Phone</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.data.length > 0
                ? data.data.map((seller, index) => (
                    <TableRow key={index} hover>
                      <TableCell>{index + 1}</TableCell>
                      <TableCell>{seller?.name || "-"}</TableCell>
                      <TableCell>{seller?.username || "-"}</TableCell>
                      <TableCell>{seller?.email || "-"}</TableCell>
                      <TableCell>{seller?.phone || "-"}</TableCell>
                      <TableCell>{seller?.approval || "-"}</TableCell>
                      <TableCell>
                        <IconButton
                          title="View Alias"
                          size="small"
                          color="secondary"
                          onClick={() =>
                            navigate(
                              `/seller/${seller.id}/alias?page=0&perPage=10`,
                              { state: { from: true } }
                            )
                          }
                        >
                          <AddLinkTwoTone />
                        </IconButton>
                        <IconButton
                          title="Vew Data Cashback"
                          size="small"
                          color="primary"
                          style={{ marginLeft: 5 }}
                          target="_blank"
                          href={`/s-cshback?page=0&perPage=10&sellerId=${seller.id}`}
                        >
                          <AttachMoneyOutlined />
                        </IconButton>

                        <IconButton
                          title="View Detail"
                          style={{ marginLeft: 5 }}
                          size="small"
                          color="primary"
                          onClick={() =>
                            navigate(`/seller/${seller.id}`, {
                              state: { from: true },
                            })
                          }
                        >
                          <ViewComfyAltOutlined />
                        </IconButton>
                        {/* <IconButton
                          title="Send Report"
                          style={{ marginLeft: 5 }}
                          size="small"
                          color="primary"
                          onClick={() => {
                            setDate("");
                            setThreePl("");
                            setId(0);
                            setNotif("");
                            setName(seller.name);
                            setId(seller.id);
                            setOpen(true);
                          }}
                        >
                          <Send />
                        </IconButton> */}
                      </TableCell>
                    </TableRow>
                  ))
                : null}
            </TableBody>
          </Table>

          {loading ? (
            <div className="center">
              <CircularProgress size={25} />
            </div>
          ) : res.message ? (
            <Alert variant="standard" severity="error">
              {res.message}
            </Alert>
          ) : data.data.length === 0 ? (
            <Alert variant="standard" severity="warning">
              No Data
            </Alert>
          ) : null}
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[5, 10, 15, 25]}
          component="div"
          count={data.count}
          rowsPerPage={parseInt(query?.perPage || "10", 10)}
          page={data.count === 0 ? 0 : parseInt(query?.page || "0", 10)}
          onPageChange={(_, newPage) =>
            push(
              query
                ? { ...query, page: newPage }
                : { page: newPage, perPage: "10" }
            )
          }
          onRowsPerPageChange={({ target: { value = "10" } }) =>
            push(
              query
                ? { ...query, page: 0, perPage: parseInt(value, 10) }
                : { page: 0, perPage: parseInt(value, 10) }
            )
          }
          ActionsComponent={TablePaginationActions}
        />
      </Fragment>
    </>
  );
};

export * from "./detail";
export * from "./alias";
export * from "./register";
