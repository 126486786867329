import { useMemo, useState } from "react";
import { Grid, Button } from "@mui/material";
import { DateRangePicker } from "react-date-range";
import { BasicModal } from "./modal";

export const RangeDateTimePicker = ({
  open,
  close,
  onFilter = ({ startDate = "", endDate = "" }) =>
    (event) =>
      null,
}) => {
  const [state, setState] = useState({
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    }),
    date = useMemo(() => {
      let { startDate, endDate } = state;
      startDate.setHours(0, 0, 0, 0);
      endDate.setHours(23, 59, 59, 59);
      return { start: startDate, end: endDate };
    }, [state]);

  return (
    <BasicModal title="Select Range Date" open={open} onClose={close}>
      <DateRangePicker
        onChange={(item) => setState(item.selection)}
        showSelectionPreview={true}
        moveRangeOnFirstSelection={false}
        months={2}
        ranges={[state]}
        direction="horizontal"
      />

      <Grid item width="100%" display="flex" flexDirection="row-reverse">
        <Button
          onClick={onFilter({
            startDate: date.start.toISOString(),
            endDate: date.end.toISOString(),
          })}
          title="Filter"
          variant="outlined"
          color="success"
          size="small"
        >
          Search
        </Button>
        <Button
          onClick={() => close(false)}
          style={{ marginRight: 15 }}
          title="Cancel"
          variant="text"
          size="small"
        >
          Cancel
        </Button>
      </Grid>
    </BasicModal>
  );
};
