import {useState, useEffect, useContext, useMemo} from 'react';
import {useEdit} from '../../../services/lists';
import {BasicModal} from '../../../components/spam/modal';
import {AdminInputValue} from '../../../components/root';
import {GroupContext} from '../../../utils/group';

export const EditAdmin = ({open, close, defaultValue, callback = _ => null}) => {
	const {onProcess, loading, res} = useEdit(),
		{data: dataGroups, updateOne} = useContext(GroupContext),
		[payload, setPayload] = useState({full_name: '', username: '', email: '', password: '', old_pass: ''}),
		onChange = ({target: {value, name}}) =>
			setPayload(prev => ({...prev, [name]: value})),
		getGroupSelection = useMemo(() => {
			if(dataGroups.length === 0)
				return null;
			const filtering = dataGroups.filter(el => el.isChecked);
			return filtering.length > 0 ? filtering[0] : null;
		}, [dataGroups]);

	async function onUpdateValue () {
		let _data = {};
		if(payload.full_name && payload.full_name !== defaultValue.full_name)
			_data.full_name = payload.full_name;
		if(payload.username && payload.username !== defaultValue.username)
			_data.username = payload.username;
		if(payload.email && payload.email !== defaultValue.email)
			_data.email = payload.email;
		if(payload.password)
			_data.password = payload.password;

		if(getGroupSelection && getGroupSelection.id && getGroupSelection.id !== defaultValue.group_id)
			_data.group_id = getGroupSelection.id;

		onProcess(defaultValue.id, _data, respData => {
			if(respData) {
				
				let dataCallback = {...defaultValue, ...respData};
				if(getGroupSelection && _data.group_id) {
					dataCallback.group_name = getGroupSelection.name;
					dataCallback.group_level = getGroupSelection.level;
					dataCallback.parent_group = getGroupSelection.parent_group;
					dataCallback.parent_group_name = getGroupSelection.parent_name;
					updateOne(getGroupSelection.id, {key: 'isChecked', value: 'false'});
				}
				callback(dataCallback);

				close(false);
			}
		})
	}

	useEffect(() => {
		setPayload({full_name: defaultValue.full_name, username: defaultValue.username, email: defaultValue.email, password: '', old_pass: '', group_name: defaultValue.group_name});
	}, [defaultValue]);

	return (
		<BasicModal open={open} onClose={close} title='Admin Update Data'>
			<AdminInputValue
				res={res}
				loading={loading}
				payload={payload}
				onAction={onUpdateValue}
				onChange={onChange}/>

		</BasicModal>
	)
}