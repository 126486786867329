import { useState, useContext, useMemo } from "react";
// import x from "axios";
import gtw from "../apis/gtw";
import { AuthContext } from "../utils/auth";
// import {GroupContext} from '../utils/group';
import { handleError } from "./helpers";
import { convertDataToQuery, getQueryUrl } from "../helpers/custom";
import writeXlsxFile from "write-excel-file";
import { ExcelRenderer } from "react-excel-renderer";
import { useSearchParams } from "react-router-dom";
// import axios from "axios";

import { useLocation, useNavigate } from "react-router-dom";

export const uploadData = async (data, processID, selectedID, upload_type) => {
  const { data: _data } = await gtw({
    method: "post",
    // url: `http://localhost:3003/finance/upload?processID=${processID}&selectedID=${selectedID}&upload_type=${upload_type}`,
    url: `/finance/upload?processID=${processID}&selectedID=${selectedID}&upload_type=${upload_type}`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    data,
  });
  return _data;
};

export const downloadDataReport = async ({ query, payload }) => {
  const { data: _data } = await gtw({
    method: "post",
    url: `/finance/download/report${query ?? ""}`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    data: payload,
  });
  return _data.data;
};

export const downloadDataReportPrev = async ({ query, payload }) => {
  const { data: _data } = await gtw({
    method: "post",
    // url: `http://localhost:3003/finance/download/report-prev${query ?? ""}`,
    url: `/finance/download/report-prev${query ?? ""}`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    data: payload,
  });
  return _data.data;
};

export const sendPrevPeriod = async (data, processID, selectedID) => {
  const { data: _data } = await gtw({
    method: "post",
    // url: `http://localhost:3003/finance/send-prev?processID=${processID}&selectedID=${selectedID}`,
    url: `/finance/send-prev?processID=${processID}&selectedID=${selectedID}`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    data,
  });
  return _data;
};

export const useDataCenter = () => {
  const { headers } = useContext(AuthContext);
  const [dataCenter, setDataCenter] = useState({});
  const [selectedMonth, setSelectedMonth] = useState(new Date());
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const { search } = useLocation();
  const push = (payload) => {
    navigate(`/financial/upload-jnt${convertDataToQuery(payload)}`);
  };
  const query = useMemo(() => {
    if (!search) return null;
    return getQueryUrl(search);
  }, [search]);

  const getList = async () => {
    try {
      setLoading(true);
      setDataCenter({});

      const { data } = await gtw({
        method: "get",
        url: `/finance/jnt-center${search}`,
        headers,
        // cancelToken: _req.token,
      });

      setDataCenter(data.message);

      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  return {
    getList,
    dataCenter,
    selectedMonth,
    setSelectedMonth,
    loading,
    query,
    push,
  };
};

export const getRewardSeller = async (
  seller,
  date,
  sellerCommision,
  tiktokCommision,
  tiktokCom1MonthPrev,
  tiktokCom2MonthPrev,
  ppCashRegular,
  ppCashPromo,
  ppCashVip
) => {
  const { data } = await gtw({
    method: "GET",
    // url: `http://localhost:3003/finance/reward-seller?seller=${seller}&date=${date}&sellerCommision=${sellerCommision}&tiktokCommision=${tiktokCommision}&tiktokCom1MonthPrev=${tiktokCom1MonthPrev}&tiktokCom2MonthPrev=${tiktokCom2MonthPrev}&ppCashRegular=${ppCashRegular}&ppCashPromo=${ppCashPromo}&ppCashVip=${ppCashVip}`,
    url: `/finance/reward-seller?seller=${seller}&date=${date}&sellerCommision=${sellerCommision}&tiktokCommision=${tiktokCommision}&tiktokCom1MonthPrev=${tiktokCom1MonthPrev}&tiktokCom2MonthPrev=${tiktokCom2MonthPrev}&ppCashRegular=${ppCashRegular}&ppCashPromo=${ppCashPromo}&ppCashVip=${ppCashVip}`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
  return data;
};

export const useComparation = () => {
  const { headers } = useContext(AuthContext);
  const navigate = useNavigate();
  const [dataCompare, setDataCompare] = useState({ create: [], update: [] });
  const [sellerListError, setSellerListError] = useState([]);
  const [openModalError, setOpenModalError] = useState(false);
  const [openModal2, setOpenModal2] = useState(false);
  const [totalPage, setTotalPage] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingCompare, setLoadingCompare] = useState(false);

  const [listData, setListData] = useState({ data: [], count: 0 });
  const [res, setRes] = useState({ status: "", message: "" });
  const { search } = useLocation();

  const pushCompare = (payload) => {
    navigate(`/financial/comparation${convertDataToQuery(payload)}`);
  };
  const query = useMemo(() => {
    if (!search) return null;
    return getQueryUrl(search);
  }, [search]);

  const editValueCompare = async ({ update, create, updateById }) => {
    try {
      let newUpdate = [];
      if (update.length) {
        let newArray = update.map((e) => {
          let obj = {
            id: e.id,
            ongkir: e.newOngkir,
          };
          return obj;
        });
        newUpdate = newArray;
      } else if (updateById.length) {
        newUpdate = updateById;
      }

      const { data: _data } = await gtw({
        method: "patch",
        url: `/transactions/update_compare_ongkir`,
        data: {
          update: newUpdate,
          create,
          uploadId: create.length ? `${Date.now()}` : "",
        },
        headers,
      });

      getList();

      if (_data.error.length) {
        setSellerListError(_data.error);

        setOpenModalError(true);
      } else if (_data.data.statusCreate && !_data.error.length) {
        let newData = dataCompare;
        newData.create = [];
        setDataCompare(newData);
        setOpenModal2(false);
      } else if (_data.data.statusUpdate) {
        setOpenModal(false);
      }
    } catch (e) {
      const message = handleError(e);
      console.log(message);
    }
  };

  const fileHandler = (e) => {
    try {
      setDataCompare({ create: [], update: [] });

      e.preventDefault();
      setLoadingCompare(true);
      setOpenModal(true);

      const getFiles = (() => {
        try {
          return e.target.files[0];
        } catch (e) {
          return null;
        }
      })();
      if (getFiles) {
        ExcelRenderer(getFiles, async (err, resp) => {
          let orders = [],
            getHeaders = resp.rows[0];
          await resp.rows.forEach((item, index) => {
            if (index !== 0) {
              if (item.length > 0) {
                let payload = {};
                getHeaders.forEach(
                  (headers, _index) =>
                    (payload[headers] =
                      item[_index] && typeof item[_index] !== "string"
                        ? `${item[_index]}`.replace(/^[ ]+|[ ]$/g, "")
                        : item[_index] && typeof item[_index] === "string"
                        ? item[_index].replace(/^[ ]+|[ ]$/g, "")
                        : item[_index])
                );
                orders.push(payload);
              }
            }
          });

          const { data: _data } = await gtw({
            method: "post",
            url: `/transactions/compare_ongkir`,
            data: orders,
            headers,
          });
          //duplicate error
          if (_data.update.length <= 100) {
            setTotalPage(1);
          } else {
            setTotalPage(Math.ceil(_data.update.length / 100));
          }
          setDataCompare(_data);
          setLoadingCompare(false);

          //awb terdaftar
        });
      }
    } catch (error) {
      const message = handleError(error);
      setRes({ status: "error", message });
    }
    e.target.value = null;
  };

  const downloadTemplate = async () => {
    try {
      const schema = [
        {
          column: "no",
          type: String,
          value: () => "",
        },
        {
          column: "Ongkir",
          type: String,
          value: () => "",
        },
        {
          column: "Airwaybill",
          type: String,
          value: () => "",
        },
      ];

      await writeXlsxFile([], {
        schema,
        fileName: "template compare.xlsx",
      });
    } catch (err) {
      console.log(err);
    }
  };

  const getList = async () => {
    try {
      setLoading(true);
      if (listData.count) {
        setListData({ data: [], count: listData.count });
      } else {
        setListData({ data: [], count: 0 });
      }
      const { data } = await gtw({
        method: "get",
        url: `/transactions/compare_ongkir${search}`,
        headers,
        // cancelToken: _req.token,
      });
      setListData(data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  const deleteOrder = async (id) => {
    try {
      await gtw({
        method: "patch",
        url: `/transactions/delete/${id}`,
        headers,
        // cancelToken: _req.token,
      });

      getList();
    } catch (err) {
      console.log(err);
    }
  };

  return {
    dataCompare,
    res,
    fileHandler,
    downloadTemplate,
    getList,
    listData,
    pushCompare,
    query,
    openModal,
    setOpenModal,
    editValueCompare,
    sellerListError,
    setOpenModalError,
    openModalError,
    openModal2,
    setOpenModal2,
    totalPage,
    setDataCompare,
    loading,
    loadingCompare,
    deleteOrder,
  };
};

export const useCommission = () => {
  const { headers } = useContext(AuthContext);
  const [dataCommission, setDataCommission] = useState({});
  const [selectedMonth, setSelectedMonth] = useState(new Date());
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const { search } = useLocation();
  const push = (payload) => {
    navigate(`/financial/commission${convertDataToQuery(payload)}`);
  };
  const query = useMemo(() => {
    if (!search) return null;
    return getQueryUrl(search);
  }, [search]);

  const getList = async () => {
    try {
      setLoading(true);
      setDataCommission({});

      const { data } = await gtw({
        method: "get",
        url: `/transactions/commission${search}`,
        headers,
        // cancelToken: _req.token,
      });

      setDataCommission(data.data);

      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  const downloadCommission = async () => {
    try {
      const header = [
        {
          value: "Seller",
          fontWeight: "bold",
        },
        {
          value: "Sales",
          fontWeight: "bold",
        },
        {
          value: "JNE",
          fontWeight: "bold",
        },
        {
          value: "JNT",
          fontWeight: "bold",
        },
        {
          value: "Anter Aja",
          fontWeight: "bold",
        },
        {
          value: "ID Express",
          fontWeight: "bold",
        },
        {
          value: "Ninja Express",
          fontWeight: "bold",
        },
        {
          value: "Total",
          fontWeight: "bold",
        },
      ];

      let data = [header];

      if (Object.keys(dataCommission).length) {
        Object.keys(dataCommission).forEach((sales) => {
          Object.keys(dataCommission[sales]).forEach((seller) => {
            const tempRow = [
              {
                type: String,
                value: seller,
              },
              {
                type: String,
                value: sales,
              },
              {
                type: Number,
                value: dataCommission[sales][seller].jne
                  ? dataCommission[sales][seller].jne
                  : 0,
              },
              {
                type: Number,
                value: dataCommission[sales][seller].jnt
                  ? dataCommission[sales][seller].jnt
                  : 0,
              },
              {
                type: Number,
                value: dataCommission[sales][seller].anteraja
                  ? dataCommission[sales][seller].anteraja
                  : 0,
              },
              {
                type: Number,
                value: dataCommission[sales][seller]["id express"]
                  ? dataCommission[sales][seller]["id express"]
                  : 0,
              },
              {
                type: Number,
                value: dataCommission[sales][seller]["ninja express"]
                  ? dataCommission[sales][seller]["ninja express"]
                  : 0,
              },
              {
                type: Number,
                value: dataCommission[sales][seller].total
                  ? dataCommission[sales][seller].total
                  : 0,
              },
            ];

            data.push(tempRow);
          });
        });
      }

      await writeXlsxFile(data, {
        fileName: `Data Komisi Bulan ${selectedMonth.toLocaleString("id-ID", {
          month: "long",
        })}.xlsx`,
      });
    } catch (err) {
      console.log(err);
    }
  };

  return {
    getList,
    dataCommission,
    selectedMonth,
    setSelectedMonth,
    downloadCommission,
    loading,
    query,
    push,
  };
};

export const useInvoice = () => {
  const { headers } = useContext(AuthContext);
  const [dataInvoice, setDataInvoice] = useState({});
  const [dataInvoiceDetail, setDataInvoiceDetail] = useState({});

  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const { search } = useLocation();
  const push = (payload) => {
    navigate(`/financial/invoice${convertDataToQuery(payload)}`);
  };
  const query = useMemo(() => {
    if (!search) return null;
    return getQueryUrl(search);
  }, [search]);

  const getList = async () => {
    try {
      setLoading(true);
      setDataInvoice({});

      const { data } = await gtw({
        method: "get",
        url: `/transactions/invoice${search}`,
        headers,
        // cancelToken: _req.token,
      });
      setDataInvoice(data.data);

      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  const getDetail = async (item) => {
    try {
      setDataInvoiceDetail({});
      setLoading(true);

      const { data } = await gtw({
        method: "get",
        url: `/transactions/invoice/detail/${item}${search}`,
        headers,
        // cancelToken: _req.token,
      });

      setDataInvoiceDetail(data.data);

      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  const downloadAllInvoice = async () => {
    try {
      const header = [
        {
          value: "Seller",
          fontWeight: "bold",
        },
        {
          value: "JNE",
          fontWeight: "bold",
        },
        {
          value: "JNT",
          fontWeight: "bold",
        },
        {
          value: "Anter Aja",
          fontWeight: "bold",
        },
        {
          value: "Ninja Express",
          fontWeight: "bold",
        },
        {
          value: "ID Express",
          fontWeight: "bold",
        },
        {
          value: "Lion",
          fontWeight: "bold",
        },
        {
          value: "SiCepat",
          fontWeight: "bold",
        },
        {
          value: "Shopee",
          fontWeight: "bold",
        },
        {
          value: "Lex",
          fontWeight: "bold",
        },
        {
          value: "Wahana",
          fontWeight: "bold",
        },
        {
          value: "Other",
          fontWeight: "bold",
        },
        {
          value: "Total",
          fontWeight: "bold",
        },
      ];

      let data = [header];

      if (Object.keys(dataInvoice).length) {
        Object.keys(dataInvoice).map((item) => {
          const tempRow = [
            {
              type: String,
              value: item,
            },
            {
              type: Number,
              value: dataInvoice[item].jne ? dataInvoice[item].jne : 0,
            },
            {
              type: Number,
              value: dataInvoice[item].jnt ? dataInvoice[item].jnt : 0,
            },
            {
              type: Number,
              value: dataInvoice[item].anteraja
                ? dataInvoice[item].anteraja
                : 0,
            },
            {
              type: Number,
              value: dataInvoice[item]["ninja express"]
                ? dataInvoice[item]["ninja express"]
                : 0,
            },
            {
              type: Number,
              value: dataInvoice[item]["id express"]
                ? dataInvoice[item]["id express"]
                : 0,
            },
            {
              type: Number,
              value: dataInvoice[item].lion ? dataInvoice[item].lion : 0,
            },
            {
              type: Number,
              value: dataInvoice[item].sicepat ? dataInvoice[item].sicepat : 0,
            },
            {
              type: Number,
              value: dataInvoice[item].shopee ? dataInvoice[item].shopee : 0,
            },
            {
              type: Number,
              value: dataInvoice[item].lex ? dataInvoice[item].lex : 0,
            },
            {
              type: Number,
              value: dataInvoice[item].wahana ? dataInvoice[item].wahana : 0,
            },
            {
              type: Number,
              value: dataInvoice[item].other ? dataInvoice[item].other : 0,
            },
            {
              type: Number,
              value: dataInvoice[item].total ? dataInvoice[item].total : 0,
            },
          ];

          data.push(tempRow);
        });
      }
      // // await writeXlsxFile(data, {
      // //   fileName: `Data Invoice Bulan ${selectedMonth.toLocaleString("id-ID", {
      // //     month: "long",
      // //   })}.xlsx`,
      // // });

      await writeXlsxFile(data, {
        fileName: `Data Invoice.xlsx`,
      });
    } catch (err) {
      console.log(err);
    }
  };

  const downloadAllInvoiceDetail = async (sellerName) => {
    try {
      let temp = [];
      if (Object.keys(dataInvoiceDetail).length) {
        Object.keys(dataInvoiceDetail).forEach((e) => {
          temp = temp.concat(dataInvoiceDetail[e].detail);
        });
      }
      const header = [
        {
          value: "Seller",
          fontWeight: "bold",
        },
        {
          value: "Tanggal",
          fontWeight: "bold",
        },
        {
          value: "ThreePl",
          fontWeight: "bold",
        },
        {
          value: "Service",
          fontWeight: "bold",
        },
        {
          value: "Airwillbay",
          fontWeight: "bold",
        },
        {
          value: "Total",
          fontWeight: "bold",
        },
      ];

      let data = [header];
      if (temp.length) {
        temp.forEach((e) => {
          data.push([
            {
              type: String,
              value: sellerName,
            },
            {
              type: String,
              value: new Date(e.tanggal).toLocaleDateString("id-ID"),
            },
            {
              type: String,
              value: e.threePL,
            },
            {
              type: String,
              value: e.service,
            },
            {
              type: String,
              value: e.awb,
            },
            {
              type: Number,
              value: e.total,
            },
          ]);
        });
      }
      await writeXlsxFile(data, {
        fileName: `Data Invoice seller ${sellerName}.xlsx`,
      });
    } catch (err) {
      console.log(err);
    }
  };
  const donwnloadPerDate = async ({ sellerName, detailPerDate }) => {
    try {
      const header = [
        {
          value: "Seller",
          fontWeight: "bold",
        },
        {
          value: "Tanggal",
          fontWeight: "bold",
        },
        {
          value: "ThreePl",
          fontWeight: "bold",
        },
        {
          value: "Service",
          fontWeight: "bold",
        },
        {
          value: "Airwillbay",
          fontWeight: "bold",
        },
        {
          value: "Total",
          fontWeight: "bold",
        },
      ];

      let data = [header];
      if (detailPerDate.length) {
        detailPerDate.forEach((e) => {
          data.push([
            {
              type: String,
              value: sellerName,
            },
            {
              type: String,
              value: new Date(e.tanggal).toLocaleDateString("id-ID"),
            },
            {
              type: String,
              value: e.threePL,
            },
            {
              type: String,
              value: e.service,
            },
            {
              type: String,
              value: e.awb,
            },
            {
              type: Number,
              value: e.total,
            },
          ]);
        });
      }
      await writeXlsxFile(data, {
        fileName: `Data Invoice seller ${sellerName}.xlsx`,
      });
    } catch (err) {
      console.log(err);
    }
  };

  return {
    getList,
    getDetail,
    dataInvoice,
    loading,
    query,
    push,
    downloadAllInvoiceDetail,
    downloadAllInvoice,
    dataInvoiceDetail,
    donwnloadPerDate,
  };
};

export const useCashback = () => {
  const { headers } = useContext(AuthContext);
  const [dataCashback, setDataCashback] = useState({});
  const [dataCashbackDetail, setDataCashbackDetail] = useState({});
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const { search } = useLocation();
  const push = (payload) => {
    navigate(`/financial/cashback${convertDataToQuery(payload)}`);
  };
  const query = useMemo(() => {
    if (!search) return null;
    return getQueryUrl(search);
  }, [search]);

  const getList = async () => {
    try {
      setDataCashback({});
      setLoading(true);

      const { data } = await gtw({
        method: "get",
        url: `/transactions/cashback${search}`,
        headers,
        // cancelToken: _req.token,
      });
      setDataCashback(data.data);

      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  const getDetail = async (item) => {
    try {
      setDataCashbackDetail({});

      setLoading(true);
      // setDataInvoice({});

      const { data } = await gtw({
        method: "get",
        url: `/transactions/cashback/detail/${item}${search}`,
        headers,
        // cancelToken: _req.token,
      });

      setDataCashbackDetail(data.data);

      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };
  const downloadAllCashback = async () => {
    try {
      const header = [
        {
          value: "Seller",
          fontWeight: "bold",
        },
        {
          value: "JNE",
          fontWeight: "bold",
        },
        {
          value: "JNT",
          fontWeight: "bold",
        },
        {
          value: "Anter Aja",
          fontWeight: "bold",
        },
        {
          value: "Ninja Express",
          fontWeight: "bold",
        },
        {
          value: "ID Express",
          fontWeight: "bold",
        },
        {
          value: "Lion",
          fontWeight: "bold",
        },
        {
          value: "SiCepat",
          fontWeight: "bold",
        },
        {
          value: "Shopee",
          fontWeight: "bold",
        },
        {
          value: "Lex",
          fontWeight: "bold",
        },
        {
          value: "Wahana",
          fontWeight: "bold",
        },
        {
          value: "Other",
          fontWeight: "bold",
        },
        {
          value: "Total",
          fontWeight: "bold",
        },
      ];

      let data = [header];

      if (Object.keys(dataCashback).length) {
        Object.keys(dataCashback).map((item) => {
          const tempRow = [
            {
              type: String,
              value: item,
            },
            {
              type: Number,
              value: dataCashback[item].jne ? dataCashback[item].jne : 0,
            },
            {
              type: Number,
              value: dataCashback[item].jnt ? dataCashback[item].jnt : 0,
            },
            {
              type: Number,
              value: dataCashback[item].anteraja
                ? dataCashback[item].anteraja
                : 0,
            },
            {
              type: Number,
              value: dataCashback[item]["ninja express"]
                ? dataCashback[item]["ninja express"]
                : 0,
            },
            {
              type: Number,
              value: dataCashback[item]["id express"]
                ? dataCashback[item]["id express"]
                : 0,
            },
            {
              type: Number,
              value: dataCashback[item].lion ? dataCashback[item].lion : 0,
            },
            {
              type: Number,
              value: dataCashback[item].sicepat
                ? dataCashback[item].sicepat
                : 0,
            },
            {
              type: Number,
              value: dataCashback[item].shopee ? dataCashback[item].shopee : 0,
            },
            {
              type: Number,
              value: dataCashback[item].lex ? dataCashback[item].lex : 0,
            },
            {
              type: Number,
              value: dataCashback[item].wahana ? dataCashback[item].wahana : 0,
            },
            {
              type: Number,
              value: dataCashback[item].other ? dataCashback[item].other : 0,
            },
            {
              type: Number,
              value: dataCashback[item].total ? dataCashback[item].total : 0,
            },
          ];

          data.push(tempRow);
        });
      }
      // // await writeXlsxFile(data, {
      // //   fileName: `Data Invoice Bulan ${selectedMonth.toLocaleString("id-ID", {
      // //     month: "long",
      // //   })}.xlsx`,
      // // });

      await writeXlsxFile(data, {
        fileName: `Data Invoice.xlsx`,
      });
    } catch (err) {
      console.log(err);
    }
  };
  const downloadAllCashbackDetail = async (sellerName) => {
    try {
      let temp = [];
      if (Object.keys(dataCashbackDetail).length) {
        Object.keys(dataCashbackDetail).forEach((e) => {
          temp = temp.concat(dataCashbackDetail[e].detail);
        });
      }
      const header = [
        {
          value: "Seller",
          fontWeight: "bold",
        },
        {
          value: "Tanggal",
          fontWeight: "bold",
        },
        {
          value: "ThreePl",
          fontWeight: "bold",
        },
        {
          value: "Service",
          fontWeight: "bold",
        },
        {
          value: "Airwillbay",
          fontWeight: "bold",
        },
        {
          value: "Total",
          fontWeight: "bold",
        },
      ];

      let data = [header];
      if (temp.length) {
        temp.forEach((e) => {
          data.push([
            {
              type: String,
              value: sellerName,
            },
            {
              type: String,
              value: new Date(e.tanggal).toLocaleDateString("id-ID"),
            },
            {
              type: String,
              value: e.threePL,
            },
            {
              type: String,
              value: e.service,
            },
            {
              type: String,
              value: e.awb,
            },
            {
              type: Number,
              value: e.total,
            },
          ]);
        });
      }
      await writeXlsxFile(data, {
        fileName: `Data Cashback seller ${sellerName}.xlsx`,
      });
    } catch (err) {
      console.log(err);
    }
  };
  const donwnloadPerDate = async ({ sellerName, detailPerDate }) => {
    try {
      const header = [
        {
          value: "Seller",
          fontWeight: "bold",
        },
        {
          value: "Tanggal",
          fontWeight: "bold",
        },
        {
          value: "ThreePl",
          fontWeight: "bold",
        },
        {
          value: "Service",
          fontWeight: "bold",
        },
        {
          value: "Airwillbay",
          fontWeight: "bold",
        },
        {
          value: "Total",
          fontWeight: "bold",
        },
      ];

      let data = [header];
      if (detailPerDate.length) {
        detailPerDate.forEach((e) => {
          data.push([
            {
              type: String,
              value: sellerName,
            },
            {
              type: String,
              value: new Date(e.tanggal).toLocaleDateString("id-ID"),
            },
            {
              type: String,
              value: e.threePL,
            },
            {
              type: String,
              value: e.service,
            },
            {
              type: String,
              value: e.awb,
            },
            {
              type: Number,
              value: e.total,
            },
          ]);
        });
      }
      await writeXlsxFile(data, {
        fileName: `Data Cashback seller ${sellerName}.xlsx`,
      });
    } catch (err) {
      console.log(err);
    }
  };

  return {
    getList,
    getDetail,
    dataCashback,
    loading,
    query,
    push,
    downloadAllCashback,
    downloadAllCashbackDetail,
    dataCashbackDetail,
    donwnloadPerDate,
  };
};
export const useInvoiceCashback = () => {
  const { headers } = useContext(AuthContext);
  const [dataInvoiceCashback, setDataInvoiceCashback] = useState({
    transactionManual: {},
    transactionCashless: {},
    transactionNotCalculated: {},
  });
  const [dataInvoiceCashbackDetail, setDataInvoiceDetail] = useState({});
  const [mainTable, setMainTable] = useState({});
  const [searchParams, setSearchParams] = useSearchParams();
  const params = Object.fromEntries([...searchParams]);
  let status = params.status;

  const [selectedMonth, setSelectedMonth] = useState(new Date());
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const { search } = useLocation();
  const push = (payload) => {
    navigate(`/financial/cashback&invoice${convertDataToQuery(payload)}`);
  };
  const query = useMemo(() => {
    if (!search) return null;
    return getQueryUrl(search);
  }, [search]);

  const getList = async () => {
    try {
      setLoading(true);
      // setDataInvoiceCashback({
      //   transactionManual: {},
      //   transactionCashless: {},
      // });
      setMainTable({});
      const { data } = await gtw({
        method: "get",
        url: `/transactions/invoice${search}`,
        headers,
        // cancelToken: _req.token,
      });
      console.log(data);
      if (status === "invoice" || !status) {
        setMainTable(data.data.transactionManual);
      } else if (status === "cashback") {
        setMainTable(data.data.transactionCashless);
      }
      setDataInvoiceCashback(data.data);
      setLoading(false);
    } catch (err) {
      console.log(err, "!!");
      setLoading(false);
    }
  };

  const getDetail = async (item) => {
    try {
      setDataInvoiceDetail({});
      setLoading(true);
      let data;
      if (status === "invoice") {
        const { data: _data } = await gtw({
          method: "get",
          url: `/transactions/invoice/detail/${item}${search}`,
          headers,
          // cancelToken: _req.token,
        });
        data = _data;
      } else if (status === "cashback") {
        const { data: _data } = await gtw({
          method: "get",
          url: `/transactions/cashback/detail/${item}${search}`,
          headers,
          // cancelToken: _req.token,
        });
        data = _data;
      }
      setDataInvoiceDetail(data.data);

      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  // const downloadAllInvoice = async () => {
  //   try {
  //     const header = [
  //       {
  //         value: "Seller",
  //         fontWeight: "bold",
  //       },
  //       {
  //         value: "JNE",
  //         fontWeight: "bold",
  //       },
  //       {
  //         value: "JNT",
  //         fontWeight: "bold",
  //       },
  //       {
  //         value: "Anter Aja",
  //         fontWeight: "bold",
  //       },
  //       {
  //         value: "Ninja Express",
  //         fontWeight: "bold",
  //       },
  //       {
  //         value: "ID Express",
  //         fontWeight: "bold",
  //       },
  //       {
  //         value: "Lion",
  //         fontWeight: "bold",
  //       },
  //       {
  //         value: "SiCepat",
  //         fontWeight: "bold",
  //       },
  //       {
  //         value: "Shopee",
  //         fontWeight: "bold",
  //       },
  //       {
  //         value: "Lex",
  //         fontWeight: "bold",
  //       },
  //       {
  //         value: "Wahana",
  //         fontWeight: "bold",
  //       },
  //       {
  //         value: "Other",
  //         fontWeight: "bold",
  //       },
  //       {
  //         value: "Total",
  //         fontWeight: "bold",
  //       },
  //     ];

  //     let data = [header];

  //     if (Object.keys(dataInvoice).length) {
  //       Object.keys(dataInvoice).map((item) => {
  //         const tempRow = [
  //           {
  //             type: String,
  //             value: item,
  //           },
  //           {
  //             type: Number,
  //             value: dataInvoice[item].jne ? dataInvoice[item].jne : 0,
  //           },
  //           {
  //             type: Number,
  //             value: dataInvoice[item].jnt ? dataInvoice[item].jnt : 0,
  //           },
  //           {
  //             type: Number,
  //             value: dataInvoice[item].anteraja
  //               ? dataInvoice[item].anteraja
  //               : 0,
  //           },
  //           {
  //             type: Number,
  //             value: dataInvoice[item]["ninja express"]
  //               ? dataInvoice[item]["ninja express"]
  //               : 0,
  //           },
  //           {
  //             type: Number,
  //             value: dataInvoice[item]["id express"]
  //               ? dataInvoice[item]["id express"]
  //               : 0,
  //           },
  //           {
  //             type: Number,
  //             value: dataInvoice[item].lion ? dataInvoice[item].lion : 0,
  //           },
  //           {
  //             type: Number,
  //             value: dataInvoice[item].sicepat ? dataInvoice[item].sicepat : 0,
  //           },
  //           {
  //             type: Number,
  //             value: dataInvoice[item].shopee ? dataInvoice[item].shopee : 0,
  //           },
  //           {
  //             type: Number,
  //             value: dataInvoice[item].lex ? dataInvoice[item].lex : 0,
  //           },
  //           {
  //             type: Number,
  //             value: dataInvoice[item].wahana ? dataInvoice[item].wahana : 0,
  //           },
  //           {
  //             type: Number,
  //             value: dataInvoice[item].other ? dataInvoice[item].other : 0,
  //           },
  //           {
  //             type: Number,
  //             value: dataInvoice[item].total ? dataInvoice[item].total : 0,
  //           },
  //         ];

  //         data.push(tempRow);
  //       });
  //     }

  //     // await writeXlsxFile(data, {
  //     //   fileName: `Data Invoice Bulan ${selectedMonth.toLocaleString("id-ID", {
  //     //     month: "long",
  //     //   })}.xlsx`,
  //     // });

  //     await writeXlsxFile(data, {
  //       fileName: `Data Invoice.xlsx`,
  //     });
  //   } catch (err) {
  //     console.log(err);
  //   }
  // };

  const downloadAllInvoiceCashback = async (sellerName) => {
    try {
      let temp = [];
      if (Object.keys(dataInvoiceCashbackDetail).length) {
        Object.keys(dataInvoiceCashbackDetail).forEach((e) => {
          temp = temp.concat(dataInvoiceCashbackDetail[e].detail);
        });
      }
      const header = [
        {
          value: "Seller",
          fontWeight: "bold",
        },
        {
          value: "Tanggal",
          fontWeight: "bold",
        },
        {
          value: "ThreePl",
          fontWeight: "bold",
        },
        {
          value: "Service",
          fontWeight: "bold",
        },
        {
          value: "Airwillbay",
          fontWeight: "bold",
        },
        {
          value: "Total",
          fontWeight: "bold",
        },
      ];

      let data = [header];
      if (temp.length) {
        temp.forEach((e) => {
          data.push([
            {
              type: String,
              value: sellerName,
            },
            {
              type: String,
              value: new Date(e.tanggal).toLocaleDateString("id-ID"),
            },
            {
              type: String,
              value: e.threePL,
            },
            {
              type: String,
              value: e.service,
            },
            {
              type: String,
              value: e.awb,
            },
            {
              type: Number,
              value: e.total,
            },
          ]);
        });
      }
      await writeXlsxFile(data, {
        fileName: `Data Invoice seller ${sellerName}.xlsx`,
      });
    } catch (err) {
      console.log(err);
    }
  };
  const donwnloadPerDate = async ({ sellerName, detailPerDate }) => {
    try {
      const header = [
        {
          value: "Seller",
          fontWeight: "bold",
        },
        {
          value: "Tanggal",
          fontWeight: "bold",
        },
        {
          value: "ThreePl",
          fontWeight: "bold",
        },
        {
          value: "Service",
          fontWeight: "bold",
        },
        {
          value: "Airwillbay",
          fontWeight: "bold",
        },
        {
          value: "Total",
          fontWeight: "bold",
        },
      ];

      let data = [header];
      if (detailPerDate.length) {
        detailPerDate.forEach((e) => {
          data.push([
            {
              type: String,
              value: sellerName,
            },
            {
              type: String,
              value: new Date(e.tanggal).toLocaleDateString("id-ID"),
            },
            {
              type: String,
              value: e.threePL,
            },
            {
              type: String,
              value: e.service,
            },
            {
              type: String,
              value: e.awb,
            },
            {
              type: Number,
              value: e.total,
            },
          ]);
        });
      }
      await writeXlsxFile(data, {
        fileName: `Data Invoice seller ${sellerName}.xlsx`,
      });
    } catch (err) {
      console.log(err);
    }
  };
  const downloadError = async ({ sellerName, errorAwb }) => {
    try {
      const header = [
        {
          value: "Seller",
          fontWeight: "bold",
        },
        {
          value: "ThreePl",
          fontWeight: "bold",
        },

        {
          value: "Service",
          fontWeight: "bold",
        },
        {
          value: "Airwillbay",
          fontWeight: "bold",
        },
        {
          value: "Ongkir",
          fontWeight: "bold",
        },
      ];

      let data = [header];
      Object.keys(errorAwb).forEach((e, i) => {
        errorAwb[e].map((e1, j) => {
          data.push([
            {
              type: String,
              value: sellerName,
            },
            {
              type: String,
              value: e,
            },
            {
              type: String,
              value: e1.service,
            },
            {
              type: String,
              value: e1.awb,
            },
            {
              type: String,
              value: e1.ongkir,
            },
          ]);
        });
      });

      await writeXlsxFile(data, {
        fileName: `Data Invoice seller ${sellerName}.xlsx`,
      });
    } catch (err) {
      console.log(err);
    }
  };

  return {
    getList,
    getDetail,
    dataInvoiceCashback,
    setDataInvoiceCashback,
    loading,
    query,
    push,
    downloadAllInvoiceCashback,
    dataInvoiceCashbackDetail,
    donwnloadPerDate,
    mainTable,
    setMainTable,
    downloadError,
  };
};
