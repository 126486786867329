import { useEffect, Fragment } from "react";
import {
  Modal,
  Grid,
  Box,
  Typography,
  List,
  ListItem,
  ListItemText,
  Snackbar,
  Alert,
  useMediaQuery,
  Zoom,
  LinearProgress,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useGetRequestById } from "../../services/transactions";

export const BasicModal = ({ open, onClose, title, children }) => (
  <Modal
    open={open}
    onClose={() => onClose(false)}
    aria-labelledby="basic-title"
    aria-describedby="basic-description"
  >
    <Box
      sx={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        minWidth: 400,
        maxWidth: "95%",
        backgroundColor: "white",
        borderRadius: 1,
        boxShadow: 24,
        p: 2,
      }}
    >
      <Typography id="basic-title" variant="h6" component="h6">
        {title}
      </Typography>
      <Grid container>{children}</Grid>
    </Box>
  </Modal>
);

export const UIAlert = ({
  open,
  onClose,
  res = { status: "", message: "" },
}) => (
  <Snackbar
    anchorOrigin={{ horizontal: "center", vertical: "top" }}
    open={open}
    onClose={() => onClose(false)}
  >
    <Alert
      severity={
        res.status === "ok"
          ? "success"
          : res.status === "warning"
          ? "warning"
          : "error"
      }
    >
      {res.message}
    </Alert>
  </Snackbar>
);

export const RequestModal = ({ open, close, id }) => {
  const { data, loading, res, onProcess } = useGetRequestById();

  useEffect(() => {
    onProcess(id);
  }, [id, onProcess]);

  return (
    <BasicModal open={open} onClose={close} title="Request History">
      <Grid container spacing={1} width={900}>
        {loading ? (
          <Typography variant="caption">Loading...</Typography>
        ) : res.message ? (
          <Alert severity="error">{res.message}</Alert>
        ) : data ? (
          <Fragment>
            <List style={{ width: "100%" }}>
              <ListItem
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <ListItemText
                  primary="Module name"
                  secondary={data.module_name || "-"}
                  secondaryTypographyProps={{ textTransform: "capitalize" }}
                />
                <ListItemText
                  primary="Action"
                  secondary={data.action || "-"}
                  secondaryTypographyProps={{ textTransform: "capitalize" }}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="Admin name"
                  secondary={data.admin_name || "-"}
                  secondaryTypographyProps={{ textTransform: "capitalize" }}
                />
                <ListItemText
                  primary="Group name"
                  secondary={data.group_name || "-"}
                  secondaryTypographyProps={{ textTransform: "capitalize" }}
                />
              </ListItem>
            </List>

            <Grid item display="flex" flexDirection="column" width="100%">
              <Typography variant="body1">Histories</Typography>
              <List style={{ width: "100%", height: 350, overflow: "auto" }}>
                {data.histories.map((history, index) => (
                  <ListItem key={index}>
                    <ListItemText
                      secondaryTypographyProps={{
                        fontWeight: "bolder",
                        color: "black",
                      }}
                      secondary={history}
                    />
                  </ListItem>
                ))}
              </List>
            </Grid>
          </Fragment>
        ) : (
          <Alert severity="warning">No Data</Alert>
        )}
      </Grid>
    </BasicModal>
  );
};

export function ModalLoading({ loadingUpload, text }) {
  const theme = useTheme();
  const matchesMobile = useMediaQuery(theme.breakpoints.between("xs", "sm"));
  return (
    <>
      <Modal
        open={loadingUpload}
        // onClose={() => setLoadingUpload(false)}
        sx={{
          backdropFilter: "blur(5px)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Zoom in={loadingUpload}>
          <Box
            sx={{
              width: matchesMobile ? "100%" : "auto",
              height: "auto",
              backgroundColor: "white",
              borderRadius: 1,
              boxShadow: 24,
              px: matchesMobile ? 2 : 10,
              py: 2,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <Box
              sx={{
                pb: 4,
              }}
            >
              <Typography variant="h6" align="center">
                {`${text.split(",")[0]}`}
                <br />
                {`${text.split(",")[1]}`}
              </Typography>
            </Box>
            <Box
              sx={{
                width: "100%",
              }}
            >
              <LinearProgress />
            </Box>
          </Box>
        </Zoom>
      </Modal>
    </>
  );
}
