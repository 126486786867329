import {Fragment} from 'react';
import {
	Grid,
	TableContainer,
	TableHead,
	TableRow,
	TableCell,
	Table,
	TableBody,
	Checkbox,
	IconButton,
	Typography,
	CircularProgress,
} from '@mui/material';
import {DeleteForeverOutlined, RefreshOutlined} from '@mui/icons-material';

export const OptionPrivilegeCreate = ({privileges = [], maxHeight, onRetry = privilege => null, updatePrivileges = ({privilege, key, value}) => null, deleteOne = privilege => null, groupName = '', moduleName = '', moduleChildren = undefined, groupChildren = undefined}) => (
	<Grid item>
		<TableContainer sx={{height: maxHeight || 250}}>
			<Table size='small' aria-label="sticky table" stickyHeader>
				<TableHead>
					<TableRow>
						<TableCell colSpan={3}/>
						<TableCell align='center' colSpan={5}>Privileges</TableCell>
					</TableRow>
					<TableRow>
						<TableCell>No</TableCell>
						<TableCell>Module Name</TableCell>
						<TableCell>Group Name</TableCell>
						<TableCell>Create Data</TableCell>
						<TableCell>Read Data</TableCell>
						<TableCell>Update Data</TableCell>
						<TableCell>Delete Data</TableCell>
						<TableCell>Action</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{privileges.map((privilege, index) => (
						<TableRow key={index} hover>
							<TableCell>{index + 1}</TableCell>
							<TableCell>
								{moduleChildren !== undefined
									?	moduleChildren(privilege)
									:	moduleName || privilege.module_name || '-'}
							</TableCell>
							<TableCell>
								{groupChildren !== undefined
									?	groupChildren(privilege)
									:	groupName || privilege.group_name || '-'}
							</TableCell>
							<TableCell>
								<Checkbox
									checked={privilege.create || false}
									onChange={({target: {checked}}) => updatePrivileges({privilege, key: 'create', value: checked})}
									color='primary'/>
							</TableCell>
							<TableCell>
								<Checkbox
									checked={privilege.read || false}
									onChange={({target: {checked}}) => updatePrivileges({privilege, key: 'read', value: checked})}
									color='primary'/>
							</TableCell>
							<TableCell>
								<Checkbox
									checked={privilege.update || false}
									onChange={({target: {checked}}) => updatePrivileges({privilege, key: 'update', value: checked})}
									color='primary'/>
							</TableCell>
							<TableCell>
								<Checkbox
									checked={privilege.delete || false}
									onChange={({target: {checked}}) => updatePrivileges({privilege, key: 'delete', value: checked})}
									color='primary'/>
							</TableCell>
							<TableCell>
								{privilege.loading
									?	<CircularProgress size={25}/>
									:	privilege.message
										?	<Fragment>
												<IconButton color='info' title='Retry' onClick={() => onRetry(privilege)}>
													<RefreshOutlined/>
												</IconButton><br/>
												<Typography variant='caption' component='span' fontSize={10}>{privilege.message}</Typography><br/>
											</Fragment>
										:	<IconButton color='error' onClick={() => deleteOne(privilege)}>
												<DeleteForeverOutlined/>
											</IconButton>}
								
							</TableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>
		</TableContainer>
	</Grid>
)