import { useEffect, Fragment, useState, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { ExcelRenderer } from "react-excel-renderer";
import {
  Grid,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Button,
  IconButton,
  TablePagination,
  CircularProgress,
  Tooltip,
  Alert,
  TableHead,
  Typography,
} from "@mui/material";
import {
  ViewComfyAltOutlined,
  RefreshOutlined,
  DeleteOutlineOutlined,
  Update,
  Downloading,
} from "@mui/icons-material";
import {
  useListSellerCashback,
  useSimpleUpdateDefaultDccb,
  useUpdateMultiple,
} from "../../services/cashback";
import { TablePaginationActions } from "../../components/spam/pagination";
import { SearchHead } from "../../components/spam/inputs";
import { BasicModal } from "../../components/spam/modal";

const BackToDefaultDccb = ({
  open,
  close,
  data = [],
  updateOne = ({ id, key, value }) => null,
  clear = () => null,
  refresh = () => null,
}) => {
  const [sellers, setSellers] = useState(
      data.map((el) => ({
        id: el.id,
        name: el?.User?.name || "",
        email: el?.User?.email || "-",
        loading: false,
        message: "",
        status: "",
      }))
    ),
    { onProcess } = useSimpleUpdateDefaultDccb(),
    checkSuccess = useMemo(() => {
      return sellers.filter((el) => el.status === "ok");
    }, [sellers]),
    checkLoading = useMemo(() => {
      if (sellers.length === 0) return false;
      return sellers.filter((el) => el.loading).length > 0;
    }, [sellers]);

  async function onProcessData() {
    if (checkLoading) return;
    await setSellers((prev) =>
      prev.map((el) => ({ ...el, loading: true, status: "", message: "" }))
    );

    for (const dccb of sellers) {
      try {
        await onProcess(dccb.id);
        await setSellers((prev) =>
          prev.map((el) => {
            if (el.id === dccb.id)
              return {
                ...el,
                loading: false,
                status: "ok",
                message: "Success Update to Default",
              };
            return el;
          })
        );
      } catch (e) {
        await setSellers((prev) =>
          prev.map((el) => {
            if (el.id === dccb.id)
              return { ...el, status: "error", message: e, loading: false };
            return el;
          })
        );
      }
    }
  }

  return (
    <BasicModal
      open={open}
      onClose={(_) => {
        if (checkSuccess.length > 0) refresh();
        clear();
        close(false);
      }}
      title="Please check again"
    >
      <TableContainer style={{ width: "100%", height: "50vh" }}>
        <Table size="small" style={{ width: "100%" }}>
          <TableHead>
            <TableRow>
              <TableCell>No</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Email</TableCell>``
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sellers.map((seller, index) => (
              <TableRow key={index}>
                <TableCell>{index + 1}</TableCell>
                <TableCell>{seller.name || "-"}</TableCell>
                <TableCell>{seller.email || "-"}</TableCell>
                <TableCell>
                  {seller.loading ? (
                    <Typography variant="caption">Loading...</Typography>
                  ) : seller.status && seller.message ? (
                    <Fragment>
                      <Tooltip title="Ulangi proses">
                        <IconButton
                          size="small"
                          onClick={() => null}
                          variant="standard"
                          color="primary"
                        >
                          <RefreshOutlined />
                        </IconButton>
                      </Tooltip>
                      <br />
                      <Typography
                        variant="caption"
                        color={seller.status === "ok" ? "green" : "red"}
                      >
                        {seller.message}
                      </Typography>
                    </Fragment>
                  ) : (
                    <Tooltip title="Hapus row" placement="right">
                      <IconButton
                        size="small"
                        variant="standard"
                        color="error"
                        onClick={() => {
                          updateOne({
                            id: seller.id,
                            key: "isChecked",
                            value: false,
                          });
                          setSellers((prev) =>
                            prev
                              .filter((el) => el.id !== seller.id)
                              .map((el) => el)
                          );
                        }}
                      >
                        <DeleteOutlineOutlined />
                      </IconButton>
                    </Tooltip>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Grid
        item
        display="flex"
        width="100%"
        justifyContent="flex-end"
        style={{ marginTop: 25 }}
      >
        <Typography variant="caption" color="red">
          *I hope you know what you're doing*
        </Typography>
      </Grid>

      <Grid
        item
        display="flex"
        width="100%"
        justifyContent="space-between"
        alignItems="center"
        style={{ marginTop: 10 }}
      >
        <Grid />
        <Grid item>
          <Tooltip title="Batal proses">
            <Button
              size="small"
              variant="outlined"
              color="primary"
              style={{ marginRight: 5 }}
              onClick={() => {
                if (checkLoading) return;
                if (checkSuccess.length > 0) refresh();
                clear();
                close(false);
              }}
            >
              Cancel
            </Button>
          </Tooltip>

          <Tooltip
            placement="right"
            title="Ubah cashback menjadi default ** ACTION INI TIDAK DAPAT DI URUNGKAN **"
          >
            <Button
              size="small"
              variant="contained"
              color="secondary"
              onClick={onProcessData}
            >
              {checkLoading ? "Loading..." : "Process Data"}
            </Button>
          </Tooltip>

          {checkSuccess.length > 0 ? (
            <Tooltip title="Selesai">
              <Button
                size="small"
                variant="outlined"
                color="success"
                style={{ marginLeft: 5 }}
                onClick={() => {
                  refresh();
                  close(false);
                }}
              >
                DONE
              </Button>
            </Tooltip>
          ) : null}
        </Grid>
      </Grid>
    </BasicModal>
  );
};

export const SellerCashbackPage = () => {
  const {
      onProcess,
      loading,
      res,
      data,
      push,
      query,
      selectItems,
      updateAll,
      updateOne,
      downloadTemplate,
    } = useListSellerCashback(),
    { onProcessUpdate } = useUpdateMultiple(),
    [values, setValues] = useState(""),
    [editValue, setEditValue] = useState(false),
    navigate = useNavigate();

  const fileHandler = async (e) => {
    if (e.preventDefault) e.preventDefault();

    const getFiles = (() => {
      try {
        return e.target.files[0];
      } catch (e) {
        return null;
      }
    })();
    ExcelRenderer(getFiles, async (err, resp) => {
      try {
        if (resp) {
          let dataCb = [],
            getHeaders = resp.rows[0];

          await resp.rows.forEach((item, index) => {
            if (item.length > 2) {
              if (index !== 0) {
                let payload = {};
                getHeaders.forEach((headers, _index) => {
                  if (
                    headers.toLowerCase() === "id" ||
                    headers.toLowerCase() === "seller" ||
                    headers.toLowerCase() === "jtr" ||
                    headers.toLowerCase() === "reg" ||
                    headers.toLowerCase() === "cashless" ||
                    headers.toLowerCase() === "shopee" ||
                    headers.toLowerCase() === "non shopee" ||
                    headers.toLowerCase() === "tiktok" ||
                    headers.toLowerCase() === "id express" ||
                    headers.toLowerCase() === "anteraja" ||
                    headers.toLowerCase() === "lex" ||
                    headers.toLowerCase() === "ninja" ||
                    headers.toLowerCase() === "shopeex" ||
                    headers.toLowerCase() === "lion" ||
                    headers.toLowerCase() === "wahana" ||
                    headers.toLowerCase() === "sicepat"
                  ) {
                    if (item[_index]) {
                      payload[headers] =
                        item[_index] && typeof item[_index] !== "string"
                          ? `${item[_index]}`.replace(/^[ ]+|[ ]$/g, "")
                          : item[_index] && typeof item[_index] === "string"
                          ? item[_index].replace(/^[ ]+|[ ]$/g, "")
                          : item[_index];
                    }
                  } else {
                    console.log("error kolom");
                    return;
                  }
                });
                dataCb.push(payload);
              }
            }
          });
          onProcessUpdate(dataCb);
        }
      } catch (error) {
        console.log(error);
      }
    });
  };

  useEffect(() => {
    onProcess();
  }, [onProcess]);

  useEffect(() => {
    if (query && query.search) setValues(query.search);
    else setValues("");
  }, [query]);

  useEffect(() => {
    document.title = "BD - Cashback";
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Fragment>
      {editValue && selectItems.length > 0 ? (
        <BackToDefaultDccb
          open
          close={setEditValue}
          data={selectItems}
          updateOne={updateOne}
          clear={() => updateAll({ key: "isChecked", value: false })}
          refresh={onProcess}
        />
      ) : null}
      <Grid
        item
        width="100%"
        justifyContent="space-between"
        display="flex"
        sx={{ mb: 3 }}
      >
        <Grid>
          {selectItems.length > 1 ? (
            <Tooltip title="Kembalikan data cashback ke default (di page Cashback)">
              <Button
                onClick={() => setEditValue(true)}
                variant="outlined"
                color="secondary"
                size="small"
              >
                Back to Default {selectItems.length} items
              </Button>
            </Tooltip>
          ) : null}
          <Button
            component="label"
            startIcon={<Update />}
            variant="outlined"
            color="success"
            size="small"
          >
            Update Cashback
            <input
              type="file"
              hidden
              onChange={(e) => {
                fileHandler(e);
                e.target.value = null;
              }}
            />
          </Button>
          <Button
            component="label"
            startIcon={<Downloading />}
            variant="outlined"
            color="primary"
            size="small"
            sx={{ ml: 2 }}
            onClick={() => downloadTemplate()}
          >
            Download Template
          </Button>
        </Grid>
        <SearchHead
          values={values}
          placeholder="Search Seller here"
          onEnter={(variable) => push({ ...query, page: 0, search: variable })}
        />
      </Grid>

      <TableContainer style={{ minHeight: "50vh", maxHeight: "80vh" }}>
        <Table size="small" style={{ width: "100%" }} stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>No</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Username</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Status</TableCell>
              <TableCell align="center">Last Update</TableCell>
              <TableCell align="center">Action</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {data.data.length > 0
              ? data.data.map((seller, index) => (
                  <TableRow key={index} hover>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{seller?.User?.name || "-"}</TableCell>
                    <TableCell>{seller?.User?.username || "-"}</TableCell>
                    <TableCell>{seller?.User?.email || "-"}</TableCell>
                    <TableCell>{seller?.status || "-"}</TableCell>
                    <TableCell align="center">
                      {new Date(seller?.User?.updatedAt).toLocaleDateString(
                        "id-ID"
                      ) || "-"}
                    </TableCell>
                    <TableCell align="center">
                      {/* <Tooltip title="Kembalikan cashback seperti semula (di halaman Cashback)">
                        <IconButton
                          size="small"
                          color="secondary"
                          onClick={async () => {
                            await updateOne({
                              id: seller.id,
                              key: "isChecked",
                              value: true,
                            });
                            setEditValue(true);
                          }}
                        >
                          <BackupOutlined />
                        </IconButton>
                      </Tooltip> */}

                      <Tooltip title="Kembalikan cashback seperti semula (di halaman Cashback)">
                        <IconButton
                          style={{ marginLeft: 15 }}
                          size="small"
                          color="primary"
                          onClick={() =>
                            navigate(`/s-cshback/${seller.id}`, {
                              state: { from: false },
                            })
                          }
                        >
                          <ViewComfyAltOutlined />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ))
              : null}
          </TableBody>
        </Table>

        {loading ? (
          <div className="center">
            <CircularProgress size={25} />
          </div>
        ) : res.message ? (
          <Alert variant="standard" severity="error">
            {res.message}
          </Alert>
        ) : data.data.length === 0 ? (
          <Alert variant="standard" severity="warning">
            No Data
          </Alert>
        ) : null}
      </TableContainer>

      <TablePagination
        rowsPerPageOptions={[5, 10, 15, 25]}
        component="div"
        count={data.count}
        rowsPerPage={parseInt(query?.perPage || "10", 10)}
        page={data.count === 0 ? 0 : parseInt(query?.page || "0", 10)}
        onPageChange={(_, newPage) =>
          push(
            query
              ? { ...query, page: newPage }
              : { page: newPage, perPage: "10" }
          )
        }
        onRowsPerPageChange={({ target: { value = "10" } }) =>
          push(
            query
              ? { ...query, page: 0, perPage: parseInt(value, 10) }
              : { page: 0, perPage: parseInt(value, 10) }
          )
        }
        ActionsComponent={TablePaginationActions}
      />
    </Fragment>
  );
};

export * from "./detail";
