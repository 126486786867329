import {
  Box,
  Button,
  FormControlLabel,
  Grid,
  Modal,
  Switch,
  Typography,
} from "@mui/material";
import {
  Publish,
  ArrowForwardIos,
  Update,
  UploadOutlined,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { Fragment, useEffect, useMemo, useState } from "react";
import { LocalizationProvider, MonthPicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import {
  sendPrevPeriod,
  uploadData,
  useDataCenter,
} from "../../services/financial";
import { read, utils, writeFile } from "xlsx";
import { handleError } from "../../services/helpers";
import { ModalLoading, UIAlert } from "../../components/spam/modal";
import { compress } from "compress-json";

export default function UploadJNTPage() {
  const navigate = useNavigate();
  const [openMonth, setOpenMonth] = useState(false);
  const [openReq, setOpenReq] = useState(false);
  const [openReqPrev, setOpenReqPrev] = useState(false);
  const [loadingProgress, setLoadingProgress] = useState(false);
  const [checked, setChecked] = useState(false);
  const [tempMonth, setTempMonth] = useState(new Date());
  const [isDisableUpload, setIsDisableUpload] = useState(false);
  const { push, query, getList, selectedMonth, setSelectedMonth, dataCenter } =
    useDataCenter();
  const [res, setRes] = useState({ status: "", message: "" });
  const [uploadType, setUploadType] = useState("mix_awb");

  useEffect(() => {
    getList();
  }, [query]);

  useMemo(() => {
    if (checked) {
      push({
        ...query,
        upload_type: "tt_awb",
      });
    } else {
      push({
        ...query,
        upload_type: "mix_awb",
      });
    }
  }, [query, checked]);

  useMemo(() => {
    if (dataCenter !== "Successfully") setIsDisableUpload(false);
    else setIsDisableUpload(true);
  }, [dataCenter]);

  const downloadTemplate = async () => {
    try {
      let rows = [
        {
          "No. Waybill": "",
          "Nama Seller": "",
          "Biaya Kirim": "",
          Diskon: "",
          "Biaya Kirim Setelah Diskon": "",
          "Cara Pembayaran": "",
          "Klien Pengirim": "",
          "Kode Promo": "",
          TTD: "",
          "Sumber Order": "",
        },
      ];
      let wscols = [
        { width: 20 },
        { width: 30 },
        { width: 14 },
        { width: 13 },
        { width: 30 },
        { width: 17 },
        { width: 25 },
        { width: 25 },
        { width: 12 },
        { width: 20 },
        { width: 25 },
      ];

      const ws = utils.json_to_sheet(rows);
      ws["!cols"] = wscols;
      const wb = utils.book_new();

      utils.book_append_sheet(wb, ws, "Data");
      writeFile(wb, "template-jnt-center.xlsx");
      setRes({ status: "ok", message: "berhasil download template" });
      // setShowResponse(true);
    } catch (error) {
      console.log(error);
      const message = await handleError(error);
      if (message.cancel) return true;
      setRes({ status: "error", message: "gagal download template" });
      // setShowResponse(true);
    }
  };

  const downloadTemplatePrev = async () => {
    try {
      let rows = [
        {
          "No. Waybill": "",
          "Nama Seller": "",
          "Biaya Kirim": "",
          Diskon: "",
          "Biaya Kirim Setelah Diskon": "",
          "Cara Pembayaran": "",
          "Klien Pengirim": "",
          "Kode Promo": "",
          TTD: "",
          "Sumber Order": "",
          "Tanggal Pembayaran": "year-month-date",
        },
      ];
      let wscols = [
        { width: 20 },
        { width: 30 },
        { width: 14 },
        { width: 13 },
        { width: 30 },
        { width: 17 },
        { width: 25 },
        { width: 25 },
        { width: 12 },
        { width: 20 },
        { width: 25 },
      ];

      const ws = utils.json_to_sheet(rows);
      ws["!cols"] = wscols;
      const wb = utils.book_new();

      utils.book_append_sheet(wb, ws, "Data");
      writeFile(wb, "template-jnt-center-prev.xlsx");
      setRes({ status: "ok", message: "berhasil download template" });
      // setShowResponse(true);
    } catch (error) {
      console.log(error);
      const message = await handleError(error);
      if (message.cancel) return true;
      setRes({ status: "error", message: "gagal download template" });
      // setShowResponse(true);
    }
  };

  const fileHandlerPrev = async (e) => {
    e.preventDefault();
    setLoadingProgress(true);
    try {
      const file = e.currentTarget.files[0];
      const f = await file.arrayBuffer();

      const wb = read(f);
      const sheet = wb.SheetNames;
      const ws = wb.Sheets[sheet[0]];
      Object.keys(ws).forEach((s) => {
        if (/c/i.test(s)) {
          if (s !== "C1") {
            if (typeof ws[s].v === "string") {
              ws[s].w = ws[s].w.split("/");

              const element = ws[s].w.splice(0, 1)[0];

              ws[s].w.splice(1, 0, element);
              ws[s].w = ws[s].w.join("/");
            }
          }
        }
      });

      const data = utils.sheet_to_json(ws, {
        raw: false,
      });

      const excel = utils.sheet_to_json(ws, { header: 1 });
      let statusError = false;
      if (
        excel[0][0].trim() !== "No. Waybill" ||
        excel[0][1].trim() !== "Nama Seller" ||
        // excel[0][2].trim() !== "Biaya Kirim" ||
        // excel[0][3].trim() !== "Diskon" ||
        excel[0][4].trim() !== "Biaya Kirim Setelah Diskon" ||
        excel[0][5].trim() !== "Cara Pembayaran" ||
        excel[0][6].trim() !== "Klien Pengirim" ||
        excel[0][7].trim() !== "Kode Promo" ||
        // excel[0][8].trim() !== "TTD" ||
        excel[0][9].trim() !== "Sumber Order" ||
        excel[0][10].trim() !== "Tanggal Pembayaran"
      ) {
        setRes([
          { status: "Error", message: "Format table tidak sesuai harapan" },
        ]);
        statusError = true;
      } else {
        let last = false;
        let _newData = { errors: [], totalCreate: 0, totalUpdate: 0 };
        let newData = [];
        const processID = Date.now();
        const selectedID = selectedMonth.valueOf();
        while (!last) {
          newData = data.splice(0, 10000);

          last = data.length === 0;
          let compressed = compress(newData);

          //! UPLOAD EXEL, AKAN DIKIRIM KE BACKEND DAN AKAN DICREATE DI DATABASE.S
          const _data = await sendPrevPeriod(compressed, processID, selectedID);
          _newData.errors = _newData.errors.concat(_data.errors);
          _newData.totalCreate += _data.totalCreate;
        }
        if (_newData.errors.length) {
          setRes({
            status: "error",
            message: _newData.errors,
          });
          statusError = true;
        }
      }

      if (statusError) return;
      setRes({
        status: "ok",
        message: "berhasil upload data, silahkan periksa kembali data tersebut",
      });
      setLoadingProgress(false);
      getList();
      setOpenReq(false);
      setUploadType("mix_awb");
    } catch (error) {
      console.log(error);
      setLoadingProgress(false);
      setOpenReq(false);
      const message = await handleError(error);
      if (message.cancel) return true;
      setRes({ status: "Error", message });
    }
  };

  const fileHandler = async (e) => {
    e.preventDefault();
    setLoadingProgress(true);
    try {
      const file = e.currentTarget.files[0];
      const f = await file.arrayBuffer();

      const wb = read(f);
      const sheet = wb.SheetNames;
      const ws = wb.Sheets[sheet[0]];
      Object.keys(ws).forEach((s) => {
        if (/c/i.test(s)) {
          if (s !== "C1") {
            if (typeof ws[s].v === "string") {
              ws[s].w = ws[s].w.split("/");

              const element = ws[s].w.splice(0, 1)[0];

              ws[s].w.splice(1, 0, element);
              ws[s].w = ws[s].w.join("/");
            }
          }
        }
      });

      const data = utils.sheet_to_json(ws, {
        raw: false,
      });

      const excel = utils.sheet_to_json(ws, { header: 1 });
      let statusError = false;
      // console.log(excel[0][1].trim());
      if (
        excel[0][0].trim() !== "No. Waybill" ||
        excel[0][1].trim() !== "Nama Seller" ||
        excel[0][2].trim() !== "Biaya Kirim" ||
        excel[0][3].trim() !== "Diskon" ||
        excel[0][4].trim() !== "Biaya Kirim Setelah Diskon" ||
        excel[0][5].trim() !== "Cara Pembayaran" ||
        excel[0][6].trim() !== "Klien Pengirim" ||
        excel[0][7].trim() !== "Kode Promo" ||
        excel[0][8].trim() !== "TTD" ||
        excel[0][9].trim() !== "Sumber Order"
      ) {
        setRes([
          { status: "Error", message: "Format table tidak sesuai harapan" },
        ]);
        statusError = true;
      } else {
        let last = false;
        let _newData = { errors: [], totalCreate: 0, totalUpdate: 0 };
        let newData = [];
        const processID = Date.now();
        const selectedID = selectedMonth.valueOf();
        while (!last) {
          newData = data.splice(0, 10000);

          last = data.length === 0;
          let compressed = compress(newData);

          //! UPLOAD EXEL, AKAN DIKIRIM KE BACKEND DAN AKAN DICREATE DI DATABASE.S
          const _data = await uploadData(
            compressed,
            processID,
            selectedID,
            uploadType
          );
          _newData.errors = _newData.errors.concat(_data.errors);
          _newData.totalCreate += _data.totalCreate;
        }
        if (_newData.errors.length) {
          setRes({
            status: "error",
            message: _newData.errors,
          });
          statusError = true;
        }
      }

      if (statusError) return;
      setRes({
        status: "ok",
        message: "berhasil upload data, silahkan periksa kembali data tersebut",
      });
      setLoadingProgress(false);
      getList();
      setOpenReq(false);
      setUploadType("mix_awb");
    } catch (error) {
      console.log(error);
      setLoadingProgress(false);
      setOpenReq(false);
      const message = await handleError(error);
      if (message.cancel) return true;
      setRes({ status: "Error", message });
    }
  };

  return (
    <article
      style={{ width: "100%", minHeight: "88dvh", position: "relative" }}
    >
      {/* alert */}
      <UIAlert
        onClose={(_) => setRes({ status: "", message: "" })}
        open={res.status !== ""}
        autoHideDuration={10000}
        res={res}
      />

      {/* modal loading */}
      <ModalLoading
        loadingUpload={loadingProgress}
        text={`Proses upload sedang berlangsung, Jangan buka tab lain`}
      />

      {/* button next */}
      <div style={{ position: "absolute", right: 0, padding: "1rem 1.5rem" }}>
        <Button
          variant="outlined"
          endIcon={<ArrowForwardIos />}
          onClick={() => navigate(`/financial/Calculate?date=${selectedMonth}`)}
          size="large"
        >
          Next
        </Button>
      </div>

      <section
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
        }}
      >
        <Button
          component="label"
          variant="outlined"
          size="small"
          color="primary"
          onClick={() => setOpenMonth(true)}
          sx={{ width: 150, mb: "1.3rem" }}
        >
          {selectedMonth.toLocaleString("default", { month: "long" })}
        </Button>

        <FormControlLabel
          control={
            <Switch
              checked={checked}
              onChange={(e) => {
                setChecked(e.target.checked);
                if (e.target.checked === true) {
                  setUploadType("tt_awb");
                } else setUploadType("mix_awb");
              }}
              color="primary"
              inputProps={{ "aria-label": "controlled" }}
            />
          }
          label="Tiktok Resi"
        />

        <Button
          variant="contained"
          startIcon={<Publish />}
          size="large"
          sx={{ mt: 0.7 }}
          disabled={isDisableUpload}
          onClick={() => setOpenReq(true)}
        >
          Upload Data Master
        </Button>

        <Button
          variant="contained"
          startIcon={<Update />}
          size="large"
          sx={{ mt: 2 }}
          disabled={isDisableUpload}
          onClick={() => setOpenReqPrev(true)}
        >
          Upload Prev Data
        </Button>

        {/* get month modal */}
        <Modal
          open={openMonth}
          sx={{
            width: "100%",
            justifyContent: "center",
            display: "flex",
            height: "80%",
            mt: 10,
          }}
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              minWidth: 400,
              maxWidth: "95%",
              backgroundColor: "white",
              borderRadius: 1,
              boxShadow: 24,
              p: 2,
            }}
          >
            <Grid
              container
              display="flex"
              width="100%"
              justifyContent="center"
              alignItems="center"
            >
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <MonthPicker
                  date={tempMonth}
                  onChange={(e) => {
                    setTempMonth(e);
                  }}
                />
              </LocalizationProvider>
              <Fragment>
                <Grid
                  container
                  display="flex"
                  width="50%"
                  justifyContent="space-between"
                  alignItems="center"
                  marginTop={5}
                >
                  <Button
                    variant="outlined"
                    color="error"
                    size="small"
                    title="Delete"
                    onClick={() => {
                      setOpenMonth(false);
                      setTempMonth(new Date());
                    }}
                  >
                    cancel
                  </Button>

                  <Button
                    color="info"
                    variant="outlined"
                    size="small"
                    title="Confirm"
                    onClick={() => {
                      push({
                        ...query,
                        date: tempMonth,
                        upload_type: uploadType,
                      });
                      setSelectedMonth(tempMonth);
                      setOpenMonth(false);
                    }}
                  >
                    Confirm
                  </Button>
                </Grid>
              </Fragment>
            </Grid>
          </Box>
        </Modal>

        {/* open modal req prev_data */}
        <Modal
          open={openReqPrev}
          onClose={() => setOpenReqPrev(false)}
          sx={{
            width: "100%",
            justifyContent: "center",
            display: "flex",
            height: "80%",
            mt: 10,
          }}
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              minWidth: 860,
              maxWidth: "95%",
              backgroundColor: "white",
              borderRadius: 1,
              boxShadow: 24,
            }}
          >
            <Grid
              item
              width="100%"
              justifyContent="center"
              flexDirection="column"
              alignItems="center"
              style={{
                height: "85vh",
                width: "100%",
                position: "relative",
                cursor: "pointer",
              }}
              display="flex"
            >
              <UploadOutlined
                style={{ width: "auto", opacity: 0.5, height: 250 }}
              />
              <Typography variant="h4">Upload File</Typography>

              <input
                type="file"
                onChange={fileHandlerPrev}
                style={{
                  width: "100%",
                  cursor: "pointer",
                  opacity: 0,
                  height: "100%",
                  position: "absolute",
                  left: 0,
                  top: 0,
                }}
              />

              <Button
                variant="outlined"
                onClick={downloadTemplatePrev}
                sx={{ mt: 10 }}
              >
                Download Template
              </Button>
            </Grid>
          </Box>
        </Modal>
        {/* open modal req upload */}
        <Modal
          open={openReq}
          onClose={() => setOpenReq(false)}
          sx={{
            width: "100%",
            justifyContent: "center",
            display: "flex",
            height: "80%",
            mt: 10,
          }}
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              minWidth: 860,
              maxWidth: "95%",
              backgroundColor: "white",
              borderRadius: 1,
              boxShadow: 24,
            }}
          >
            <Grid
              item
              width="100%"
              justifyContent="center"
              flexDirection="column"
              alignItems="center"
              style={{
                height: "85vh",
                width: "100%",
                position: "relative",
                cursor: "pointer",
              }}
              display="flex"
            >
              <UploadOutlined
                style={{ width: "auto", opacity: 0.5, height: 250 }}
              />
              <Typography variant="h4">Upload File</Typography>

              <input
                type="file"
                onChange={fileHandler}
                style={{
                  width: "100%",
                  cursor: "pointer",
                  opacity: 0,
                  height: "100%",
                  position: "absolute",
                  left: 0,
                  top: 0,
                }}
              />

              <Button
                variant="outlined"
                onClick={downloadTemplate}
                sx={{ mt: 10 }}
              >
                Download Template
              </Button>
            </Grid>
          </Box>
        </Modal>
      </section>
    </article>
  );
}
