import {
  ArrowLeftOutlined,
  Close,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import {
  Alert,
  Box,
  Button,
  Chip,
  CircularProgress,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Snackbar,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { Fragment, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  useCreateDropper,
  useGetAllSellerSbd,
  useListUserSellerSbd,
} from "../../services/user-seller";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, sellerDropper, theme) {
  return {
    fontWeight:
      sellerDropper.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export const RegisterDropper = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { state } = useLocation();
  const { onProcess, res, loading, openSnack, setOpenSnack } =
    useCreateDropper();
  const { onProcess: listSeller, data } = useGetAllSellerSbd();
  const [sellerDropper, setsellerDropper] = useState([]);
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [alamat, setAlamat] = useState("");
  const [phone, setPhone] = useState("");
  const [showPassword, setShowPassword] = useState("");

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setsellerDropper(typeof value === "string" ? value.split(",") : value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Lakukan tindakan saat form dikirim (misalnya, kirim data ke server)
    onProcess({
      username,
      email,
      password,
      alamat,
      phone: `62${phone}`,
      sellerDropper,
    });
    // Reset nilai input setelah pengiriman
    if (openSnack) {
      setUsername("");
      setEmail("");
      setPassword("");
      setAlamat("");
      setPhone("");
      setsellerDropper([]);
    }
  };

  useEffect(() => {
    listSeller();
  }, []);
  return (
    <Fragment>
      <Box sx={{ ml: 10, my: 3, placeContent: "center" }}>
        {openSnack ? (
          <Box display="flex">
            <Alert
              variant="standard"
              severity={res.status}
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setOpenSnack(false);
                  }}
                >
                  <Close fontSize="inherit" />
                </IconButton>
              }
            >
              {res.message}
            </Alert>
          </Box>
        ) : null}
        <Button
          onClick={() =>
            navigate(
              state?.from ? -1 : "/seller/bigdelivery/dropper?page=0&perPage=10"
            )
          }
          startIcon={<ArrowLeftOutlined />}
          size="small"
          title="Go Back"
          sx={{ mb: 2 }}
        >
          Go Back
        </Button>
        <Typography variant="h5" mb={2}>
          Register Dropper
        </Typography>
        <form onSubmit={handleSubmit}>
          <TextField
            label="Username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Email"
            value={email}
            type="email"
            onChange={(e) => setEmail(e.target.value)}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Password"
            type={showPassword ? "text" : "password"}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            fullWidth
            margin="normal"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowPassword(!showPassword)}>
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          <TextField
            label="Nomor HP"
            value={phone}
            type="text"
            onChange={(e) => {
              setPhone(e.target.value);
            }}
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">+62</InputAdornment>
              ),
            }}
            margin="normal"
          />

          <div>
            <FormControl sx={{ m: 1, width: 300 }}>
              <InputLabel id="demo-multiple-chip-label">
                Seller Dropper
              </InputLabel>
              <Select
                labelId="demo-multiple-chip-label"
                id="demo-multiple-chip"
                multiple
                value={sellerDropper}
                onChange={handleChange}
                input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                renderValue={(selected) => (
                  <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                    {selected.map((value) => (
                      <Chip key={value} label={value} />
                    ))}
                  </Box>
                )}
                MenuProps={MenuProps}
              >
                {data.data.map((name, i) => (
                  <MenuItem
                    key={name?.id}
                    value={name?.username}
                    style={getStyles(name?.username, sellerDropper, theme)}
                  >
                    {`${name?.username}`}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>

          <TextField
            label="Alamat Dropper"
            multiline
            rows={4}
            value={alamat}
            onChange={(e) => setAlamat(e.target.value)}
            fullWidth
            margin="normal"
          />

          <Button
            type="submit"
            variant="contained"
            color="primary"
            sx={{ float: "right" }}
          >
            {loading ? (
              <div className="center">
                <CircularProgress size={10} />
              </div>
            ) : (
              "Register"
            )}
          </Button>
        </form>
      </Box>
    </Fragment>
  );
};
