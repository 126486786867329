import {Fragment} from 'react';
import {useParams, useLocation, useNavigate} from 'react-router-dom';
import {
	Button,
	Divider,
} from '@mui/material';
import {ArrowLeftOutlined} from '@mui/icons-material';
import {GroupCreate} from './group';
import {AdminCreate} from './admin';
import {ModuleCreate} from './modules';
import {PrivilegeCreate} from './privileges';

export const HomeCreate = () => {
	const {module: moduleName} = useParams(),
		{state} = useLocation(),
		push = useNavigate();

	return (
		<Fragment>
			<Button
				startIcon={<ArrowLeftOutlined/>}
				onClick={() => push(state && state.from ? state.from : `/dashboard`)}>Go Back</Button>

			<Divider/>

			{moduleName === 'group'
				?	<GroupCreate/>
				:	moduleName === 'admin'
					?	<AdminCreate/>
					:	moduleName === 'modules'
						?	<ModuleCreate/>
						:	moduleName === 'privileges'
							?	<PrivilegeCreate/>
							:	null}
		</Fragment>
	)
}