import { useEffect, Fragment, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";

import {
  Grid,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  IconButton,
  TablePagination,
  CircularProgress,
  Alert,
  Button,
  Tooltip,
  Typography,
  Box,
  FormControl,
  InputLabel,
  Select,
  OutlinedInput,
  Chip,
  MenuItem,
  ListItem,
  ListItemText,
  List,
  ListItemIcon,
  Divider,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { AddCircleOutline, AddOutlined } from "@mui/icons-material";
import {
  useGetAllSellerSbd,
  useListUserDropperSbd,
  useUpdateSellerDropper,
} from "../../services/user-seller";
import { TablePaginationActions } from "../../components/spam/pagination";
import { SearchHead } from "../../components/spam/inputs";
import { BasicModal, UIAlert } from "../../components/spam/modal";
import ListIcon from "@mui/icons-material/List";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, sellerDropper, theme) {
  return {
    fontWeight:
      sellerDropper.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export const DropperBigdiPage = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const {
    onProcess: updateProcess,
    res: updateRes,
    setRes: updateSetRes,
    loading: updateLoading,
    openSnack,
    setOpenSnack,
  } = useUpdateSellerDropper();
  const { loading, onProcess, res, data, push, query, setRes } =
      useListUserDropperSbd(),
    [values, setValues] = useState("");
  const { onProcess: listSeller, data: allSeller } = useGetAllSellerSbd();
  const [showModal, setShowModal] = useState(false);
  const [showModalSeller, setShowModalSeller] = useState(false);
  const [sellerDropper, setsellerDropper] = useState([]);
  const [dropperId, setDropperId] = useState(0);
  const [listSellerModal, setListSellerModal] = useState([]);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setsellerDropper(typeof value === "string" ? value.split(",") : value);
  };

  useEffect(() => {
    onProcess();
  }, [onProcess]);

  useEffect(() => {
    if (query && query.search) setValues(query.search);
    else setValues("");
  }, [query]);

  return (
    <>
      <UIAlert
        open={openSnack}
        onClose={() => updateSetRes({ status: "", message: "" })}
        res={updateRes}
      />
      {/* MODAL ADD SELLER */}
      <BasicModal
        open={showModal}
        onClose={() => {
          setShowModal(false);
        }}
      >
        <Grid
          container
          display="flex"
          width="100%"
          justifyContent="center"
          flexDirection="column"
          alignItems="center"
        >
          <Typography variant="h6" component="h2" gutterBottom>
            Add Seller
          </Typography>
          {/* <TextField
              label="Seller Name"
              value={newSellerName}
              onChange={(event) => setNewSellerName(event.target.value)}
              fullWidth
              margin="normal"
            /> */}

          <div>
            <FormControl sx={{ m: 1, width: 300 }}>
              <InputLabel id="demo-multiple-chip-label">
                Seller Dropper
              </InputLabel>
              <Select
                labelId="demo-multiple-chip-label"
                id="demo-multiple-chip"
                multiple
                value={sellerDropper}
                onChange={handleChange}
                input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                renderValue={(selected) => (
                  <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                    {selected.map((value) => (
                      <Chip key={value} label={value} />
                    ))}
                  </Box>
                )}
                MenuProps={MenuProps}
              >
                {allSeller.data.map((name, i) => (
                  <MenuItem
                    key={name?.id}
                    value={name?.username}
                    style={getStyles(name?.username, sellerDropper, theme)}
                  >
                    {`${name?.username}`}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <Fragment>
            <Grid
              container
              display="flex"
              width="100%"
              justifyContent="space-between"
              alignItems="center"
              paddingX={5}
              marginTop={5}
            >
              <Button
                variant="outlined"
                color="error"
                size="small"
                title="cancel Add"
                onClick={() => {
                  setsellerDropper([]);
                  setDropperId(0);
                  setShowModal(false);
                }}
              >
                cancel
              </Button>

              <Button
                color="info"
                variant="outlined"
                size="small"
                title="confirm add"
                onClick={() => {
                  updateProcess(sellerDropper, dropperId);
                  setDropperId(0);
                  setsellerDropper([]);
                  setShowModal(false);

                  setTimeout(() => {
                    window.location.reload();
                  }, 1000);
                }}
              >
                Confirm
              </Button>
            </Grid>
          </Fragment>
        </Grid>
      </BasicModal>

      {/* MODAL LIST SELLER */}
      <BasicModal
        open={showModalSeller}
        onClose={() => {
          setListSellerModal([]);
          setShowModalSeller(false);
        }}
      >
        <Grid
          container
          display="flex"
          width="100%"
          justifyContent="center"
          flexDirection="column"
        >
          <Typography variant="h6" component="h2" gutterBottom>
            List Seller
          </Typography>
          {listSellerModal?.length ? (
            listSellerModal.map((e, i) => (
              <List
                key={i}
                sx={{
                  width: "100%",
                  maxWidth: 360,
                  bgcolor: "background.paper",
                }}
              >
                <ListItem>
                  <ListItemText primary={e.username} secondary={e.email} />
                </ListItem>
                <Divider />
              </List>
            ))
          ) : (
            <Alert variant="standard" severity="warning">
              No Data
            </Alert>
          )}
        </Grid>
      </BasicModal>

      <Fragment>
        <Grid item width="100%" justifyContent="flex-start" display="flex">
          <Tooltip title="Register Dropper">
            <Button
              startIcon={<AddOutlined />}
              onClick={() => navigate("/seller/bigdelivery/dropper/create")}
              variant="outlined"
              size="small"
              color="success"
            >
              Register Dropper
            </Button>
          </Tooltip>
        </Grid>
        {/* <Grid item width="100%" justifyContent="flex-end" display="flex">
          <SearchHead
            values={values}
            placeholder="Search Dropper here"
            onEnter={(variable) =>
              push({ ...query, page: 0, search: variable })
            }
          />
        </Grid> */}

        <TableContainer style={{ minHeight: "50vh", maxHeight: "80vh" }}>
          <Table style={{ width: "100%" }} stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>No</TableCell>
                <TableCell>Username</TableCell>
                <TableCell>Email</TableCell>
                <TableCell align="center">Total Seller</TableCell>
                <TableCell align="center">Tambah Seller</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.rows.length > 0
                ? data.rows.map((seller, index) => (
                    <TableRow key={index} hover>
                      <TableCell>{index + 1}</TableCell>
                      <TableCell>{seller?.username || "-"}</TableCell>
                      <TableCell>{seller?.email || "-"}</TableCell>
                      <TableCell align="center">
                        {seller?.Users.length || "-"}
                        {seller?.Users.length ? (
                          <IconButton
                            title="Detail Seller"
                            style={{ marginLeft: 5 }}
                            size="small"
                            color="primary"
                            onClick={() => {
                              setListSellerModal(seller?.Users);
                              setShowModalSeller(true);
                            }}
                          >
                            <ListIcon />
                          </IconButton>
                        ) : null}
                      </TableCell>
                      <TableCell align="center">
                        <IconButton
                          title="add seller"
                          style={{ marginLeft: 5 }}
                          size="small"
                          color="primary"
                          onClick={(e) => {
                            e.preventDefault();
                            setDropperId(seller?.id);
                            setShowModal(true);
                            // navigate(
                            //   `/seller/bigdelivery/app/store?userId=${seller.id}`
                            // );
                            listSeller();
                          }}
                        >
                          <AddCircleOutline />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))
                : null}
            </TableBody>
          </Table>

          {loading ? (
            <div className="center">
              <CircularProgress size={25} />
            </div>
          ) : res.message ? (
            <Alert variant="standard" severity="error">
              {res.message}
            </Alert>
          ) : data.rows.length === 0 ? (
            <Alert variant="standard" severity="warning">
              No Data
            </Alert>
          ) : null}
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[5, 10, 15, 25]}
          component="div"
          count={data.count}
          rowsPerPage={parseInt(query?.perPage || "10", 10)}
          page={data.count === 0 ? 0 : parseInt(query?.page || "0", 10)}
          onPageChange={(_, newPage) =>
            push(
              query
                ? { ...query, page: newPage }
                : { page: newPage, perPage: "10" }
            )
          }
          onRowsPerPageChange={({ target: { value = "10" } }) =>
            push(
              query
                ? { ...query, page: 0, perPage: parseInt(value, 10) }
                : { page: 0, perPage: parseInt(value, 10) }
            )
          }
          ActionsComponent={TablePaginationActions}
        />
      </Fragment>
    </>
  );
};
