import {Fragment, useEffect, useState} from 'react';
import {
	TableContainer,
	Table,
	Grid,
	TableBody,
	Alert,
	TableCell,
	TableRow,
	Checkbox,
	CircularProgress,
	TablePagination,
} from '@mui/material';
import {EnchancedTableHead, TablePaginationActions} from '../../components/spam/pagination';
import {convertDate} from '../../helpers/custom';
import {SearchHead} from '../../components/spam/inputs';
import {useMyPrivilegeLists} from '../../services/auth';


export const MyPrivilegePage = () => {
	const {onProcess, loading, res, data, onPush, parseQuery, offset, limit} = useMyPrivilegeLists(),
		[values, setValues] = useState('');


	function handleRequestSort (_, property) {
		if(!property)
			return;
		let query = {...parseQuery};
		query.orderBy = property;
		query.sortBy = parseQuery.orderBy !== property ? 'desc' : parseQuery.sortBy === 'asc' ? 'desc' : 'asc';

		onPush({page: 1, query});
	}

	useEffect(() => {
		onProcess();
	}, [onProcess])
	
	useEffect(() => {
		if(parseQuery && parseQuery.search)
			setValues(parseQuery.search);
		else
			setValues('');
	}, [parseQuery]);
	
	return (
		<Fragment>
			<Grid item width='100%' justifyContent='space-between' display='flex'>
				<Grid/>

				<SearchHead
					values={values}
					placeholder='Search module here'
					onEnter={variable => onPush({page: 1, query: {...parseQuery, search: variable}})}/>
			</Grid>

			<TableContainer style={{minHeight: '50vh', maxHeight: '70vh'}}>
				<Table style={{width: '100%'}} stickyHeader>
					<EnchancedTableHead
						hideCheckbox
						order={parseQuery.sortBy || 'desc'}
						orderBy={parseQuery.orderBy || 'create_date'}
						onRequestSort={handleRequestSort}
						rowCount={data.data.length}
						headCells={[
							{id: 1, original: '', label: 'No'},
							{id: 2, original: 'create_date', label: 'Create Date'},
							{id: 3, original: 'update_date', label: 'Last Update'},
							{id: 4, original: '', label: 'Module Name'},
							{id: 5, original: '', label: 'Group Name'},
							{id: 6, original: '', label: 'Create Data'},
							{id: 7, original: '', label: 'Read Data'},
							{id: 8, original: '', label: 'Update Data'},
							{id: 9, original: '', label: 'Delete Data'},
						]}/>
					<TableBody>
						{data.data.length > 0 && !loading
							?	data.data.map((option, index) => {
								const {date, time} = convertDate(option.create_date),
									{date: _lastDate, time: _lastTime} = convertDate(option.update_date);
								return (
									<TableRow key={index} hover>
										<TableCell>{index + 1}</TableCell>
										<TableCell>
											{date}<br/>
											{time}
										</TableCell>
										<TableCell>
											{_lastDate}<br/>
											{_lastTime}
										</TableCell>
										
										<TableCell>
											{option.module_name || '-'}
										</TableCell>
										<TableCell>
											{option.group_name || '-'}
										</TableCell>
										<TableCell>
											<Checkbox
												disabled
												checked={option.create || false}
												color={option.create ? 'success' : 'error'}
												onChange={() => null}/>
										</TableCell>
										<TableCell>
											<Checkbox
												disabled
												checked={option.read || false}
												color={option.read ? 'success' : 'error'}
												onChange={() => null}/>
										</TableCell>
										<TableCell>
											<Checkbox
												disabled
												checked={option.update || false}
												color={option.update ? 'success' : 'error'}
												onChange={() => null}/>
										</TableCell>
										<TableCell>
											<Checkbox
												disabled
												checked={option.delete || false}
												color={option.delete ? 'success' : 'error'}
												onChange={() => null}/>
										</TableCell>
									</TableRow>
								)
							})
							:	null}
					</TableBody>
				</Table>
				{loading
					?	<div className="center">
							<CircularProgress size={25}/>
						</div>
					:	res.message
						?	<Alert variant='standard' severity='error'>{res.message}</Alert>
						:	data.data.length === 0
							?	<Alert variant='standard' severity='warning'>No Data</Alert>
							:	null}
			</TableContainer>

			<TablePagination
				rowsPerPageOptions={[5, 10, 15, 25]}
				component='div'
				count={data.count}
				rowsPerPage={parseInt(limit || '10', 10)}
				page={data.count === 0 ? 0 : parseInt(offset || '1', 10) - 1}
				onPageChange={(_, newPage) => onPush({page: newPage + 1})}
				onRowsPerPageChange={({target: {value = '10'}}) => onPush({page: 1, perPage: parseInt(value, 10)})}
				ActionsComponent={TablePaginationActions}/>
		</Fragment>
	)
}