import { useEffect, Fragment, useState, useMemo } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  Grid,
  TableContainer,
  Table,
  Typography,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Button,
  ListItem,
  Stack,
  ListItemText,
  IconButton,
  TablePagination,
  Alert,
  CircularProgress,
  Menu,
  MenuItem,
  // TextField,
  // FormControl,
  // InputLabel,
  // Select,
  // LinearProgress,
} from "@mui/material";
import {
  UploadFileOutlined,
  HistoryEduOutlined,
  ImportExportOutlined,
  RemoveCircleOutlineOutlined,
  PersonOutlineOutlined,
  // Send,
  LocalShippingOutlined,
} from "@mui/icons-material";
import { useList, useExportTransactions } from "../../services/transactions";
import { convertDataToQuery } from "../../helpers/custom";
import { exportFileExcelNew } from "../../helpers/excel";
import { UIAlert, BasicModal, RequestModal } from "../../components/spam/modal";
import { TablePaginationActions } from "../../components/spam/pagination";
import {
  SearchHead,
  LinearProgressWithLabel,
} from "../../components/spam/inputs";
// import { AuthContext } from "../../utils/auth";
import { UserSellerList } from "../../components/user-seller";
import { RangeDateTimePicker } from "../../components/spam/dateTime";
// import { LocalizationProvider } from "@mui/x-date-pickers";
// import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
// import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
// import gtw from "../../apis/gtw";
// import { handleError } from "../../services/helpers";

export const TransactionPage = () => {
  const { loading, onProcess, res, data, push, query /*setNextPage, lastId*/ } =
      useList(),
    {
      res: _res,
      setRes,
      loading: loadExport,
      progress,
      // itemLength,
      onProcess: exportTransaction,
    } = useExportTransactions(),
    [showSeller, setShowSeller] = useState(false),
    [values, setValues] = useState(""),
    [options, setOptions] = useState(false),
    [dateFilter, setShowDateFilter] = useState(false),
    // { headers } = useContext(AuthContext),
    // [showReportWa, setShowReportWa] = useState(false),
    // [notif, setNotif] = useState({ status: "", message: "" }),
    // [date, setDate] = useState(""),
    // [threePl, setThreePl] = useState(""),
    // [selectedThreePl, setSelectedThreePl] = useState("ALL"),
    [requestId, setRequestId] = useState(null),
    [anchorEl, setAnchorEl] = useState(null),
    openThreePl = Boolean(anchorEl),
    navigate = useNavigate(""),
    [searchParams] = useSearchParams(),
    params = Object.fromEntries([...searchParams]),
    mountFilter = useMemo(() => {
      if (!query)
        return {
          sellerId: false,
          dateRange: { value: false, minDate: "", maxDate: "" },
        };

      let dateRange = {
        value: query.minDate && query.maxDate ? true : false,
        minDate: "",
        maxDate: "",
      };

      if (dateRange.value)
        dateRange = {
          ...dateRange,
          minDate: query.minDate,
          maxDate: query.maxDate,
        };

      return { sellerId: query.sellerId ? true : false, dateRange };
    }, [query]);

  async function exportData(extensionChoose) {
    // let itemLength = 0;
    if (data.data.length === 0)
      return setRes({ status: "error", message: "No Data" });
    const perPage = parseInt(query.perPage || "10", 10),
      totalItem = data.data.length;

    if (totalItem < perPage) {
      exportFileExcelNew(data.data, "ReportTransactions", extensionChoose);
      return;
    }

    await exportTransaction(query, extensionChoose, data.count /*, lastId*/);

    setOptions(false);
  }

  // async function sendReport() {
  //   try {
  //     if (!date) {
  //       setNotif({
  //         status: "error",
  //         message: "Tolong isi tanggal",
  //         type: "date",
  //       });
  //       return;
  //     } else if (!threePl) {
  //       setNotif({
  //         status: "error",
  //         message: "tolong isi jenis expedisi",
  //         type: "expedisi",
  //       });
  //     }

  //     await gtw({
  //       method: "post",
  //       url: `/whatsapp/send_report?date=${date}&threepl=${threePl}&status=multiple`,
  //       headers,
  //     });
  //     setDate("");
  //     setShowReportWa(false);
  //     setRes({ status: "ok", message: "Kirim report berhasil" });
  //     setTimeout(() => setNotif(""), 5000);
  //   } catch (err) {
  //     setDate("");
  //     const message = handleError(err);
  //     if (message.cancel) return true;
  //     setRes({ status: "error", message });
  //     setShowReportWa(false);
  //     setTimeout(() => setNotif(""), 5000);
  //   }
  // }

  useEffect(() => {
    onProcess();
    // if (query.minDate || query.search) {
    // } else {
    //   return setRes({
    //     status: "warning",
    //     message: "Pilih Tanggal Terlebih dahulu",
    //   });
    // }
  }, [onProcess]);

  useEffect(() => {
    // if (!query.minDate) setShowDateFilter(true);
    if (query && query.search) setValues(query.search);
    else setValues("");
  }, [query]);

  return (
    <Fragment>
      {requestId !== null && requestId ? (
        <RequestModal open close={(_) => setRequestId(null)} id={requestId} />
      ) : null}
      {options ? (
        <BasicModal
          title="Choose Extension *jika format xlsx atau xls error, coba download dengan csv"
          open
          onClose={(_) => setOptions(false)}
        >
          <Stack direction="row" spacing={1} alignItems="center">
            {/* <Button
              variant="outlined"
              color="primary"
              size="small"
              onClick={(_) => exportData(".csv")}
            >
              .CSV
            </Button> */}
            {/* <Button
              variant="outlined"
              color="primary"
              size="small"
              onClick={(_) => exportData(".xls")}
            >
              .XLS
            </Button> */}
            <Button
              variant="outlined"
              color="primary"
              size="small"
              onClick={(_) => exportData(".xlsx")}
            >
              .XLSX
            </Button>
            {/* <Button
              variant="outlined"
              color="primary"
              size="small"
              onClick={(_) => exportData(".excel")}
            >
              .EXCEL
            </Button> */}
          </Stack>
        </BasicModal>
      ) : null}
      {loadExport ? (
        <BasicModal title="Loading..." open onClose={() => null}>
          <Grid
            container
            spacing={2}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Grid item>
              <LinearProgressWithLabel value={progress} />
              <Typography variant="h6" color="red" textAlign="center">
                Your request is being processed.
                <br />
                Please don't leave the page
              </Typography>
            </Grid>
          </Grid>
        </BasicModal>
      ) : // <BasicModal open onClose={() => null}>
      //   <Grid
      //     container
      //     spacing={2}
      //     style={{
      //       display: "flex",
      //       alignItems: "center",
      //       justifyContent: "center",
      //     }}
      //   >
      //     <Grid item>
      //       <Typography variant="h6" color="red" textAlign="center">
      //         Proses export sedang berlangsung.
      //         <br />
      //         Harap ditunggu
      //       </Typography>
      //     </Grid>
      //     <Grid
      //       sx={{
      //         mt: 5,
      //         display: "flex",
      //         width: "100%",
      //         justifyContent: "center",
      //       }}
      //     >
      //       <CircularProgress disableShrink />
      //     </Grid>
      //   </Grid>
      // </BasicModal>
      null}
      <UIAlert
        open={_res.status && _res.message ? true : false}
        onClose={() => setRes({ ...res, status: "", message: "" })}
        res={_res}
      />
      {dateFilter ? (
        <RangeDateTimePicker
          onFilter={({ startDate, endDate }) =>
            (_) => {
              console.log(startDate);
              push({ ...query, minDate: startDate, maxDate: endDate });
              setShowDateFilter(false);
            }}
          open
          close={setShowDateFilter}
        />
      ) : null}
      {showSeller ? (
        <UserSellerList
          open
          onSelect={(seller) => {
            push({ ...query, page: 0, sellerId: seller.id });
            setShowSeller(false);
          }}
          close={setShowSeller}
        />
      ) : null}
      {/* <BasicModal open={showReportWa} onClose={() => null}>
        <Grid
          container
          display="flex"
          width="100%"
          justifyContent="center"
          flexDirection="column"
          alignItems="center"
        >
          <Typography variant="h4" component="h2" style={{ marginBottom: 20 }}>
            Send Report
          </Typography>
          <Fragment>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DesktopDatePicker
                label="Choose Date"
                inputFormat="dd/MM/yyyy"
                value={date}
                onChange={(e) => {
                  setDate(e);
                  setNotif("");
                }}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
            {notif.type === "date" ? (
              <Grid>
                <Alert severity={notif.status === "ok" ? "success" : "error"}>
                  {notif.message}{" "}
                </Alert>
              </Grid>
            ) : null}
            <FormControl sx={{ m: 1, minWidth: 120, marginTop: 5 }}>
              <InputLabel id="demo-simple-select-helper-label">
                Expedisi
              </InputLabel>
              <Select
                labelId="demo-simple-select-helper-label"
                id="demo-simple-select-helper"
                value={threePl}
                label="Expedisi"
                onChange={(e) => {
                  setThreePl(e.target.value);
                  setNotif("");
                }}
              >
                <MenuItem value={"jnt"}>JNT</MenuItem>
                <MenuItem value={"jne"}>JNE</MenuItem>
                <MenuItem value={"ninja express"}>Ninja Express</MenuItem>
                <MenuItem value={"anteraja"}>Anteraja</MenuItem>
                <MenuItem value={"shopee"}>Shopee</MenuItem>
                <MenuItem value={"lex"}>Lex</MenuItem>
                <MenuItem value={"id express"}>Id Expres</MenuItem>
                <MenuItem value={"sicepat"}>SiCepat</MenuItem>
                <MenuItem value={"other"}>other</MenuItem>
              </Select>
            </FormControl>
            {notif.type === "expedisi" ? (
              <Grid>
                <Alert severity={notif.status === "ok" ? "success" : "error"}>
                  {notif.message}{" "}
                </Alert>
              </Grid>
            ) : null}
          </Fragment>

          <Fragment>
            <Grid
              container
              display="flex"
              width="100%"
              justifyContent="space-between"
              alignItems="center"
              paddingX={5}
              marginTop={5}
            >
              <Button
                variant="outlined"
                color="error"
                size="small"
                title="Delete"
                onClick={() => {
                  setDate("");
                  setNotif("");
                  setThreePl("");
                  setShowReportWa(false);
                }}
              >
                cancel
              </Button>

              <Button
                color="info"
                variant="outlined"
                size="small"
                title="Cancel Delete"
                onClick={() => sendReport()}
              >
                Confirm
              </Button>
            </Grid>
          </Fragment>
          <Grid item />
        </Grid>
      </BasicModal> */}
      {!loading ? (
        <Grid item width="100%" justifyContent="space-between" display="flex">
          <Grid item display="flex">
            <Button
              startIcon={<UploadFileOutlined />}
              onClick={() =>
                navigate(`/transactions/upload`, {
                  state: {
                    from: `/transactions${
                      query ? convertDataToQuery(query) : ""
                    }`,
                  },
                })
              }
              variant="outlined"
              color="success"
              size="small"
            >
              Upload
            </Button>

            {mountFilter.sellerId ? (
              <Button
                size="small"
                variant="outlined"
                color="error"
                style={{ marginLeft: 5 }}
                onClick={() => {
                  let obj = { ...query };
                  delete obj.sellerId;
                  push(obj);
                }}
                startIcon={<RemoveCircleOutlineOutlined />}
              >
                Remove Filter User
              </Button>
            ) : (
              <Button
                size="small"
                variant="outlined"
                style={{ marginLeft: 5 }}
                color="primary"
                onClick={() => setShowSeller(true)}
                startIcon={<PersonOutlineOutlined />}
              >
                Choose Seller
              </Button>
            )}
            <Button
              size="small"
              variant="outlined"
              style={{ marginLeft: 5 }}
              color="primary"
              id="expedition-button"
              aria-controls={openThreePl ? "expedition-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={openThreePl ? "true" : undefined}
              onClick={(e) => setAnchorEl(e.currentTarget)}
              startIcon={<LocalShippingOutlined />}
            >
              {params.threePl === "ALL" ? "Choose Three PL" : params.threePl}
            </Button>
            <Menu
              id="expedition-menu"
              anchorEl={anchorEl}
              open={openThreePl}
              onClose={() => push({ ...query, threePl: "all" })}
              MenuListProps={{
                "aria-labelledby": "expedition-button",
              }}
            >
              {[
                "ALL",
                "JNE",
                "JNT",
                "ANTER AJA",
                "NINJA",
                "ID",
                "LION",
                "SICEPAT",
                "SHOPEE",
                "LEX",
                "WAHANA",
                "OTHER",
              ].map((el, i) => (
                <MenuItem
                  key={i}
                  onClick={(e) => {
                    // setSelectedThreePl(el.toLowerCase());
                    setAnchorEl(null);
                    console.log(el);
                    push({
                      ...query,
                      threePl: el.toLowerCase().replaceAll(" ", ""),
                    });
                  }}
                  value={params.threePl}
                >
                  {el}
                </MenuItem>
              ))}
            </Menu>

            {mountFilter.dateRange.value ? (
              <Button
                size="small"
                endIcon={<RemoveCircleOutlineOutlined />}
                variant="outlined"
                style={{ marginLeft: 5 }}
                color="error"
                onClick={() => push({ ...query, minDate: "", maxDate: "" })}
                title="Remove Filter Date"
              >
                {new Date(mountFilter.dateRange.minDate).toLocaleDateString(
                  "en-US",
                  { timeZone: "Asia/Jakarta" }
                )}{" "}
                -{" "}
                {new Date(mountFilter.dateRange.maxDate).toLocaleDateString(
                  "en-US",
                  { timeZone: "Asia/Jakarta" }
                )}
              </Button>
            ) : (
              <Button
                size="small"
                variant="outlined"
                style={{ marginLeft: 5 }}
                onClick={() => setShowDateFilter(true)}
                title="Filter date"
              >
                Choose Date Range
              </Button>
            )}

            <Button
              title="Export Excel"
              style={{ marginLeft: 5 }}
              endIcon={<ImportExportOutlined />}
              size="small"
              variant="outlined"
              color="secondary"
              onClick={(_) => setOptions(true)}
            >
              Export Data
            </Button>
            {/* <Button
              title="Send Report"
              style={{ marginLeft: 5 }}
              endIcon={<Send />}
              size="small"
              variant="outlined"
              onClick={(_) => setShowReportWa(true)}
            >
              Send Report
            </Button> */}
          </Grid>

          <SearchHead
            values={values}
            placeholder="Search here"
            onEnter={(variable) =>
              push({ ...query, page: 0, search: variable })
            }
          />
        </Grid>
      ) : null}

      <Grid item />

      <TableContainer style={{ minHeight: "50vh", maxHeight: "80vh" }}>
        <Table size="small" style={{ width: "100%" }} stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>No</TableCell>
              <TableCell>Shipping Date</TableCell>
              <TableCell>Expedisi Name</TableCell>
              <TableCell>Expedisi Service</TableCell>
              <TableCell>Awb</TableCell>
              <TableCell>Store Name</TableCell>
              <TableCell>Shipper Info</TableCell>
              <TableCell>Receiver Info</TableCell>
              <TableCell>Product Name</TableCell>
              <TableCell>Shipping Fee</TableCell>
              <TableCell>Weight</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.data.length > 0
              ? data.data.map((order, index) => (
                  <TableRow key={index} hover>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>
                      {order.tanggal_pengiriman
                        ? new Date(order.tanggal_pengiriman).toLocaleDateString(
                            "id-ID"
                          )
                        : "-"}
                    </TableCell>
                    <TableCell>{order?.three_PL || "-"}</TableCell>
                    <TableCell>{order?.service || "-"}</TableCell>
                    <TableCell>{order?.awb || "-"}</TableCell>
                    <TableCell>{order?.nama_toko || "-"}</TableCell>
                    <TableCell>
                      <ListItem disablePadding>
                        <ListItemText
                          primaryTypographyProps={{
                            fontSize: 13,
                            fontWeight: "bolder",
                          }}
                          primary={`${order?.nama_pengirim || "-"} - ${
                            order?.kontak_pengirim || "-"
                          }`}
                          secondary={order?.alamat_pengirim || "-"}
                        />
                      </ListItem>
                    </TableCell>
                    <TableCell>
                      <ListItem disablePadding>
                        <ListItemText
                          primaryTypographyProps={{
                            fontSize: 13,
                            fontWeight: "bolder",
                          }}
                          primary={`${order?.nama_penerima || "-"} - ${
                            order?.kontak_penerima || "-"
                          }`}
                          secondary={order?.alamat_penerima || "-"}
                        />
                      </ListItem>
                    </TableCell>
                    <TableCell>{order?.nama_barang || "-"}</TableCell>
                    <TableCell>{order?.ongkir || "-"}</TableCell>
                    <TableCell>{order?.berat || "-"}</TableCell>
                    <TableCell>
                      {order?.requestId ? (
                        <IconButton
                          color="primary"
                          variant="standard"
                          size="small"
                          onClick={() => setRequestId(order.requestId)}
                          title="History Request"
                        >
                          <HistoryEduOutlined />
                        </IconButton>
                      ) : (
                        "-"
                      )}
                    </TableCell>
                  </TableRow>
                ))
              : null}
          </TableBody>
        </Table>
        {loading ? (
          <div className="center">
            <CircularProgress size={25} />
          </div>
        ) : res.message ? (
          <Alert variant="standard" severity="error">
            {res.message}
          </Alert>
        ) : data.data.length === 0 ? (
          <Alert variant="standard" severity="error">
            No Data
          </Alert>
        ) : null}
      </TableContainer>

      <TablePagination
        rowsPerPageOptions={[5, 10, 15, 25, 50, 100, 200, 500]}
        component="div"
        // count={data.count}
        count={data.count}
        rowsPerPage={parseInt(query?.perPage || "10", 10)}
        page={data.count === 0 ? 0 : parseInt(query?.page || "0", 10)}
        onPageChange={(_, newPage) => {
          // if (newPage < query.page) {
          //   setNextPage(false);
          // } else {
          //   setNextPage(true);
          // }
          push(
            query
              ? { ...query, page: newPage }
              : { page: newPage, perPage: "10" }
          );
        }}
        onRowsPerPageChange={({ target: { value = "10" } }) =>
          push(
            query
              ? { ...query, page: 0, perPage: parseInt(value, 10) }
              : { page: 0, perPage: parseInt(value, 10) }
          )
        }
        ActionsComponent={TablePaginationActions}
      />
    </Fragment>
  );
};

export * from "./upload";
