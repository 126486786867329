import {
  Alert,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Chip,
  Modal,
  Zoom,
  Box,
  TextField,
  Select,
  MenuItem,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { Fragment, useEffect, useMemo, useState } from "react";
import { SearchHead } from "../../components/spam/inputs";
import {
  downloadData,
  getTotalDownload,
  uploadData,
  uploadDataJNE,
  useListDataScan,
  useManipulationData,
} from "../../services/scan";
import {
  convertCurrency,
  convertPrice,
  formatDate,
} from "../../helpers/custom";
import {
  Close,
  FindInPage,
  AutoFixHigh,
  DeleteForever,
  // UploadFileOutlined,
  Today,
  BrowserUpdatedOutlined,
} from "@mui/icons-material";
import { TablePaginationActions } from "../../components/spam/pagination";
import { read, utils, writeFile } from "xlsx";
import { compress, decompress } from "compress-json";
import { handleError } from "../../services/helpers";
import { BasicModal, ModalLoading } from "../../components/spam/modal";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import dayjs from "dayjs";
import ModalConfirmed from "./modalDownloadConfirmed";
// import ModalUploadDocument from "./modalUpload";

const Item = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1.5),
  textAlign: "left",
  color: theme.palette.text.secondary,
  fontSize: "1rem",
}));

const titleTable = [
  "Nama Toko",
  "Airwaybill",
  "Status",
  "Tanggal Scan",
  "Description",
  "Service",
  "Pengirim",
  "Penerima",
  "Tlp. Penerima",
  "Alamat Penerima",
  "Berat (kg)",
  "Nama Barang",
  "Ongkir",
];

export default function EasyDropPage() {
  const [values, setValues] = useState("");
  const [dataEdit, setDataEdit] = useState({});
  const [dataId, setDataId] = useState("");
  const [dataModal, setDataModal] = useState([]);
  const [openModalEdit, setOpenModalEdit] = useState(false);
  const { loading, onProcess, res, data, push, query } = useListDataScan();
  const { onDelete, onUpdate, loadingM, res: _res } = useManipulationData();
  const [errorUploadChecked, setErrorUploadChecked] = useState([]);
  const [loadingProgress, setLoadingProgress] = useState(false);
  const [openModalErrorChecked, setOpenModalErrorChecked] = useState(false);
  const [response, setResponse] = useState([]);
  const [showResponse, setShowResponse] = useState(false);
  const [open, setOpen] = useState(false);
  const [date, setDate] = useState(new Date());
  const [downloadDate, setDownloadDate] = useState(false);
  const [ShopName, setShopName] = useState("");
  const [openModalConfirmed, setOpenModalConfirmed] = useState(false);
  // const [isUpload, setIsUpload] = useState(false);
  // const [idShop, setIdShop] = useState("");

  const listData = data.data;

  const downloadDataAction = async (e, command = "all") => {
    e.preventDefault();
    try {
      setResponse({
        status: "Loading...",
        message: "Proses download sedang berlangsung",
      });
      setLoadingProgress(true);
      const payload = {
        date: downloadDate,
        ShopName,
        state: command,
      };
      await new Promise((resolve) => setTimeout(resolve, 2000));
      let data = [];
      let newTotalData = await getTotalDownload({ payload });
      if (!newTotalData) {
        setResponse({ status: "error", message: "tidak ada data" });
        setLoadingProgress(false);
        setShowResponse(true);
        return;
      }
      let limitDownload = 2000;
      let page = 0;
      if (newTotalData > limitDownload) {
        let loop = Math.ceil(+newTotalData / limitDownload);
        let _data = [];
        for (let index = 0; index < loop; index++) {
          if (index === loop - 1) {
            _data = await downloadData({
              search: `?limitDownload=${newTotalData}&pageDownload=${
                page * limitDownload
              }`,
              payload,
            });
          } else {
            _data = await downloadData({
              search: `?limitDownload=${limitDownload}&pageDownload=${
                page * limitDownload
              }`,
              payload,
            });
          }
          page += 1;
          newTotalData -= limitDownload;
          data = data.concat(decompress(_data));
        }
      } else {
        data = await downloadData({ payload });
        data = decompress(data);
        // console.log(data);
      }
      let rows = data.map((e) => {
        return {
          "NAMA SELLER": e.shipper ?? "[kosong / butuh-update]",
          "TANGGAL SCAN": e.order_date,
          "NAMA BARANG": e.goods_name,
          "NAMA PENERIMA": e.receiver,
          AIRWAYBILL: e.awb.toUpperCase(),
          KETERANGAN: e.expedition,
          ONGKIR: e.cost ? convertCurrency(e.cost) : "",
          BERAT: e.weight ? `${e.weight} Kg` : "",
        };
      });
      let wscols = [
        { width: 24 },
        { width: 24 },
        { width: 24 },
        { width: 24 },
        { width: 24 },
        { width: 17 },
        { width: 20 },
        { width: 10 },
      ];

      const ws = utils.json_to_sheet(rows);
      ws["!cols"] = wscols;
      const wb = utils.book_new();
      utils.book_append_sheet(wb, ws, "Data");
      writeFile(wb, "Report.xlsx");

      setLoadingProgress(false);
    } catch (error) {
      setLoadingProgress(false);
      const message = await handleError(error);
      if (Array.isArray(message)) {
        if (message.length) {
          setResponse(message);
        }
      } else if (message === "Tidak ada koneksi") {
        setResponse({ status: message });
      } else {
        setResponse({ status: message });
      }
      setShowResponse(true);
    }
  };

  const handleFileUpdate = async (e) => {
    setLoadingProgress(true);
    setErrorUploadChecked([]);
    setResponse({
      status: "Proses upload sedang berlangsung",
      message: "Jangan buka tab lain",
    });
    e.preventDefault();
    try {
      const getFiles = (() => {
        try {
          return e.target.files[0];
        } catch (e) {
          return null;
        }
      })();

      // if (!idShop) throw new Error("Please choose dop agent first");

      const f = await getFiles.arrayBuffer();
      const wb = read(f);
      const sheet = wb.SheetNames;
      const ws = wb.Sheets[sheet[0]];
      Object.keys(ws).forEach((s) => {
        if (/c/i.test(s)) {
          if (s !== "C1") {
            if (typeof ws[s].v === "string") {
              ws[s].w = ws[s].w.split("/");

              const element = ws[s].w.splice(0, 1)[0];

              ws[s].w.splice(1, 0, element);
              ws[s].w = ws[s].w.join("/");
            }
          }
        }
      });

      const data = utils.sheet_to_json(ws, {
        raw: false,
      }); //? UBAH DATA DARI EXEL KE DALAM BENTUK JSON

      const excel = utils.sheet_to_json(ws, { header: 1 });
      let statusError = false;
      if (
        excel[0][0].trim() !== "No. Waybill" ||
        excel[0][18].trim() !== "Nama Pengirim" ||
        excel[0][23].trim() !== "Nama Penerima" ||
        excel[0][29].trim() !== "Alamat Penerima" ||
        excel[0][25].trim() !== "HP Penerima" ||
        excel[0][33].trim() !== "Nama Barang" ||
        excel[0][35].trim() !== "Berat Ditagih" ||
        excel[0][11].trim() !== "Biaya Kirim" ||
        excel[0][9].trim() !== "Cara Pembayaran" ||
        excel[0][16].trim() !== "Sumber Order"
      ) {
        setErrorUploadChecked([
          { status: "Format table salah harap menggunakan tamplate JNT" },
        ]);
        statusError = true;
      } else {
        let last = false;
        let _newData = { errors: [], totalUpdate: 0 };
        while (!last) {
          let newData = data.splice(0, 10000);
          last = data.length === 0;
          let compressed = compress(newData);

          //! UPLOAD EXEL, AKAN DIKIRIM KE BACKEND DAN AKAN DICREATE DI DATABASE.S
          const _data = await uploadData(compressed);
          _newData.errors = _newData.errors.concat(_data.errors);
          _newData.totalUpdate += _data.totalUpdate;
        }
        if (_newData.errors.length) {
          setErrorUploadChecked(_newData.errors);
          setOpenModalErrorChecked(true);
          statusError = true;
        }
      }

      setLoadingProgress(false);
      // setIsUpload(false);
      // setIdShop("");
      if (statusError) {
        setOpenModalErrorChecked(true);
        onProcess();
      } else {
        setResponse({
          status: "Success",
          message:
            "Berhasil update semua data, silahkan periksa kembali data tersebut",
        });
        setShowResponse(true);
        onProcess();
      }
    } catch (error) {
      const message = await handleError(error);
      if (Array.isArray(message)) {
        if (message.length) {
          setErrorUploadChecked(message);
        }
      } else if (message === "Tidak ada koneksi") {
        setErrorUploadChecked([{ status: message }]);
      } else {
        setErrorUploadChecked([{ status: message }]);
      }

      // setIsUpload(false);
      // setIdShop("");
      setLoadingProgress(false);
      setOpenModalErrorChecked(true);
    }
    e.target.value = null;
  };

  const handleFileUpdateJNE = async (e) => {
    setLoadingProgress(true);
    setErrorUploadChecked([]);
    setResponse({
      status: "Proses upload sedang berlangsung",
      message: "Jangan buka tab lain",
    });
    e.preventDefault();
    try {
      const getFiles = (() => {
        try {
          return e.target.files[0];
        } catch (e) {
          return null;
        }
      })();

      // if (!idShop) throw new Error("Please choose dop agent first");

      const f = await getFiles.arrayBuffer();
      const wb = read(f);
      const sheet = wb.SheetNames;
      const ws = wb.Sheets[sheet[0]];
      Object.keys(ws).forEach((s) => {
        if (/c/i.test(s)) {
          if (s !== "C1") {
            if (typeof ws[s].v === "string") {
              ws[s].w = ws[s].w.split("/");

              const element = ws[s].w.splice(0, 1)[0];

              ws[s].w.splice(1, 0, element);
              ws[s].w = ws[s].w.join("/");
            }
          }
        }
      });

      const data = utils.sheet_to_json(ws, {
        raw: false,
      }); //? UBAH DATA DARI EXEL KE DALAM BENTUK JSON

      const excel = utils.sheet_to_json(ws, { header: 1 });
      let statusError = false;
      if (
        excel[0][0].trim() !== "Nama Seller" ||
        excel[0][2].trim() !== "Nama Penerima" ||
        excel[0][3].trim() !== "Alamat Penerima" ||
        excel[0][4].trim() !== "Nomor Penerima" ||
        excel[0][5].trim() !== "Nama Barang" ||
        excel[0][6].trim() !== "Berat" ||
        excel[0][9].trim() !== "Ongkir" ||
        excel[0][10].trim() !== "Airwaybill"
      ) {
        setErrorUploadChecked([
          { status: "Format table salah harap menggunakan tamplate JNE" },
        ]);
        statusError = true;
      } else {
        let last = false;
        let _newData = { errors: [], totalUpdate: 0 };
        while (!last) {
          let newData = data.splice(0, 10000);
          last = data.length === 0;
          let compressed = compress(newData);

          //! UPLOAD EXEL, AKAN DIKIRIM KE BACKEND DAN AKAN DICREATE DI DATABASE.S
          const _data = await uploadDataJNE(compressed);
          _newData.errors = _newData.errors.concat(_data.errors);
          _newData.totalUpdate += _data.totalUpdate;
        }
        if (_newData.errors.length) {
          setErrorUploadChecked(_newData.errors);
          setOpenModalErrorChecked(true);
          statusError = true;
        }
      }

      setLoadingProgress(false);
      // setIsUpload(false);
      // setIdShop("");
      if (statusError) {
        setOpenModalErrorChecked(true);
        onProcess();
      } else {
        setResponse({
          status: "Success",
          message:
            "Berhasil update semua data, silahkan periksa kembali data tersebut",
        });
        setShowResponse(true);
        onProcess();
      }
    } catch (error) {
      const message = await handleError(error);
      if (Array.isArray(message)) {
        if (message.length) {
          setErrorUploadChecked(message);
        }
      } else if (message === "Tidak ada koneksi") {
        setErrorUploadChecked([{ status: message }]);
      } else {
        setErrorUploadChecked([{ status: message }]);
      }

      // setIsUpload(false);
      // setIdShop("");
      setLoadingProgress(false);
      setOpenModalErrorChecked(true);
    }
    e.target.value = null;
  };

  useMemo(() => {
    const res = titleTable.map((item, idx) => {
      return {
        title: item,
        text: Object.values(dataEdit)[idx],
      };
    });
    setDataModal(res);
  }, [dataEdit]);

  useEffect(() => {
    onProcess();
  }, [onProcess]);

  useEffect(() => {
    if (query && query.search) setValues(query.search);
    else setValues("");
  }, [query]);

  return (
    <>
      <Grid item width="100%" justifyContent="space-between" display="flex">
        <Grid
          item
          display="flex"
          sx={{
            width: "100%",
          }}
        >
          {/* <Tooltip title="Make sure that you have this column *Airwaybill | *Nama Toko | Pengirim | Penerima | Alamat Penerima | Telepon Penerima | Nama Barang | Berat | Ongkir"> */}
          <Button
            component="label"
            size="small"
            style={{ marginRight: 10 }}
            color="info"
            // endIcon={<UploadFileOutlined />}
            variant="outlined"
          >
            Update Resi JNT
            <input type="file" hidden onChange={handleFileUpdate} />
          </Button>
          <Button
            component="label"
            size="small"
            style={{ marginRight: 10 }}
            color="info"
            // endIcon={<UploadFileOutlined />}
            variant="outlined"
          >
            Update Resi JNE
            <input type="file" hidden onChange={handleFileUpdateJNE} />
          </Button>
          {/* </Tooltip> */}
          <Button
            component="label"
            size="small"
            // style={{ marginRight: 10 }}
            color="info"
            endIcon={<BrowserUpdatedOutlined />}
            variant="outlined"
            // onClick={(e) => downloadDataAction(e)}
            onClick={() => setOpenModalConfirmed(true)}
          >
            Download List
          </Button>
          <IconButton
            title="View Detail"
            size="small"
            color="primary"
            sx={{
              ml: "14%",
            }}
            onClick={() => setOpen(true)}
          >
            <Today />
            Choose Date :{" "}
            {new Date(date).toLocaleString("en-US", {
              month: "long",
            })}{" "}
            {new Date(date).getFullYear()}
          </IconButton>
        </Grid>

        <SearchHead
          values={values}
          placeholder="Search Shop Here..."
          onEnter={(variable) => {
            push({ ...query, page: 0, ShopName: variable });
            setShopName(variable);
          }}
        />
      </Grid>

      {/* modal upload */}
      {/* <ModalUploadDocument
        openModal={isUpload}
        setOpenModal={setIsUpload}
        // chooseShop={setIdShop}
        handleFile={handleFileUpdate}
        // idShop={idShop}
      /> */}

      {/* modal confirmed */}
      <ModalConfirmed
        openModalConfirmed={openModalConfirmed}
        setOpenModalConfirmed={setOpenModalConfirmed}
        downloadDataAction={downloadDataAction}
      />

      {/* modal datepicker */}
      {open ? (
        <BasicModal open onClose={() => null}>
          <Grid
            container
            display="flex"
            width="100%"
            justifyContent="center"
            alignItems="center"
          >
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                views={["year", "month"]}
                label="Year and Month"
                minDate={dayjs("2022-01-01")}
                maxDate={dayjs("2030-12-31")}
                value={date}
                onChange={(newValue) => {
                  setDate(newValue);
                  setDownloadDate(newValue);
                }}
                renderInput={(params) => (
                  <TextField {...params} helperText={null} />
                )}
              />
            </LocalizationProvider>
            <Fragment>
              <Grid
                container
                display="flex"
                width="50%"
                justifyContent="space-between"
                alignItems="center"
                marginTop={5}
              >
                <Button
                  variant="outlined"
                  color="error"
                  size="small"
                  title="Delete"
                  onClick={() => {
                    setDate(new Date());
                    setOpen(false);
                  }}
                >
                  cancel
                </Button>

                <Button
                  color="info"
                  variant="outlined"
                  size="small"
                  title="Confirm"
                  onClick={() => {
                    push({
                      ...query,
                      date: `${new Date(date).toLocaleString("en-US", {
                        month: "long",
                      })}-${new Date(date).getFullYear()}`,
                    });
                    setOpen(false);
                  }}
                >
                  Confirm
                </Button>
              </Grid>
            </Fragment>
          </Grid>
        </BasicModal>
      ) : null}

      {/* snackbar */}
      <Modal
        open={showResponse}
        onClose={() => setShowResponse(false)}
        sx={{
          backdropFilter: "blur(5px)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Zoom in={showResponse}>
          <Box
            sx={{
              width: "50%",
              height: "50%",
              backgroundColor: "white",
              borderRadius: 1,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                height: "10%",
                width: "100%",
              }}
            >
              <Button
                size="small"
                sx={{
                  color: "#bababa",
                  "&:hover": {
                    backgroundColor: "transparent",
                    color: "#333333",
                  },
                }}
                onClick={() => {
                  setShowResponse(false);
                }}
              >
                <Close />
              </Button>
            </Box>
            <Box
              sx={{
                height: "85%",
                width: "100%",
              }}
            >
              <TableContainer
                sx={{
                  backgroundColor: "#ffffff",
                  maxHeight: "100%",
                }}
              >
                <Table
                  sx={{
                    backgroundColor: "#ffffff",
                  }}
                  size="small"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell>Status</TableCell>
                      <TableCell>Keterangan</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {response ? (
                      <TableRow>
                        <TableCell>{response.status}</TableCell>
                        <TableCell>{response.message}</TableCell>
                      </TableRow>
                    ) : null}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Box>
        </Zoom>
      </Modal>

      {/* modal loading */}
      <ModalLoading
        loadingUpload={loadingProgress}
        text={`${response.status}, ${response.message}`}
      />

      {/* modal edit data */}
      <Modal
        open={openModalEdit}
        sx={{
          backdropFilter: "blur(5px)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        onClose={() => setOpenModalEdit(false)}
      >
        <Zoom in={openModalEdit}>
          <Box
            sx={{
              width: "41%",
              height: "80%",
              backgroundColor: "white",
              borderRadius: 1,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              overflowY: "scroll",
              pt: "1rem",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                height: "7%",
                width: "100%",
              }}
            >
              <Button
                size="small"
                sx={{
                  color: "#bababa",
                  "&:hover": {
                    backgroundColor: "transparent",
                    color: "#333333",
                  },
                }}
                onClick={() => {
                  setOpenModalEdit(false);
                }}
              >
                <Close />
              </Button>
            </Box>
            <Box
              sx={{
                height: "100%",
                width: "100%",
                px: "1.3rem",
              }}
            >
              <Box sx={{ display: "flex", flexDirection: "column", pt: "0px" }}>
                {dataModal.map((item, i) => (
                  <Grid key={i} container spacing={1}>
                    <Grid item xs={4}>
                      <Item>{item.title}</Item>
                    </Grid>
                    <Grid item xs={8}>
                      <Item>
                        {item.title === "Airwaybill" ? (
                          item.text?.toUpperCase()
                        ) : item.title === "Status" ? (
                          <Select
                            value={item.text}
                            onChange={(event) =>
                              setDataEdit({
                                ...dataEdit,
                                status: event.target?.value,
                              })
                            }
                            displayEmpty
                            inputProps={{ "aria-label": "Without label" }}
                            size="small"
                            margin="none"
                            sx={{
                              borderRadius: 2,
                              backgroundColor: "#ffffff",
                            }}
                          >
                            <MenuItem value="on process">
                              <em>On Process</em>
                            </MenuItem>
                            <MenuItem value={"confirmed"}>Confirmed</MenuItem>
                            <MenuItem value={"return"}>Return</MenuItem>
                          </Select>
                        ) : item.title === "Tanggal Scan" ? (
                          formatDate(item.text)
                        ) : item.title === "Description" ? (
                          <TextField
                            value={item.text}
                            variant="standard"
                            size="small"
                            fullWidth
                            onChange={(e) =>
                              setDataEdit({
                                ...dataEdit,
                                expedition: e.target?.value.toUpperCase(),
                              })
                            }
                          />
                        ) : item.title === "Service" ? (
                          <TextField
                            value={item.text}
                            variant="standard"
                            size="small"
                            fullWidth
                            onChange={(e) =>
                              setDataEdit({
                                ...dataEdit,
                                service: e.target?.value.toUpperCase(),
                              })
                            }
                          />
                        ) : item.title === "Ongkir" && item.text ? (
                          convertPrice(item.text)
                        ) : item.text ? (
                          item.text
                        ) : (
                          "-"
                        )}
                      </Item>
                    </Grid>
                  </Grid>
                ))}
                <Grid container spacing={2} marginTop={0.1} marginBottom={2}>
                  <Grid
                    item
                    xs={6}
                    sx={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "end",
                    }}
                  >
                    <IconButton
                      sx={{
                        color: "#000000",
                        borderRadius: "0px",
                        gap: "5px",
                        ":hover": {
                          bgcolor: "orange",
                          color: "#fff",
                        },
                      }}
                      onClick={() => {
                        setOpenModalEdit(false);
                        onUpdate({ ...dataEdit, id: dataId });
                      }}
                    >
                      <AutoFixHigh />
                      Update
                    </IconButton>
                  </Grid>
                  <Grid item xs={6}>
                    <IconButton
                      sx={{
                        color: "#000000",
                        ":hover": {
                          bgcolor: "red",
                          color: "#fff",
                        },
                        borderRadius: "0px",
                        gap: "5px",
                      }}
                      onClick={() => {
                        setOpenModalEdit(false);
                        onDelete(dataId);
                      }}
                    >
                      <DeleteForever />
                      Delete
                    </IconButton>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Box>
        </Zoom>
      </Modal>

      {/* modal error */}
      <Modal
        open={openModalErrorChecked}
        sx={{
          backdropFilter: "blur(5px)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Zoom in={openModalErrorChecked}>
          <Box
            sx={{
              width: "50%",
              height: "50%",
              backgroundColor: "white",
              borderRadius: 1,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                height: "10%",
                width: "100%",
              }}
            >
              <Button
                size="small"
                sx={{
                  color: "#bababa",
                  "&:hover": {
                    backgroundColor: "transparent",
                    color: "#333333",
                  },
                }}
                onClick={() => {
                  setOpenModalErrorChecked(false);
                }}
              >
                <Close />
              </Button>
            </Box>
            <Box
              sx={{
                height: "85%",
                width: "100%",
              }}
            >
              <TableContainer
                sx={{
                  backgroundColor: "#ffffff",
                  maxHeight: "100%",
                }}
              >
                <Table
                  sx={{
                    backgroundColor: "#ffffff",
                  }}
                  size="small"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell>No.</TableCell>
                      <TableCell>Keterangan</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {/* {JSON.stringify(errorUploadChecked)} */}
                    {errorUploadChecked.length
                      ? errorUploadChecked.map((e, i) => (
                          <TableRow key={i}>
                            <TableCell>{i + 1}</TableCell>
                            <TableCell>
                              {`${e.awb ? e.awb : ""} ${
                                e.status ? e.status : ""
                              }`}
                            </TableCell>
                          </TableRow>
                        ))
                      : null}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Box>
        </Zoom>
      </Modal>

      <TableContainer style={{ minHeight: "50vh", maxHeight: "80vh" }}>
        <Table style={{ width: "100%" }} stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell sx={{ minWidth: 100 }}>Nama DOP</TableCell>
              <TableCell>Airwaybill</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Tanggal Scan</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Layanan</TableCell>
              <TableCell>Pengirim</TableCell>
              <TableCell>Penerima</TableCell>
              <TableCell>Metode Pembayaran</TableCell>
              <TableCell>Sumber Order</TableCell>
              <TableCell>Ongkir</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading || loadingM ? (
              <TableRow hover>
                <TableCell colSpan={11}>
                  <div className="center">
                    <CircularProgress size={25} />
                  </div>
                </TableCell>
              </TableRow>
            ) : (
              <>
                {listData.length > 0 &&
                  listData.map((item, index) => (
                    <TableRow key={index} hover>
                      <TableCell>{item.ShopName}</TableCell>
                      <TableCell>{item?.awb?.toUpperCase() || "-"}</TableCell>
                      <TableCell>
                        <Chip
                          label={item?.status}
                          color={
                            item?.status === "confirmed"
                              ? "success"
                              : item?.status === "return"
                              ? "warning"
                              : "info"
                          }
                          variant="outlined"
                          clickable
                        />
                      </TableCell>
                      <TableCell>
                        {item.order_date ? formatDate(item.order_date) : "-"}
                      </TableCell>
                      <TableCell>
                        {item?.expedition?.toUpperCase() || "-"}
                      </TableCell>
                      <TableCell>
                        {item?.service?.toUpperCase() || "-"}
                      </TableCell>
                      <TableCell>{item.shipper || "-"}</TableCell>
                      <TableCell>{item.receiver || "-"}</TableCell>
                      <TableCell>{item.payment_method || "-"}</TableCell>
                      <TableCell>{item.order_source || "-"}</TableCell>
                      <TableCell>
                        {item.cost === 0
                          ? ""
                          : item.cost > 0
                          ? convertPrice(item.cost)
                          : "-"}
                      </TableCell>
                      <TableCell>
                        <Tooltip title="Look the data!">
                          <IconButton
                            title="View Detail"
                            style={{ marginLeft: 5 }}
                            size="small"
                            color="primary"
                            onClick={() => {
                              if (!item.status) {
                                return;
                              } else {
                                setDataEdit({
                                  ...dataEdit,
                                  ShopName: item.ShopName,
                                  awb: item.awb,
                                  status: item.status,
                                  order_date: item.order_date,
                                  expedition: item.expedition,
                                  service: item.service,
                                  shipper: item.shipper,
                                  receiver: item.receiver,
                                  receiver_phone: item.receiver_phone,
                                  receiver_address: item.receiver_address,
                                  weight: item.weight,
                                  goods_name: item.goods_name,
                                  cost: item.cost,
                                });
                                setOpenModalEdit(true);
                                setDataId(item.id);
                              }
                            }}
                          >
                            <FindInPage />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  ))}
              </>
            )}
          </TableBody>
        </Table>
        {res.message || _res.message ? (
          <Alert variant="standard" severity="error">
            {res.message || _res.message}
          </Alert>
        ) : data.data.length === 0 ? (
          <Alert variant="standard" severity="warning">
            No Data
          </Alert>
        ) : null}
      </TableContainer>

      <TablePagination
        rowsPerPageOptions={[5, 10, 15, 25]}
        component="div"
        count={data.count}
        rowsPerPage={parseInt(query?.perPage || "10", 10)}
        page={data.count === 0 ? 0 : parseInt(query?.page || "0", 10)}
        onPageChange={(_, newPage) =>
          push(
            query
              ? { ...query, page: newPage }
              : { page: newPage, perPage: "10" }
          )
        }
        onRowsPerPageChange={({ target: { value = "10" } }) =>
          push(
            query
              ? { ...query, page: 0, perPage: parseInt(value, 10) }
              : { page: 0, perPage: parseInt(value, 10) }
          )
        }
        ActionsComponent={TablePaginationActions}
      />
    </>
  );
}
