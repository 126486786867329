import { Fragment, useState, useContext } from "react";
import {
  Grid,
  Typography,
  Modal,
  Button,
  Card,
  CardContent,
  // Stack,
} from "@mui/material";
import { ExcelRenderer } from "react-excel-renderer";
import { UploadOutlined } from "@mui/icons-material";
import { BasicModal, UIAlert } from "../../components/spam/modal";
import { useTransactionUpload } from "../../services/transactions";
import gtw from "../../apis/gtw";
import { AuthContext } from "../../utils/auth";
import { handleError } from "../../services/helpers";
import writeXlsxFile from "write-excel-file";
import { LinearProgressWithLabel } from "../../components/spam/inputs";
import CloseIcon from "@mui/icons-material/Close";
import CircularProgress from "@mui/material/CircularProgress";
import { compress, decompress } from "compress-json";

export const TransactionUpload = () => {
  const [loading, setLoading] = useState(false),
    [progress, setProgress] = useState(0),
    [dataError, setDataError] = useState([]),
    [res, setRes] = useState({ status: "", message: "" }),
    [checkError, setCheckError] = useState(true),
    { headers } = useContext(AuthContext),
    [openModal, setOpenmodal] = useState(false),
    // [statusWa, setStatusWa] = useState(false),
    // [openDownload, setOpenDownload] = useState(false),
    { onProcess } = useTransactionUpload();

  // async function getQrCode() {
  //   try {
  //     const dataQr = await gtw({
  //       method: "get",
  //       url: "/whatsapp/barcode",
  //       headers,
  //     });
  //     setStatusWa(dataQr.data.statusWa);
  //   } catch (e) {
  //     const message = handleError(e);
  //     if (message.cancel) return true;
  //     setRes({ status: "error", message });
  //     console.log(message);
  //     console.log(e, "error");
  //   }
  // }

  // useEffect(() => {
  //   getQrCode();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  function fileHandler(e) {
    if (e.preventDefault) e.preventDefault();

    const getFiles = (() => {
      try {
        return e.target.files[0];
      } catch (e) {
        return null;
      }
    })();
    if (getFiles) {
      ExcelRenderer(getFiles, async (err, resp) => {
        try {
          if (resp) {
            setCheckError(true);

            let uploadId;
            let sendSeller = [];
            let awb = [];
            let sellerArray = [];
            let orders = [];
            let getHeaders = resp.rows[0];
            const tempError = [];
            const validateEmpty = [];
            let newOrder = [];
            let newOrderlength = 0;
            let ordersLength = 0;

            let filterOrder = [];
            if (resp.rows[0][0] !== "Nama Seller") {
              setRes({
                status: "error",
                message: "Harap menggunakan template terbaru",
              });
              setLoading(false);

              return;
            } else if (resp.rows[0][1] !== "Nama Pengirim") {
              setRes({
                status: "error",
                message: "Harap menggunakan template terbaru",
              });
              setLoading(false);

              return;
            } else if (resp.rows[0][2] !== "Nama Penerima") {
              setRes({
                status: "error",
                message: "Harap menggunakan template terbaru",
              });
              setLoading(false);

              return;
            } else if (resp.rows[0][3] !== "Alamat Penerima") {
              setRes({
                status: "error",
                message: "Harap menggunakan template terbaru",
              });
              setLoading(false);

              return;
            } else if (resp.rows[0][4] !== "Nomor Penerima") {
              setRes({
                status: "error",
                message: "Harap menggunakan template terbaru",
              });
              setLoading(false);

              return;
            } else if (resp.rows[0][5] !== "Nama Barang") {
              setRes({
                status: "error",
                message: "Harap menggunakan template terbaru",
              });
              setLoading(false);

              return;
            } else if (resp.rows[0][6] !== "Berat") {
              setRes({
                status: "error",
                message: "Harap menggunakan template terbaru",
              });
              setLoading(false);

              return;
            } else if (resp.rows[0][7] !== "Servis") {
              setRes({
                status: "error",
                message: "Harap menggunakan template terbaru",
              });
              setLoading(false);

              return;
            } else if (resp.rows[0][8] !== "threePl") {
              setRes({
                status: "error",
                message: "Harap menggunakan template terbaru",
              });
              setLoading(false);

              return;
            } else if (resp.rows[0][9] !== "Ongkir") {
              setRes({
                status: "error",
                message: "Harap menggunakan template terbaru",
              });
              setLoading(false);

              return;
            } else if (resp.rows[0][10] !== "Airwaybill") {
              setRes({
                status: "error",
                message: "Harap menggunakan template terbaru",
              });
              setLoading(false);

              return;
            }

            if (!resp.rows[1].length) {
              setRes({
                status: "error",
                message: "Data tidak boleh kosong",
              });
              setLoading(false);

              return;
            }
            setLoading(true);
            setProgress(0);
            await resp.rows.forEach((item, index) => {
              if (index !== 0) {
                if (item.length > 0) {
                  let payload = {};
                  let obj = {
                    namaSeller: "",
                    threePl: "",
                  };
                  getHeaders.forEach((headers, _index) => {
                    if (headers === "Nama Seller" && !item[_index])
                      validateEmpty.push(
                        `Nama seller kolom ${index + 1} tidak boleh kosong`
                      );
                    if (headers === "Airwaybill" && !item[_index])
                      validateEmpty.push(
                        `Airwaybill kolom ${index + 1} tidak boleh kosong`
                      );
                    if (headers === "threePl" && !item[_index])
                      validateEmpty.push(
                        `threePl kolom ${index + 1} tidak boleh kosong`
                      );
                    if (headers === "Berat" && item[_index]) {
                      if (isNaN(item[_index])) {
                        validateEmpty.push(
                          `Berat kolom ${index + 1} harus angka`
                        );
                      }
                    }

                    if (headers === "Ongkir" && item[_index]) {
                      if (isNaN(item[_index])) {
                        validateEmpty.push(
                          `Ongkir kolom ${index + 1} harus angka`
                        );
                      }
                    }
                    if (!validateEmpty.length) {
                      if (headers === "Airwaybill" && item[_index]) {
                        if (awb.length) {
                          let find = awb.find(
                            (e) => `${e}` === `${item[_index]}`
                          );
                          if (find) {
                            tempError.push(`Awb ${item[_index]} duplicate`);
                          }
                        }
                        awb.push(item[_index]);
                      }

                      if (headers === "Nama Seller" && item[_index])
                        obj.namaSeller = item[_index];

                      if (headers === "threePl" && item[_index])
                        obj.threePl = item[_index];

                      payload[headers] = !item[_index]
                        ? ""
                        : item[_index] && typeof item[_index] !== "string"
                        ? `${item[_index]}`.replace(/^[ ]+|[ ]$/g, "")
                        : item[_index] && typeof item[_index] === "string"
                        ? item[_index].replace(/^[ ]+|[ ]$/g, "")
                        : item[_index];
                    }
                  });
                  sellerArray.push(obj);

                  orders.push(payload);
                }
              }
            });
            ordersLength = orders.length;
            uploadId = `${Date.now()}`;
            //duplicate error

            if (!validateEmpty.length) {
              // orders.forEach((e) => {
              //   awb.push(`${e["Airwaybill"]}`.trim());
              //   if (e["Nama Seller"]) {
              //     sellerArray.push({
              //       namaSeller: e["Nama Seller"],
              //       threePl: e["threePl"],
              //     });
              //   }
              // });
              sellerArray = [
                ...new Map(
                  sellerArray.map((item) => [item.namaSeller, item])
                ).values(),
              ];
              let { data: dataAllAlias } = await gtw({
                method: "post",
                url: `/user-seller/errorAlias`,
                data: {
                  data: compress(sellerArray),
                },
                headers,
              });
              sendSeller = dataAllAlias.find;

              if (dataAllAlias.notFind.length) {
                dataAllAlias.notFind.forEach((e) => {
                  tempError.push(`${e.namaSeller} tidak terdaftar`);
                });
              }
              // jika tiap file banyak expedisi
              // let sellerArray = {};
              // orders.forEach((e) => {
              //   awb.push(`${e["Airwaybill"]}`.trim());
              //   if (e["Nama Seller"]) {
              //     if (!sellerArray[e["Nama Seller"]])
              //       sellerArray[e["Nama Seller"]] = {};
              //     if (!sellerArray[e["Nama Seller"]][e["threePl"]])
              //       sellerArray[e["Nama Seller"]][e["threePl"]] = "";
              //   }
              // });
              let { data: newdata } = await gtw({
                method: "get",
                url: `/transactions/errorawb`,
                // data: awb,
                headers,
              });

              newdata = decompress(newdata);

              if (newdata.length) {
                orders = orders.forEach(
                  (e) => {
                    let find = newdata.find(
                      (e1) =>
                        `${e1.awb}`.trim().toLowerCase() ===
                        `${e.Airwaybill}`.trim().toLowerCase()
                    );
                    if (find) {
                      filterOrder.push(`awb ${e.Airwaybill} sudah terdaftar`);
                    } else {
                      newOrder.push(e);
                    }
                  }
                  // !newdata.find(
                  //   (e1) =>
                  //     `${e1.awb}`.trim().toLowerCase() ===
                  //     `${e.Airwaybill}`.trim().toLowerCase()
                  // )
                );
                orders = newOrder;
                newOrderlength = newOrder.length;
                // orders = orders.filter(
                //   (e) =>
                //     !newdata.find(
                //       (e1) =>
                //         `${e1.awb}`.trim().toLowerCase() ===
                //         `${e.Airwaybill}`.trim().toLowerCase()
                //     )
                // );
              }
              if (!orders.length) {
                setRes({
                  status: "error",
                  message: "tidak ada data yang terupload",
                });
                setLoading(false);
                // setCheckError(false);

                return;
              }
              setCheckError(false);
            } else {
              setDataError(validateEmpty);
              setRes({
                status: "error",
                message: "ada data yang kosong",
              });
              setOpenmodal(true);
              setCheckError(false);
              setLoading(false);
              return;
            }
            if (!tempError.length) {
              let last = false,
                perPage = Math.ceil([...orders].length / 10),
                totalItems = [...orders].length,
                request_id = null;
              while (!last) {
                const orderList = orders.splice(0, perPage),
                  progressValue = Math.floor(
                    ((totalItems - orders.length) / totalItems) * 100
                  );
                last = orders.length === 0;

                let payload = {
                  uploadId,
                  orderList: compress(orderList),
                  alias: sendSeller,
                };
                if (request_id !== null && request_id) {
                  payload.request_id = request_id;
                }
                const { requestId = "" } = await onProcess(
                  payload,
                  `Upload transactions ${orderList.length}/${totalItems}`
                );
                if (requestId) request_id = requestId;
                setProgress(progressValue);
              }
              setProgress(100);
              if (filterOrder.length) {
                setDataError(filterOrder);

                setRes({
                  status: "warning",
                  message: `Berhasil menambahkan ${newOrderlength} dari ${ordersLength} transaksi`,
                });
                setOpenmodal(true);
              } else {
                setRes({
                  status: "ok",
                  message: "Berhasil menambahkan semua data transaksi",
                });
              }

              // if (statusWa) {
              //   await gtw({
              //     method: "post",
              //     url: `/whatsapp/send_report?status=upload`,
              //     data: {
              //       data: compress(sendSeller),
              //       uploadId,
              //     },
              //     headers,
              //   });
              //   // setRes({
              //   //   status: "success",
              //   //   message: "Proses upload selesai",
              //   // });
              // } else {
              //   setRes({
              //     status: "warning",
              //     message: "Data terupload tetapi whatsapp tidak terkirim",
              //   });
              // }
            } else {
              setDataError(tempError);
              setRes({
                status: "error",
                message: "Gagal menambahkan data transaksi",
              });
              setOpenmodal(true);
            }
            setLoading(false);
          }
        } catch (e) {
          const message = handleError(e);
          console.log(message);
          if (message.cancel) return true;
          if (/network/i.test(message) || /connection/i.test(message))
            setRes({ status: "error", message });
          else
            setRes({
              status: "error",
              message: "Gagal menambahkan data transaksi",
            });
          setLoading(false);
        }
      });
    }
  }

  const handleDownload = async () => {
    const schema = [
      // {
      //   column: "Tanggal (mm/dd/yyyy)",
      //   type: String,
      //   width: 24,
      //   value: () => "",
      // },
      {
        column: "Nama Seller",
        width: 18,
        type: String,
        value: () => "",
      },
      {
        column: "Nama Pengirim",
        width: 15,
        type: String,
        value: () => "",
      },
      {
        column: "Nama Penerima",
        width: 15,
        type: String,
        value: () => "",
      },
      {
        column: "Alamat Penerima",
        width: 15,
        type: String,
        value: () => "",
      },
      {
        column: "Nomor Penerima",
        width: 15,
        type: String,
        value: () => "",
      },
      {
        column: "Nama Barang",
        width: 20,
        type: String,
        value: () => "",
      },
      {
        column: "Berat",
        width: 12,
        type: String,
        value: () => "",
      },
      {
        column: "Servis",
        width: 12,
        type: String,
        value: () => "",
      },
      {
        column: "threePl",
        width: 12,
        type: String,
        value: () => "",
      },
      {
        column: "Ongkir",
        width: 13,
        type: String,
        value: () => "",
      },
      {
        column: "Airwaybill",
        width: 13,
        type: String,
        value: () => "",
      },
    ];

    await writeXlsxFile([], {
      schema,
      headerStyle: {
        fontWeight: "bold",
        align: "center",
      },
      fileName: "template transaction.xlsx",
    });
  };

  return (
    <Fragment>
      <UIAlert
        onClose={(_) => setRes({ status: "", message: "" })}
        open={res.status !== ""}
        autoHideDuration={10000}
        res={res}
      />
      <Modal
        open={openModal}
        sx={{
          width: "100%",
          justifyContent: "center",
          display: "flex",
          height: "70%",
          mt: 10,
        }}
      >
        <Grid>
          <Grid
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              backgroundColor: "white",
              height: 50,
            }}
          >
            <Button
              size="small"
              onClick={() => {
                setOpenmodal(false);
              }}
            >
              <CloseIcon />
            </Button>
          </Grid>
          <Card
            sx={{
              width: "auto",
              overflow: "auto",
              height: "100%",
              borderRadius: 0,
            }}
          >
            <CardContent>
              {dataError.map((e, i) => (
                <Typography variant="body2" sx={{ mb: 5 }} key={i}>{`${
                  i + 1
                }. ${e}`}</Typography>
              ))}
            </CardContent>
          </Card>
        </Grid>
      </Modal>
      {loading ? (
        <BasicModal title="Loading..." open onClose={() => null}>
          <Grid
            container
            spacing={2}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Grid item>
              {!checkError ? (
                <LinearProgressWithLabel value={progress} />
              ) : (
                <Grid
                  sx={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "center",
                  }}
                >
                  <CircularProgress disableShrink />
                </Grid>
              )}
              <Typography variant="h6" color="red" textAlign="center">
                {checkError
                  ? "Proses pengecekan sedang berlangsung"
                  : "Proses upload sedang berlangsung"}
                <br />
                Jangan buka tab lain
              </Typography>
            </Grid>
          </Grid>
        </BasicModal>
      ) : null}

      {dataError.length ? (
        <Button onClick={() => setOpenmodal(true)}>Tampilkan Log</Button>
      ) : (
        <></>
      )}
      <Grid
        item
        width="100%"
        justifyContent="center"
        flexDirection="column"
        alignItems="center"
        style={{
          height: "85vh",
          width: "100%",
          position: "relative",
          cursor: "pointer",
        }}
        display="flex"
      >
        <UploadOutlined style={{ width: "auto", opacity: 0.5, height: 250 }} />
        <Typography variant="h4">Upload File</Typography>

        <input
          type="file"
          onChange={(e) => {
            fileHandler(e);
            e.target.value = null;
          }}
          style={{
            width: "100%",
            cursor: "pointer",
            opacity: 0,
            height: "100%",
            position: "absolute",
            left: 0,
            top: 0,
          }}
        />

        {/* {openDownload ? (
          <BasicModal
            title="Choose Extension *jika format xlsx atau xls error, coba download dengan csv"
            open
            onClose={(_) => setOpenDownload(false)}
          >
            <Stack direction="row" spacing={1} alignItems="center">
              <Button
                variant="outlined"
                color="primary"
                size="small"
                onClick={(_) => handleDownload(".xlsx")}
              >
                .XLSX
              </Button>
            </Stack>
          </BasicModal>
        ) : null} */}

        <Button
          variant="outlined"
          onClick={(e) => {
            handleDownload(".xlsx");
            // setOpenDownload(true);
          }}
          sx={{ mt: 10 }}
        >
          Download Template
        </Button>
      </Grid>
    </Fragment>
  );
};
