import { useEffect, Fragment, useMemo, useState, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Grid,
  Button,
  List,
  ListItem,
  ListItemText,
  Typography,
  Alert,
  TextField,
} from "@mui/material";
import { ArrowLeftOutlined } from "@mui/icons-material";
import { AuthContext } from "../../utils/auth";

import { useDetailUserSellerr } from "../../services/user-seller";
import { useGetAdminId } from "../../services/auth";
import gtw from "../../apis/gtw";

export const DetailUserSellerPage = () => {
  const { onProcess, loading, data, res } = useDetailUserSellerr(),
    navigate = useNavigate(),
    { state } = useLocation(),
    [edit, setEdit] = useState(false),
    [newObj, setNewObj] = useState({}),
    { headers } = useContext(AuthContext);
  const updateSeller = async () => {
    let id = data.id;
    if (Object.keys(newObj).length !== 0) {
      try {
        await gtw({
          method: "patch",
          url: `/user-seller/update/${id}`,
          data: newObj,
          headers,
        });
      } catch (e) {
        return;
      }
    }
    setNewObj({});
    setEdit(false);
  };

  useEffect(() => {
    onProcess();
  }, [onProcess]);

  useEffect(() => {
    document.title = "BD - Detail Seller";
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Fragment>
      <Grid
        item
        width="100%"
        alignItems="center"
        justifyContent="flex-start"
        display="flex"
      >
        <Button
          startIcon={<ArrowLeftOutlined />}
          onClick={() => {
            if (edit) {
              setEdit(false);
            } else {
              navigate(state?.from ? -1 : `/seller?page=0&perPage=10`);
            }
          }}
          size="small"
          variant="outlined"
          color="info"
        >
          Go Back
        </Button>
        {!edit ? (
          <Button
            onClick={() => setEdit(true)}
            size="small"
            variant="contained"
            color="success"
            sx={{ ml: 5 }}
          >
            Edit
          </Button>
        ) : (
          <Button
            onClick={() => updateSeller()}
            size="small"
            variant="contained"
            color="warning"
            sx={{ ml: 5 }}
          >
            Save
          </Button>
        )}
      </Grid>

      {loading ? (
        <div className="center">
          <Typography variant="caption" textAlign="center">
            Loading...
          </Typography>
        </div>
      ) : data ? (
        <ListItemDetailSeller data={data} edit={edit} setNewObj={setNewObj} />
      ) : res.message || !data ? (
        <Grid item>
          <Alert severity={res.status ? "error" : "warning"}>
            {res.message || "No Data"}
          </Alert>
        </Grid>
      ) : null}
    </Fragment>
  );
};

const ListItemDetailSeller = ({ data, edit, setNewObj }) => {
  const obj = useMemo(() => Object.keys(data), [data]);
  const editInput = async (e, key) => {
    data[key] = e.target.value;
    let updatedValue = {};
    updatedValue = { [key]: e.target.value };
    setNewObj((previous) => ({
      ...previous,
      ...updatedValue,
    }));
  };

  const { onProcess, loading, res, data: _data } = useGetAdminId(),
    ListItemCustom = ({ dataKey }) => (
      <ListItem>
        <ListItemText
          primary={dataKey}
          secondaryTypographyProps={{
            color: res.status === "error" ? "red" : "black",
          }}
          secondary={
            dataKey === "salesRefID"
              ? loading
                ? "Loading..."
                : _data && _data.id
                ? `${_data.full_name || "-"} - ${
                    _data.group_name || "-"
                  } level (${_data.group_level || "-"})`
                : res.message
                ? res.message
                : "-"
              : typeof data[dataKey] === "boolean"
              ? data[dataKey]
                ? "Y"
                : "N"
              : data[dataKey] || "-"
          }
        />
      </ListItem>
    );

  useEffect(() => {
    if (data.salesRefID) onProcess(data.salesRefID);
  }, [data.salesRefID, onProcess]);

  return (
    <Grid container display="flex" sx={{ mt: 4 }}>
      <List style={{ width: "45%" }}>
        {obj.slice(0, obj.length / 2).map((key, index) => (
          <Grid sx={{ display: "flex", mb: 5, ml: 3 }} key={index}>
            {/* <ListItemCustom dataKey={key} key={key} /> */}
            {edit ? (
              <>
                {key === "id" ||
                key === "role" ||
                typeof data[key] === "boolean" ? (
                  <TextField
                    disabled
                    id="outlined-disabled"
                    label={key}
                    defaultValue={data[key]}
                  />
                ) : (
                  <TextField
                    id="outlined-disabled"
                    label={key}
                    defaultValue={data[key]}
                    onChange={(e) => editInput(e, key)}
                  />
                )}
              </>
            ) : (
              <ListItemCustom dataKey={key} key={key} />
            )}
          </Grid>
        ))}
      </List>
      <List>
        {obj.slice(obj.length / 2, obj.length).map((key, index) => (
          // <ListItemCustom dataKey={key} key={key} />

          <Grid sx={{ display: "flex", mb: 5, ml: 3 }} key={index}>
            {edit ? (
              <>
                {key === "approval" ||
                key === "status" ||
                key === "joinDate" ||
                key === "salesRefID" ||
                typeof data[key] === "boolean" ? (
                  <TextField
                    disabled
                    id="outlined-disabled"
                    label={key}
                    defaultValue={data[key]}
                  />
                ) : (
                  <TextField
                    id="outlined-disabled"
                    label={key}
                    defaultValue={data[key]}
                    onChange={(e) => editInput(e, key)}
                  />
                )}
              </>
            ) : (
              <>
                <ListItemCustom dataKey={key} key={key} />
              </>
            )}
          </Grid>
        ))}

        <Typography></Typography>
      </List>
    </Grid>
  );
};
