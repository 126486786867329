import { useEffect, Fragment, useState, useContext, useMemo } from "react";
import {
  Grid,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Menu,
  Modal,
  Zoom,
  Box,
  Typography,
  InputAdornment,
  IconButton,
  CircularProgress,
  LinearProgress,
  Alert,
  Stack,
  ImageList,
  ImageListItem,
  TablePagination,
  // Divider,
  // Snackbar,
  // CardMedia,
  Badge,
} from "@mui/material";
import {
  RemoveCircleOutlineOutlined,
  AddCircleOutline,
  DeleteOutline,
  SearchOutlined,
  // SaveOutlined,
  AttachFile,
  Clear,
  ChangeCircleOutlined,
  FeaturedPlayListOutlined,
  Send,
  ImportExportOutlined,
} from "@mui/icons-material";
import { useSearchParams } from "react-router-dom";
import { TablePaginationActions } from "../../components/spam/pagination";
// import { SearchHead } from "../../components/spam/inputs";
// import { BasicModal, UIAlert } from "../../components/spam/modal";
import { UIAlert } from "../../components/spam/modal";
import { LinearProgressWithLabel } from "../../components/spam/inputs";
import { useTicketing } from "../../services/ticketing";
import { RangeDateTimePicker } from "../../components/spam/dateTime";
// import { DateRangePicker } from "react-date-range";
import { useListUserSellerOne } from "../../services/user-seller";
import { handleError } from "../../services/helpers";
// import { set } from "date-fns";
import { AuthContext } from "../../utils/auth";

export const TicketPage = () => {
  const {
    getList,
    query,
    push,
    loading,
    data,
    setData,
    count,
    addList,
    res,
    setRes,
    progress,
    editList,
    findAwb,
    addDetail,
    dataDetail,
    setDataDetail,
    getDetail,
    loadingExport,
    loadingDetail,
    setLoadingDetail,
    exportFile,
  } = useTicketing();
  const {
    onProcessFind,
    // value,
    setValue,
    dataSellerInternal,
    setDataSellerInternal,
    // loadingInternal,
    // resInternal,
  } = useListUserSellerOne();
  //==============
  //Value

  //navigation
  const { auth } = useContext(AuthContext);
  const [searchParams] = useSearchParams();
  const params = Object.fromEntries([...searchParams]);
  //navigation
  const [openModal, setOpenModal] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);
  // const [openModal, setOpenModal] = useState(false);

  //filter
  const [selectedStatus, setSelectedStatus] = useState(params.status || "All");
  const [showDateFilter, setShowDateFilter] = useState(false);
  const [search, setSearch] = useState(params.search || "");
  //filter

  //edit ticket
  const [dataEdit, setDataEdit] = useState({});
  // const [statusEdit, setStatusEdit] = useState("");

  const [isEdit, setIsEdit] = useState(-1);
  //edit ticket

  const openStatus = Boolean(anchorEl);
  //add ticketing
  const [checkAvailableAwb, setCheckAvailableAwb] = useState("empty");
  const [loadingCheckAwb, setLoadingCheckAwb] = useState(false);
  const [awbFind, setAwbFind] = useState({});
  const [findAwbOrSeller, setFindAwbOrSeller] = useState("");
  const [addTicketData, setAddTicketData] = useState({
    awb: "",
    note: "",
    awbManual: "",
  });

  //add ticketing

  //detail
  const [uploadImages, setUploadImages] = useState([]);
  const [messageDetail, setMessageDetail] = useState("");
  const [titleDetail, setTitleDetail] = useState({
    seller: "",
    awb: "",
    note: "",
  });
  // const [dataDetail, setDataDetail] = useState({
  //   0: {
  //     message: "ada barang rusak neeh hehehe",
  //     images: [
  //       "https://picsum.photos/200/300",
  //       "https://picsum.photos/200/300",
  //     ],
  //     username: "Susi",
  //   },
  //   1: {
  //     message: "ininih bukti unboxing nyeee",
  //     images: [
  //       "https://picsum.photos/200/300",
  //       "https://picsum.photos/200/300",
  //     ],
  //     username: "Yuli",
  //   },
  // });
  const [openDetail, setOpenDetail] = useState(false);
  const [openDetailImage, setOpenDetailImage] = useState(false);
  const [idDetail, setIdDetail] = useState(0);
  const [indexDetail, setIndexDetail] = useState(-1);
  //detail

  //==============

  //function
  const mountFilter = useMemo(() => {
    if (!query)
      return {
        sellerId: false,
        dateRange: { value: false, minDate: "", maxDate: "" },
      };

    let dateRange = {
      value: query.minDate && query.maxDate ? true : false,
      minDate: "",
      maxDate: "",
    };

    if (dateRange.value)
      dateRange = {
        ...dateRange,
        minDate: query.minDate,
        maxDate: query.maxDate,
      };

    return { sellerId: query.sellerId ? true : false, dateRange };
  }, [query]);

  const checkAwb = async () => {
    try {
      if (addTicketData.awb) {
        setLoadingCheckAwb(true);
        const data = await findAwb({ awb: addTicketData.awb });
        setAwbFind(data);
        setLoadingCheckAwb(false);
        setCheckAvailableAwb(true);
      }
    } catch (err) {
      console.log("error");
      // const message = handleError(err);
      setLoadingCheckAwb(false);
      setCheckAvailableAwb(false);
      console.log(err);
    }
  };

  const fileHandler = async (e) => {
    try {
      e.preventDefault();
      const getFiles = (() => {
        try {
          return e.target.files;
        } catch (e) {
          return null;
        }
      })();

      let images = [];

      Object.keys(getFiles).forEach((e) => {
        // let form = new FormData();
        // form.append("image", getFiles[e]);
        // console.log(form);
        // console.log(URL.createObjectURL(getFiles[e]));
        images.push(getFiles[e]);
        // setImage(URL.createObjectURL(getFiles[0]));
      });
      // setUploadImages(images);

      // setFormImage(form);
      setUploadImages(images);
    } catch (err) {}
  };

  const sendMessage = async (e) => {
    try {
      e.preventDefault();
      let newMessage = messageDetail.trim();
      setRes({ status: "", message: "" });
      if (!newMessage && !uploadImages.length) {
        setRes({ status: "error", message: "message tidak boleh kososng" });
        return;
      }
      // await getDetail(idDetail);

      // const dataAddEdit = { ...dataDetail };
      // let index = Object.keys(dataAddEdit).length
      //   ? Object.keys(dataAddEdit).length
      //   : 0;
      // dataAddEdit[index] = { message: newMessage  };
      let form = new FormData();
      for (let i = 0; i < uploadImages.length; i++) {
        form.append("image", uploadImages[i]);
      }
      let _data = await addDetail({
        // data: dataAddEdit,
        message: newMessage,
        id: idDetail,
        img: form,
        statusImg: uploadImages.length ? true : false,
      });
      setDataDetail(_data.data);
      setMessageDetail("");
      setUploadImages([]);
      // log_data.data;
      let temp = [...data];
      temp[indexDetail].detail = _data.data;
      setData(temp);
    } catch (err) {
      const message = handleError(err);
      console.log(message);
    }
  };

  //function
  console.log(progress);

  useEffect(() => {
    getList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  return (
    <Fragment>
      {/* {requestId !== null && requestId ? (
        <RequestModal open close={(_) => setRequestId(null)} id={requestId} />
      ) : null} */}

      {/* <Snackbar
        anchorOrigin={{ horizontal: "center", vertical: "top" }}
        open={res.message ? true : false}
        onClose={() => {
          setOpenNotification(false);
        }}
      >
        <Alert severity={res.status}>{res.message}</Alert>
      </Snackbar> */}

      <Modal
        open={loadingExport}
        sx={{
          backdropFilter: "blur(5px)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "100%",
        }}
      >
        <Box
          sx={{
            backgroundColor: "white",
            p: 3,
            borderRadius: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            width: { lg: "40%", md: "50%" },
          }}
        >
          <Box sx={{ width: "100%" }}>
            <Typography>Loading...</Typography>
            <LinearProgressWithLabel value={progress} />
          </Box>
        </Box>
      </Modal>

      <Modal
        open={openModal}
        onClose={() => {
          setOpenModal(false);
          setCheckAvailableAwb("empty");
          setAddTicketData({ awb: "", note: "", awbManual: "" });
          setFindAwbOrSeller("");
        }}
        sx={{
          backdropFilter: "blur(5px)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "100%",
        }}
      >
        <Zoom in={openModal}>
          <Box
            sx={{
              // width: "auto",
              backgroundColor: "white",
              p: 3,
              borderRadius: 1,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              width: { lg: "40%", md: "50%" },
            }}
          >
            <Box
              sx={{ display: "flex", flexDirection: "column", width: "50%" }}
            >
              {!findAwbOrSeller ? (
                <Box
                  sx={{
                    display: "flex",
                    gap: 3,
                    width: "100%",
                    justifyContent: "center",
                  }}
                >
                  <Button
                    variant="contained"
                    onClick={() => setFindAwbOrSeller("awb")}
                  >
                    Berdasarkan Awb
                  </Button>
                  <Button
                    variant="contained"
                    onClick={() => setFindAwbOrSeller("seller")}
                  >
                    Berdasarkan Seller
                  </Button>
                </Box>
              ) : (
                <TextField
                  label={findAwbOrSeller !== "seller" ? "Awb" : "seller"}
                  variant="standard"
                  onChange={(e) => {
                    if (findAwbOrSeller !== "seller") {
                      setAddTicketData({
                        ...addTicketData,
                        awb: e.target.value,
                      });
                    } else {
                      setValue(e.target.value);
                    }
                  }}
                  onKeyUp={({ key }) => {
                    if (key === "Enter") {
                      if (findAwbOrSeller !== "seller") {
                        checkAwb();
                      } else {
                        onProcessFind();
                      }
                    }
                  }}
                  autoComplete="off"
                  sx={{ mb: 2 }}
                  // disabled={
                  //   findAwbOrSeller === "awb" &&
                  //   checkAvailableAwb !== "empty" &&
                  //   checkAvailableAwb
                  // }
                  // hidden={
                  //   findAwbOrSeller === "seller" &&
                  //   checkAvailableAwb !== "empty" &&
                  //   checkAvailableAwb
                  // }
                />
              )}
              {addTicketData.awb ? (
                <Button
                  variant="outlined"
                  sx={{ mb: 2 }}
                  onClick={() => {
                    if (findAwbOrSeller !== "seller") {
                      checkAwb();
                    } else {
                      onProcessFind();
                    }
                  }}
                >
                  Cek
                </Button>
              ) : null}
              {dataSellerInternal.data.length > 0 ? (
                <TableContainer
                  style={{ minHeight: "50vh", maxHeight: "80vh" }}
                >
                  <Table style={{ width: "100%" }} stickyHeader>
                    <TableHead>
                      <TableRow>
                        <TableCell>No</TableCell>
                        <TableCell>Username</TableCell>
                        <TableCell>Action</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {dataSellerInternal.data.length > 0
                        ? dataSellerInternal.data.map((seller, index) => (
                            <TableRow key={index} hover>
                              <TableCell>{index + 1}</TableCell>
                              <TableCell>{seller?.username || "-"}</TableCell>
                              <TableCell>
                                <IconButton
                                  title="add seller"
                                  style={{ marginLeft: 5 }}
                                  size="small"
                                  color="primary"
                                  onClick={() => {
                                    setAwbFind(seller);
                                    setCheckAvailableAwb(true);
                                    setDataSellerInternal({
                                      ...dataSellerInternal,
                                      data: [],
                                    });
                                  }}
                                >
                                  <AddCircleOutline />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          ))
                        : null}
                    </TableBody>
                  </Table>
                  {dataSellerInternal.data.length === 0 ? (
                    <Alert variant="standard" severity="warning">
                      No Data
                    </Alert>
                  ) : null}
                </TableContainer>
              ) : null}
              {loadingCheckAwb ? (
                <div className="center">
                  <CircularProgress size={25} />
                </div>
              ) : checkAvailableAwb === "empty" ? null : checkAvailableAwb ? (
                <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                  <Typography sx={{ color: "#4caf50" }}>
                    {findAwbOrSeller !== "seller"
                      ? "awb terdaftar di big delivery"
                      : ""}
                  </Typography>
                  <TextField
                    label="Nama Seller"
                    variant="standard"
                    value={awbFind.username}
                    disabled
                  />
                  <TextField
                    label="Nomor Telpon"
                    variant="standard"
                    value={awbFind.phone}
                    disabled
                  />
                  {findAwbOrSeller === "seller" ? (
                    <TextField
                      label="Awb"
                      variant="standard"
                      onChange={(e) => {
                        setAddTicketData({
                          ...addTicketData,
                          awbManual: e.target.value,
                        });
                      }}
                    />
                  ) : null}

                  <TextField
                    label="Notes"
                    variant="standard"
                    onChange={(e) => {
                      setAddTicketData({
                        ...addTicketData,
                        note: e.target.value,
                      });
                    }}
                  />
                </Box>
              ) : (
                <Typography sx={{ color: "#ef5350" }}>
                  awb tidak terdaftar di big delivery, mohon periksa kembali
                </Typography>
              )}
            </Box>
            <Box
              sx={{
                width: "100%",
                mt: 5,
                display: "flex",
                justifyContent: "end",
                alignItems: "center",
              }}
            >
              <Button
                onClick={(e) => {
                  setRes({ ...res, status: "", message: "" });
                  setOpenModal(false);
                  setCheckAvailableAwb("empty");
                  setAddTicketData({
                    ...addTicketData,
                    awb: "",
                    note: "",
                    awbManual: "",
                  });
                  setFindAwbOrSeller("");

                  // setSelectedData({});
                }}
                style={{ marginRight: 15 }}
                variant="outlined"
                sx={{
                  borderColor: "#bd454f",
                  color: "#bd454f",
                  "&:hover": {
                    backgroundColor: "#bd454f30",
                  },
                }}
              >
                Batal
              </Button>
              <Button
                onClick={async (e) => {
                  await addList({
                    addTicketData,
                    sellerId:
                      findAwbOrSeller !== "seller"
                        ? awbFind.sellerId
                        : awbFind.id,
                  });
                  setOpenModal(false);
                  setCheckAvailableAwb("empty");
                  setFindAwbOrSeller("");
                  push({
                    ...query,
                    page: 0,
                    perPage: 10,
                  });
                  // restoreHandler(selectedData);
                }}
                variant="contained"
                disabled={
                  checkAvailableAwb === "empty"
                    ? true
                    : checkAvailableAwb
                    ? false
                    : true
                }
              >
                Tambah
              </Button>
            </Box>
          </Box>
        </Zoom>
      </Modal>

      <Modal
        open={openDetail}
        onClose={() => {
          setOpenDetail(false);
        }}
        sx={{
          backdropFilter: "blur(5px)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "100%",
        }}
      >
        <Zoom in={openDetail}>
          <Box
            sx={{
              // width: "auto",
              backgroundColor: "white",
              p: 3,
              borderRadius: 1,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              width: { lg: "40%", md: "50%" },
              maxHeight: "90%",
            }}
          >
            {loadingDetail ? (
              <CircularProgress size={25} />
            ) : (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  maxHeight: "100%",
                  gap: 2,
                  overflow: "auto",
                }}
              >
                <Box
                  sx={{
                    borderBottom: 2,
                    borderColor: "#000000",
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    gap: 1,
                    paddingY: 2,
                  }}
                >
                  {Object.keys(titleDetail).map((e, i) =>
                    titleDetail[e] ? (
                      <Typography
                        sx={{
                          fontWeight:
                            e === "awb" || e === "seller" ? "bold" : "light",
                        }}
                        key={i}
                      >
                        {`${e.toUpperCase()} : ${titleDetail[e]}`}
                      </Typography>
                    ) : null
                  )}
                </Box>
                {Object.keys(dataDetail).length ? (
                  Object.keys(dataDetail).map((e, i) => (
                    <Box
                      sx={{
                        display: "flex",
                        borderBottom: 1,
                        borderColor: "#000000",
                        width: "100%",
                        flexDirection: "column",
                        p: 1,
                        gap: 0.5,
                        // maxHeight: "90%",
                      }}
                      key={i}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          width: "100%",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <Typography sx={{ fontWeight: "bold" }}>
                          {dataDetail[e].username
                            ? dataDetail[e].username
                            : "-"}
                        </Typography>
                        <Typography sx={{ color: "#00000070", fontSize: 12 }}>
                          {dataDetail[e].date
                            ? `${new Date(
                                dataDetail[e].date
                              ).toLocaleTimeString("id-ID", {
                                hour: "2-digit",
                                minute: "2-digit",
                              })} - ${new Date(
                                dataDetail[e].date
                              ).toLocaleDateString("id-ID")}`
                            : "-"}
                        </Typography>
                      </Box>
                      {/* {dataDetail[e].message ? (
                        <Typography>{dataDetail[e].message}</Typography>
                      ) : null} */}
                      {dataDetail[e].message
                        ? dataDetail[e].message
                            .split("\n")
                            .map((el, i) => (
                              <Typography key={i}>{el}</Typography>
                            ))
                        : null}
                      {dataDetail[e].images ? (
                        <ImageList
                          sx={{ width: "100%", maxHeight: 150 }}
                          cols={6}
                        >
                          {dataDetail[e].images.map((img, indexImg) => (
                            <ImageListItem
                              key={indexImg}
                              onClick={() => setOpenDetailImage(img)}
                              sx={{
                                "&:hover": {
                                  cursor: "pointer",
                                },
                              }}
                            >
                              <img
                                src={img}
                                loading="lazy"
                                alt="gambar rusak"
                              />
                            </ImageListItem>
                          ))}
                        </ImageList>
                      ) : null}
                    </Box>
                  ))
                ) : (
                  <Typography sx={{ color: "#00000050" }} align="center">
                    Belum ada Komentar
                  </Typography>
                )}
                <Box
                  sx={{
                    display: "flex",
                    width: "100%",
                    borderTop: 2,
                    borderColor: "#000000",
                    paddingTop: 2,
                  }}
                >
                  <Box
                    sx={{
                      width: "90%",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "end",
                      border: 1,
                      borderColor: "#000000",
                    }}
                  >
                    <TextField
                      multiline
                      value={messageDetail}
                      rows={4}
                      sx={{
                        width: "100%",
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "none",
                        },
                      }}
                      placeholder="Tulis komentarmu disini"
                      inputProps={{ maxLength: 250 }}
                      onChange={(e) => {
                        // setNotif({
                        //   ...notif,
                        //   type: "",
                        //   status: "success",
                        //   message: "",
                        // });
                        // setDataTicket({ ...dataTicket, note: e.target.value });
                        setMessageDetail(e.target.value);
                      }}
                    />
                    <IconButton component="label" sx={{ width: "10%" }}>
                      <AttachFile />
                      <input
                        type="file"
                        hidden
                        multiple
                        onChange={fileHandler}
                      />
                    </IconButton>
                  </Box>
                  <Box
                    sx={{
                      width: "10%",
                      display: "flex",
                      alignItems: "end",
                      justifyContent: "center",
                    }}
                  >
                    <IconButton onClick={(e) => sendMessage(e)}>
                      <Send />
                    </IconButton>
                  </Box>
                </Box>
                {uploadImages.length ? (
                  <Stack
                    spacing={0}
                    direction="row"
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      flexWrap: "wrap",
                      gap: 2,
                      p: 2,
                    }}
                  >
                    {uploadImages.map((e, i) => (
                      <Box
                        sx={{
                          border: 1,
                          borderColor: "#000000",
                          display: "flex",
                          alignItems: "center",
                        }}
                        key={i}
                      >
                        <Typography sx={{ mr: 1 }}>{e.name}</Typography>
                        <IconButton
                          onClick={(e) => {
                            setUploadImages([
                              ...uploadImages.slice(0, i),
                              ...uploadImages.slice(i + 1),
                            ]);
                          }}
                        >
                          <Clear />
                        </IconButton>
                      </Box>
                    ))}
                  </Stack>
                ) : null}
              </Box>
            )}
          </Box>
        </Zoom>
      </Modal>

      <Modal
        open={openDetailImage}
        onClose={() => {
          setOpenDetailImage(false);
        }}
        sx={{
          backdropFilter: "blur(5px)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "100%",
        }}
      >
        <Zoom in={openDetailImage}>
          {/mp4/i.test(openDetailImage) || /mkv/i.test(openDetailImage) ? (
            <Box sx={{ height: "80%", width: "80%" }}>
              <video width="100%" height="100%" controls>
                <source src={`${openDetailImage}#t=10`} type="video/mp4" />
              </video>
            </Box>
          ) : (
            <Box
              component="img"
              sx={{
                // height: 233,
                // width: 350,
                maxHeight: "80%",
                maxWidth: "80%",
              }}
              src={openDetailImage}
            />
          )}
        </Zoom>
      </Modal>

      <UIAlert
        open={res.status && res.message ? true : false}
        onClose={() =>
          setRes({ ...res, status: res.status, message: res.message })
        }
        res={res}
      />

      <Grid item width="100%" justifyContent="space-between" display="flex">
        <Grid item display="flex">
          {auth.group_name === "root" ||
          auth.group_name === "Customer Service" ? (
            <Button
              startIcon={<AddCircleOutline />}
              onClick={() => {
                setAddTicketData({
                  ...addTicketData,
                  awb: "",
                  note: "",
                  awbManual: "",
                });
                setRes({ ...res, status: "sucess", message: "" });
                // setOpen(true);
                setOpenModal(true);
              }}
              variant="outlined"
              color="success"
              size="small"
            >
              Add
            </Button>
          ) : null}
          <Button
            size="small"
            variant="outlined"
            style={{ marginLeft: 5 }}
            color="primary"
            id="basic-button"
            aria-controls={openStatus ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={openStatus ? "true" : undefined}
            onClick={(e) => setAnchorEl(e.currentTarget)}
          >
            {selectedStatus}
          </Button>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={openStatus}
            onClose={() => {
              setAnchorEl(null);
            }}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            {[
              "All",
              "Belum Diproses",
              "Dalam Proses",
              "Claim Internal",
              "Claim External",
              "Claim Internal Selesai",
              "Claim External Selesai",
              "Retur",
              "Retur Selesai",
              "Permasalahan Lainnya",
              "Permasalahan Lainnya Selesai",
              "Selesai",
            ].map((el, i) => (
              <MenuItem
                key={i}
                onClick={(e) => {
                  setSelectedStatus(el.toLowerCase());
                  setAnchorEl(null);

                  if (el !== "All") {
                    push({
                      ...query,
                      status: el.toLowerCase(),
                      page: 0,
                      perPage: 10,
                    });
                  } else {
                    let temp = { ...query };
                    temp.page = 0;
                    temp.perPage = 10;
                    if (temp.status) {
                      delete temp.status;
                    }
                    push(temp);
                  }
                }}
                value={el.toLowerCase()}
              >
                {el}
              </MenuItem>
            ))}
          </Menu>

          {showDateFilter ? (
            <RangeDateTimePicker
              onFilter={({ startDate, endDate }) =>
                (_) => {
                  push({
                    ...query,
                    page: 0,
                    perPage: 10,
                    minDate: startDate,
                    maxDate: endDate,
                  });
                  setShowDateFilter(false);
                }}
              open
              close={setShowDateFilter}
            />
          ) : null}

          {mountFilter.dateRange.value ? (
            <Button
              size="small"
              endIcon={<RemoveCircleOutlineOutlined />}
              variant="outlined"
              style={{ marginLeft: 5 }}
              color="error"
              onClick={() => push({ ...query, minDate: "", maxDate: "" })}
              title="Remove Filter Date"
            >
              {new Date(mountFilter.dateRange.minDate).toLocaleDateString(
                "en-US",
                { timeZone: "Asia/Jakarta" }
              )}{" "}
              -{" "}
              {new Date(mountFilter.dateRange.maxDate).toLocaleDateString(
                "en-US",
                { timeZone: "Asia/Jakarta" }
              )}
            </Button>
          ) : (
            <Button
              size="small"
              variant="outlined"
              style={{ marginLeft: 5 }}
              onClick={() => setShowDateFilter(true)}
              title="Filter date"
            >
              Choose Date Range
            </Button>
          )}

          {count ? (
            <Button
              title="Export Excel"
              style={{ marginLeft: 5 }}
              endIcon={<ImportExportOutlined />}
              size="small"
              variant="outlined"
              color="secondary"
              onClick={(_) => exportFile()}
            >
              Export Data
            </Button>
          ) : null}
        </Grid>

        <TextField
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          onKeyUp={({ key }) => {
            if (key === "Enter") {
              if (search) push({ ...query, search });
              else {
                let temp = { ...query };
                if (temp.search) {
                  delete temp.search;
                }
                push(temp);
              }
            }
          }}
          name="search"
          variant="standard"
          color="info"
          placeholder={"Search here"}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {query.search ? (
                  <IconButton
                    color="error"
                    onClick={() => {
                      let temp = { ...query };
                      if (temp.search) {
                        delete temp.search;
                      }
                      setSearch("");
                      push(temp);
                    }}
                  >
                    <DeleteOutline />
                  </IconButton>
                ) : (
                  <IconButton
                    onClick={() => {
                      if (search) push({ ...query, search });
                    }}
                  >
                    <SearchOutlined />
                  </IconButton>
                )}
              </InputAdornment>
            ),
          }}
        />
      </Grid>

      <Grid item />

      <TableContainer style={{ minHeight: "50vh", maxHeight: "80vh" }}>
        <Table size="small" style={{ width: "100%" }} stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell sx={{ width: 20 }}>No</TableCell>
              <TableCell sx={{ width: 100 }} align="center">
                Tanggal
              </TableCell>
              <TableCell sx={{ width: 100 }}>Awb</TableCell>
              <TableCell sx={{ width: 100 }}>Nama Seller</TableCell>
              <TableCell sx={{ width: 100 }}>Nomor Telpon</TableCell>
              <TableCell sx={{ width: 300 }}>Notes</TableCell>
              <TableCell sx={{ width: 200 }}>Status</TableCell>
              <TableCell sx={{ width: 200 }}>Dibuat</TableCell>
              <TableCell align="center" sx={{ width: 300 }}>
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.length
              ? data.map((e, i) => (
                  <TableRow
                    key={i}
                    sx={{
                      backgroundColor: /selesai/i.test(e.status)
                        ? "#edf7ed"
                        : e.status === "retur"
                        ? "#C780FA30"
                        : e.status === "claim external"
                        ? "#85000030"
                        : e.status === "claim internal"
                        ? "#F5732830"
                        : "#ffffff",
                    }}
                  >
                    <TableCell>{i + 1}</TableCell>
                    <TableCell align="center">
                      <Typography sx={{ fontSize: 12 }}>
                        {`${new Date(e.createdAt).toLocaleTimeString("id-ID", {
                          hour: "2-digit",
                          minute: "2-digit",
                        })}`}
                      </Typography>
                      <Typography sx={{ fontSize: 12 }}>{`${new Date(
                        e.createdAt
                      ).toLocaleDateString("id-ID")}`}</Typography>
                    </TableCell>
                    <TableCell>{e.awb}</TableCell>
                    <TableCell>{e.username}</TableCell>
                    <TableCell>{e.phone}</TableCell>
                    <TableCell>{e.note}</TableCell>
                    <TableCell>
                      {isEdit === i ? (
                        <FormControl fullWidth size="small">
                          <InputLabel>Status</InputLabel>
                          <Select
                            label="Status"
                            value={dataEdit.status}
                            onChange={(el) => {
                              // let temp = e;
                              // temp.status = e.target.value;
                              setDataEdit({
                                ...dataEdit,
                                status: el.target.value,
                              });
                            }}
                          >
                            <MenuItem value={"belum diproses"}>
                              Belum Diproses
                            </MenuItem>
                            <MenuItem value={"dalam proses"}>
                              Dalam Proses
                            </MenuItem>
                            <MenuItem value={"claim internal"}>
                              Claim Internal
                            </MenuItem>
                            <MenuItem value={"claim external"}>
                              Claim External
                            </MenuItem>
                            <MenuItem value={"claim internal selesai"}>
                              Claim Internal Selesai
                            </MenuItem>
                            <MenuItem value={"claim external selesai"}>
                              Claim External Selesai
                            </MenuItem>
                            <MenuItem value={"retur"}>Retur</MenuItem>
                            <MenuItem value={"retur selesai"}>
                              Retur Selesai
                            </MenuItem>
                            <MenuItem value={"permasalahan lainnya"}>
                              Permasalahan Lainnya
                            </MenuItem>
                            <MenuItem value={"permasalahan lainnya selesai"}>
                              Permasalahan Lainnya Selesai
                            </MenuItem>

                            <MenuItem value={"selesai"}>Selesai</MenuItem>
                          </Select>
                        </FormControl>
                      ) : (
                        e.status
                      )}
                    </TableCell>
                    <TableCell>{e.createdBy}</TableCell>
                    <TableCell align="center">
                      {isEdit === i ? (
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            flexDirection: {
                              lg: "row",
                              md: "column",
                              sm: "column",
                              xs: "column",
                            },
                          }}
                        >
                          <Button
                            variant="contained"
                            color="error"
                            size="small"
                            onClick={(e) => {
                              e.preventDefault();
                              setIsEdit(-1);
                            }}
                            sx={{
                              mr: 2,
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            Batal
                          </Button>
                          <Button
                            variant="contained"
                            color="success"
                            size="small"
                            onClick={(e) => {
                              e.preventDefault();
                              if (dataEdit.status !== data[isEdit].status) {
                                editList({
                                  payload: dataEdit,
                                  id: dataEdit.id,
                                });
                                let newData = [...data];
                                newData[isEdit] = dataEdit;
                                setData(newData);
                              }

                              setIsEdit(-1);
                            }}
                          >
                            Simpan
                          </Button>
                        </Box>
                      ) : (
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            flexDirection: {
                              lg: "row",
                              md: "column",
                              sm: "column",
                              xs: "column",
                            },
                          }}
                        >
                          {auth.group_name === "root" ||
                          auth.group_name === "Customer Service" ? (
                            <IconButton
                              onClick={(el) => {
                                el.preventDefault();
                                setIsEdit(i);
                                setDataEdit(e);
                              }}
                            >
                              <ChangeCircleOutlined />
                            </IconButton>
                          ) : null}
                          <IconButton
                            onClick={async () => {
                              // setDataDetail(e.detail);
                              setIndexDetail(i);
                              setIdDetail(e.id);
                              setOpenDetail(true);
                              setLoadingDetail(true);
                              await getDetail(e.id);
                              setTitleDetail({
                                seller: e.username || "",
                                awb: e.awb || "",
                                note: e.note || "",
                              });
                            }}
                          >
                            <Badge
                              badgeContent={Object.keys(e.detail).length || 0}
                              color="primary"
                              showZero
                            >
                              <FeaturedPlayListOutlined />
                            </Badge>
                          </IconButton>
                          {/* <Button
                            variant="contained"
                            size="small"
                            onClick={(el) => {
                              el.preventDefault();
                              setIsEdit(i);
                              setDataEdit(e);
                            }}
                          >
                            Edit
                          </Button>
                          <Button
                            variant="contained"
                            size="small"
                            onClick={() => {
                              console.log("detail");
                              setOpenDetail(true);
                            }}
                          >
                            Detail
                          </Button> */}
                        </Box>
                      )}
                    </TableCell>
                  </TableRow>
                ))
              : null}
          </TableBody>
        </Table>
        {loading ? (
          <div className="center">
            <CircularProgress size={25} />
          </div>
        ) : res.message ? (
          <Alert variant="standard" severity="error">
            {res.message}
          </Alert>
        ) : data.length === 0 ? (
          <Alert variant="standard" severity="error">
            No Data
          </Alert>
        ) : null}
      </TableContainer>

      <TablePagination
        rowsPerPageOptions={[5, 10, 15, 25, 50]}
        component="div"
        // count={data.count}
        count={count}
        rowsPerPage={parseInt(query?.perPage || "10", 10)}
        page={count === 0 ? 0 : parseInt(query?.page || "0", 10)}
        onPageChange={(_, newPage) =>
          push(
            query
              ? { ...query, page: newPage }
              : { page: newPage, perPage: "10" }
          )
        }
        onRowsPerPageChange={({ target: { value = "10" } }) =>
          push(
            query
              ? { ...query, page: 0, perPage: parseInt(value, 10) }
              : { page: 0, perPage: parseInt(value, 10) }
          )
        }
        ActionsComponent={TablePaginationActions}
      />
    </Fragment>
  );
};
