import { UploadOutlined } from "@mui/icons-material";
import { Button, Grid, Switch, Typography } from "@mui/material";
import { BasicModal, UIAlert } from "../../components/spam/modal";
import { LinearProgressWithLabel } from "../../components/spam/inputs";
// import CloseIcon from "@mui/icons-material/Close";
import { useState } from "react";
import writeXlsxFile from "write-excel-file";
import { ExcelRenderer } from "react-excel-renderer";
import { handleError } from "../../services/helpers";
import { useReportsUpload } from "../../services/delivery";
import { compress } from "compress-json";
import { serialDate } from "../../helpers/custom";

export default function UploadReportPage() {
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  // const [checkError, setCheckError] = useState(true);
  const [checked, setChecked] = useState(false);
  // const [dataError, setDataError] = useState([]);
  // const [loadExcel, setLoadExcel] = useState(false);
  const [res, setRes] = useState({ status: "", message: "" });
  const { onProcess, onUpdate } = useReportsUpload();

  const handleDownload = async () => {
    const schema = [
      {
        column: "No. Waybill",
        width: 13,
        type: String,
        value: () => "",
      },
      {
        column: "Waktu Delivery",
        width: 17,
        type: String,
        value: () => "",
      },
      {
        column: "Sprinter Delivery",
        width: 22,
        type: String,
        value: () => "",
      },
      {
        column: "Penerima",
        width: 15,
        type: String,
        value: () => "",
      },
      {
        column: "COD",
        width: 13,
        type: Number,
        value: () => "",
      },
    ];

    const schemaTTD = [
      {
        column: "No. Waybill",
        width: 13,
        type: String,
        value: () => "",
      },
      {
        column: "Waktu TTD",
        width: 17,
        type: String,
        value: () => "",
      },
      {
        column: "TTD Retur",
        width: 9,
        type: String,
        value: () => "",
      },
    ];

    if (!checked) {
      await writeXlsxFile([], {
        schema,
        headerStyle: {
          fontWeight: "bold",
          align: "center",
        },
        fileName: "template reports.xlsx",
      });
    } else {
      await writeXlsxFile([], {
        schema: schemaTTD,
        headerStyle: {
          fontWeight: "bold",
          align: "center",
        },
        fileName: "template reports ttd.xlsx",
      });
    }
  };

  function fileHandle(e) {
    if (e.preventDefault) e.preventDefault();

    const getFiles = (() => {
      try {
        return e.target.files[0];
      } catch (e) {
        return null;
      }
    })();
    if (getFiles) {
      ExcelRenderer(getFiles, async (err, resp) => {
        try {
          if (err) throw err;

          let getHeaders = resp.rows[0];
          let rpts = [];

          if (!resp.rows[1].length) {
            setRes({
              status: "error",
              message: "Data tidak boleh kosong",
            });
            setLoading(false);

            return;
          }
          setLoading(true);
          setProgress(0);
          await resp.rows.forEach((item, index) => {
            if (index !== 0) {
              if (item.length > 0) {
                let payload = {};
                getHeaders.forEach((headers, _index) => {
                  // console.log(Number(item[_index]));
                  payload[headers] = !item[_index]
                    ? ""
                    : item[_index] &&
                      typeof item[_index] !== "string" &&
                      String(item[_index]).length > 10
                    ? `${serialDate(item[_index])}`
                    : item[_index] && typeof item[_index] !== "string"
                    ? `${item[_index]}`.replace(/^[ ]+|[ ]$/g, "")
                    : item[_index] && typeof item[_index] === "string"
                    ? item[_index].replace(/^[ ]+|[ ]$/g, "")
                    : item[_index];
                });

                rpts.push(payload);
              }
            }
          });

          let last = false;
          let perPage = Math.ceil([...rpts].length / 10);
          let totalItems = [...rpts].length;
          let dataReturn = 0;
          if (!checked) {
            while (!last) {
              const orderList = rpts.splice(0, perPage);
              const progressValue = Math.floor(
                ((totalItems - rpts.length) / totalItems) * 100
              );
              last = rpts.length === 0;

              let payload = {
                reports: compress(orderList),
              };

              const updateReturn = await onProcess(
                payload,
                `Upload transactions ${orderList.length}/${totalItems}`
              );
              if (!!updateReturn.missing_sprinter) dataReturn++;
              setProgress(progressValue);
            }
          } else {
            while (!last) {
              const orderList = rpts.splice(0, perPage);
              const progressValue = Math.floor(
                ((totalItems - rpts.length) / totalItems) * 100
              );
              last = rpts.length === 0;

              let payload = {
                reports: compress(orderList),
              };

              const updateReturn = await onUpdate(
                payload,
                `Upload transactions ${orderList.length}/${totalItems}`
              );
              if (!!updateReturn.missing_awb) dataReturn++;
              setProgress(progressValue);
            }
          }
          setProgress(100);
          setRes({
            status: "ok",
            message: `Berhasil menambahkan semua data delivery report${
              dataReturn > 0 ? `, missing ${dataReturn}` : ""
            }`,
          });

          // if (statusWa) {
          //   await gtw({
          //     method: "post",
          //     url: `/whatsapp/send_report?status=upload`,
          //     data: {
          //       data: compress(sendSeller),
          //       uploadId,
          //     },
          //     headers,
          //   });
          //   // setRes({
          //   //   status: "success",
          //   //   message: "Proses upload selesai",
          //   // });
          // } else {
          //   setRes({
          //     status: "warning",
          //     message: "Data terupload tetapi whatsapp tidak terkirim",
          //   });
          // }

          setLoading(false);
          setTimeout(() => {
            window.location.reload();
          }, 4000);
        } catch (e) {
          const message = handleError(e);
          console.log(message);
          if (message.cancel) return true;
          if (/network/i.test(message) || /connection/i.test(message))
            setRes({ status: "error", message });
          else
            setRes({
              status: "error",
              message: "Gagal menambahkan data transaksi",
            });
          setLoading(false);
        }
      });
    }
  }

  const handleUpdateTTD = (e) => {
    setChecked(e.target.checked);
  };

  return (
    <>
      <UIAlert
        onClose={(_) => setRes({ status: "", message: "" })}
        open={res.status !== ""}
        autoHideDuration={10000}
        res={res}
      />

      <Grid
        item
        width="100%"
        justifyContent="center"
        flexDirection="row"
        rowGap={"12px"}
        alignItems="center"
        style={{
          width: "100%",
          position: "relative",
          cursor: "pointer",
        }}
        display="flex"
      >
        <Switch
          checked={checked}
          onChange={handleUpdateTTD}
          inputProps={{ "aria-label": "controlled" }}
        />
        <Typography variant="h7">Update TTD</Typography>
      </Grid>

      {loading ? (
        <BasicModal title="Loading..." open onClose={() => null}>
          <Grid
            container
            spacing={2}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Grid item>
              <LinearProgressWithLabel value={progress} />

              <Typography variant="h6" color="red" textAlign="center">
                Proses upload sedang berlangsung
                <br />
                Jangan buka tab lain
              </Typography>
            </Grid>
          </Grid>
        </BasicModal>
      ) : null}

      <Grid
        item
        width="100%"
        justifyContent="center"
        flexDirection="column"
        alignItems="center"
        style={{
          height: "85vh",
          width: "100%",
          position: "relative",
          cursor: "pointer",
        }}
        display="flex"
      >
        <UploadOutlined style={{ width: "auto", opacity: 0.5, height: 250 }} />
        <Typography variant="h4">Upload File</Typography>

        <input
          type="file"
          onChange={fileHandle}
          style={{
            width: "100%",
            cursor: "pointer",
            opacity: 0,
            height: "100%",
            position: "absolute",
            left: 0,
            top: 0,
          }}
        />

        <Button
          variant="outlined"
          onClick={() => {
            handleDownload();
          }}
          sx={{ mt: 10 }}
        >
          Download Template
        </Button>
      </Grid>
    </>
  );
}
