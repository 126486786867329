import { useContext } from "react";
import { Outlet, Navigate } from "react-router-dom";
import { Grid } from "@mui/material";
import { AuthContext } from "../../utils/auth";
import { GroupProvider } from "../../utils/group";
import { ModuleProvider } from "../../utils/modules";
import "./root.scss";

export const RootPage = () => {
  const { auth } = useContext(AuthContext);

  if (auth.group_name === "executor") return <Navigate to="/dashboard" />;

  return (
    <GroupProvider>
      <ModuleProvider>
        <Grid
          item
          xs={12}
          md={12}
          lg={12}
          component="div"
          className="root-container"
        >
          <Outlet />
        </Grid>
      </ModuleProvider>
    </GroupProvider>
  );
};
export * from "./homeLists";
export * from "./create";
