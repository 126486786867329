import { Box, Divider, Typography } from "@mui/material";
import { convertCurrency } from "../helpers/custom";

export const differenceCard = (internal = 0, external = 0) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        border: 1,
        height: 50,
        width: 200,
        backgroundColor: external
          ? +internal === +external
            ? "#ffffff"
            : "#d32f2f30"
          : "#ffffff",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "100%",
          flexDirection: "column",
        }}
      >
        <Typography>Report</Typography>
        <Divider
          orientation="horizontal"
          sx={{
            width: "100%",
            borderTopWidth: "1px",
            backgroundColor: "#000000",
          }}
        />
        <Typography>{!internal ? "-" : internal}</Typography>
      </Box>
      <Divider
        orientation="vertical"
        sx={{
          height: "100%",
          borderRightWidth: "1px",
          backgroundColor: "#000000",
        }}
      />
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "100%",
          flexDirection: "column",
        }}
      >
        <Typography>Surat Jalan</Typography>
        <Divider
          orientation="horizontal"
          sx={{
            width: "100%",
            borderTopWidth: "1px",
            backgroundColor: "#000000",
          }}
        />
        <Typography>{!external ? "-" : external}</Typography>
      </Box>
    </Box>
  );
};
