import { useState, Fragment } from "react";
import { Typography, Box, Button } from "@mui/material";
import { RangeDateTimePicker } from "../../components/spam/dateTime";

export const FinancialComparationUploadPage = () => {
  const [showDateFilter, setShowDateFilter] = useState(false);

  const fileHandler = (e) => {
    e.preventDefault();

    console.log("buat uplaod ye");
  };

  return (
    <Fragment>
      {showDateFilter ? (
        <RangeDateTimePicker
          onFilter={({ startDate, endDate }) =>
            (_) => {
              // push({ ...query, minDate: startDate, maxDate: endDate });
              setShowDateFilter(false);
            }}
          open
          close={setShowDateFilter}
        />
      ) : null}

      <Button
        size="small"
        variant="outlined"
        style={{ marginLeft: 5 }}
        onClick={() => setShowDateFilter(true)}
        title="Filter date"
      >
        Choose Date Range
      </Button>
      <Button
        component="label"
        // startIcon={<Update />}
        variant="outlined"
        color="success"
        size="small"
      >
        Upload Comparation File
        <input type="file" hidden onChange={fileHandler} />
      </Button>
      <Typography>Compare nih</Typography>
    </Fragment>
  );
};
