import {useEffect, useState, Fragment} from 'react';
import {useNavigate, useLocation} from 'react-router-dom';
import {
	Button,
	TableContainer,
	Table,
	Grid,
	TableBody,
	Alert,
	TableCell,
	TableRow,
	Checkbox,
	TableHead,
	IconButton,
	TablePagination,
	Typography,
	ListItem,
	List,
	ListItemText,
} from '@mui/material';
import {ArrowLeftOutlined, AddOutlined, EditOutlined, DeleteOutlineOutlined} from '@mui/icons-material';
import {useSellerAlias, useDetailUserSellerr, useActionAlias} from '../../services/user-seller';
import {TablePaginationActions} from '../../components/spam/pagination';
import {SearchHead, AddAlias} from '../../components/spam/inputs';
import {BasicModal} from '../../components/spam/modal';

const DeleteItems = ({data = [], open, close, refresh}) => {
	const {onProcess, res: _response} = useActionAlias(),
		[loading, setLoading] = useState(false),
		[count, setCount] = useState({succes: 0, error: 0}),
		[res, setRes] = useState({status: '', message:''}),
		execute = async () => {
			if(data.length === 0)
				return setRes({status: 'error', message: 'please select alias name'});
			setRes({status: '', message: ''});
			setLoading(true);
			for(const alias of data) {
				await onProcess({aliasId: alias.id, method: 'delete'}, {}, () => null);
			}
			setLoading(false);

			setRes({status: 'ok', message: `Success delete item`});
			refresh();
			close();
		}

	useEffect(() => {
		if(_response.status)
			setCount(prev => ({succes: _response.status === 'ok' ? prev.succes + 1 : prev.succes, error: _response.status === 'error'? prev.error + 1 : prev.error}))
	}, [_response.status])


	return (
		<BasicModal open={open} onClose={close} title={`Are you sure delete ${data.length} items`}>
			<Grid container spacing={3} display='flex' width='100%' justifyContent='space-around'>
				<Grid item/>
				{loading
					?	<Grid item>
							<Typography variant='caption'>Loading...</Typography>
						</Grid>
					:	<Fragment>
							<Grid item>
								<Button
									color='info'
									variant='text'
									size='small'
									title='Cancel Delete'
									onClick={() => close(false)}>Cancel</Button>
							</Grid>
							<Grid item>
								<Button
									variant='outlined'
									color='error'
									size='small'
									title='Delete'
									onClick={execute}>Sure</Button>
							</Grid>
						</Fragment>}

				<Grid item/>
				{res.message
					?	<Grid item>
							<Alert severity={res.status === 'ok' ? 'success' : 'error'}>{res.message} {count.succes > 0 ? `Success ${count.succes}` : ''} {count.error > 0 ? `Error ${count.error}` : ''}</Alert>
						</Grid>
					: null}
			</Grid>
		</BasicModal>
	)
}

export const AliasSellerPage = () => {
	const {onProcess, loading, res, data, push, query, updateOne, updateAll, selectionItem} = useSellerAlias(),
		{onProcess: getSeller, res: _resDetail, data: _dataDetail, loading: _loadingDetail} = useDetailUserSellerr(),
		[values, setValues] = useState(''),
		optionDate = {timeZone: 'Asia/Jakarta'},
		[deleteData, setDeleteData] = useState(false),
		[showModal, setShowModal] = useState(false),
		{state} = useLocation(),
		navigate = useNavigate();


	useEffect(() => {
		if(_dataDetail && _dataDetail.id)
			onProcess();
	}, [onProcess, _dataDetail])

	useEffect(() => {
		getSeller();
	}, [getSeller])


	useEffect(() => {
		if(query && query.search)
			setValues(query.search);
		else
			setValues('');
	}, [query])

	return (
		<Fragment>
			{(deleteData && selectionItem) || (deleteData && deleteData?.length > 0)
				?	<DeleteItems
						data={typeof deleteData && Array.isArray(deleteData) ? deleteData : selectionItem}
						open
						refresh={onProcess}
						close={setDeleteData}/>
				:	null}
			{_dataDetail && _dataDetail.id && (showModal || showModal?.id)
				?	<AddAlias
						open
						close={setShowModal}
						refresh={newAlias => {
							if(showModal && showModal.id)
								return updateOne({id: showModal.id, key: 'alias', value: newAlias});
							onProcess();
						}}
						edit={{
							value: typeof showModal === 'object' && showModal.id,
							data: {
								id: showModal?.id || '',
								alias: showModal?.alias || '',
							}
						}}
						data={_dataDetail}/>
				:	null}
			

			<Grid item>
				<Button size='small' startIcon={<ArrowLeftOutlined/>} onClick={() => navigate(state?.from ? -1 : `/seller?page=0&perPage=10`)} variant='outlined' color='info'>
					Go Back
				</Button>
			</Grid>
			<Grid item width='100%' justifyContent='space-between' display='flex'>
				<Grid display='flex'>
					{_dataDetail && _dataDetail.id
						?	<Button
								startIcon={<AddOutlined/>}
								onClick={() => setShowModal(true)}
								variant='outlined'
								size='small'
								color='success'>
								Add New Alias
							</Button>
						:	<Grid/>}

					
					{selectionItem.length > 0
						?	<Button
								startIcon={<DeleteOutlineOutlined/>}
								onClick={() => setDeleteData(true)}
								variant='outlined'
								size='small'
								style={{marginLeft: 10}}
								color='error'>
								Delete {selectionItem.length} Items
							</Button>
						:	<Grid/>}

				</Grid>
				

				<SearchHead
					values={values}
					placeholder='Search Seller here'
					onEnter={variable => push({...query, page: 0, search: variable})}/>
			</Grid>

			<Grid item>
				<List style={{display: 'flex'}}>
					<ListItem disablePadding>
						<ListItemText primaryTypographyProps={{fontSize: 13, fontWeight: 'bolder'}} primary='Full Name' secondary={_dataDetail?.name || '-'}/>
					</ListItem>
					<ListItem disablePadding style={{marginLeft: 20}}>
						<ListItemText primaryTypographyProps={{fontSize: 13, fontWeight: 'bolder'}} primary='Email' secondary={_dataDetail?.email || '-'}/>
					</ListItem>
				</List>
			</Grid>


			<TableContainer style={{minHeight: '50vh', marginTop: 5, maxHeight: '70vh'}}>
				<Table size='small' style={{width: '100%'}} stickyHeader>
					<TableHead>
						<TableRow>
							<TableCell padding='checkbox'>
								<Checkbox
									disabled={data.data.length === 0}
									checked={data.data.length === 0 ? false : selectionItem.length === data.data.length}
									onChange={({target: {checked}}) => updateAll({key: 'isChecked', value: checked})}
									variant='standard'
									color='primary'
									size='small'/>
							</TableCell>
							<TableCell>No</TableCell>
							<TableCell>Create Date</TableCell>
							<TableCell>Update Date</TableCell>
							<TableCell>Alias Name</TableCell>
							<TableCell>Action</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{data.data.length > 0
							?	data.data.map((item, index) => (
								<TableRow hover key={index}>
									<TableCell padding='checkbox'>
										<Checkbox
											checked={item.isChecked}
											onChange={({target: {checked}}) => updateOne({id: item.id, key: 'isChecked', value: checked})}
											variant='standard'
											color='primary'
											size='small'/>
									</TableCell>
									<TableCell>{index +1}</TableCell>
									<TableCell>
										{item.createdAt
											?	<Fragment>
													{new Date(item.createdAt).toLocaleDateString('en-US', optionDate)}<br/>
													{new Date(item.createdAt).toLocaleTimeString('en-US', optionDate)}
												</Fragment> : ' - '}
									</TableCell>
									<TableCell>
										{item.updatedAt
											?	<Fragment>
													{new Date(item.updatedAt).toLocaleDateString('en-US', optionDate)}<br/>
													{new Date(item.updatedAt).toLocaleTimeString('en-US', optionDate)}
												</Fragment> : ' - '}
									</TableCell>
									<TableCell>{item.alias || '-'}</TableCell>
									<TableCell>
										<IconButton
											title='Edit Alias'
											size='small'
											variant='standard'
											color='primary'
											onClick={() => setShowModal({id: item.id, alias: item.alias})}>
											<EditOutlined/>
										</IconButton>
										<IconButton
											title='Delete Alias'
											size='small'
											style={{marginLeft: 5}}
											variant='standard'
											color='error'
											onClick={() => setDeleteData([item])}>
											<DeleteOutlineOutlined/>
										</IconButton>
									</TableCell>
								</TableRow>
							))
							:	null}
					</TableBody>
				</Table>
				{loading || _loadingDetail
					?	<div className='center'>
							<Typography variant='caption'>Loading...</Typography>
						</div>
					:	res.message || _resDetail.message
						?	<Alert variant='standard' severity='error'>{res.message || _resDetail.message}</Alert>
						: data.data.length === 0
							?	<Alert variant='standard' severity='warning'>No Data</Alert>
							:	null}
			</TableContainer>

			<TablePagination
				rowsPerPageOptions={[5, 10, 15, 25]}
				component='div'
				count={data.count}
				rowsPerPage={parseInt(query?.perPage || '10', 10)}
				page={data.count === 0 ? 0 : parseInt(query?.page || '0', 10)}
				onPageChange={(_, newPage) => push(query ? {...query, page: newPage} : {page: newPage, perPage: '10'})}
				onRowsPerPageChange={({target: {value = '10'}}) => push(query ? {...query, page: 0, perPage: parseInt(value, 10)} : {page: 0, perPage: parseInt(value, 10)})}
				ActionsComponent={TablePaginationActions}/>
		</Fragment>
	)
}