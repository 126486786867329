import {
  Grid,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Button,
  Alert,
  CircularProgress,
  TablePagination,
  Divider,
  Box,
  // Typography,
  TextField,
  Modal,
  Zoom,
} from "@mui/material";
import {
  // Today,
  RemoveCircleOutlineOutlined,
  // ImportExportOutlined,
} from "@mui/icons-material";
import { differenceCard } from "../../components/differenceCard";
import { SearchHead } from "../../components/spam/inputs";
import { useEffect, useState, Fragment } from "react";
import { useListDifference } from "../../services/transactions";
import { StaticDatePicker } from "@mui/x-date-pickers/StaticDatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { useSearchParams } from "react-router-dom";
import { TablePaginationActions } from "../../components/spam/pagination";

export const DifferenceTransactionPage = () => {
  //route
  const [searchParams, setSearchParams] = useSearchParams();
  const params = Object.fromEntries([...searchParams]);
  //route

  const { getList, data, push, query, loading, newData, setNewData } =
    useListDifference();
  // const [searchData, setSearchData] = useState({});
  const [count, setCount] = useState(0);
  const [date, setDate] = useState("");
  const [openDatePicker, setOpenDatePicker] = useState(false);

  const searchSeller = (obj) => {
    const newObjSearch = {};
    let newobj = {};
    let page = +params.page - 1;
    let limit = 10;
    let offset = page * limit;
    let newData = params.filter && params.filter === "selisih" ? obj : data;
    if (params.search) {
      // setValues(searchQuery);
      Object.keys(newData).forEach((e) => {
        if (e.toLocaleLowerCase().match(params.search.toLowerCase())) {
          newObjSearch[e] = data[e];
        }
      });
      if (Object.keys(newObjSearch).length >= limit) {
        Object.entries(newObjSearch).forEach(function ([key, item], index) {
          if (index >= offset && index < offset + limit) {
            newobj[key] = item;
          }
        });
      }
      newData = Object.keys(newobj).length ? newobj : newObjSearch;
      setCount(
        Object.keys(Object.keys(newobj).length ? newobj : newObjSearch).length
      );
      // setSearchData(newData);
      setNewData(newData);
    }
    // changePage(newObjSearch);
    // setLimit(Object.keys(newObj).length);
  };

  const changePage = (obj) => {
    let newobj = {};
    let page = +params.page - 1;
    let limit = 10;
    let offset = page * limit;
    let newData = params.filter && params.filter === "selisih" ? obj : data;
    Object.entries(newData).forEach(function ([key, item], index) {
      if (index >= offset && index < offset + limit) {
        newobj[key] = item;
      }
    });
    newData = Object.keys(newobj).length ? newobj : data;
    setNewData(newData);
  };

  const filterSelisih = () => {
    let obj = {};
    Object.keys(data).forEach((e) => {
      Object.keys(data[e]).forEach((e1) => {
        if (data[e][e1].internal && data[e][e1].external) {
          if (+data[e][e1].internal !== +data[e][e1].external) {
            obj = { ...obj, [e]: data[e] };
          }
        }
      });
    });
    if (!Object.keys(obj).length) {
      setNewData(obj);
    } else {
      setCount(Object.keys(obj).length);
      if (params.search) {
        searchSeller(obj);
      } else {
        changePage(obj);
      }
    }
  };

  useEffect(() => {
    getList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.date]);

  useEffect(() => {
    if (Object.keys(data).length) {
      setCount(Object.keys(data).length);
      if (params.filter) {
        filterSelisih();
      } else if (params.search) {
        searchSeller();
      } else if (!params.search && params.page) {
        changePage();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, searchParams]);
  return (
    <Fragment>
      <Grid
        item
        width="100%"
        justifyContent="space-between"
        display="flex"
        sx={{ mb: 2 }}
      >
        <Modal
          open={openDatePicker}
          onClose={() => setOpenDatePicker(false)}
          sx={{
            backdropFilter: "blur(5px)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Zoom in={openDatePicker}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                backgroundColor: "#ffffff",
                padding: 2,
              }}
            >
              <Box>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <StaticDatePicker
                    displayStaticWrapperAs="desktop"
                    openTo="day"
                    value={date || new Date(params.date.replaceAll("-", "/"))}
                    sx={{ "& input": { color: "red" } }}
                    onChange={(e) => {
                      setDate(e);
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </Box>
              <Box sx={{ display: "flex", justifyContent: "end" }}>
                <Button
                  onClick={() => setOpenDatePicker(false)}
                  style={{ marginRight: 15 }}
                  variant="outlined"
                  sx={{
                    borderColor: "#ab8c36",
                    color: "#ab8c36",
                    width: "10%",
                    "&:hover": {
                      backgroundColor: "#ffffff",
                      borderColor: "#ab8c36",
                      transform: "scale3d(1.1, 1.2, 1)",
                    },
                  }}
                >
                  Batal
                </Button>
                <Button
                  onClick={() => {
                    let obj = { ...query };
                    delete obj.search;
                    push({
                      ...obj,
                      date: new Date(date)
                        .toLocaleDateString()
                        .replaceAll("/", "-"),
                    });
                    // getOrderValidate();
                    setDate("");
                    setOpenDatePicker(false);
                  }}
                  variant="contained"
                  sx={{
                    backgroundColor: "#333333",
                    width: "10%",
                    "&:hover": {
                      backgroundColor: "#333333",
                      transform: "scale3d(1.1, 1.2, 1)",
                    },
                  }}
                >
                  Cari
                </Button>
              </Box>
            </Box>
          </Zoom>
        </Modal>
        <Grid item display="flex">
          {/* {statusSearch ? (
            <Button
              size="small"
              endIcon={<RemoveCircleOutlineOutlined />}
              variant="outlined"
              style={{ marginLeft: 5 }}
              color="error"
              onClick={() => {
                const obj = params;
                delete obj.startDate;
                delete obj.endDate;
                setSearchParams(obj);
                setStatusSearch(false);
                setState({
                  startDate: new Date(),
                  endDate: new Date(),
                  key: "selection",
                });
              }}
              title="Remove Filter Date"
            >
              {new Date(state.startDate).toLocaleDateString("id-ID", {
                timeZone: "Asia/Jakarta",
              })}{" "}
              -{" "}
              {new Date(state.endDate).toLocaleDateString("id-ID", {
                timeZone: "Asia/Jakarta",
              })}
              Choose Date
            </Button>
          ) : (
            <Button
              size="small"
              variant="outlined"
              style={{ marginLeft: 5 }}
              onClick={() => setOpen(true)}
              title="Filter date"
            >
              Choose Date Range
            </Button>
          )} */}
          <Button
            size="small"
            variant="outlined"
            style={{ marginLeft: 5 }}
            onClick={() => setOpenDatePicker(true)}
            title="Filter date"
          >
            {`Tanggal : ${new Date(
              params.date.replaceAll("-", "/")
            ).toLocaleDateString("id-ID")}`}
          </Button>
          {/* <Button
                title="Export Excel"
                style={{ marginLeft: 5 }}
                endIcon={<ImportExportOutlined />}
                size="small"
                variant="outlined"  
                color="secondary"
                // onClick={(_) => setOpenExport(true)}
              >
                Export Data
              </Button> */}
          {params.filter ? (
            <Button
              size="small"
              endIcon={<RemoveCircleOutlineOutlined />}
              variant="outlined"
              style={{ marginLeft: 5 }}
              color="error"
              onClick={() => {
                let obj = { ...params };
                delete obj.filter;
                setSearchParams(obj);
              }}
              title="Remove Filter Date"
            >
              delete filter
            </Button>
          ) : (
            <Button
              title="Export Excel"
              style={{ marginLeft: 5 }}
              // endIcon={<ImportExportOutlined />}
              size="small"
              variant="outlined"
              color="secondary"
              onClick={(_) => setSearchParams({ ...params, filter: "selisih" })}
            >
              Filter selisih
            </Button>
          )}
        </Grid>

        <SearchHead
          values={params.search}
          // setCount={setCount}
          placeholder="Search here"
          onEnter={(variable) => {
            const obj = params;
            obj.search = variable;
            obj.page = 1;
            if (!variable) {
              // setValues("");
              const obj = params;
              delete obj.search;
              setSearchParams(obj);
            }
            setSearchParams(obj);
            // searchSeller(variable);
          }}
        />
      </Grid>

      {/* <Grid item /> */}

      <Divider />

      <TableContainer
        style={{
          minHeight: "70vh",
          maxWidth: 1500,
          // maxHeight: limit > 10 ? "70vh" : "70vh",
        }}
      >
        <Table size="small" style={{ width: "100%" }} stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell
                sx={{
                  position: "sticky",
                  left: 0,
                  background: "white",
                  zIndex: 3,
                }}
              >
                SELLER
              </TableCell>
              <TableCell align="center">JNE</TableCell>
              <TableCell align="center">JNT</TableCell>
              <TableCell align="center">ANTER AJA</TableCell>
              <TableCell align="center">NINJA</TableCell>
              <TableCell align="center">ID</TableCell>
              <TableCell align="center">LION</TableCell>
              <TableCell align="center">SI CEPAT</TableCell>
              <TableCell align="center">SHOPEE</TableCell>
              <TableCell align="center">LEX</TableCell>
              <TableCell align="center">WAHANA</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.keys(newData).length > 0
              ? Object.keys(newData).map((e, i) => (
                  <TableRow key={i}>
                    <TableCell
                      sx={{
                        position: "sticky",
                        left: 0,
                        background: "white",
                        zIndex: 3,
                        borderRight: 1,
                      }}
                    >
                      {e}
                    </TableCell>
                    <TableCell>
                      {differenceCard(
                        newData[e].jne ? newData[e].jne.internal : "",
                        newData[e].jne ? newData[e].jne.external : ""
                      )}
                    </TableCell>
                    <TableCell>
                      {differenceCard(
                        newData[e].jnt ? newData[e].jnt.internal : "",
                        newData[e].jnt ? newData[e].jnt.external : ""
                      )}
                    </TableCell>
                    <TableCell>
                      {differenceCard(
                        newData[e].anteraja ? newData[e].anteraja.internal : "",
                        newData[e].anteraja ? newData[e].anteraja.external : ""
                      )}
                    </TableCell>
                    <TableCell>
                      {differenceCard(
                        newData[e]["ninja express"]
                          ? newData[e]["ninja express"].internal
                          : "",
                        newData[e]["ninja express"]
                          ? newData[e]["ninja express"].external
                          : ""
                      )}
                    </TableCell>
                    <TableCell>
                      {differenceCard(
                        newData[e]["id express"]
                          ? newData[e]["id express"].internal
                          : "",
                        newData[e]["id express"]
                          ? newData[e]["id express"].external
                          : ""
                      )}
                    </TableCell>
                    <TableCell>
                      {differenceCard(
                        newData[e].lion ? newData[e].lion.internal : "",
                        newData[e].lion ? newData[e].lion.external : ""
                      )}
                    </TableCell>
                    <TableCell>
                      {differenceCard(
                        newData[e].sicepat ? newData[e].sicepat.internal : "",
                        newData[e].sicepat ? newData[e].sicepat.external : ""
                      )}
                    </TableCell>
                    <TableCell>
                      {differenceCard(
                        newData[e].shopee ? newData[e].shopee.internal : "",
                        newData[e].shopee ? newData[e].shopee.external : ""
                      )}
                    </TableCell>
                    <TableCell>
                      {differenceCard(
                        newData[e].lex ? newData[e].lex.internal : "",
                        newData[e].lex ? newData[e].lex.external : ""
                      )}
                    </TableCell>
                    <TableCell>
                      {differenceCard(
                        newData[e].wahana ? newData[e].wahana.internal : "",
                        newData[e].wahana ? newData[e].wahana.external : ""
                      )}
                    </TableCell>
                  </TableRow>
                ))
              : null}
          </TableBody>
        </Table>

        {loading ? (
          <div className="center">
            <CircularProgress size={25} />
          </div>
        ) : !Object.keys(newData).length ? (
          <Alert variant="standard" severity="warning">
            No Data
          </Alert>
        ) : null}
      </TableContainer>
      <TablePagination
        component="div"
        count={count}
        onPageChange={(_, newPage) => {
          const obj = params;
          obj.page = `${newPage + 1}`;
          setSearchParams(obj);
        }}
        page={+params.page - 1}
        rowsPerPage={10}
        rowsPerPageOptions={[5, 10]}
        ActionsComponent={TablePaginationActions}
      />
    </Fragment>
  );
};
