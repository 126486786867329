import { createContext, useReducer } from "react";
import jwtDecoded from "jwt-decode";

const defaultAuth = {
  id: null,
  username: "",
  password: "",
  full_name: "",
  email: "",
  group_id: null,
  create_date: null,
  update_date: null,
  group_name: "",
  group_level: null,
};

let initialState = {
  headers: {
    Authorization: "",
  },
  auth: defaultAuth,
};

const token = localStorage.getItem("token");

if (token) {
  try {
    const decode = jwtDecoded(token);
    if (decode.exp && decode.exp * 1000 <= Date.now())
      localStorage.removeItem("token");
    else
      initialState = {
        ...initialState,
        auth: { ...initialState.auth, ...decode },
        headers: { Authorization: `Bearer ${token}` },
      };
  } catch (e) {
    console.error(e);
  }
}

const AuthContext = createContext({
  headers: {
    Authorization: "",
    // "Content-Type": "multipart/form-data",
  },
  auth: defaultAuth,
  login: (token) => {},
  logout: (_) => {},
});

function authReducer(state, action) {
  switch (action.type) {
    case "LOGIN":
      return { ...state, ...action.payload };
    case "LOGOUT":
      return { ...state, headers: { Authorization: "" }, auth: defaultAuth };
    default:
      return state;
  }
}

function AuthProvider(props) {
  const [state, dispatch] = useReducer(authReducer, {
      ...initialState,
      login: (token) => null,
      logout: (_) => null,
    }),
    login = (token) => {
      if (!token) throw new Error("tidak ada token dari server");

      const decoded = jwtDecoded(token);
      localStorage.setItem("token", token);
      if (decoded && decoded.id)
        return dispatch({
          type: "LOGIN",
          payload: {
            headers: { Authorization: `Bearer ${token}` },
            auth: { ...decoded },
          },
        });

      localStorage.removeItem("token");
      dispatch({ type: "LOGOUT", payload: null });
    },
    logout = (_) => {
      localStorage.removeItem("token");
      dispatch({ type: "LOGOUT", payload: null });
    };

  return (
    <AuthContext.Provider value={{ ...state, login, logout }} {...props} />
  );
}

export { AuthContext, AuthProvider };
