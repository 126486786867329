import {
  Alert,
  Box,
  Button,
  CircularProgress,
  IconButton,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Typography,
  Zoom,
  styled,
  tableCellClasses,
} from "@mui/material";
import { Navigate, useLocation, useSearchParams } from "react-router-dom";
import {
  convertCurrency,
  convertDataToQuery,
  getQueryUrl,
} from "../../helpers/custom";
import { useContext, useEffect, useMemo, useState } from "react";
import { handleError } from "../../services/helpers";
import { getAllDataBagging, updateBaggingStatus } from "../../services/scan";
import { AuthContext } from "../../utils/auth";
import {
  CheckCircle,
  Close,
  HourglassTop,
  Preview,
  Upgrade,
} from "@mui/icons-material";
import { TablePaginationActions } from "../../components/spam/pagination";
import { utils, writeFile } from "xlsx";

export default function BaggingPage() {
  const { search } = useLocation();
  const [searchParams] = useSearchParams();
  const { headers } = useContext(AuthContext);
  const params = Object.fromEntries([...searchParams]);
  const push = (payload) => {
    Navigate(`/easy-drop/bagging${convertDataToQuery(payload)}`);
  };

  const StyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#ffffff",
    },
  }));

  const [baggingList, setBaggingList] = useState([]);
  const [listAwb, setListAwb] = useState([]);
  const [openSealNumber, setOpenSealNumber] = useState("");
  const [openListResi, setOpenListResi] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [count, setCount] = useState(0);

  const getData = async () => {
    try {
      setLoading(true);
      const data = await getAllDataBagging(search, headers);
      setBaggingList(data.rows);
      setCount(data.count);
      setLoading(false);
    } catch (error) {
      const message = await handleError(error);
      setError(message);
    }
  };

  const query = useMemo(() => {
    if (!search) return null;
    return getQueryUrl(search);
  }, [search]);

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  const handleUpdateBagging = async (id) => {
    try {
      await updateBaggingStatus(id, headers);
      window.location.reload();
    } catch (error) {
      const message = await handleError(error);
      setError(message);
    }
  };

  const handleDownload = () => {
    let headers = [];
    let rows = [];

    baggingList.forEach((e, i) => {
      headers.push(e.seal);
      e.in_seal.forEach((item, index) => {
        if (!rows[index]) {
          rows[index] = [];
        }
        rows[index][i] = item.awb;
      });
    });

    let finalOutput = [headers, ...rows];

    const ws = utils.json_to_sheet(finalOutput, { skipHeader: true });
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, "Data");
    writeFile(wb, "seal-data.xlsx");
    // setRes({
    //   status: "success",
    //   message: "berhasil meng-unduh data seal tersebut",
    // });
  };

  return (
    <>
      <Box
        sx={{
          p: 4,
          display: "flex",
          flexDirection: "column",
          gap: 3,
          justifyContent: "center",
          width: "100%",
          maxHeight: "88dvh",
          overflowY: "auto",
        }}
      >
        <TableContainer sx={{ maxHeight: "100%" }}>
          <Table size="large" stickyHeader>
            <TableHead>
              <TableRow>
                <StyledTableCell>Nama DOP</StyledTableCell>
                <StyledTableCell>No. Seal Bagging</StyledTableCell>
                <StyledTableCell>Status</StyledTableCell>
                <StyledTableCell>Tanggal</StyledTableCell>
                <StyledTableCell align="center">Action</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loading ? (
                <TableRow>
                  <StyledTableCell colSpan={4}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        textAlign: "center",
                      }}
                    >
                      <CircularProgress size={25} sx={{ color: "#333333" }} />
                    </Box>
                  </StyledTableCell>
                </TableRow>
              ) : error ? (
                <TableRow>
                  <StyledTableCell colSpan={4}>
                    <Alert
                      variant="standard"
                      severity="error"
                      sx={{ borderRadius: 0 }}
                    >
                      {error}
                    </Alert>
                  </StyledTableCell>
                </TableRow>
              ) : baggingList.length ? (
                baggingList.map((row, i) => (
                  <TableRow key={row.id}>
                    <StyledTableCell>{row.shop_name}</StyledTableCell>
                    <StyledTableCell>{row.seal}</StyledTableCell>
                    <StyledTableCell>
                      <div className="flex justify-center max-w-[40%]">
                        {row.status ? (
                          <CheckCircle color="success" />
                        ) : (
                          <HourglassTop color="disabled" />
                        )}
                      </div>
                    </StyledTableCell>
                    <StyledTableCell>{row.input_date}</StyledTableCell>

                    <StyledTableCell align="center">
                      <IconButton
                        color="primary"
                        onClick={() => {
                          setListAwb(row.in_seal);
                          setOpenSealNumber(row.seal);
                          setOpenListResi(true);
                        }}
                      >
                        <Preview />
                      </IconButton>
                      <Tooltip title="Update Status" arrow>
                        <IconButton
                          color="success"
                          onClick={() => handleUpdateBagging(row.id)}
                        >
                          <Upgrade />
                        </IconButton>
                      </Tooltip>
                    </StyledTableCell>
                  </TableRow>
                ))
              ) : null}
            </TableBody>
          </Table>
          {baggingList.length === 0 ? (
            <Alert variant="standard" severity="error">
              No Data
            </Alert>
          ) : null}
        </TableContainer>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "end",
            height: "3rem",
          }}
        >
          <TablePagination
            rowsPerPageOptions={[5, 10, 15, 25]}
            component="div"
            count={count}
            rowsPerPage={+params.limit}
            page={count <= 0 ? 0 : +params.page - 1}
            onPageChange={(_, newPage) => push({ ...query, page: newPage + 1 })}
            onRowsPerPageChange={({ target: { value = "10" } }) =>
              push({ ...query, limit: value, page: 1 })
            }
            ActionsComponent={TablePaginationActions}
          />
        </Box>

        <Button
          type="button"
          onClick={handleDownload}
          disabled={!!baggingList.length ? false : true}
        >
          Download Data
        </Button>
      </Box>

      {/* modal open list resi */}
      <Modal
        open={openListResi}
        onClose={() => setOpenListResi(false)}
        sx={{
          backdropFilter: "blur(5px)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Zoom in={openListResi}>
          <Box
            sx={{
              width: "60%",
              height: "auto",
              boxShadow: 24,
              // p: 0.8,
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <Box
              sx={{
                display: "flex",
                pb: 1.5,
                alignItems: "center",
                width: "100%",
                height: "100%",
                flexDirection: "column",
                backgroundColor: "#fff",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "98%",
                  height: "10%",
                }}
              >
                <Typography sx={{ color: "#333", fontWeight: "bold" }}>
                  Seal-{openSealNumber}
                </Typography>
                <Typography sx={{ color: "#333", fontWeight: "bold" }}>
                  Total data {listAwb.length}
                </Typography>
                <IconButton
                  sx={{
                    color: "#333",
                    height: "fit-content",
                    "&:hover": {
                      backgroundColor: "#ffffff20",
                    },
                  }}
                  onClick={() => setOpenListResi(false)}
                >
                  <Close />
                </IconButton>
              </Box>
              <Box
                sx={{
                  minHeight: "50%",
                  width: "100%",
                  px: 1,
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <TableContainer
                  sx={{
                    maxHeight: "70dvh",
                    "&::-webkit-scrollbar": { width: 10, height: 10 },
                    "&::-webkit-scrollbar-track": {
                      backgroundColor: "transparent",
                    },
                    "&::-webkit-scrollbar-thumb": {
                      backgroundColor: "#33333350",
                      borderRadius: 1,
                    },
                  }}
                >
                  <Table size="large" stickyHeader>
                    <TableHead>
                      <TableRow>
                        <TableCell align="center">No. Airwaybill</TableCell>
                        <TableCell align="center">From DOP</TableCell>
                        <TableCell align="center">Description</TableCell>
                        <TableCell align="center">Service</TableCell>
                        <TableCell align="center">Receiver</TableCell>
                        <TableCell align="center">Cost</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody
                      sx={{
                        backgroundColor: "white",
                      }}
                    >
                      {listAwb.length > 0
                        ? listAwb.map((item) => (
                            <TableRow key={item.id}>
                              <TableCell align="center">{item.awb}</TableCell>
                              <TableCell align="center">
                                {item.shopName}
                              </TableCell>
                              <TableCell align="center">
                                {item.expedition}
                              </TableCell>
                              <TableCell align="center">
                                {item.service}
                              </TableCell>
                              <TableCell align="center">
                                {item.receiver}
                              </TableCell>
                              <TableCell align="center">
                                {convertCurrency(item.cost)}
                              </TableCell>
                            </TableRow>
                          ))
                        : null}
                    </TableBody>
                  </Table>
                  {listAwb.length === 0 ? (
                    <Alert
                      variant="standard"
                      severity="warning"
                      sx={{ borderRadius: 0 }}
                    >
                      Tidak ada data
                    </Alert>
                  ) : null}
                </TableContainer>
              </Box>
            </Box>
          </Box>
        </Zoom>
      </Modal>
    </>
  );
}
