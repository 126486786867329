import { useContext, useEffect, useState } from "react";
import { Grid } from "@mui/material";
import gtw from "../../apis/gtw";
import { AuthContext } from "../../utils/auth";
import { QRCodeSVG } from "qrcode.react";

export const WhatsAppPage = () => {
  const { headers } = useContext(AuthContext),
    [qrCode, setQrCode] = useState({});

  async function getQrCode() {
    try {
      const dataQr = await gtw({
        method: "get",
        url: "/whatsapp/barcode",
        headers,
      });
      setQrCode(dataQr.data);
    } catch (err) {
      console.log(err, "error");
    }
  }

  useEffect(() => {
    getQrCode();
    // const timer = setInterval(() => {
    //   getQrCode();
    //   console.log("get data");
    // }, 2000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return qrCode.statusWa ? (
    <h5>client already logged in</h5>
  ) : (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      sx={{ pb: 3 }}
    >
      <QRCodeSVG value={qrCode.sendQrCode} size={512} />
    </Grid>
  );
};
