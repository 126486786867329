import { useState, useEffect, Fragment } from "react";
import {
  TextField,
  Box,
  LinearProgress,
  InputAdornment,
  Alert,
  IconButton,
  Grid,
  Button,
  Typography,
} from "@mui/material";
import {
  DeleteOutline,
  SearchOutlined,
  AddOutlined,
  ErrorOutline,
} from "@mui/icons-material";
import { BasicModal } from "./modal";
import { useAddSellerAlias, useActionAlias } from "../../services/user-seller";

export const SearchHead = ({
  setCount,
  values,
  onEnter = (value) => null,
  placeholder,
}) => {
  const [value, setValue] = useState(values || "");

  useEffect(() => {
    if (values) setValue(decodeURIComponent(values));
    else setValue("");
  }, [values]);

  return (
    <TextField
      value={value}
      onChange={({ target: { value } }) => setValue(value)}
      onKeyUp={({ key }) => {
        if (key === "Enter") onEnter(value);
      }}
      name="search"
      variant="standard"
      color="info"
      placeholder={placeholder || "search admin here"}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            {values ? (
              <IconButton
                color="error"
                onClick={() => {
                  onEnter("");
                }}
              >
                <DeleteOutline />
              </IconButton>
            ) : (
              <IconButton
                onClick={() => {
                  onEnter(value);
                }}
              >
                <SearchOutlined />
              </IconButton>
            )}
          </InputAdornment>
        ),
      }}
    />
  );
};

export const AddAlias = ({
  open,
  close,
  data,
  edit = { value: false, data: { id: "", alias: "" } },
  refresh = (_) => null,
}) => {
  const [alias, setAlias] = useState(
      edit.value && edit.data.id && edit.data.alias ? edit.data.alias : ""
    ),
    { onProcess, loading, res } = useAddSellerAlias(),
    { onProcess: onAction, loading: _loading, res: _res } = useActionAlias(),
    execute = (e) => {
      if (e.preventDefault) e.preventDefault();

      if (edit.value) {
        onAction({ aliasId: edit.data.id, method: "put" }, { alias }, () => {
          refresh(alias);
          setAlias("");
          close();
        });
        return;
      }
      onProcess(alias, () => setAlias(""));
    };

  return (
    <BasicModal
      title={`Add alias to ${data?.name || "-"}`}
      open={open}
      onClose={() => {
        if (!edit.value) refresh();
        close(false);
      }}
    >
      <Grid
        container
        spacing={3}
        component="form"
        onSubmit={execute}
        flexDirection="column"
        style={{ width: 500 }}
      >
        <Grid item />
        <Grid item style={{ width: "100%" }}>
          <TextField
            fullWidth
            placeholder="eg: bigdel"
            variant="standard"
            size="small"
            autoFocus
            label="Alias Name"
            value={alias}
            onChange={({ target: { value } }) => setAlias(value)}
          />
        </Grid>
        <Grid item />

        <Grid
          item
          display="flex"
          justifyContent="space-around"
          style={{ width: "100%" }}
        >
          {loading || _loading ? (
            <Typography varinat="caption">Loading...</Typography>
          ) : (
            <Fragment>
              <Button
                startIcon={<ErrorOutline />}
                title="Cancel"
                size="small"
                variant="text"
                color="primary"
                onClick={() => {
                  if (!edit.value) refresh();
                  close(false);
                }}
              >
                Cancel
              </Button>
              <Button
                startIcon={<AddOutlined />}
                title="Add"
                type="submit"
                size="small"
                variant="outlined"
                color="primary"
              >
                Add
              </Button>
            </Fragment>
          )}
        </Grid>

        {res.message || _res.message ? (
          <Grid item>
            <Alert
              severity={
                res.status === "ok" || _res.status === "ok"
                  ? "success"
                  : "error"
              }
              variant="standard"
            >
              {res.message || _res.message}
            </Alert>
          </Grid>
        ) : null}
      </Grid>
    </BasicModal>
  );
};

export const LinearProgressWithLabel = (props) => (
  <Box sx={{ display: "flex", alignItems: "center" }}>
    <Box sx={{ width: "100%", mr: 1 }}>
      <LinearProgress variant="determinate" {...props} />
    </Box>
    <Box sx={{ minWidth: 35 }}>
      <Typography variant="body2" color="text.secondary">{`${Math.round(
        props.value
      )}%`}</Typography>
    </Box>
  </Box>
);
