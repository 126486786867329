import {useState, Fragment, useMemo} from 'react';
import {
	Grid,
	Button,
	ListItem,
	ListItemText,
	ListItemIcon,
	CircularProgress,
	Alert,
} from '@mui/material';
import {AddOutlined, EditOutlined} from '@mui/icons-material';
import {useCreate} from '../../../services/privileges';
import {GroupSelection, ModuleSelection} from '../../../components/root';
import {OptionPrivilegeCreate} from '../../../components/spam/optionPrivilegesCreate';

export const PrivilegeCreate = () => {
	const [privileges, setPrivileges] = useState([{key: 1, group_id: null, module_name: null, group_name: null, module_id: null, create: true, read: true, update: true, delete: true, loading: false, message: ''}]),
		[triggerSave, setTriggerSave] = useState(false),
		[res, setRes] = useState({status: '', message: ''}),
		{onProcess} = useCreate(),
		[optionView, setOptionView] = useState({privilege: null, type: ''}),
		addPrivileges = () => {
			if(privileges.length > 0) {
				const validate = privileges.findIndex(el => !el.group_id || !el.module_id)
				if(validate !== -1) {
					setRes({status: 'error', message: 'please complete the previous data'});
					return;
				}
			}
			setPrivileges(prev => [...prev, {key: privileges.length + 1, group_id: null, module_name: null, group_name: null, module_id: null, create: true, read: true, update: true, delete: true, loading: false, message: ''}])
		},
		updatePrivileges = ({payload}) => {
			if(res.status)
				setRes({status: '', message: ''});

			if(!optionView.privilege.key)
				return;

			setPrivileges(prev => prev.map(el => {
				if(el.key === optionView.privilege.key)
					return {...el, ...payload};

				return el;
			}))
		},
		updateDataPrivilege = ({index, key, value}) =>
			setPrivileges(prev => prev.map(el => {
				if(el.key === index)
					return {...el, [key]: value};
				return el;
			})),
		checkLoading = useMemo(() => {
			if(privileges.length === 0)
				return false;
			return privileges.filter(el => el.loading).length > 0;
		}, [privileges])
	
	async function onProcessPrivilege () {
		if(privileges.length === 0)
			return setRes({status: 'error', message: 'please select the privileges'});

		setTriggerSave(true);
		setPrivileges(prev => prev.map(el => ({...el, loading: true, message: ''})));

		for(const privilege of privileges) {
			if(!privilege.module_id || !privilege.group_id)
				continue;
			const payload = {
				group_id: privilege.group_id,
				module_id: privilege.module_id,
				create: privilege.create,
				read: privilege.read,
				update: privilege.update,
				delete: privilege.delete,
			}

			await onProcess(payload, async (err, _) => {
				await setPrivileges(prev => prev.map(el => {
					if(el.key === privilege.key)
						return {...el, loading: false, message: err || ''};

					return el;
				}))
			})
		}

		setRes({status: 'ok', message: `Berhasil proses data, if any error check the table and retry again`})
		
	}

	function onRetryOne (privilege) {
		if(!privilege.group_id || !privilege.module_id)
			return;

		setPrivileges(prev => prev.map(el => {
			if(el.key === privilege.key)
				return {...el, loading: true, message: ''};
			return el;
		}))

		const payload = {
			group_id: privilege.group_id,
			module_id: privilege.module_id,
			create: privilege.create,
			read: privilege.read,
			update: privilege.update,
			delete: privilege.delete,
		}

		onProcess(payload, (err, _) => {
			setPrivileges(prev => prev.map(el => {
				if(el.key === privilege.key)
					return {...el, message: err || '', loading: false};
				return el;
			}))
		})
	}
	return (
		<Grid container flexDirection='column' spacing={2} marginTop={1}>
			{optionView.privilege
				?	(optionView.type === 'groups'
					?	<GroupSelection
							open={optionView.type === 'groups'}
							onChangeSingle={group => updatePrivileges({payload: {group_id: group.id, group_name: group.name}})}
							close={() => setOptionView({privilege: null, type: ''})}
							single/>
					:	optionView.type === 'modules'
						?	<ModuleSelection
								close={() => setOptionView({privilege: null, type: ''})}
								open={optionView.type === 'modules'}
								onChangeSingle={_module => updatePrivileges({payload: {module_id: _module.id, module_name: _module.identity}})}
								single/>
						:	null)
				:	null}
			
			<Grid item>
				<ListItem button onClick={addPrivileges}>
					<ListItemText primary='Add Privileges'/>
					<ListItemIcon>
						<AddOutlined color='primary'/>
					</ListItemIcon>
				</ListItem>
			</Grid>

			{privileges.length > 0
				?	<OptionPrivilegeCreate
				module_id
						privileges={privileges}
						maxHeight={500}
						onRetry={onRetryOne}
						updatePrivileges={({privilege, key, value}) => {
							if(res.status)
								setRes({status: '', message: ''});

							updateDataPrivilege({index: privilege.key, key, value});
						}}
						deleteOne={privilege => {
							if(res.status)
								setRes({status: '', message: ''});

							setPrivileges(prev => prev.filter(el => el.key !== privilege.key).map(el => el));
						}}
						moduleChildren={p => 
							<ListItem button onClick={() => setOptionView({privilege: p, type: 'modules'})}>
								<ListItemText style={{color: 'blue'}} primary={p.module_name || 'Choose Modules'} secondary={p.module_name ? 'Edit' : ''}/>
								<ListItemIcon>
									{p.module_id
										?	<EditOutlined/>
										:	<AddOutlined/>}
								</ListItemIcon>
							</ListItem>
						}
						groupChildren={p => (
							<ListItem button onClick={() => setOptionView({privilege: p, type: 'groups'})}>
								<ListItemText style={{color: 'blue'}} primary={p.group_name || 'Choose Group'} secondary={p.group_name ? 'Edit' : ''}/>
								<ListItemIcon>
									{p.group_id
										?	<EditOutlined/>
										:	<AddOutlined/>}
								</ListItemIcon>
							</ListItem>
						)}/>
				:	null}


			<Grid item>
				{checkLoading
					?	<CircularProgress size={25}/>
					:	<Fragment>
							<Button variant='outlined' color='primary' onClick={onProcessPrivilege}>Save Privilege</Button>
							{triggerSave
								?	<Button variant='outlined' color='primary' onClick={() => {
									setPrivileges([{key: 1, group_id: null, module_name: null, group_name: null, module_id: null, create: true, read: true, update: true, delete: true, loading: false, message: ''}]);
									setRes({status: '', message: ''});
									setTriggerSave(false);
								}} style={{marginLeft: 25}}>Refresh Data</Button>
								:	null}
						</Fragment>}

				
			</Grid>

			{res.message
				?	<Alert severity={res.status === 'ok' ? 'success' : 'error'}>{res.message}</Alert>
				:	null}
		</Grid>
	)
}