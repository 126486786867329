import { Fragment, useState, useContext } from "react";
import {
  Grid,
  Typography,
  Modal,
  Button,
  Box,
  TextField,
  Divider,
  CircularProgress,
  Snackbar,
  Alert,
  InputAdornment,
  IconButton,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from "@mui/material";
import { VisibilityOff, Visibility } from "@mui/icons-material";
import gtw from "../../apis/gtw";
import { DateRangePicker } from "react-date-range";
import { AuthContext } from "../../utils/auth";
import { handleError } from "../../services/helpers";

export const LotteryPage = () => {
  const [openModal, setOpenModal] = useState(false),
    [openModalWinner, setOpenModalWinner] = useState(false),
    [password, setPassword] = useState(""),
    [listData, setListData] = useState([]),
    [statusWinner, setStatusWinner] = useState(false),
    [showPassword, setShowPassword] = useState(false),
    [res, setRes] = useState({ status: "", message: "", open: false }),
    [loading, setLoading] = useState(false),
    [total, setTotal] = useState(1),
    [state, setState] = useState({
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    }),
    { headers } = useContext(AuthContext);

  const submitWinner = async (e) => {
    try {
      const { data } = await gtw({
        method: "post",
        url: `/transactions/lottery/winner?total=${total}`,
        data: {
          password,
        },
        headers,
      });
      setListData(data.data);
      setRes({
        status: "success",
        message: "berhasil mendapatkan pemenang",
        open: true,
      });

      setLoading(false);
      setOpenModal(false);
    } catch (error) {
      const message = handleError(error);
      console.log(message);
      setLoading(false);
      setRes({
        status: "error",
        message: message,
        open: true,
      });
    }
  };

  const updateWinner = async (e) => {
    try {
      const { data } = await gtw({
        method: "patch",
        url: `/transactions/lottery/winner`,
        data: {
          data: listData,
        },
        headers,
      });
      let temp = [...listData];
      data.data.forEach((e, i) => {
        temp[i].statusWinner = e.lotteryStatus;
      });
      setStatusWinner(true);
      setListData(temp);
    } catch (err) {
      console.log(err);
      const message = handleError(err);
      console.log(message);
      setLoading(false);
      setRes({
        status: "error",
        message: message,
        open: true,
      });
    }
  };

  // const submitHandler = async (e) => {
  //   try {
  //     e.preventDefault();

  //     setLoading(true);

  //     await gtw({
  //       method: "patch",
  //       url: `/transactions/update/lottery?date=${state.startDate}`,
  //       data: {
  //         password,
  //       },
  //       headers,
  //     });

  //     setRes({
  //       status: "success",
  //       message: "berhasil update dan check order",
  //       open: true,
  //     });

  //     setLoading(false);
  //     setOpenModal(false);
  //   } catch (err) {
  //     const message = handleError(err);
  //     console.log(message);
  //     setLoading(false);
  //     setRes({
  //       status: "error",
  //       message: message,
  //       open: true,
  //     });
  //   }
  // };

  const submitHandler = async (e) => {
    try {
      e.preventDefault();

      setLoading(true);

      let { data } = await gtw({
        method: "post",
        url: `/transactions/get/transactionday?date=${state.startDate}`,
        data: {
          password,
        },
        headers,
      });

      let dataAwb = data.data;
      let flag = 0;

      if (dataAwb.length > 10000) {
        let max = Math.ceil(dataAwb.length / 2000);

        for (let i = 0; i < max; i++) {
          let { data: _data } = await gtw({
            method: "patch",
            url: `/transactions/update/lottery2`,
            data: {
              data: dataAwb.slice(0, 2000),
            },
            headers,
          });

          if (!_data.data) {
            flag++;
          }

          dataAwb.splice(0, 2000);
        }

        if (flag === max) {
          setRes({
            status: "error",
            message: "tidak ada data yang bisa diupdate",
            open: true,
          });
          setLoading(false);
          setOpenModal(false);
          return;
        }
      } else {
        let { data: _data } = await gtw({
          method: "patch",
          url: `/transactions/update/lottery2`,
          data: {
            data: dataAwb.slice(0, 2000),
          },
          headers,
        });

        if (!_data.data) {
          setRes({
            status: "error",
            message: "tidak ada data yang bisa diupdate",
            open: true,
          });
          setLoading(false);
          setOpenModal(false);
          return;
        }
      }

      setRes({
        status: "success",
        message: "berhasil update dan check order",
        open: true,
      });

      setLoading(false);
      setOpenModal(false);
    } catch (err) {
      const message = handleError(err);
      console.log(message);
      setLoading(false);
      setRes({
        status: "error",
        message: message,
        open: true,
      });
    }
  };

  return (
    <Fragment>
      <Snackbar
        anchorOrigin={{ horizontal: "center", vertical: "top" }}
        open={res.open}
        onClose={() => setRes({ status: "", message: "", open: false })}
      >
        <Alert
          severity={
            res.status === "success"
              ? "success"
              : res.status === "warning"
              ? "warning"
              : "error"
          }
        >
          {res.message}
        </Alert>
      </Snackbar>

      <Modal open={openModal}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            minWidth: 400,
            maxWidth: "95%",
            backgroundColor: "white",
            borderRadius: 1,
            boxShadow: 24,
            p: 2,
          }}
        >
          <DateRangePicker
            onChange={(item) => setState(item.selection)}
            showSelectionPreview={true}
            moveRangeOnFirstSelection={false}
            months={2}
            ranges={[state]}
            direction="horizontal"
          />
          <Divider sx={{ marginY: 2 }} />
          <TextField
            id="outlined-basic"
            label="Password Konfirmasi"
            variant="outlined"
            type={showPassword ? "text" : "password"}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            sx={{ mr: 2 }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  <IconButton onClick={() => setShowPassword((prev) => !prev)}>
                    {!showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <Divider sx={{ marginY: 2 }} />
          <Grid item width="100%" display="flex" flexDirection="row-reverse">
            <Button
              onClick={submitHandler}
              title="Filter"
              variant="outlined"
              color="success"
              size="small"
              disabled={loading}
            >
              {loading ? <CircularProgress size={20} /> : "Update"}
            </Button>
            <Button
              onClick={() => setOpenModal(false)}
              style={{ marginRight: 15 }}
              title="Cancel"
              variant="text"
              size="small"
              disabled={loading}
            >
              Cancel
            </Button>
          </Grid>
        </Box>
      </Modal>

      <Modal open={openModalWinner}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            minWidth: 400,
            maxWidth: "95%",
            backgroundColor: "white",
            borderRadius: 1,
            boxShadow: 24,
            p: 2,
          }}
        >
          <Typography variant="h5" align="center">
            Pengundian Pemenang
          </Typography>
          <Divider sx={{ marginY: 2 }} />
          <TextField
            label="Total pengundian"
            type="number"
            variant="outlined"
            value={total}
            onChange={(e) => setTotal(e.target.value)}
            sx={{ mr: 2 }}
          />
          <Divider sx={{ marginY: 2 }} />

          <TextField
            label="Password Konfirmasi"
            variant="outlined"
            type={showPassword ? "text" : "password"}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            sx={{ mr: 2 }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  <IconButton onClick={() => setShowPassword((prev) => !prev)}>
                    {!showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <Divider sx={{ marginY: 2 }} />
          <Grid item width="100%" display="flex" flexDirection="row-reverse">
            {listData.length ? (
              <Button
                onClick={updateWinner}
                title="Filter"
                variant="contained"
                color="primary"
                size="small"
                disabled={loading}
                sx={{ ml: 2 }}
              >
                Konfirmasi
              </Button>
            ) : null}
            <Button
              onClick={submitWinner}
              title="Filter"
              variant="outlined"
              color="success"
              size="small"
              disabled={loading}
            >
              {loading ? <CircularProgress size={20} /> : "Random"}
            </Button>
            <Button
              onClick={() => setOpenModalWinner(false)}
              style={{ marginRight: 15 }}
              title="Cancel"
              variant="text"
              size="small"
              disabled={loading}
            >
              Cancel
            </Button>
          </Grid>
          <Divider sx={{ marginY: 2 }} />
          <TableContainer sx={{ maxHeight: "30vh" }}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell align="center">Nomor</TableCell>
                  <TableCell align="center">Nama Toko</TableCell>
                  <TableCell align="center">Kode Undian</TableCell>
                  {statusWinner ? (
                    <TableCell align="center">Status Winner</TableCell>
                  ) : null}
                </TableRow>
              </TableHead>
              <TableBody>
                {listData.length
                  ? listData.map((e, i) => (
                      <TableRow key={i}>
                        <TableCell align="center">{i + 1}</TableCell>
                        <TableCell align="center">{e.shopName}</TableCell>
                        <TableCell align="center">{e.kodeUnique}</TableCell>
                        {e.statusWinner ? (
                          <TableCell align="center">{e.statusWinner}</TableCell>
                        ) : null}
                      </TableRow>
                    ))
                  : null}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Modal>

      <Grid
        item
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          height: "85vh",
          width: "100%",
        }}
      >
        {/* <UploadOutlined style={{ width: "auto", opacity: 0.5, height: 250 }} /> */}
        <Typography variant="h4">Update Unique Code and Check Order</Typography>

        <Button
          variant="outlined"
          onClick={(e) => setOpenModal(true)}
          sx={{ mt: 10 }}
        >
          Update Lottery Check
        </Button>
        <Button
          variant="outlined"
          color="success"
          onClick={(e) => setOpenModalWinner(true)}
          sx={{ mt: 10 }}
        >
          Start Lottery
        </Button>
      </Grid>
    </Fragment>
  );
};
