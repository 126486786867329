import {createContext, useReducer, useCallback} from 'react';

let initialState = {
	count: 0,
	offset: 0,
	data: [],
};

const ModuleContext = createContext({
	...initialState,
	updateOne: (id, {key, value}) => {},
	addOne: data => {},
	updateAll: ({key, value}) => {},
	clear: _ => {},
	deleteOne: ({id}) => {},
	setData: (callback = prevState => null) => {},
});

function moduleReducer (state, action) {
	switch (action.type) {
		case 'UPDATE_ONE':
			const {id, key, value} = action.payload;
			return {...state, data: state.data.map(data => {
				if(data.id === id)
					return {...data, [key]: value};
				return data;
			})};
		case 'UPDATE_ALL':
			const {key: _key, value: _value} = action.payload;
			return {...state, data: state.data.map(data => ({...data, [_key]: _value}))}
		case 'SET_STATE':
			if(action.payload.first)
				return {...state, ...action.payload};

			return {...state, offset: action.payload.offset || 0, data: [...state.data, ...action.payload.data]}
		case 'ADD_STATE':
			return {...state, count: state.count + 1, data: [...state.data, action.payload]};
		case 'DELETE_ONE':
			const {id: _delID} = action.payload;
			return {...state, data: state.data.filter(el => el.id !== _delID).map(el => el)};
		case 'CLEAR_STATE':
			return {...state, count: 0, offset: 0, data: []};
		default:
			return state;
	}
}

function ModuleProvider (props) {
	const [state, dispatch] = useReducer(moduleReducer, {...initialState, deleteOne: ({id}) => null, clear: _ => null, updateAll: ({key, value}) => null, updateOne: (id, {key, value}) => null, addOne: data => null, setData: (callback = prevState => null) => null}),
		updateOne = (id, {key, value}) => dispatch({type: 'UPDATE_ONE', payload: {id, key, value}}),
		addOne = payload => dispatch({type: 'ADD_STATE', payload}),
		updateAll = useCallback(payload => dispatch({type: 'UPDATE_ALL', payload}), [dispatch]),
		setData = useCallback(payload => dispatch({type: 'SET_STATE', payload}), [dispatch]),
		deleteOne = payload => dispatch({type: 'DELETE_ONE', payload}),
		clear = _ => dispatch({type: 'CLEAR_STATE', payload: null});

	return (
		<ModuleContext.Provider
			value={{...state, updateOne, addOne, updateAll, setData, deleteOne, clear}}
			{...props}/>
	)
}

export {ModuleProvider, ModuleContext};