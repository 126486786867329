import { Close } from "@mui/icons-material";
import { Box, Button, Grid, Modal, Typography, Zoom } from "@mui/material";

export default function ModalConfirmed({
  openModalConfirmed,
  setOpenModalConfirmed,
  downloadDataAction,
}) {
  return (
    <>
      <Modal
        open={openModalConfirmed}
        sx={{
          backdropFilter: "blur(5px)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        onClose={() => setOpenModalConfirmed(false)}
      >
        <Zoom in={openModalConfirmed}>
          <Box
            sx={{
              width: "41%",
              height: "20%",
              backgroundColor: "white",
              borderRadius: 1,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              pt: "1rem",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                height: "7%",
                width: "100%",
              }}
            >
              <Button
                size="small"
                sx={{
                  color: "#bababa",
                  "&:hover": {
                    backgroundColor: "transparent",
                    color: "#333333",
                  },
                }}
                onClick={() => {
                  setOpenModalConfirmed(false);
                }}
              >
                <Close />
              </Button>
            </Box>
            <Box
              sx={{
                height: "100%",
                width: "100%",
                px: "1.3rem",
              }}
            >
              <Box sx={{ display: "flex", flexDirection: "column", pt: "0px" }}>
                <Typography py={2} variant="h5" component={"h5"} align="center">
                  Are you sure want download all data?
                </Typography>
                <Grid
                  container
                  columnSpacing={2}
                  sx={{
                    placeContent: "center",
                    pl: "4.3rem",
                  }}
                >
                  <Grid item xs={4}>
                    <Button
                      type="button"
                      variant="contained"
                      onClick={(e) => {
                        downloadDataAction(e, "all");
                        setOpenModalConfirmed(false);
                      }}
                    >
                      All Data
                    </Button>
                  </Grid>
                  <Grid item xs={6}>
                    <Button
                      type="button"
                      variant="contained"
                      onClick={(e) => {
                        downloadDataAction(e, "pending");
                        setOpenModalConfirmed(false);
                      }}
                    >
                      Only Pending Data
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Box>
        </Zoom>
      </Modal>
    </>
  );
}
