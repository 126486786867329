import { useEffect, useState, Fragment } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  Button,
  TableContainer,
  Table,
  Grid,
  TableBody,
  Alert,
  TableCell,
  TableRow,
  TableHead,
  IconButton,
  Typography,
  ListItem,
  List,
  ListItemText,
  TextField,
  InputAdornment,
  CircularProgress,
} from "@mui/material";
import {
  ArrowLeftOutlined,
  AddOutlined,
  DeleteOutlineOutlined,
  DeleteOutline,
  SearchOutlined,
  AddCircleOutline,
} from "@mui/icons-material";
import {
  useUserInternalId,
  useListUserSellerOne,
} from "../../services/user-seller";
// import { TablePaginationActions } from "../../components/spam/pagination";
import { SearchHead, AddAlias } from "../../components/spam/inputs";
import { BasicModal, UIAlert } from "../../components/spam/modal";
import { handleError } from "../../services/helpers";
export const SellerBigdiStorePage = () => {
  const {
      onProcessUpdate,
      setDataUpdate,
      dataUpdate,
      setResInternalConfirm,
      resInternalConfirm,
      dataInternal,
      onProcessGet,
      setDataInternal,
      loadingStore,
    } = useUserInternalId(),
    {
      onProcessFind,
      value,
      setValue,
      dataSellerInternal,
      setDataSellerInternal,
      loadingInternal,
      resInternal,
      statusFind,
      setStatusFind,
    } = useListUserSellerOne(),
    [searchParams] = useSearchParams(),
    params = Object.fromEntries([...searchParams]),
    [showModal, setShowModal] = useState(false),
    navigate = useNavigate();
  const addSellerInternal = async () => {
    try {
      setStatusFind(false);
      setShowModal(false);

      setDataUpdate({});
      setValue("");
      await onProcessUpdate();

      await onProcessGet(params.userId);
    } catch (error) {
      const message = handleError(error);
      setResInternalConfirm({ status: "error", message });
    }
  };
  useEffect(() => {
    onProcessGet(params.userId);
  }, []);
  return (
    <Fragment>
      <UIAlert
        open={
          resInternalConfirm.status && resInternalConfirm.message ? true : false
        }
        onClose={() => setResInternalConfirm({ status: "", message: "" })}
        res={resInternalConfirm}
      />
      <BasicModal open={showModal} onClose={() => null}>
        <Grid
          container
          display="flex"
          width="100%"
          justifyContent="center"
          flexDirection="column"
          alignItems="center"
        >
          <Typography variant="h4" component="h2" style={{ marginBottom: 20 }}>
            Add seller
          </Typography>
          {!Object.keys(dataUpdate).length ? (
            <Fragment>
              <TextField
                value={value}
                onChange={({ target: { value } }) => setValue(value)}
                onKeyUp={({ key }) => {
                  if (key === "Enter") {
                    setResInternalConfirm({ status: "", message: "" });
                    setStatusFind(false);

                    setDataSellerInternal((prev) => ({
                      ...prev,
                      data: [],
                    }));
                    onProcessFind();
                  }
                }}
                name="search"
                variant="standard"
                color="info"
                placeholder={"search seller here"}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {dataSellerInternal.data.length > 0 ? (
                        <IconButton
                          color="error"
                          onClick={() => {
                            setStatusFind(false);
                            setDataSellerInternal((prev) => ({
                              ...prev,
                              data: [],
                            }));

                            setValue("");
                          }}
                        >
                          <DeleteOutline />
                        </IconButton>
                      ) : (
                        <IconButton
                          onClick={() => {
                            setResInternalConfirm({ status: "", message: "" });
                            setStatusFind(false);
                            setDataSellerInternal((prev) => ({
                              ...prev,
                              data: [],
                            }));
                            onProcessFind();
                          }}
                        >
                          <SearchOutlined />
                        </IconButton>
                      )}
                    </InputAdornment>
                  ),
                }}
              />
            </Fragment>
          ) : null}

          <Fragment>
            {Object.keys(dataUpdate).length ? (
              <Typography>{dataUpdate.username}</Typography>
            ) : dataSellerInternal.data.length > 0 ? (
              <TableContainer style={{ minHeight: "50vh", maxHeight: "80vh" }}>
                <Table style={{ width: "100%" }} stickyHeader>
                  <TableHead>
                    <TableRow>
                      <TableCell>No</TableCell>
                      <TableCell>Username</TableCell>
                      <TableCell>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {dataSellerInternal.data.length > 0
                      ? dataSellerInternal.data.map((seller, index) => (
                          <TableRow key={index} hover>
                            <TableCell>{index + 1}</TableCell>
                            <TableCell>{seller?.username || "-"}</TableCell>
                            <TableCell>
                              <IconButton
                                title="add seller"
                                style={{ marginLeft: 5 }}
                                size="small"
                                color="primary"
                                onClick={() => {
                                  setDataUpdate(seller);
                                }}
                              >
                                <AddCircleOutline />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        ))
                      : null}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : null}
            {dataSellerInternal.data.length <= 0 && statusFind ? (
              <Alert variant="standard" severity="warning">
                No Data
              </Alert>
            ) : null}
            {loadingInternal ? (
              <div className="center">
                <CircularProgress size={25} />
              </div>
            ) : null}
          </Fragment>
          <Fragment>
            <Grid
              container
              display="flex"
              width="100%"
              justifyContent="space-between"
              alignItems="center"
              paddingX={5}
              marginTop={5}
            >
              <Button
                variant="outlined"
                color="error"
                size="small"
                title="Cancel add"
                onClick={() => {
                  setValue("");
                  setStatusFind(false);
                  // setRes({ status: "", message: "" });
                  setDataSellerInternal((prev) => ({ ...prev, data: [] }));
                  setDataUpdate({});
                  setShowModal(false);
                }}
              >
                cancel
              </Button>

              <Button
                color="info"
                variant="outlined"
                size="small"
                title="Confirm add"
                onClick={async () => {
                  setDataSellerInternal((prev) => ({ ...prev, data: [] }));
                  // setRes({ status: "", message: "" });
                  if (!Object.keys(dataUpdate).length) {
                    setResInternalConfirm({
                      status: "error",
                      message: "harap pilih seller",
                    });
                    return;
                  }
                  if (dataInternal.userInternalId) {
                    let find = dataInternal.userInternalId.find(
                      (e) => +e.id === +dataUpdate.id
                    );
                    if (find) {
                      setResInternalConfirm({
                        status: "error",
                        message: "seller sudah terdaftar",
                      });
                    } else {
                      await addSellerInternal();
                    }
                  }
                }}
              >
                Confirm
              </Button>
            </Grid>
          </Fragment>
          <Grid item />
        </Grid>
      </BasicModal>
      <Grid item>
        <Button
          size="small"
          startIcon={<ArrowLeftOutlined />}
          // onClick={() => navigate(`/seller/bigdelivery/app?page=0&perPage=10`)}
          onClick={() => navigate(-1)}
          variant="outlined"
          color="info"
        >
          Go Back
        </Button>
      </Grid>
      <Grid item width="100%" justifyContent="space-between" display="flex">
        <Grid display="flex">
          {!loadingStore ? (
            <Button
              startIcon={<AddOutlined />}
              onClick={() => setShowModal(true)}
              variant="outlined"
              size="small"
              color="success"
            >
              Add Seller / Store
            </Button>
          ) : null}
        </Grid>

        {/* <SearchHead
          // values={values}
          placeholder="Search Seller here"
          // onEnter={(variable) => push({ ...query, page: 0, search: variable })}
        /> */}
      </Grid>

      <Grid item>
        <List style={{ display: "flex" }}>
          <ListItem disablePadding>
            <ListItemText
              primaryTypographyProps={{ fontSize: 13, fontWeight: "bolder" }}
              primary="Full Name"
              secondary={dataInternal.shopName || "-"}
            />
          </ListItem>
        </List>
      </Grid>

      <TableContainer
        style={{ minHeight: "50vh", marginTop: 5, maxHeight: "70vh" }}
      >
        <Table size="small" style={{ width: "100%" }} stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>No</TableCell>
              <TableCell>Internal Seller Name</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dataInternal.userInternalId.length > 0
              ? dataInternal.userInternalId.map((item, index) => (
                  <TableRow hover key={index}>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{item.username}</TableCell>

                    <TableCell>
                      <IconButton
                        title="Delete seller"
                        size="small"
                        style={{ marginLeft: 5 }}
                        variant="standard"
                        color="error"
                        onClick={async () => {
                          let temp = dataInternal.userInternalId;
                          temp.splice(index, 1);

                          await onProcessUpdate(temp);
                          setDataInternal({
                            ...dataInternal,
                            userInternalId: temp,
                          });
                        }}
                      >
                        <DeleteOutlineOutlined />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))
              : null}
          </TableBody>
        </Table>
        {loadingStore ? (
          <div className="center">
            <CircularProgress size={25} />
          </div>
        ) : dataInternal.userInternalId.length === 0 ? (
          <Alert variant="standard" severity="warning">
            No Data
          </Alert>
        ) : null}
      </TableContainer>

      {/* <TablePagination
        rowsPerPageOptions={[5, 10, 15, 25]}
        component="div"
        count={data.count}
        rowsPerPage={parseInt(query?.perPage || "10", 10)}
        page={data.count === 0 ? 0 : parseInt(query?.page || "0", 10)}
        // onPageChange={(_, newPage) =>
        //   push(
        //     query
        //       ? { ...query, page: newPage }
        //       : { page: newPage, perPage: "10" }
        //   )
        // }
        // onRowsPerPageChange={({ target: { value = "10" } }) =>
        //   push(
        //     query
        //       ? { ...query, page: 0, perPage: parseInt(value, 10) }
        //       : { page: 0, perPage: parseInt(value, 10) }
        //   )
        // }
        ActionsComponent={TablePaginationActions}
      /> */}
    </Fragment>
  );
};
