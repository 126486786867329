import { useEffect, Fragment, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";

import {
  Grid,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  IconButton,
  TablePagination,
  CircularProgress,
  Alert,
} from "@mui/material";
import { AddCircleOutline } from "@mui/icons-material";
import { useListUserSellerSbd } from "../../services/user-seller";
import { TablePaginationActions } from "../../components/spam/pagination";
import { SearchHead } from "../../components/spam/inputs";

export const SellerBigdiPage = () => {
  const navigate = useNavigate();
  const { loading, onProcess, res, data, push, query, setRes } =
      useListUserSellerSbd(),
    [values, setValues] = useState("");
  useEffect(() => {
    onProcess();
  }, [onProcess]);
  useEffect(() => {
    if (query && query.search) setValues(query.search);
    else setValues("");
  }, [query]);

  return (
    <>
      {/* <BasicModal open={open} onClose={() => null}>
        <Grid
          container
          display="flex"
          width="100%"
          justifyContent="center"
          flexDirection="column"
          alignItems="center"
        >
          <Typography variant="h4" component="h2" style={{ marginBottom: 20 }}>
            Add seller
          </Typography>
          {!Object.keys(dataUpdate).length ? (
            <Fragment>
              <TextField
                value={value}
                onChange={({ target: { value } }) => setValue(value)}
                onKeyUp={({ key }) => {
                  if (key === "Enter") {
                    setResInternalConfirm({ status: "", message: "" });
                    onProcessFind();
                  }
                }}
                name="search"
                variant="standard"
                color="info"
                placeholder={"search seller here"}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {dataSellerInternal.data.length > 0 ? (
                        <IconButton
                          color="error"
                          onClick={() => {
                            setDataSellerInternal((prev) => ({
                              ...prev,
                              data: [],
                            }));

                            setValue("");
                          }}
                        >
                          <DeleteOutline />
                        </IconButton>
                      ) : (
                        <IconButton
                          onClick={() => {
                            setResInternalConfirm({ status: "", message: "" });
                            onProcessFind();
                          }}
                        >
                          <SearchOutlined />
                        </IconButton>
                      )}
                    </InputAdornment>
                  ),
                }}
              />
            </Fragment>
          ) : null}

          <Fragment>
            {Object.keys(dataUpdate).length ? (
              <Typography>{dataUpdate.username}</Typography>
            ) : dataSellerInternal.data.length > 0 ? (
              <TableContainer style={{ minHeight: "50vh", maxHeight: "80vh" }}>
                <Table style={{ width: "100%" }} stickyHeader>
                  <TableHead>
                    <TableRow>
                      <TableCell>No</TableCell>
                      <TableCell>Username</TableCell>
                      <TableCell>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {dataSellerInternal.data.length > 0
                      ? dataSellerInternal.data.map((seller, index) => (
                          <TableRow key={index} hover>
                            <TableCell>{index + 1}</TableCell>
                            <TableCell>{seller?.username || "-"}</TableCell>
                            <TableCell>
                              <IconButton
                                title="add seller"
                                style={{ marginLeft: 5 }}
                                size="small"
                                color="primary"
                                onClick={() => {
                                  setDataUpdate(seller);
                                }}
                              >
                                <AddCircleOutline />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        ))
                      : null}
                  </TableBody>
                </Table>
                {data.data.length === 0 ? (
                  <Alert variant="standard" severity="warning">
                    No Data
                  </Alert>
                ) : null}
              </TableContainer>
            ) : null}
            {loadingInternal ? (
              <div className="center">
                <CircularProgress size={25} />
              </div>
            ) : null}
          </Fragment>
          <Fragment>
            <Grid
              container
              display="flex"
              width="100%"
              justifyContent="space-between"
              alignItems="center"
              paddingX={5}
              marginTop={5}
            >
              <Button
                variant="outlined"
                color="error"
                size="small"
                title="Delete"
                onClick={() => {
                  setValue("");
                  setRes({ status: "", message: "" });
                  setDataSellerInternal((prev) => ({ ...prev, data: [] }));
                  setDataUpdate({});
                  setOpen(false);
                }}
              >
                cancel
              </Button>

              <Button
                color="info"
                variant="outlined"
                size="small"
                title="Cancel Delete"
                onClick={async () => {
                  setDataSellerInternal((prev) => ({ ...prev, data: [] }));
                  setRes({ status: "", message: "" });

                  if (!Object.keys(dataUpdate).length) {
                    setResInternalConfirm({
                      status: "error",
                      message: "harap pilih seller",
                    });
                    return;
                  }
                  await onProcessUpdate();
                  await onProcess();
                  setOpen(false);
                  setValue("");
                }}
              >
                Confirm
              </Button>
            </Grid>
          </Fragment>
          <Grid item />
        </Grid>
      </BasicModal> */}

      {/* <UIAlert
        open={
          resInternalConfirm.status && resInternalConfirm.message ? true : false
        }
        onClose={() => setResInternalConfirm({ status: "", message: "" })}
        res={resInternalConfirm}
      /> */}

      <Fragment>
        <Grid item width="100%" justifyContent="flex-end" display="flex">
          {/* <Grid item display="flex">
            <Tooltip title="Register seller secara massal">
              <Button
                size="small"
                onClick={() =>
                  navigate("/seller/register/massal", { state: { from: true } })
                }
                variant="outlined"
                color="success"
              >
                Register Seller Massal
              </Button>
            </Tooltip>
            {/* <Tooltip title="Register seller secara massal" sx={{ ml: 2 }}>
              <Button
                size="small"
                onClick={() => sendReport()}
                variant="outlined"
                color="primary"
              >
                Kirim Report
              </Button>
            </Tooltip> */}
          {/* {auth.group_name !== "root" ? (
              <Fragment>
                <Tooltip title="Link Referensi anda, undang seller register dengan link ini">
                  <Button
                    color={copy ? "success" : "info"}
                    style={{ marginLeft: 5 }}
                    variant="text"
                    endIcon={copy ? <DoneAllOutlined /> : <CopyAllOutlined />}
                    onClick={copyData}
                  >
                    Copy Register Link
                  </Button>
                </Tooltip>
                <TextField
                  hidden
                  style={{ width: 350, opacity: 0 }}
                  variant="standard"
                  size="small"
                  type="text"
                  disabled
                  value={`${env.sasFeUrl}/register?salesRefID=${auth.id}`}
                  id="myInput"
                />
              </Fragment>
            ) : null}
            <Grid />
          </Grid> */}

          <SearchHead
            values={values}
            placeholder="Search Seller here"
            onEnter={(variable) =>
              push({ ...query, page: 0, search: variable })
            }
          />
        </Grid>

        <TableContainer style={{ minHeight: "50vh", maxHeight: "80vh" }}>
          <Table style={{ width: "100%" }} stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>No</TableCell>
                <TableCell>Shop Name</TableCell>
                <TableCell>Address</TableCell>
                <TableCell align="center">Total Internal Seller</TableCell>
                <TableCell align="center">Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.data?.length > 0
                ? data.data.map((seller, index) => (
                    <TableRow key={index} hover>
                      <TableCell>{index + 1}</TableCell>
                      <TableCell>{seller?.shopName || "-"}</TableCell>
                      <TableCell>{seller?.address || "-"}</TableCell>
                      <TableCell align="center">
                        {seller?.userInternalId?.length || "-"}
                      </TableCell>
                      <TableCell align="center">
                        {/* {seller.userInternalId ? (
                          <IconButton
                            title="add seller"
                            style={{ marginLeft: 5 }}
                            size="small"
                            color="primary"
                            onClick={() => {
                              setResInternalConfirm({
                                ...resInternalConfirm,
                                status: "",
                                message: "",
                              });
                              setIdShop(seller.id);
                              // setOpen(true);
                            }}
                          >
                            <Edit />
                          </IconButton>
                        ) : (
                          <IconButton
                            title="add seller"
                            style={{ marginLeft: 5 }}
                            size="small"
                            color="primary"
                            onClick={() => {
                              setResInternalConfirm({
                                ...resInternalConfirm,
                                status: "",
                                message: "",
                              });
                              setIdShop(seller.id);
                              setOpen(true);
                            }}
                          >
                            <AddCircleOutline />
                          </IconButton>
                        )} */}
                        <IconButton
                          title="add seller"
                          style={{ marginLeft: 5 }}
                          size="small"
                          color="primary"
                          onClick={() => {
                            navigate(
                              `/seller/bigdelivery/app/store?userId=${seller.id}`
                            );
                          }}
                        >
                          <AddCircleOutline />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))
                : null}
            </TableBody>
          </Table>

          {loading ? (
            <div className="center">
              <CircularProgress size={25} />
            </div>
          ) : res.message ? (
            <Alert variant="standard" severity="error">
              {res.message}
            </Alert>
          ) : data.data.length === 0 ? (
            <Alert variant="standard" severity="warning">
              No Data
            </Alert>
          ) : null}
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[5, 10, 15, 25]}
          component="div"
          count={data.count}
          rowsPerPage={parseInt(query?.perPage || "10", 10)}
          page={data.count === 0 ? 0 : parseInt(query?.page || "0", 10)}
          onPageChange={(_, newPage) =>
            push(
              query
                ? { ...query, page: newPage }
                : { page: newPage, perPage: "10" }
            )
          }
          onRowsPerPageChange={({ target: { value = "10" } }) =>
            push(
              query
                ? { ...query, page: 0, perPage: parseInt(value, 10) }
                : { page: 0, perPage: parseInt(value, 10) }
            )
          }
          ActionsComponent={TablePaginationActions}
        />
      </Fragment>
    </>
  );
};
