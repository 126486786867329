import { useContext } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { AuthContext } from "../utils/auth";
import { RequestProvider } from "../utils/requests";

export const PrivateRoute = () => {
  const { auth } = useContext(AuthContext);

  if (!auth.id || auth.id === null) return <Navigate to="/" />;

  return (
    <RequestProvider>
      <Outlet />
    </RequestProvider>
  );
};

export const PublicRoute = ({ component: Component, ...rest }) => {
  const { auth } = useContext(AuthContext);

  if (auth && auth.id)
    return (
      <Navigate
        to={`/dashboard?date=${new Date().toLocaleString("en-US", {
          month: "long",
        })}-${new Date().getFullYear()}`}
      />
    );

  return <Component />;
};
