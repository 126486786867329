import { Fragment, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { ExcelRenderer } from "react-excel-renderer";
import {
  Grid,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  IconButton,
  Typography,
  Button,
  CircularProgress,
  Tooltip,
  Alert,
} from "@mui/material";
import {
  RefreshOutlined,
  ArrowLeftOutlined,
  DownloadOutlined,
  UploadFileOutlined,
} from "@mui/icons-material";
import { useRegisterSeller, useLoopAddAlias } from "../../services/user-seller";
// import { exportFileExcel } from "../../helpers/excel";
import writeXlsxFile from "write-excel-file";

export const SellerRegisterMassal = () => {
  const navigate = useNavigate(),
    { onProcess: register } = useRegisterSeller(),
    { state } = useLocation(),
    [res, setRes] = useState({ status: "", message: "" }),
    [users, setUsers] = useState([]),
    { onProcess: addAliases } = useLoopAddAlias(),
    [loadExcel, setLoadExcel] = useState(false);

  const downloadTemplate = async () => {
    const schema = [
      {
        column: "name",
        type: String,
        width: 15,
        value: () => "",
      },
      {
        column: "username",
        width: 15,
        type: String,
        value: () => "",
      },
      {
        column: "email",
        width: 13,
        type: String,
        value: () => "",
      },
      {
        column: "password",
        width: 15,
        type: String,
        value: () => "",
      },
      {
        column: "shopName",
        width: 15,
        type: String,
        value: () => "",
      },
      {
        column: "shopImgUrl",
        width: 15,
        type: String,
        value: () => "",
      },
      {
        column: "address",
        width: 15,
        type: String,
        value: () => "",
      },
      {
        column: "homeOwner (Y/N)",
        width: 20,
        type: String,
        value: () => "",
      },
      {
        column: "IDCardImgUrl",
        width: 20,
        type: String,
        value: () => "",
      },
      {
        column: "phone",
        width: 13,
        type: String,
        value: () => "",
      },
      {
        column: "bankName",
        width: 15,
        type: String,
        value: () => "",
      },
      {
        column: "bankAccountNumber",
        width: 20,
        type: String,
        value: () => "",
      },
      {
        column: "bankAccountName",
        width: 18,
        type: String,
        value: () => "",
      },
      {
        column: "pickupAddress",
        width: 16,
        type: String,
        value: () => "",
      },
      {
        column: "pickupLocationOwner (Y/N)",
        width: 25,
        type: String,
        value: () => "",
      },
      {
        column: "pickupPointImgUrl",
        width: 20,
        type: String,
        value: () => "",
      },
      {
        column: "acquaintanceName1",
        width: 20,
        type: String,
        value: () => "",
      },
      {
        column: "acquaintancePhone1",
        width: 20,
        type: String,
        value: () => "",
      },
      {
        column: "acquaintanceAddress1",
        width: 22,
        type: String,
        value: () => "",
      },
      {
        column: "acquaintanceRelationship1",
        width: 25,
        type: String,
        value: () => "",
      },
      {
        column: "acquaintanceName2",
        width: 20,
        type: String,
        value: () => "",
      },
      {
        column: "acquaintancePhone2",
        width: 20,
        type: String,
        value: () => "",
      },
      {
        column: "acquaintanceAddress2",
        width: 21,
        type: String,
        value: () => "",
      },
      {
        column: "acquaintanceRelationship2",
        width: 24,
        type: String,
        value: () => "",
      },
      {
        column: "alias",
        width: 13,
        type: String,
        value: () => "",
      },
      {
        column: "salesRefID",
        width: 15,
        type: String,
        value: () => "",
      },
    ];

    await writeXlsxFile([], {
      schema,
      headerStyle: {
        fontWeight: "bold",
        align: "center",
      },
      fileName: "BigdelRegister[].xlsx",
    });
  };
  // function downloadTemplate() {
  //   exportFileExcel(
  //     [
  //       {
  //         name: "",
  //         username: "",
  //         email: "",
  //         password: "",
  //         shopName: "",
  //         shopImgUrl: "",
  //         address: "",
  //         "homeOwner (Y/N)": "",
  //         IDCardImgUrl: "",
  //         phone: "",
  //         bankName: "",
  //         bankAccountNumber: "",
  //         bankAccountName: "",
  //         pickupAddress: "",
  //         "pickupLocationOwner (Y/N)": "",
  //         pickupPointImgUrl: "",
  //         acquaintanceName1: "",
  //         acquaintancePhone1: "",
  //         acquaintanceAddress1: "",
  //         acquaintanceRelationship1: "",
  //         acquaintanceName2: "",
  //         acquaintancePhone2: "",
  //         acquaintanceAddress2: "",
  //         acquaintanceRelationship2: "",
  //         alias: "",
  //         salesRefID: "",
  //       },
  //     ],
  //     "BigdelRegister[]"
  //   );
  // }

  const retrySingle = (user) => async (_) => {
    await setUsers((prev) =>
      prev.map((el) => {
        if (el.id === user.id)
          return { ...el, loading: true, message: "", status: "" };
        return el;
      })
    );

    try {
      const message = await register(user);
      await setUsers((prev) =>
        prev.map((el) => {
          if (el.id === user.id)
            return { ...el, loading: false, message, status: "ok" };
          return el;
        })
      );
    } catch (e) {
      await setUsers((prev) =>
        prev.map((el) => {
          if (el.id === user.id)
            return { ...el, loading: false, status: "error", message: e };
          return el;
        })
      );
    }
  };

  function fileHandle(e) {
    if (e.preventDefault) e.preventDefault();

    setLoadExcel(true);

    const getFiles = (() => {
      try {
        return e.target.files[0];
      } catch (e) {
        return null;
      }
    })();

    if (!getFiles) {
      setLoadExcel(false);
      return setRes({ status: "error", message: "we cant load your file" });
    }

    setRes({ status: "", message: "" });
    const type = getFiles.type,
      fileName = (() => {
        try {
          return getFiles.name.split("[]")[0];
        } catch (e) {
          setRes({
            status: "error",
            message: "check your file name, please download template again",
          });
        }
      })();

    if (
      !/xml/i.test(type) &&
      !/csv/i.test(type) &&
      !/xls/i.test(type) &&
      !/excel/i.test(type) &&
      !/csv/i.test(type)
    ) {
      setLoadExcel(false);
      setRes({
        status: "error",
        message:
          "only '.cvs', '.xml', '.xls', '.csv', 'excel' files are allowed",
      });
      return;
    }

    if (fileName !== "BigdelRegister") {
      setLoadExcel(false);
      return setRes({
        status: "error",
        message: "please don't change the filename",
      });
    }

    setUsers([]);

    ExcelRenderer(getFiles, async (err, resp) => {
      if (resp) {
        let _users = [],
          getHeaders = resp.rows[0],
          _message = "no data on rows";
        await resp.rows.forEach((item, index) => {
          try {
            if (index !== 0) {
              if (item.length > 0) {
                let payload = {};
                getHeaders.forEach((header, _index) => {
                  if (/(Y\/N)/i.test(header)) {
                    payload[header.split(" (")[0]] =
                      item[_index] &&
                      (item[_index] === "Y" || item[_index] === "y")
                        ? true
                        : false;
                    return;
                  }
                  payload[header] =
                    item[_index] && typeof item[_index] !== "string"
                      ? `${item[_index]}`.replace(/^[ ]+|[ ]$/g, "")
                      : item[_index] && typeof item[_index] === "string"
                      ? item[_index].replace(/^[ ]+|[ ]$/g, "")
                      : item[_index];
                });
                _users.push({
                  ...payload,
                  id: _users.length + 1,
                  agreement1: true,
                  agreement2: true,
                  loading: true,
                  message: "",
                  status: "",
                });
              }
            }
          } catch (e) {
            _message = "make sure you don't change the column name";
          }
        });

        if (_users.length === 0)
          return setRes({ status: "error", message: _message });

        await setUsers(_users);

        for (const user of _users) {
          try {
            const { message, sellerId } = (await register(user)) || {
                message: "",
                sellerId: "",
              },
              getAliases = (() => {
                try {
                  return user.alias.split(",") || [];
                } catch (e) {
                  return [];
                }
              })();

            let aliasErrors = [];
            if (getAliases.length > 0 && sellerId) {
              // proses alias
              for (const alias of getAliases) {
                try {
                  await addAliases({ sellerId, alias });
                } catch (e) {
                  aliasErrors.push(`Error - ${alias} - ${e}`);
                }
              }
            }
            await setUsers((prev) =>
              prev.map((el) => {
                if (el.id === user.id)
                  return {
                    ...el,
                    loading: false,
                    message: `${message}${
                      aliasErrors.length > 0 ? ` ${aliasErrors.join(", ")}` : ""
                    }`,
                    status: "ok",
                  };
                return el;
              })
            );
          } catch (e) {
            let __message =
              typeof e === "string" ? e : e.message || "internal error";
            if (/You do not have access/i.test(e)) {
              setRes({ status: "error", message: __message });
              await setUsers([]);
              break;
            }
            await setUsers((prev) =>
              prev.map((el) => {
                if (el.id === user.id)
                  return {
                    ...el,
                    loading: false,
                    message: __message,
                    status: "error",
                  };
                return el;
              })
            );
          }
        }
      }
      setLoadExcel(false);
    });
  }

  return (
    <Fragment>
      <Grid item width="100%" justifyContent="space-between" display="flex">
        <Grid item>
          <Button
            onClick={() =>
              navigate(state?.from ? -1 : "/seller?page=0&perPage=10")
            }
            startIcon={<ArrowLeftOutlined />}
            size="small"
            title="Go Back"
          >
            Go Back
          </Button>
        </Grid>
        <Grid item display="flex">
          {users.length > 0 ? null : (
            <Tooltip title="Setelah download template dan isi data yang diperlukan, klik tombol ini kemudian upload excel. setelah itu kami yang proses datanya">
              <Button
                component="label"
                size="small"
                style={{ marginRight: 10 }}
                color="info"
                endIcon={<UploadFileOutlined />}
                variant="outlined"
              >
                Upload File
                <input type="file" hidden onChange={fileHandle} />
              </Button>
            </Tooltip>
          )}
          <Tooltip title="Download template, sesuaikan data2 kemudian upload file">
            <Button
              size="small"
              color="info"
              endIcon={<DownloadOutlined />}
              onClick={downloadTemplate}
              variant="outlined"
            >
              Download Template
            </Button>
          </Tooltip>
        </Grid>
      </Grid>

      <TableContainer style={{ minHeight: "50vh", maxHeight: "80vh" }}>
        <Table style={{ width: "100%" }} stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>No</TableCell>
              <TableCell>Username</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Password</TableCell>
              <TableCell style={{ width: 800 }}>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users.length > 0
              ? users.map((user, index) => (
                  <TableRow key={index}>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{user.username || "-"}</TableCell>
                    <TableCell>{user.email || "-"}</TableCell>
                    <TableCell>{user.password || "-"}</TableCell>
                    <TableCell>
                      {user.loading ? (
                        <CircularProgress size={25} />
                      ) : user.message ? (
                        <Fragment>
                          {user.status === "error" ? (
                            <Fragment>
                              <IconButton
                                title="Retry"
                                color="error"
                                size="small"
                                onClick={retrySingle(user)}
                              >
                                <RefreshOutlined />
                              </IconButton>
                              <br />
                            </Fragment>
                          ) : null}
                          {user.message}
                        </Fragment>
                      ) : null}
                    </TableCell>
                  </TableRow>
                ))
              : null}
          </TableBody>
        </Table>

        {loadExcel ? (
          <div className="center">
            <Typography variant="caption">Loading...</Typography>
          </div>
        ) : res.message ? (
          <Alert severity="error">{res.message}</Alert>
        ) : users.length === 0 ? (
          <Alert severity="warning">
            Download Template then upload seller data
          </Alert>
        ) : (
          <Grid item>
            <Button
              size="small"
              variant="contained"
              color="primary"
              onClick={() => window.location.replace("massal")}
            >
              Upload More
            </Button>
          </Grid>
        )}
      </TableContainer>
    </Fragment>
  );
};
