import { Box, Divider, Typography } from "@mui/material";
import { convertCurrency } from "../helpers/custom";

export const financeCard = (total, weight, cost, auth) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        flexDirection: "column",
        border: 1,
        height: auth === "root" ? 100 : 50,
        width: 200,
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
          height: auth === "root" ? "50%" : "100%",
        }}
      >
        <Typography
          sx={{
            width: "50%",
            textAlign: "center",
          }}
        >
          {!total || total == 0 ? "-" : total}
        </Typography>
        <Divider
          orientation="vertical"
          sx={{
            height: "100%",
            borderRightWidth: "1px",
            backgroundColor: "#000000",
          }}
        />
        <Typography sx={{ width: "50%" }}>
          {!weight || weight == 0 ? "-" : `${weight} kg`}
        </Typography>
      </Box>
      {auth == "root" ? (
        <>
          <Divider
            flexItem={true}
            sx={{
              backgroundColor: "#000000",
              borderBottomWidth: "1px",
            }}
          />
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "50%",
            }}
          >
            <Typography>
              {!cost || cost == 0 ? "-" : convertCurrency(cost)}
            </Typography>
          </Box>
        </>
      ) : null}
    </Box>
  );
};
