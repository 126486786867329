import {useEffect, useRef, useCallback} from 'react';
import {
	TableContainer,
	Table,
	TableBody,
	TableCell,
	Alert,
	IconButton,
	CircularProgress,
	Checkbox,
} from '@mui/material';
import {AddOutlined, RemoveCircleOutline} from '@mui/icons-material';
import {useGroupList} from '../../services/group';
import {useModuleList} from '../../services/modules';
import {BasicModal} from '../spam/modal';
import {EnchancedTableHead} from '../../components/spam/pagination';
import {convertDate} from '../../helpers/custom';

export const GroupSelection = ({open, currentLevel, close, single = false, onChangeSingle}) => {
	const {onProcess, loading, query, setQuery, res, data, hasMore, updateAll, updateOne, selectItems} = useGroupList(),
		
    observed = useRef(),
    lastItemFetchRef = useCallback(onHandleCallbackRefFetch, [loading, setQuery, hasMore]);

	function onHandleCallbackRefFetch (node) {
		if(loading) return;
		if(observed.current) observed.current.disconnect();
		observed.current = new IntersectionObserver(async entries => {
			if(entries[0].isIntersecting) {
				if(hasMore)
					setQuery(prev => ({...prev, offset: prev.offset + 1}))
			}
		})

		if(node) observed.current.observe(node);
	}
	function handleRequestSort (_, property) {
		if(!property)
			return;
		let _query = {...query};
		_query.orderBy = property;
		_query.sortBy = query.orderBy !== property ? 'desc' : query.sortBy === 'asc' ? 'desc' : 'asc';

		setQuery({
			..._query,
			page: 0,
		})
	}

	useEffect(() => {
		onProcess();
	}, [onProcess])

	return (
		<BasicModal open={open} onClose={close} title='Select Group'>
			<TableContainer style={{width: 1200, height: 350}}>
				<Table stickyHeader style={{width: '100%'}}>
					<EnchancedTableHead
						numSelected={selectItems.length}
						order={query.sortBy || 'desc'}
						orderBy={query.orderBy || 'create_date'}
						hideCheckbox={single}
						onSelectAllClick={({target: {checked = false}}) => updateAll({key: 'isChecked', value: checked})}
						onRequestSort={handleRequestSort}
						rowCount={data.length}
						headCells={[
							{id: 1, original: '', label: 'No'},
							{id: 2, original: 'create_date', label: 'Create Date'},
							{id: 3, original: 'update_date', label: 'Last Update'},
							{id: 4, original: 'name', label: 'Group Name'},
							{id: 5, original: 'level', label: 'Group Level'},
							{id: 6, original: '', label: 'Superior Name'},
							{id: 7, original: '', label: 'Superior Level'},
							{id: 8, original: '', label: 'Action'}
						]}/>
					<TableBody>
						{data.length > 0
							? data.map((group, index) => {
								const {date, time} = convertDate(group.create_date),
									{date: _lastDate, time: _lastTime} = convertDate(group.update_date);
								return (
									<tr key={index} ref={index === data.length - 1 ? lastItemFetchRef : undefined}>
										{!single
											?	<TableCell padding='checkbox'>
													<Checkbox
														color='primary'
														onChange={({target: {checked}}) => updateOne(group.id, {key: 'isChecked', value: checked || false})}
														checked={group.isChecked}
														inputProps={{'aria-labelledby': `enchanced-table-checkbox-${group.id}`}}/>
												</TableCell> : null}
										<TableCell>{index + 1}</TableCell>
										<TableCell>
											{date}<br/>
											{time}
										</TableCell>
										<TableCell>
											{_lastDate}<br/>
											{_lastTime}
										</TableCell>
										<TableCell>{group.name}</TableCell>
										<TableCell>{group.level || '-'}</TableCell>
										<TableCell>{group.parent_name || '-'}</TableCell>
										<TableCell>{group.parent_level || '-'}</TableCell>
										<TableCell>
											{currentLevel === undefined || group.level < currentLevel
												?	<IconButton
														color='success'
														onClick={() => {
															if(onChangeSingle !== undefined) {
																onChangeSingle(group);
																return close(false);
															}
															updateOne(group.id, {key: 'isChecked', value: !group.isChecked});
															if(single) {
																const findOne = selectItems.find(el => el.isChecked);
																if(findOne && findOne.id !== group.id)
																	updateOne(findOne.id, {key: 'isChecked', value: false});
																
																
																if(!group.isChecked)
																	close(false);
															}
														}}>
														{group.isChecked
															?	<RemoveCircleOutline/>
															:	<AddOutlined/>}
													</IconButton>
												:	'Superior must high level'}
										</TableCell>
									</tr> 
								)
							})
							:	null}
					</TableBody>
				</Table>
				{loading
					?	<div className='center'>
							<CircularProgress size={25}/>
						</div>
					: res.message
						?	<Alert severity='warning'>
								{res.message}
							</Alert>
						: data.length === 0
							?	<Alert severity='warning'>
									no group settings yet
								</Alert> : null}
				
			</TableContainer>
		</BasicModal>
	)
}



export const ModuleSelection = ({open, close, single = false, onChangeSingle}) => {
	const {onProcess, loading, query, setQuery, res, data, hasMore, updateAll, updateOne, selectItems} = useModuleList(),
    observed = useRef(),
    lastItemFetchRef = useCallback(onHandleCallbackRefFetch, [loading, setQuery, hasMore]);

	function onHandleCallbackRefFetch (node) {
		if(loading) return;
		if(observed.current) observed.current.disconnect();
		observed.current = new IntersectionObserver(async entries => {
			if(entries[0].isIntersecting) {
				if(hasMore)
					setQuery(prev => ({...prev, offset: prev.offset + 1}))
			}
		})

		if(node) observed.current.observe(node);
	}
	function handleRequestSort (_, property) {
		if(!property)
			return;
		let _query = {...query};
		_query.orderBy = property;
		_query.sortBy = query.orderBy !== property ? 'desc' : query.sortBy === 'asc' ? 'desc' : 'asc';

		setQuery({
			..._query,
			page: 0,
		})
	}

	useEffect(() => {
		onProcess();
	}, [onProcess])

	return (
		<BasicModal open={open} onClose={close} title='Select Modules'>
			<TableContainer style={{width: 1200, height: 350}}>
				<Table stickyHeader style={{width: '100%'}}>
					<EnchancedTableHead
						numSelected={selectItems.length}
						order={query.sortBy || 'desc'}
						orderBy={query.orderBy || 'create_date'}
						hideCheckbox={single}
						onSelectAllClick={({target: {checked = false}}) => updateAll({key: 'isChecked', value: checked})}
						onRequestSort={handleRequestSort}
						rowCount={data.length}
						headCells={[
							{id: 1, original: '', label: 'No'},
							{id: 2, original: 'create_date', label: 'Create Date'},
							{id: 3, original: 'update_date', label: 'Last Update'},
							{id: 4, original: 'identity', label: 'Module Name'},
							{id: 5, original: '', label: 'Approval'},
							{id: 6, original: '', label: 'Count Approval'},
							{id: 8, original: '', label: 'Action'}
						]}/>
					<TableBody>
						{data.length > 0
							? data.map((_module, index) => {
								const {date, time} = convertDate(_module.create_date),
									{date: _lastDate, time: _lastTime} = convertDate(_module.update_date);
								return (
									<tr key={index} ref={index === data.length - 1 ? lastItemFetchRef : undefined}>
										{!single
											?	<TableCell padding='checkbox'>
													<Checkbox
														color='primary'
														onChange={({target: {checked}}) => updateOne(_module.id, {key: 'isChecked', value: checked || false})}
														checked={_module.isChecked}
														inputProps={{'aria-labelledby': `enchanced-table-checkbox-${_module.id}`}}/>
												</TableCell> : null}
										<TableCell>{index + 1}</TableCell>
										<TableCell>
											{date}<br/>
											{time}
										</TableCell>
										<TableCell>
											{_lastDate}<br/>
											{_lastTime}
										</TableCell>
										<TableCell>{_module.identity || '-'}</TableCell>
										<TableCell>
											<Checkbox
												disabled
												checked={_module.approval || false}
												onChange={() => null}
												color='primary'/>
										</TableCell>
										<TableCell>{_module.count_approval || '-'}</TableCell>
										<TableCell>
											<IconButton
												color='success'
												onClick={() => {
													if(onChangeSingle !== undefined) {
														onChangeSingle(_module)
														return close(false);
													}
													updateOne(_module.id, {key: 'isChecked', value: !_module.isChecked});
													if(single) {
														const findOne = selectItems.find(el => el.isChecked);
														if(findOne && findOne.id !== _module.id)
															updateOne(findOne.id, {key: 'isChecked', value: false});
														
														
														if(!_module.isChecked)
															close(false);
													}
												}}>
												{_module.isChecked
													?	<RemoveCircleOutline/>
													:	<AddOutlined/>}
											</IconButton>
										</TableCell>
									</tr> 
								)
							})
							:	null}
					</TableBody>
				</Table>
				{loading
					?	<div className='center'>
							<CircularProgress size={25}/>
						</div>
					: res.message
						?	<Alert severity='warning'>
								{res.message}
							</Alert>
						: data.length === 0
							?	<Alert severity='warning'>
									no module settings yet
								</Alert> : null}
				
			</TableContainer>
		</BasicModal>
	)
}