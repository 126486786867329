import writeXlsxFile from "write-excel-file";
import { useState, useContext, useMemo } from "react";
// import x from "axios";
import gtw from "../apis/gtw";
import { AuthContext } from "../utils/auth";
// import {GroupContext} from '../utils/group';
import { handleError } from "./helpers";
import { convertDataToQuery, getQueryUrl } from "../helpers/custom";
import { ExcelRenderer } from "react-excel-renderer";
import { useSearchParams } from "react-router-dom";

import { useLocation, useNavigate } from "react-router-dom";

export const useTicketing = () => {
  const { headers } = useContext(AuthContext);
  const [data, setData] = useState([]);
  const [dataDetail, setDataDetail] = useState({});
  const [progress, setProgress] = useState(0);
  const [count, setCount] = useState(0);
  const [res, setRes] = useState({ status: "success", message: "" });
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [loadingDetail, setLoadingDetail] = useState(false);
  const [loadingExport, setLoadingExport] = useState(false);

  const { search } = useLocation();

  const push = (payload) => {
    navigate(`/tickets${convertDataToQuery(payload)}`);
  };

  const query = useMemo(() => {
    if (!search) return null;
    return getQueryUrl(search);
  }, [search]);

  const getList = async () => {
    try {
      setLoading(true);
      setData([]);

      const { data } = await gtw({
        method: "get",
        url: `/sync/all/ticket${search}`,
        headers,
      });
      setData(data.data);
      setCount(data.count);
      setLoading(false);
    } catch (err) {
      const message = handleError(err);
      setRes({
        status: "error",
        message,
      });
      setLoading(false);
    }
  };

  const addList = async (payload) => {
    try {
      console.log(payload);
      // setRes({ ...res, status: "", message: "" });
      console.log("aaaaa");
      if (!payload.addTicketData.note) {
        console.log("asdf");
        setRes({
          status: "error",
          message: "note tidak boleh kosong",
        });

        return;
      }
      const { data } = await gtw({
        method: "post",
        url: `/sync/ticket/internal`,
        headers,
        data: payload,
      });
      setRes({
        status: "ok",
        message: "add list success",
      });

      await getList();
      setTimeout(() => {
        setRes({ ...res, status: "success", message: "" });
      }, 3000);
    } catch (err) {
      const message = handleError(err);

      setRes({
        status: "error",
        message,
      });
      setLoading(false);
    }
  };

  const editList = async ({ payload, id }) => {
    try {
      setRes({ ...res, status: "", message: "" });

      const { data } = await gtw({
        method: "patch",
        url: `/sync/ticket/${id}`,
        headers,
        data: {
          status: payload.status,
        },
      });
      setRes({ ...res, status: "ok", message: "edit list success" });
    } catch (err) {
      const message = handleError(err);

      setRes({ ...res, status: "error", message });
      setLoading(false);
    }
  };

  const findAwb = async ({ awb }) => {
    // setRes({ ...res, status: "", message: "" });

    const { data } = await gtw({
      method: "post",
      url: `/sync/find/awb`,
      headers,
      data: { awb },
    });
    // setRes({
    //   status: "ok",
    //   message: "edit list success",
    // });
    return data.data;
  };

  const addDetail = async ({ message, id, img, statusImg }) => {
    let picturesUpload = [];

    // if (!statusImg && !data.message) {
    //   return;
    // }
    if (statusImg) {
      const { data } = await gtw({
        method: "post",
        url: `/sync/detail/ticket`,
        data: img,
        headers,
      });
      picturesUpload = data;
    }

    const { data: _data } = await gtw({
      method: "post",
      url: `/sync/detail/ticket`,
      data: { message, id, pictures: picturesUpload },
      headers,
    });
    return _data;
  };

  const getDetail = async (id) => {
    try {
      setDataDetail({});
      // setLoadingDetail(true);
      const { data } = await gtw({
        method: "get",
        url: `/sync/detail/ticket/${id}`,
        headers,
      });
      setDataDetail(data.data);
      setLoadingDetail(false);
    } catch (err) {
      const message = handleError(err);

      setRes({ ...res, status: "error", message });
      setLoading(false);
    }
  };

  const exportFile = async () => {
    try {
      setLoadingExport(true);
      setProgress(0);
      setRes({ status: "", message: "" });
      let items = [];
      let newQuery = { ...query };
      let pageOffset = 0;
      let progressLoading = 0;
      let limit = Math.floor(count / 10);
      while (items.length !== count) {
        // const { data: _data } = await gtw({
        //   method: "get",
        //   url: `/transactions/export/transactions${convertDataToQuery(
        //     query
        //   )}&limit=${limit}&pageOffset=${pageOffset}`,
        //   headers,
        //   // cancelToken: _req.token,
        // });
        newQuery.page = pageOffset;
        newQuery.perPage = limit;

        const { data } = await gtw({
          method: "get",
          url: `/sync/all/ticket${convertDataToQuery(newQuery)}`,
          headers,
        });

        items = items.concat(data.data);
        if (progressLoading < 100) {
          progressLoading += 10;
          setProgress(progressLoading);
        }

        pageOffset++;
      }
      const schema = [
        {
          column: "Tanggal",
          type: String,
          width: 18,
          value: (data) =>
            new Date(data["createdAt"]).toLocaleDateString("id-ID"),
        },
        {
          column: "Waktu",
          type: String,
          width: 18,
          value: (data) =>
            new Date(data["createdAt"]).toLocaleTimeString("id-ID", {
              hour: "2-digit",
              minute: "2-digit",
            }),
        },
        {
          column: "Awb",
          type: String,
          width: 22,
          value: (data) => data["awb"],
        },
        {
          column: "Nama Seller",
          type: String,
          width: 18,

          value: (data) => data["username"],
        },
        {
          column: "Nomor Telepon",
          type: String,
          width: 18,

          value: (data) => data["phone"],
        },
        {
          column: "Notes",
          type: String,
          width: 18,

          value: (data) => data["note"],
        },
        {
          column: "Status",
          type: String,
          width: 22,

          value: (data) => data["status"],
        },
        {
          column: "Dibuat Oleh",
          type: String,
          width: 18,

          value: (data) =>
            data["createdBy"] ? data["createdBy"].split(" : ")[1] : "",
        },
      ];
      await writeXlsxFile(items, {
        schema,
        fileName: `ExportTicket.xlsx`,
      });
      // setDataDetail(data.data);
      setLoadingExport(false);
    } catch (err) {
      const message = handleError(err);

      setRes({ ...res, status: "error", message });
      setLoadingExport(false);
    }
  };

  return {
    getList,
    addList,
    addDetail,
    query,
    push,
    loading,
    data,
    count,
    res,
    progress,
    setData,
    editList,
    findAwb,
    setRes,
    dataDetail,
    setDataDetail,
    getDetail,
    loadingExport,
    loadingDetail,
    setLoadingDetail,
    exportFile,
  };
};
