import { useState, Fragment, useEffect } from "react";
import {
  Typography,
  Box,
  Button,
  Grid,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Divider,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
  Modal,
  Card,
  CardContent,
  Alert,
  CircularProgress,
  Zoom,
} from "@mui/material";
import { InfoOutlined, Close, Today } from "@mui/icons-material";
import { SearchHead } from "../../components/spam/inputs";
import { RangeDateTimePicker } from "../../components/spam/dateTime";
import { useCommission } from "../../services/financial";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { MonthPicker } from "@mui/x-date-pickers/MonthPicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

export const FinancialCommissionPage = () => {
  const [showDateFilter, setShowDateFilter] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openMonth, setOpenMonth] = useState(false);
  const [salesDetail, setSalesDetail] = useState("");
  const [tempMonth, setTempMonth] = useState(new Date());
  const {
    getList,
    dataCommission,
    selectedMonth,
    setSelectedMonth,
    downloadCommission,
    loading,
    query,
    push,
  } = useCommission();

  useEffect(() => {
    getList();
  }, [query]);

  return (
    <Fragment>
      <Modal
        open={openModal}
        sx={{
          width: "100%",
          justifyContent: "center",
          display: "flex",
          height: "70%",
          mt: 10,
        }}
      >
        <Zoom in={openModal}>
          <Grid>
            <Grid
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                backgroundColor: "white",
                height: 50,
              }}
            >
              <Button
                size="small"
                onClick={() => {
                  setOpenModal(false);
                }}
              >
                <Close />
              </Button>
            </Grid>
            <Card
              sx={{
                width: 1000,
                overflow: "auto",
                height: "100%",
                borderRadius: 0,
              }}
            >
              <CardContent>
                <Typography variant="h5" sx={{ mb: 2 }}>
                  Detail Komisi {salesDetail.toUpperCase()}
                </Typography>
                <TableContainer style={{ minHeight: "80%" }}>
                  <Table size="small" style={{ width: "100%" }} stickyHeader>
                    <TableHead>
                      <TableRow>
                        <TableCell>No</TableCell>
                        <TableCell>Seller Name</TableCell>
                        <TableCell align="center">JNE</TableCell>
                        <TableCell align="center">JNT</TableCell>
                        <TableCell align="center">AnterAja</TableCell>
                        <TableCell align="center">ID Express</TableCell>
                        <TableCell align="center">Ninja Express</TableCell>
                        <TableCell align="center">Total</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {Object.keys(dataCommission).length
                        ? salesDetail
                          ? Object.keys(dataCommission[salesDetail]).map(
                              (seller, i) =>
                                i !==
                                Object.keys(dataCommission[salesDetail])
                                  .length -
                                  1 ? (
                                  <TableRow key={i}>
                                    <TableCell>{i + 1}</TableCell>
                                    <TableCell>{seller}</TableCell>
                                    <TableCell align="center">
                                      {dataCommission[salesDetail][seller].jne
                                        ? dataCommission[salesDetail][
                                            seller
                                          ].jne.toFixed(2)
                                        : 0}
                                    </TableCell>
                                    <TableCell align="center">
                                      {dataCommission[salesDetail][seller].jnt
                                        ? dataCommission[salesDetail][
                                            seller
                                          ].jnt.toFixed(2)
                                        : 0}
                                    </TableCell>
                                    <TableCell align="center">
                                      {dataCommission[salesDetail][seller]
                                        .anteraja
                                        ? dataCommission[salesDetail][
                                            seller
                                          ].anteraja.toFixed(2)
                                        : 0}
                                    </TableCell>
                                    <TableCell align="center">
                                      {dataCommission[salesDetail][seller][
                                        "id express"
                                      ]
                                        ? dataCommission[salesDetail][seller][
                                            "id express"
                                          ].toFixed(2)
                                        : 0}
                                    </TableCell>
                                    <TableCell align="center">
                                      {dataCommission[salesDetail][seller][
                                        "ninja express"
                                      ]
                                        ? dataCommission[salesDetail][seller][
                                            "ninja express"
                                          ].toFixed(2)
                                        : 0}
                                    </TableCell>
                                    <TableCell align="center">
                                      {dataCommission[salesDetail][seller][
                                        "total"
                                      ]
                                        ? dataCommission[salesDetail][seller][
                                            "total"
                                          ].toFixed(2)
                                        : 0}
                                    </TableCell>
                                  </TableRow>
                                ) : null
                            )
                          : null
                        : null}
                    </TableBody>
                  </Table>
                  {Object.keys(dataCommission).length < 1 ? (
                    <Alert variant="standard" severity="warning">
                      No Data
                    </Alert>
                  ) : null}
                </TableContainer>
              </CardContent>
            </Card>
          </Grid>
        </Zoom>
      </Modal>

      <Modal
        open={openMonth}
        sx={{
          width: "100%",
          justifyContent: "center",
          display: "flex",
          height: "80%",
          mt: 10,
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            minWidth: 400,
            maxWidth: "95%",
            backgroundColor: "white",
            borderRadius: 1,
            boxShadow: 24,
            p: 2,
          }}
        >
          <Grid
            container
            display="flex"
            width="100%"
            justifyContent="center"
            alignItems="center"
          >
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <MonthPicker
                date={tempMonth}
                onChange={(e) => {
                  setTempMonth(e);
                }}
              />
            </LocalizationProvider>
            <Fragment>
              <Grid
                container
                display="flex"
                width="50%"
                justifyContent="space-between"
                alignItems="center"
                marginTop={5}
              >
                <Button
                  variant="outlined"
                  color="error"
                  size="small"
                  title="Delete"
                  onClick={() => {
                    setOpenMonth(false);
                    setTempMonth(new Date());
                  }}
                >
                  cancel
                </Button>

                <Button
                  color="info"
                  variant="outlined"
                  size="small"
                  title="Confirm"
                  onClick={() => {
                    push({
                      ...query,
                      date: tempMonth,
                    });
                    setSelectedMonth(tempMonth);
                    setOpenMonth(false);
                  }}
                >
                  Confirm
                </Button>
              </Grid>
            </Fragment>
          </Grid>
        </Box>
      </Modal>

      <Grid
        item
        width="100%"
        justifyContent="space-between"
        display="flex"
        flexDirection="column"
      >
        <Box
          sx={{
            mb: 2,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box>
            <Button
              component="label"
              // startIcon={<Update />}
              variant="outlined"
              size="small"
              color="primary"
              onClick={() => setOpenMonth(true)}
              sx={{ mr: 2, width: 150 }}
            >
              {selectedMonth.toLocaleString("default", { month: "long" })}
            </Button>
            <Button
              component="label"
              // startIcon={<Update />}
              variant="outlined"
              size="small"
              color="secondary"
              onClick={() => downloadCommission()}
            >
              Download Commission
            </Button>
          </Box>
          <Box>
            <SearchHead
              // values={values}
              placeholder="Search seller / awb"
              // onEnter={(variable) =>
              //   push({ ...query, page: 0, search: variable })
              // }
            />
          </Box>
        </Box>
        <Divider sx={{ ml: -3 }} />
        <Box sx={{ mb: 3, mt: 2 }}>
          {/* <FormControl sx={{ minWidth: 200 }} size="small">
            <InputLabel>Nama Sales</InputLabel>
            <Select
              // value={age}
              // onChange={handleChange}
              label="Nama Sales"
            >
              <MenuItem value={10}>SALES A</MenuItem>
              <MenuItem value={20}>SALES B</MenuItem>
              <MenuItem value={30}>SALES C</MenuItem>
            </Select>
          </FormControl> */}
        </Box>
      </Grid>
      <TableContainer style={{ minHeight: "50vh", maxHeight: "80vh" }}>
        <Table size="small" style={{ width: "100%" }} stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>No</TableCell>
              <TableCell>Sales Name</TableCell>
              <TableCell>Total Commission</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.keys(dataCommission).length
              ? Object.keys(dataCommission).map((sales, i) => (
                  <TableRow key={i}>
                    <TableCell>{i + 1}</TableCell>
                    <TableCell>{sales.toUpperCase()}</TableCell>
                    <TableCell>
                      {dataCommission[sales].totalKomisi.toFixed(2)}
                    </TableCell>
                    <TableCell>
                      <IconButton
                        color="primary"
                        onClick={() => {
                          setOpenModal(true);
                          setSalesDetail(sales);
                        }}
                      >
                        <InfoOutlined />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))
              : null}
          </TableBody>
        </Table>

        {loading ? (
          <div className="center">
            <CircularProgress size={25} />
          </div>
        ) : Object.keys(dataCommission).length < 1 ? (
          <Alert variant="standard" severity="warning">
            No Data
          </Alert>
        ) : null}
      </TableContainer>

      {/* <TablePagination
        rowsPerPageOptions={[5, 10, 15, 25, 50, 100, 200, 500]}
        component="div"
        // count={data.count}
        count={data.count}
        rowsPerPage={parseInt(query?.perPage || "10", 10)}
        page={data.count === 0 ? 0 : parseInt(query?.page || "0", 10)}
        onPageChange={(_, newPage) =>
          push(
            query
              ? { ...query, page: newPage }
              : { page: newPage, perPage: "10" }
          )
        }
        onRowsPerPageChange={({ target: { value = "10" } }) =>
          push(
            query
              ? { ...query, page: 0, perPage: parseInt(value, 10) }
              : { page: 0, perPage: parseInt(value, 10) }
          )
        }
        ActionsComponent={TablePaginationActions}
      /> */}
    </Fragment>
  );
};
