import {useEffect, useMemo, useRef, useCallback} from 'react';
import {
	TableContainer,
	Table,
	TableBody,
	TableCell,
	Alert,
	IconButton,
	Grid,
	Typography,
	CircularProgress,
} from '@mui/material';
import {AddOutlined} from '@mui/icons-material';
import {BasicModal} from '../spam/modal';
import {EnchancedTableHead} from '../../components/spam/pagination';
import {useInfiniteSeller} from '../../services/user-seller';
import {SearchHead} from '../spam/inputs';

export const UserSellerList = ({open, close, onSelect = seller => null}) => {
	const {onProcess, loading, data, res, query, hasMore, setQuery} = useInfiniteSeller(),
		observed = useRef(),
		lastItemFetchRef = useCallback(onHandleCallbackRefFetch, [loading, setQuery, hasMore]),
		_data = useMemo(() => {
			if(data.data.length === 0)
				return []
			return data.data.filter(el => el.approval === 'approved')
		}, [data.data])

	function onHandleCallbackRefFetch (node) {
		if(loading) return;
		if(observed.current) observed.current.disconnect();
		observed.current = new IntersectionObserver(async entries => {
			if(entries[0].isIntersecting) {
				if(hasMore)
					setQuery(prev => ({...prev, page: prev.page + 1}))
			}
		})

		if(node) observed.current.observe(node);
	}


	useEffect(() => {
		onProcess();
	}, [onProcess])
	return (
		<BasicModal open={open} onClose={close} title='Select seller'>
			<Grid item display='flex' justifyContent='space-between' width='100%'>
				<Typography variant='caption' color='blueviolet'>{data.count || '0'} Sellers</Typography>
				<SearchHead
					values={query.search || ''}
					placeholder='search seller here'
					onEnter={variable => setQuery(prev => ({...prev, page: 0, search: variable}))}/>
			</Grid>
			<TableContainer style={{width: 1200, height: 550}}>
				<Table stickyHeader style={{width: '100%'}}>
					<EnchancedTableHead
						order={query.sortBy || 'desc'}
						orderBy={query.orderBy || 'create_date'}
						hideCheckbox
						rowCount={data.data.length}
						headCells={[
							{id: 1, original: '', label: 'No'},
							{id: 2, original: '', label: 'Name'},
							{id: 3, original: '', label: 'Username'},
							{id: 4, original: '', label: 'Email'},
							{id: 5, original: '', label: 'Phone'},
							{id: 6, original: '', label: 'Action'}
						]}/>
					<TableBody>
						{_data.length > 0
							? _data.map((seller, index) => (
									<tr key={index} ref={index === _data.length - 1 ? lastItemFetchRef : undefined}>
										<TableCell>{index + 1}</TableCell>
										<TableCell>{seller.name}</TableCell>
										<TableCell>{seller.username || '-'}</TableCell>
										<TableCell>{seller.email || '-'}</TableCell>
										<TableCell>{seller.phone || '-'}</TableCell>
										<TableCell>
											<IconButton size='small' color='primary' variant='standard' onClick={() => onSelect(seller)}>
												<AddOutlined/>
											</IconButton>
										</TableCell>
									</tr> 
								))
							:	null}
					</TableBody>
				</Table>
				{loading
					?	<div className='center'>
							<CircularProgress size={25}/>
						</div>
					: res.message
						?	<Alert severity='warning'>
								{res.message}
							</Alert>
						: _data.length === 0
							?	<Alert severity='warning'>
									No Data
								</Alert> : null}
				
			</TableContainer>
		</BasicModal>
	)
}