import {
  Grid,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Button,
  IconButton,
  Alert,
  CircularProgress,
  Box,
  TextField,
} from "@mui/material";
import { Today, Settings } from "@mui/icons-material";
import { useEffect, useState, Fragment } from "react";
import { useListMonitoring } from "../services/transactions";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { BasicModal } from "../components/spam/modal";
import { useSearchParams } from "react-router-dom";
import dayjs from "dayjs";

export const DashboardPage = () => {
  const {
      loading,
      onProcess,
      updateOperationnal,
      res,
      data,
      query,
      push,
      operationals,
      setOperationals,
    } = useListMonitoring(),
    [open, setOpen] = useState(false),
    [openSetting, setOpenSetting] = useState(false),
    // [tempMonth, setTempMonth] = useState(new Date()),
    [searchParams] = useSearchParams(),
    params = Object.fromEntries([...searchParams]),
    [date, setDate] = useState(new Date(params.date));
  useEffect(() => {
    onProcess();
  }, [onProcess]);
  // useEffect(() => {}, [data]);

  return (
    <Fragment>
      {open ? (
        <BasicModal open onClose={() => null}>
          <Grid
            container
            display="flex"
            width="100%"
            justifyContent="center"
            alignItems="center"
          >
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              {/* <MonthPicker
                date={tempMonth}
                onChange={(e) => {
                  setTempMonth(e);
                }}
              /> */}

              <DatePicker
                views={["year", "month"]}
                label="Year and Month"
                minDate={dayjs("2022-01-01")}
                maxDate={dayjs("2030-12-31")}
                value={date}
                onChange={(newValue) => {
                  setDate(newValue);
                }}
                renderInput={(params) => (
                  <TextField {...params} helperText={null} />
                )}
              />
            </LocalizationProvider>
            <Fragment>
              <Grid
                container
                display="flex"
                width="50%"
                justifyContent="space-between"
                alignItems="center"
                marginTop={5}
              >
                <Button
                  variant="outlined"
                  color="error"
                  size="small"
                  title="Delete"
                  onClick={() => {
                    setDate(new Date(params.date));
                    setOpen(false);
                  }}
                >
                  cancel
                </Button>

                <Button
                  color="info"
                  variant="outlined"
                  size="small"
                  title="Confirm"
                  onClick={() => {
                    push({
                      ...query,
                      date: `${new Date(date).toLocaleString("en-US", {
                        month: "long",
                      })}-${new Date(date).getFullYear()}`,
                    });
                    setOpen(false);
                  }}
                >
                  Confirm
                </Button>
              </Grid>
            </Fragment>
          </Grid>
        </BasicModal>
      ) : null}
      {openSetting ? (
        <BasicModal open={openSetting} onClose={() => setOpenSetting(false)}>
          <Grid
            container
            display="flex"
            width="100%"
            justifyContent="center"
            alignItems="center"
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <TextField
                label="Admin Manual"
                variant="outlined"
                defaultValue={operationals.admin_manual}
                type="number"
                sx={{ mr: 1 }}
                InputProps={{ inputProps: { min: 0 } }}
                onChange={(e) => {
                  setOperationals({
                    ...operationals,
                    admin_manual: +e.target.value,
                  });
                }}
              />
              <TextField
                label="Admin Ekspedisi"
                variant="outlined"
                type="number"
                sx={{ mr: 1 }}
                defaultValue={operationals.admin_expedisi}
                InputProps={{ inputProps: { min: 0 } }}
                onChange={(e) => {
                  setOperationals({
                    ...operationals,
                    admin_expedisi: +e.target.value,
                  });
                }}
              />
              <TextField
                label="Quality Control"
                variant="outlined"
                type="number"
                sx={{ mr: 1 }}
                defaultValue={operationals.quality_control}
                InputProps={{ inputProps: { min: 0 } }}
                onChange={(e) => {
                  setOperationals({
                    ...operationals,
                    quality_control: +e.target.value,
                  });
                }}
              />
              <TextField
                label="Driver JKS"
                variant="outlined"
                type="number"
                defaultValue={operationals.driver_jks}
                InputProps={{ inputProps: { min: 0 } }}
                onChange={(e) => {
                  setOperationals({
                    ...operationals,
                    driver_jks: +e.target.value,
                  });
                }}
              />
            </Box>
            <Fragment>
              <Grid
                container
                display="flex"
                width="50%"
                justifyContent="space-between"
                alignItems="center"
                marginTop={5}
              >
                <Button
                  variant="outlined"
                  color="error"
                  size="small"
                  title="Delete"
                  onClick={() => {
                    setOpenSetting(false);
                  }}
                >
                  cancel
                </Button>

                <Button
                  color="info"
                  variant="outlined"
                  size="small"
                  title="Confirm"
                  onClick={async () => {
                    await updateOperationnal(operationals);
                    setOpenSetting(false);
                    await onProcess();
                  }}
                >
                  Confirm
                </Button>
              </Grid>
            </Fragment>
          </Grid>
        </BasicModal>
      ) : null}
      <Grid
        marginLeft={5}
        marginRight={5}
        marginBottom={5}
        marginTop={2}
        sx={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <IconButton
          title="View Detail"
          size="small"
          color="primary"
          onClick={() => setOpen(true)}
        >
          <Today />
          Choose Date :{" "}
          {new Date(params.date).toLocaleString("en-US", {
            month: "long",
          })}{" "}
          {new Date(params.date).getFullYear()}
        </IconButton>
        {!loading ? (
          <IconButton
            title="View Detail"
            size="small"
            color="primary"
            onClick={() => setOpenSetting(true)}
          >
            <Settings />
          </IconButton>
        ) : null}
      </Grid>
      <TableContainer style={{ minHeight: "50vh", maxHeight: "80vh" }}>
        <Table style={{ width: "100%" }} stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>TANGGAL</TableCell>
              <TableCell>JNE</TableCell>
              <TableCell>JNT</TableCell>
              <TableCell>ANTER AJA</TableCell>
              <TableCell>NINJA</TableCell>
              <TableCell>ID</TableCell>
              <TableCell>LION</TableCell>
              <TableCell>SI CEPAT</TableCell>
              <TableCell>SHOPEE</TableCell>
              <TableCell>LEX</TableCell>
              <TableCell>WAHANA</TableCell>
              <TableCell>OTHER</TableCell>
              <TableCell>TOTAL PAKET</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.keys(data).length >= 1
              ? Object.keys(data).map((order, index) => (
                  <TableRow key={index} hover>
                    <TableCell>{order}</TableCell>
                    <TableCell>
                      {data[order]["jne"] ? data[order]["jne"] : "-"}
                    </TableCell>
                    <TableCell>
                      {data[order]["jnt"] ? data[order]["jnt"] : "-"}
                    </TableCell>
                    <TableCell>
                      {data[order]["anteraja"] ? data[order]["anteraja"] : "-"}
                    </TableCell>
                    <TableCell>
                      {data[order]["ninja express"]
                        ? data[order]["ninja express"]
                        : "-"}
                    </TableCell>
                    <TableCell>
                      {data[order]["id express"]
                        ? data[order]["id express"]
                        : "-"}
                    </TableCell>
                    <TableCell>
                      {data[order]["lion"] ? data[order]["lion"] : "-"}
                    </TableCell>
                    <TableCell>
                      {data[order]["sicepat"] ? data[order]["sicepat"] : "-"}
                    </TableCell>
                    <TableCell>
                      {data[order]["shopee"] ? data[order]["shopee"] : "-"}
                    </TableCell>
                    <TableCell>
                      {data[order]["lex"] ? data[order]["lex"] : "-"}
                    </TableCell>
                    <TableCell>
                      {data[order]["wahana"] ? data[order]["wahana"] : "-"}
                    </TableCell>
                    <TableCell>
                      {data[order]["other"] ? data[order]["other"] : "-"}
                    </TableCell>
                    <TableCell>
                      {data[order]["total"] ? data[order]["total"] : "-"}
                    </TableCell>
                  </TableRow>
                ))
              : null}
          </TableBody>
        </Table>

        {loading ? (
          <div className="center">
            <CircularProgress size={25} />
          </div>
        ) : res.message ? (
          <Alert variant="standard" severity="error">
            {res.message}
          </Alert>
        ) : Object.keys(data).length < 1 ? (
          <Alert variant="standard" severity="warning">
            No Data
          </Alert>
        ) : null}
      </TableContainer>
    </Fragment>
  );
};
