import {Fragment} from 'react';
import {useNavigate, useLocation} from 'react-router-dom';
import {
	ButtonGroup,
	Button,
	Grid
} from '@mui/material';
import {
	ArrowLeftOutlined,
	Person,
	SupervisedUserCircleOutlined,
} from '@mui/icons-material';
import {Outlet} from 'react-router-dom';



export const MyOutlet = () => {
	const push = useNavigate(),
		{state} = useLocation();

	return (
		<Fragment>
			<Grid item width='100%' display='flex' alignItems='center'>
				<ButtonGroup variant='text' size='large'>
					<Button startIcon={<ArrowLeftOutlined/>} color='info' onClick={() => push(state?.from ? -1 : '/dashboard')}>Back</Button>
					<Button endIcon={<Person/>}>Profile</Button>
					<Button endIcon={<SupervisedUserCircleOutlined/>}>Privilege</Button>
				</ButtonGroup>
			</Grid>

			<Grid item width='100%' display='flex' flexDirection='column'>
				<Outlet/>
			</Grid>
		</Fragment>
	)
}

export * from './privileges';