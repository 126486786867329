import { useEffect, Fragment } from "react";
import {
  Grid,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TextField,
  Button,
  TableCell,
  CircularProgress,
  Alert,
  Tooltip,
} from "@mui/material";
import { useGetCashback, useUpdateDefaultCashback } from "../services/cashback";

export const CashbackPage = () => {
  const { onProcess, loading, res, data, updateState } = useGetCashback(),
    {
      onProcess: updateSellerCashback,
      loading: loadingUpdate,
      res: resUpdate,
    } = useUpdateDefaultCashback(),
    onChangeValue =
      (index) =>
      ({ target: { name, value } }) => {
        let _value = value;
        if (!value || isNaN(Number(value))) _value = "";

        updateState({ index, key: name, value: _value });
      };

  function onUpdate() {
    const newData = data.map((el) => ({
      ...el,
      total: parseFloat(el.total || "0"),
      Fix: parseFloat(el.Rate || "0"),
      Rate: parseFloat(el.Fix || "0"),
    }));

    updateSellerCashback(newData);
  }

  useEffect(() => {
    onProcess();
  }, [onProcess]);

  return (
    <Fragment>
      {/* <Grid item width='100%' justifyContent='space-betwee' display='flex'>
			</Grid> */}

      <TableContainer style={{ maxHeight: "80vh" }}>
        <Table style={{ width: "100%" }} stickyHeader>
          <TableHead>
            <TableRow>
              <Tooltip title="Number">
                <TableCell>No</TableCell>
              </Tooltip>
              <Tooltip title="Nama Ekspedisi">
                <TableCell>Expedisi Name</TableCell>
              </Tooltip>
              <Tooltip title="Tipe Ekspedisi">
                <TableCell>Expedisi Service</TableCell>
              </Tooltip>
              <Tooltip title="Kode Awb">
                <TableCell>Awb Code</TableCell>
              </Tooltip>
              <Tooltip title="Detail">
                <TableCell>Note</TableCell>
              </Tooltip>
              <TableCell>Target</TableCell>
              <TableCell>Cashback Fix</TableCell>
              <TableCell>Cashback Rate</TableCell>
              <TableCell>Discount Fix</TableCell>
              <TableCell>Discount Rate</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.length > 0
              ? data.map((item, index) => (
                  <ItemSellerCashback
                    onChange={onChangeValue}
                    key={index}
                    index={index}
                    item={item}
                  />
                ))
              : null}
          </TableBody>
        </Table>
      </TableContainer>
      {loading ? (
        <div className="center">
          <CircularProgress size={25} />
        </div>
      ) : res.message ? (
        <Alert severity="error">{res.message}</Alert>
      ) : data.length === 0 ? (
        <Alert severity="warning">No Data</Alert>
      ) : null}

      <Grid
        item
        style={{ marginTop: 20 }}
        width="100%"
        display="flex"
        flexDirection="column"
        alignItems="flex-end"
      >
        {resUpdate.message ? (
          <Alert severity={resUpdate.status === "ok" ? "success" : "error"}>
            {resUpdate.message}
          </Alert>
        ) : null}
        {loadingUpdate ? (
          <CircularProgress size={35} />
        ) : !loading ? (
          <Button variant="outlined" color="primary" onClick={onUpdate}>
            Update
          </Button>
        ) : null}
      </Grid>
    </Fragment>
  );
};

export const ItemSellerCashback = ({
  item,
  index,
  onChange = (index) => (event) => null,
  actionComponent = undefined,
  readOnly,
}) => (
  <TableRow hover>
    <TableCell>{index + 1}</TableCell>
    <TableCell>{item.threePL || "-"}</TableCell>
    <TableCell>{item.service || "-"}</TableCell>
    <TableCell>{item.kodeAWB || "-"}</TableCell>
    <TableCell>{item.keterangan || "-"}</TableCell>
    <TableCell>
      {readOnly ? (
        item.Fix || "0"
      ) : (
        <TextField
          size="small"
          placeholder="eg: 100"
          variant="standard"
          name="Fix"
          color="primary"
          style={{ width: 80 }}
          value={item.Fix ? item.Fix.toString() : ""}
          onChange={onChange(index)}
        />
      )}
    </TableCell>
    <TableCell>
      {readOnly ? (
        `${item.Rate}%` || "0%"
      ) : (
        <TextField
          size="small"
          placeholder="eg: 2"
          variant="standard"
          name="Rate"
          color="primary"
          style={{ width: 80 }}
          value={item.Rate ? item.Rate.toString() : ""}
          onChange={onChange(index)}
        />
      )}
    </TableCell>
    {actionComponent !== undefined ? actionComponent : null}
  </TableRow>
);
