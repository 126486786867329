import { useState, useContext, useCallback, useMemo } from "react";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import x from "axios";
import gtw from "../apis/gtw";
import { AuthContext } from "../utils/auth";
import { handleError, useCancelRequest } from "./helpers";
import { convertDataToQuery, getQueryUrl } from "../helpers/custom";

export const useListUserSeller = () => {
  const [loading, setLoading] = useState(false),
    [request, setRequest] = useState(null),
    [res, setRes] = useState({ status: "", message: "" }),
    { headers } = useContext(AuthContext),
    navigate = useNavigate(),
    [data, setData] = useState({ count: 0, data: [] }),
    { search } = useLocation(),
    onProcess = useCallback(async () => {
      setLoading(true);
      setRes({ status: "", message: "" });
      setData((prev) => ({ ...prev, data: [] }));
      const cancel = await (async () => {
        try {
          const _req = x.CancelToken.source();
          setRequest(_req);
          const { data: _data } = await gtw({
            method: "get",
            url: `/user-seller${search}`,
            headers,
            cancelToken: _req.token,
          });
          setData(_data);
        } catch (e) {
          const message = handleError(e);
          if (message.cancel) return true;
          setRes({ status: "error", message });
        }
      })();
      if (cancel) return;
      setLoading(false);
    }, [headers, search]),
    push = (payload) => navigate(`/seller${convertDataToQuery(payload)}`),
    query = useMemo(() => {
      if (!search) return null;
      return getQueryUrl(search);
    }, [search]);

  useCancelRequest(request);
  return { onProcess, loading, query, res, data, push };
};

export const useInfiniteSeller = () => {
  const [loading, setLoading] = useState(false),
    [request, setRequest] = useState(null),
    { headers } = useContext(AuthContext),
    [query, setQuery] = useState({ page: 0, perPage: 10 }),
    [data, setData] = useState({ count: 0, data: [] }),
    [res, setRes] = useState({ status: "", message: "" }),
    onProcess = useCallback(
      async function () {
        setLoading(true);
        setRes({ status: "", message: "" });
        const cancel = await (async function () {
          try {
            const _req = x.CancelToken.source();
            setRequest(_req);
            const { data: _data } = await gtw({
              method: "get",
              url: `/user-seller${convertDataToQuery(query)}`,
              headers,
              cancelToken: _req.token,
            });

            if (_data.data.length > 0) {
              if (query.page === 0)
                return setData({
                  ..._data,
                  data: _data.data.map((el) => ({ ...el, isChecked: false })),
                });

              return setData((prev) => ({
                ...prev,
                data: [
                  ...prev.data,
                  ..._data.data.map((el) => ({ ...el, isChecked: false })),
                ],
              }));
            }
          } catch (e) {
            const message = handleError(e);
            if (message.cancel) return true;
            setRes({ status: "error", message });
          }
        })();
        if (cancel) return;
        setLoading(false);
      },
      [headers, query]
    ),
    hasMore = useMemo(() => {
      const _count = Math.floor(data.count / query.perPage);
      return query.page < _count;
    }, [query.page, query.perPage, data.count]),
    selectItems = useMemo(() => {
      if (data.data.length === 0) return [];
      return data.data.filter((el) => el.isChecked);
    }, [data.data]);

  useCancelRequest(request);
  return {
    onProcess,
    loading,
    hasMore,
    query,
    selectItems,
    setQuery,
    res,
    data,
  };
};

export const useSellerAlias = () => {
  const [loading, setLoading] = useState(false),
    [request, setRequest] = useState(null),
    [res, setRes] = useState({ status: "", message: "" }),
    { headers } = useContext(AuthContext),
    { sellerId } = useParams(),
    navigate = useNavigate(),
    [data, setData] = useState({ count: 0, data: [] }),
    { search } = useLocation(),
    onProcess = useCallback(async () => {
      setLoading(true);
      setRes({ status: "", message: "" });
      setData((prev) => ({ ...prev, data: [] }));
      const cancel = await (async () => {
        try {
          const _req = x.CancelToken.source();
          setRequest(_req);
          const { data: _data } = await gtw({
            method: "get",
            url: `/user-seller/alias/${sellerId}${search}`,
            headers,
            cancelToken: _req.token,
          });
          setData({
            count: _data.count || 0,
            data:
              _data.data.length > 0
                ? _data.data.map((el) => ({ ...el, isChecked: false }))
                : [],
          });
        } catch (e) {
          const message = handleError(e);
          if (message.cancel) return true;
          setRes({ status: "error", message });
        }
      })();
      if (cancel) return;
      setLoading(false);
    }, [headers, search, sellerId]),
    push = (payload) =>
      navigate(`/seller/${sellerId}/alias${convertDataToQuery(payload)}`),
    query = useMemo(() => {
      if (!search) return null;
      return getQueryUrl(search);
    }, [search]),
    updateOne = ({ id, key, value }) =>
      setData((prev) => ({
        ...prev,
        data: prev.data.map((item) => {
          if (item.id === id) return { ...item, [key]: value };
          return item;
        }),
      })),
    updateAll = ({ key, value }) =>
      setData((prev) => ({
        ...prev,
        data: prev.data.map((item) => ({ ...item, [key]: value })),
      })),
    selectionItem = useMemo(() => {
      if (data.data.length > 0) return data.data.filter((el) => el.isChecked);
      return [];
    }, [data.data]);

  useCancelRequest(request);
  return {
    onProcess,
    loading,
    query,
    res,
    data,
    push,
    updateOne,
    updateAll,
    selectionItem,
  };
};

export const useAddSellerAlias = () => {
  const [loading, setLoading] = useState(false),
    [request, setRequest] = useState(null),
    [res, setRes] = useState({ status: "", message: "" }),
    { headers } = useContext(AuthContext),
    { sellerId } = useParams(),
    onProcess = async (alias, refresh = (_) => null) => {
      setLoading(true);
      setRes({ status: "", message: "" });
      const cancel = await (async () => {
        try {
          const _req = x.CancelToken.source();
          setRequest(_req);
          const { data } = await gtw({
            method: "post",
            url: `/user-seller/alias/${sellerId}`,
            data: { alias },
            headers,
            cancelToken: _req.token,
          });
          if (data && data.data) {
            setRes({ status: "ok", message: `Success add alias name` });
            refresh();
            return;
          }
          setRes({ status: "error", message: "server is not responding" });
        } catch (e) {
          const message = handleError(e);
          if (message.cancel) return true;
          setRes({ status: "error", message });
        }
      })();
      if (cancel) return;
      setLoading(false);
    };
  useCancelRequest(request);
  return { onProcess, loading, res };
};

export const useActionAlias = () => {
  const [loading, setLoading] = useState(false),
    [request, setRequest] = useState(null),
    [res, setRes] = useState({ status: "", message: "" }),
    { headers } = useContext(AuthContext),
    onProcess = async (
      { aliasId, method },
      data = {},
      refresh = (_) => null
    ) => {
      setLoading(true);
      setRes({ status: "", message: "" });
      const cancel = await (async () => {
        try {
          const _req = x.CancelToken.source();
          setRequest(_req);
          const { data: _data } = await gtw({
            method,
            url: `/user-seller/alias/${aliasId}`,
            data,
            headers,
            cancelToken: _req.token,
          });

          if (_data && _data.message) {
            setRes({ status: "ok", message: _data.message });
            return refresh();
          }

          setRes({ status: "error", message: "server is not responding" });
        } catch (e) {
          const message = handleError(e);
          if (message.cancel) return true;
          setRes({ status: "error", message });
        }
      })();
      if (cancel) return;
      setLoading(false);
    };
  useCancelRequest(request);
  return { onProcess, loading, res };
};

export const useDetailUserSellerr = () => {
  const [loading, setLoading] = useState(false),
    [request, setRequest] = useState(null),
    [res, setRes] = useState({ status: "", message: "" }),
    [data, setData] = useState(null),
    { sellerId } = useParams(),
    { headers } = useContext(AuthContext),
    onProcess = useCallback(async () => {
      setLoading(true);
      setRes({ status: "", message: "" });
      setData(null);
      const cancel = await (async () => {
        try {
          const _req = x.CancelToken.source();
          setRequest(_req);
          const { data: _data } = await gtw({
            method: "get",
            url: `/user-seller/${sellerId}`,
            headers,
            cancelToken: _req.token,
          });
          setData(_data.data || null);
        } catch (e) {
          const message = handleError(e);
          if (message.cancel) return true;
          setRes({ status: "error", message });
        }
      })();
      if (cancel) return;
      setLoading(false);
    }, [headers, sellerId]);
  useCancelRequest(request);
  return { onProcess, loading, data, res };
};

export const useRegisterSeller = () => {
  const [request, setRequest] = useState(null),
    { headers } = useContext(AuthContext),
    onProcess = async (payload) => {
      return new Promise((resolve, reject) => {
        setTimeout(async () => {
          try {
            const _req = x.CancelToken.source();
            setRequest(_req);
            let data = { ...payload };
            if (data.id) delete data.id;
            const { data: _data } = await gtw({
              method: "post",
              url: `/user-seller/register`,
              data,
              headers,
              cancelToken: _req.token,
            });
            if (_data.payload && _data.payload.id)
              return resolve({
                sellerId: _data.payload.id,
                message: _data.message || "Register Berhasil",
              });

            reject("server is not responding");
          } catch (e) {
            const message = handleError(e);
            reject(message);
          }
        }, 500);
      });
    };
  useCancelRequest(request);
  return { onProcess };
};

export const useLoopAddAlias = () => {
  const [request, setRequest] = useState(null),
    { headers } = useContext(AuthContext),
    onProcess = async ({ sellerId, alias }) => {
      return new Promise((resolve, reject) => {
        setTimeout(async () => {
          try {
            const _req = x.CancelToken.source();
            setRequest(_req);
            const { data } = await gtw({
              method: "post",
              url: `/user-seller/alias/${sellerId}`,
              data: { alias },
              headers,
              cancelToken: _req.token,
            });
            if (data && data.data) return resolve(true);

            reject("server is not responding");
          } catch (e) {
            const message = handleError(e);
            if (message.cancel) return true;
            reject(message);
          }
        }, 250);
      });
    };
  useCancelRequest(request);
  return { onProcess };
};

export const useSingle = () => {
  const [request, setRequest] = useState(null),
    { headers } = useContext(AuthContext),
    onProcess = async (identity) => {
      return new Promise(async (resolve, reject) => {
        try {
          const _req = x.CancelToken.source();
          setRequest(_req);
          const { data } = await gtw({
            method: "get",
            url: `/user-seller/single/name?name=${encodeURIComponent(
              identity.replace(/&/g, "_AND_")
            )}`,
            headers,
            cancelToken: _req.token,
          });
          if (data) return resolve(data);
          reject("tidak dapat respon dari server");
        } catch (e) {
          const message = handleError(e);
          if (message.cancel) return reject("cancel request");
          reject(message);
        }
      });
    };
  useCancelRequest(request);

  return { onProcess };
};

//sbd

export const useListUserSellerSbd = () => {
  const [loading, setLoading] = useState(false),
    [request, setRequest] = useState(null),
    [res, setRes] = useState({ status: "", message: "" }),
    { headers } = useContext(AuthContext),
    navigate = useNavigate(),
    [data, setData] = useState({ count: 0, data: [] }),
    { search } = useLocation(),
    onProcess = useCallback(async () => {
      setLoading(true);
      setRes({ status: "", message: "" });
      setData((prev) => ({ ...prev, data: [] }));
      const cancel = await (async () => {
        try {
          const _req = x.CancelToken.source();
          setRequest(_req);
          const { data: _data } = await gtw({
            method: "get",
            url: `/user-seller/sbd/getallseller${
              search ? search : "?page=0&perPage=1000"
            }`,
            headers,
            cancelToken: _req.token,
          });
          setData(_data);
        } catch (e) {
          const message = handleError(e);
          if (message.cancel) return true;
          setRes({ status: "error", message });
        }
      })();
      if (cancel) return;
      setLoading(false);
    }, [headers, search]),
    push = (payload) =>
      navigate(`/seller/bigdelivery/app${convertDataToQuery(payload)}`),
    query = useMemo(() => {
      if (!search) return null;
      return getQueryUrl(search);
    }, [search]);

  useCancelRequest(request);
  return { onProcess, loading, query, res, data, push, setRes };
};

export const useListUserSellerOne = () => {
  const [loadingInternal, setLoadingInternal] = useState(false),
    [value, setValue] = useState(""),
    [resInternal, setResInternal] = useState({ status: "", message: "" }),
    { headers } = useContext(AuthContext),
    [dataSellerInternal, setDataSellerInternal] = useState({
      data: [],
    }),
    [statusFind, setStatusFind] = useState(false),
    onProcessFind = useCallback(async () => {
      if (value) {
        setLoadingInternal(true);
        setResInternal({ status: "", message: "" });
        setDataSellerInternal((prev) => ({ ...prev, data: [] }));
        try {
          const { data: _data } = await gtw({
            method: "get",
            url: `/user-seller?search=${value}`,
            headers,
          });
          setStatusFind(true);
          setDataSellerInternal(_data);
        } catch (e) {
          const message = handleError(e);
          setResInternal({ status: "error", message });
        }
        setLoadingInternal(false);
      }
    });

  return {
    onProcessFind,
    value,
    setValue,
    dataSellerInternal,
    setDataSellerInternal,
    loadingInternal,
    resInternal,
    statusFind,
    setStatusFind,
  };
};

export const useUserInternalId = () => {
  const [loadingInternal, setLoadingInternal] = useState(false);
  const [loadingStore, setLoadingStore] = useState(false);
  const [dataUpdate, setDataUpdate] = useState({});
  const [dataInternal, setDataInternal] = useState({
    shopName: "",
    userInternalId: [],
  });
  const [resInternalConfirm, setResInternalConfirm] = useState({
    status: "",
    message: "",
  });
  const { headers } = useContext(AuthContext);

  const onProcessUpdate = async (data = []) => {
    let newData = "";
    let temp = data.length ? data : dataInternal.userInternalId;
    temp.forEach((e, i) => {
      if (data.length) {
        if (data.length - 1 !== i) newData += e.id + ",";
        else newData += e.id;
      } else {
        newData += e.id + ",";
      }
    });
    if (Object.keys(dataUpdate).length) {
      newData += `${+dataUpdate.id}`;
    }
    setResInternalConfirm({ status: "", message: "" });
    setDataUpdate({});

    const { data: _data } = await gtw({
      method: "patch",
      url: `/user-seller/sbd/update/shop/idinternal`,
      data: {
        id: dataInternal.id,
        idInternal: newData,
      },
      headers,
    });

    // setLoadingInternal(false);
  };

  const onProcessGet = async (id) => {
    setLoadingStore(true);
    const { data: _data } = await gtw({
      method: "get",
      url: `/user-seller/sbd/shop/idinternal/${id}`,
      headers,
    });
    setDataInternal(_data);
    setLoadingStore(false);
    // setLoadingInternal(false);
  };

  return {
    onProcessUpdate,
    setDataUpdate,
    dataUpdate,
    setResInternalConfirm,
    resInternalConfirm,
    onProcessGet,
    dataInternal,
    setDataInternal,
    loadingStore,
  };
};

export const useListUserDropperSbd = () => {
  const [loading, setLoading] = useState(false),
    [request, setRequest] = useState(null),
    [res, setRes] = useState({ status: "", message: "" }),
    { headers } = useContext(AuthContext),
    navigate = useNavigate(),
    [data, setData] = useState({ count: 0, rows: [] }),
    { search } = useLocation(),
    onProcess = useCallback(async () => {
      setLoading(true);
      setRes({ status: "", message: "" });
      setData((prev) => ({ ...prev, data: [] }));
      const cancel = await (async () => {
        try {
          const _req = x.CancelToken.source();
          setRequest(_req);
          const { data: _data } = await gtw({
            method: "get",
            url: `/user-seller/sbd/getalldropper${search}`,
            headers,
            cancelToken: _req.token,
          });
          setData(_data);
        } catch (e) {
          const message = handleError(e);
          if (message.cancel) return true;
          setRes({ status: "error", message });
        }
      })();
      if (cancel) return;
      setLoading(false);
    }, [headers, search]),
    push = (payload) =>
      navigate(`/seller/bigdelivery/app${convertDataToQuery(payload)}`),
    query = useMemo(() => {
      if (!search) return null;
      return getQueryUrl(search);
    }, [search]);

  useCancelRequest(request);
  return { onProcess, loading, query, res, data, push, setRes };
};

export const useCreateDropper = () => {
  const [request, setRequest] = useState(null),
    { headers } = useContext(AuthContext),
    [loading, setLoading] = useState(false),
    [openSnack, setOpenSnack] = useState(false),
    [res, setRes] = useState({ status: "", message: "" }),
    onProcess = async (payload) => {
      return new Promise((resolve, reject) => {
        setTimeout(async () => {
          try {
            setLoading(true);
            const _req = x.CancelToken.source();
            setRequest(_req);
            let data = { ...payload };
            if (data.id) delete data.id;
            const { data: _data } = await gtw({
              method: "post",
              url: `/user-seller/sbd/createDropper`,
              data,
              headers,
              cancelToken: _req.token,
            });
            if (_data.status === 201) {
              setRes({
                status: "success",
                message: `Dropper ${data.username} berhasil didaftarkan`,
              });
              setOpenSnack(true);
              setLoading(false);
            }
            reject("server is not responding");
          } catch (e) {
            const message = handleError(e);
            if (message.alamat) {
              setRes({ status: "error", message: message.alamat });
              setOpenSnack(true);
            } else if (message.email) {
              setRes({ status: "error", message: message.email });
              setOpenSnack(true);
            } else if (message.username) {
              setRes({ status: "error", message: message.username });
              setOpenSnack(true);
            } else if (message.phone) {
              setRes({ status: "error", message: message.phone });
              setOpenSnack(true);
            }
            reject(message);
          }
        }, 500);
      });
    };
  useCancelRequest(request);
  return { onProcess, loading, res, setRes, openSnack, setOpenSnack };
};

export const useGetAllSellerSbd = () => {
  const [loading, setLoading] = useState(false),
    [request, setRequest] = useState(null),
    [res, setRes] = useState({ status: "", message: "" }),
    { headers } = useContext(AuthContext),
    navigate = useNavigate(),
    [data, setData] = useState({ count: 0, data: [] }),
    { search } = useLocation(),
    onProcess = useCallback(async () => {
      setLoading(true);
      setRes({ status: "", message: "" });
      setData((prev) => ({ ...prev, data: [] }));
      const cancel = await (async () => {
        try {
          const _req = x.CancelToken.source();
          setRequest(_req);
          const { data: _data } = await gtw({
            method: "get",
            url: `/user-seller/sbd/getallsellersbd${
              search ? search : "?page=0&perPage=1000"
            }`,
            headers,
            cancelToken: _req.token,
          });
          setData(_data);
        } catch (e) {
          const message = handleError(e);
          if (message.cancel) return true;
          setRes({ status: "error", message });
        }
      })();
      if (cancel) return;
      setLoading(false);
    }, [headers, search]),
    push = (payload) =>
      navigate(`/seller/bigdelivery/app${convertDataToQuery(payload)}`),
    query = useMemo(() => {
      if (!search) return null;
      return getQueryUrl(search);
    }, [search]);

  useCancelRequest(request);
  return { onProcess, loading, query, res, data, push, setRes };
};

export const useUpdateSellerDropper = () => {
  const [request, setRequest] = useState(null),
    { headers } = useContext(AuthContext),
    [loading, setLoading] = useState(false),
    [openSnack, setOpenSnack] = useState(false),
    [res, setRes] = useState({ status: "", message: "" }),
    onProcess = async (payload, dropperId) => {
      return new Promise((resolve, reject) => {
        setTimeout(async () => {
          try {
            setLoading(true);
            const _req = x.CancelToken.source();
            setRequest(_req);
            let data = { ...payload };
            if (data.id) delete data.id;
            console.log(data);
            const { data: _data } = await gtw({
              method: "patch",
              url: `/user-seller/sbd/update/dropper/${dropperId}`,
              data,
              headers,
              cancelToken: _req.token,
            });
            if (_data.status === 201) {
              setRes({
                status: "ok",
                message: `${_data.message}`,
              });
              setOpenSnack(true);
              setLoading(false);
            }
            reject("server is not responding");
          } catch (e) {
            const message = handleError(e);
            if (message.message) {
              setRes({ status: "error", message: message.message });
              setOpenSnack(true);
            }
            reject(message);
          }
        }, 500);
      });
    };
  useCancelRequest(request);
  return { onProcess, loading, res, setRes, openSnack, setOpenSnack };
};
