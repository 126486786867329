import { useCallback, useContext, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { AuthContext } from "../utils/auth";
import gtw from "../apis/gtw";
import axios from "axios";
import { handleError, useCancelRequest } from "./helpers";
import { convertDataToQuery, getQueryUrl } from "../helpers/custom";

export const useListReportDelivery = () => {
  const [loading, setLoading] = useState(false);
  const [request, setRequest] = useState(null);
  const [res, setRes] = useState({ status: "", message: "" });
  const { headers } = useContext(AuthContext);
  const navigate = useNavigate();
  const [data, setData] = useState({ count: 0, data: [] });
  const { search } = useLocation();

  const onProcess = useCallback(async () => {
    setLoading(true);
    setRes({ status: "", message: "" });
    setData((prev) => ({ ...prev, data: [] }));
    const cancel = await (async () => {
      try {
        const _req = axios.CancelToken.source();
        setRequest(_req);
        const { data: _data } = await gtw({
          method: "get",
          url: `/delivery/report${search}`,
          headers,
          cancelToken: _req.token,
        });
        setData(_data);
      } catch (e) {
        const message = handleError(e);
        if (message.cancel) return true;
        setRes({ status: "error", message });
      }
    })();
    if (cancel) return;
    setLoading(false);
  }, [search, headers]);

  const push = (payload) => navigate(`/delivery${convertDataToQuery(payload)}`);

  const query = useMemo(() => {
    if (!search) return null;
    return getQueryUrl(search);
  }, [search]);

  useCancelRequest(request);
  return { onProcess, loading, query, res, data, push };
};

export const useReportsUpload = () => {
  const { headers } = useContext(AuthContext);
  const [request, setRequest] = useState(null);
  const onProcess = (data) => {
    return new Promise((resolve, reject) => {
      setTimeout(async () => {
        try {
          const _req = axios.CancelToken.source();
          setRequest(_req);
          const { data: _data } = await gtw({
            method: "post",
            url: `/delivery/report?new=true`,
            data,
            headers,
            cancelToken: _req.token,
          });

          if (_data) return resolve(_data);

          reject("server is not responding");
        } catch (e) {
          const message = handleError(e);
          if (message.cancel) return;

          reject(message);
        }
      }, 500);
    });
  };

  const onUpdate = (data) => {
    return new Promise((resolve, reject) => {
      setTimeout(async () => {
        try {
          const _req = axios.CancelToken.source();
          setRequest(_req);
          const { data: _data } = await gtw({
            method: "post",
            url: `/delivery/report`,
            data,
            headers,
            cancelToken: _req.token,
          });

          if (_data) return resolve(_data);

          reject("server is not responding");
        } catch (e) {
          const message = handleError(e);
          if (message.cancel) return;

          reject(message);
        }
      }, 500);
    });
  };
  useCancelRequest(request);
  return { onProcess, onUpdate };
};
