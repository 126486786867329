import { useState, useContext, useCallback, useMemo } from "react";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import x from "axios";
import gtw from "../apis/gtw";
import { AuthContext } from "../utils/auth";
import { handleError, useCancelRequest } from "./helpers";
import { convertDataToQuery, getQueryUrl } from "../helpers/custom";
import writeXlsxFile from "write-excel-file";

export const useGetCashback = () => {
  const [loading, setLoading] = useState(false),
    [request, setRequest] = useState(null),
    [res, setRes] = useState({ status: "", message: "" }),
    [data, setData] = useState([]),
    { headers } = useContext(AuthContext),
    onProcess = useCallback(async () => {
      setLoading(true);
      const cancel = await (async function () {
        try {
          setRes({ status: "", message: "" });
          setData([]);
          const _req = x.CancelToken.source();
          setRequest(_req);
          const { data } = await gtw({
            method: "get",
            url: `/dccb`,
            headers,
            cancelToken: _req.token,
          });

          if (
            data &&
            data.data &&
            data.data.dataAwb &&
            data.data.dataAwb.length
          )
            setData(data.data.dataAwb);
        } catch (e) {
          const message = handleError(e);
          if (message.cancel) return true;
          setRes({ status: "error", message });
        }
      })();
      if (cancel) return;
      setLoading(false);
    }, [headers]),
    updateState = ({ index, key, value }) =>
      setData((prev) =>
        prev.map((item, _index) => {
          if (_index === index) return { ...item, [key]: value };
          return item;
        })
      );

  useCancelRequest(request);
  return { onProcess, loading, res, data, updateState };
};

export const useUpdateDefaultCashback = () => {
  const [loading, setLoading] = useState(false),
    [res, setRes] = useState({ status: "", message: "" }),
    [request, setRequest] = useState(null),
    { headers } = useContext(AuthContext),
    onProcess = async (dataAwb = "") => {
      setLoading(true);
      const cancel = await (async function () {
        try {
          setRes({ status: "", message: "" });
          const _req = x.CancelToken.source();
          setRequest(_req);
          const { data } = await gtw({
            method: "patch",
            url: "/dccb",
            headers,
            data: { dataAwb },
          });
          if (data.message)
            return setRes({ status: "ok", message: data.message });

          setRes({ status: "error", message: "server is not responding" });
        } catch (e) {
          const message = handleError(e);
          if (message.cancel) return true;
          setRes({ status: "error", message });
        }
      })();
      if (cancel) return;
      setLoading(false);
    };

  useCancelRequest(request);
  return { onProcess, loading, res };
};

export const useListSellerCashback = () => {
  const [loading, setLoading] = useState(false),
    [request, setRequest] = useState(null),
    [res, setRes] = useState({ status: "", message: "" }),
    { headers } = useContext(AuthContext),
    navigate = useNavigate(),
    [data, setData] = useState({ count: 0, data: [] }),
    { search } = useLocation(),
    onProcess = useCallback(async () => {
      setLoading(true);
      setRes({ status: "", message: "" });
      setData((prev) => ({ ...prev, data: [] }));
      const cancel = await (async function () {
        try {
          const _req = x.CancelToken.source();
          setRequest(_req);
          const { data: _data } = await gtw({
            method: "get",
            url: `/dccb/seller${search}`,
            headers,
            cancelToken: _req.token,
          });

          if (_data.data.length === 0) return setData(_data);
          setData({
            count: _data.count,
            data: _data.data.map((el) => ({ ...el, isChecked: false })),
          });
        } catch (e) {
          const message = handleError(e);
          if (message.cancel) return true;
          setRes({ status: "error", message });
        }
      })();
      if (cancel) return;
      setLoading(false);
    }, [headers, search]),
    push = (payload) => navigate(`/s-cshback${convertDataToQuery(payload)}`),
    query = useMemo(() => {
      if (!search) return null;
      return getQueryUrl(search);
    }, [search]),
    selectItems = useMemo(() => {
      if (data.data.length === 0) return [];
      return data.data.filter((el) => el.isChecked);
    }, [data]),
    updateOne = ({ id, key, value, payload = undefined }) =>
      setData((prev) => ({
        ...prev,
        data: prev.data.map((el) => {
          if (el.id === id)
            return payload !== undefined
              ? { ...el, ...payload }
              : { ...el, [key]: value };
          return el;
        }),
      })),
    updateAll = ({ key, value, payload = undefined }) =>
      setData((prev) => ({
        ...prev,
        data: prev.data.map((el) =>
          payload !== undefined
            ? { ...el, ...payload }
            : { ...el, [key]: value }
        ),
      })),
    downloadTemplate = async () => {
      try {
        const header = [
          {
            value: "ID",
            fontWeight: "bold",
          },
          {
            value: "Seller",
            fontWeight: "bold",
          },
          {
            value: "JTR",
            fontWeight: "bold",
          },
          {
            value: "REG",
            fontWeight: "bold",
          },
          {
            value: "Cashless",
            fontWeight: "bold",
          },
          {
            value: "Shopee",
            fontWeight: "bold",
          },
          {
            value: "Non Shopee",
            fontWeight: "bold",
          },
          {
            value: "Tiktok",
            fontWeight: "bold",
          },
          {
            value: "ID Express",
            fontWeight: "bold",
          },
          {
            value: "Anteraja",
            fontWeight: "bold",
          },
          {
            value: "Lex",
            fontWeight: "bold",
          },
          {
            value: "Ninja",
            fontWeight: "bold",
          },
          {
            value: "Shopeex",
            fontWeight: "bold",
          },
          {
            value: "Lion",
            fontWeight: "bold",
          },
          {
            value: "Wahana",
            fontWeight: "bold",
          },
          {
            value: "Sicepat",
            fontWeight: "bold",
          },
        ];

        let data = [header];

        const { data: sellers } = await gtw({
          method: "get",
          url: `/user-seller/allseller`,
          headers,
        });

        sellers.data.map((seller) =>
          data.push([
            {
              type: String,
              value: `${seller.id}`,
            },
            {
              type: String,
              value: seller.name,
            },
          ])
        );

        await writeXlsxFile(data, {
          fileName: `template cashback.xlsx`,
        });
      } catch (e) {
        console.log(e);
      }
    };

  useCancelRequest(request);
  return {
    onProcess,
    loading,
    query,
    res,
    data,
    push,
    selectItems,
    updateOne,
    updateAll,
    downloadTemplate,
  };
};

export const useDetailSellerCashback = () => {
  const [loading, setLoading] = useState(false),
    [request, setRequest] = useState(null),
    [res, setRes] = useState({ status: "", message: "" }),
    [data, setData] = useState(null),
    { cashbackId } = useParams(),
    { headers } = useContext(AuthContext),
    onProcess = useCallback(
      async function () {
        setLoading(true);
        setRes({ status: "", message: "" });
        setData(null);
        const cancel = await (async function () {
          try {
            const _req = x.CancelToken.source();
            setRequest(_req);
            const { data: _data } = await gtw({
              method: "get",
              url: `/dccb/seller/${cashbackId}`,
              headers,
              cancelToken: _req.token,
            });
            setData(_data.data || null);
          } catch (e) {
            const message = handleError(e);
            if (message.cancel) return true;
            setRes({ status: "error", message });
          }
        })();
        if (cancel) return;
        setLoading(false);
      },
      [headers, cashbackId]
    ),
    updateState = ({ index, key, value }) =>
      setData((prev) => ({
        ...prev,
        dataAwb: prev.dataAwb.map((el, _index) => {
          if (_index === index) return { ...el, [key]: value };
          return el;
        }),
      })),
    deleteOneState = (index) =>
      setData((prev) => ({
        ...prev,
        dataAwb: prev.dataAwb
          .filter((_, _index) => _index !== index)
          .map((el) => el),
      }));
  useCancelRequest(request);
  return { onProcess, loading, data, res, updateState, deleteOneState };
};

export const useUpdateDccbSeller = () => {
  const [loading, setLoading] = useState(false),
    [res, setRes] = useState({ status: "", message: "" }),
    [request, setRequest] = useState(null),
    { headers } = useContext(AuthContext),
    onProcess = async (sellerId, dataKodeAwb, refresh = () => null) => {
      setLoading(true);
      setRes({ status: "", message: "" });
      const cancel = await (async () => {
        try {
          const _req = x.CancelToken.source();
          setRequest(_req);
          const { data } = await gtw({
            method: "put",
            url: `/dccb/seller/${sellerId}`,
            data: { dataKodeAwb },
            headers,
            cancelToken: _req.token,
          });

          if (data.message) {
            refresh();
            return setRes({ status: "ok", message: data.message });
          }

          setRes({ status: "error", message: "server is not responding" });
        } catch (e) {
          const message = handleError(e);
          if (message.cancel) return true;
          setRes({ status: "error", message });
        }
      })();
      if (cancel) return;
      setLoading(false);
    };
  useCancelRequest(request);
  return { loading, res, onProcess };
};

export const useSimpleUpdateDefaultDccb = () => {
  const [request, setRequest] = useState(null),
    { headers } = useContext(AuthContext),
    onProcess = (dccbId) => {
      return new Promise((resolve, reject) => {
        setTimeout(async () => {
          try {
            const _req = x.CancelToken.source();
            setRequest(_req);
            const { data } = await gtw({
              method: "put",
              url: `/dccb/seller/default/${dccbId}`,
              headers,
              cancelToken: _req.token,
            });

            if (data.message) return resolve(data.message);
            reject("server is not responding");
          } catch (e) {
            const message = handleError(e);
            if (message.cancel) return;
            reject(message);
          }
        }, 250);
      });
    };
  useCancelRequest(request);
  return { onProcess };
};

export const useResponDccb = () => {
  const [loading, setLoading] = useState(false),
    [res, setRes] = useState({ status: "", message: "" }),
    [request, setRequest] = useState(null),
    { cashbackId } = useParams(),
    { headers } = useContext(AuthContext),
    onProcess = async ({ accept, reason, requestId }, refresh = () => null) => {
      setLoading(true);
      setRes({ status: "", message: "" });
      const cancel = await (async () => {
        try {
          const status = accept ? "approve" : "reject";
          if (!reason)
            return setRes({
              status: "error",
              message: "Reason / Note is required",
            });

          const _req = x.CancelToken.source();
          setRequest(_req);
          const { data } = await gtw({
            method: "patch",
            url: `/dccb/seller/${cashbackId}?request_id=${requestId}`,
            headers,
            cancelToken: _req.token,
            data: { status, reason },
          });

          if (data.message) {
            refresh();
            return setRes({ status: "ok", message: data.message });
          }
          setRes({ status: "error", message: "server is not responding" });
        } catch (e) {
          const message = handleError(e);
          if (message.cancel) return true;
          setRes({ status: "error", message });
        }
      })();
      if (cancel) return;
      setLoading(false);
    };
  useCancelRequest(request);
  return { onProcess, loading, res };
};

export const useUpdateMultiple = () => {
  const [loading, setLoading] = useState(false),
    [res, setRes] = useState({ status: "", message: "" }),
    [request, setRequest] = useState(null),
    { headers } = useContext(AuthContext),
    onProcessUpdate = async (data) => {
      setLoading(true);
      const cancel = await (async function () {
        try {
          setRes({ status: "", message: "" });
          const _req = x.CancelToken.source();
          setRequest(_req);

          const { data: _data } = await gtw({
            method: "put",
            url: "/dccb/seller/multiple",
            headers,
            data,
          });
          if (_data.message)
            return setRes({ status: "ok", message: _data.message });

          setRes({ status: "error", message: "server is not responding" });
        } catch (e) {
          const message = handleError(e);
          if (message.cancel) return true;
          setRes({ status: "error", message });
        }
      })();
      if (cancel) return;
      setLoading(false);
    };

  useCancelRequest(request);
  return { onProcessUpdate, loading, res };
};
