import {useState, useContext, useMemo} from 'react';
import {
	Grid,
	TextField,
	InputAdornment,
	ListItem,
	ListItemText,
	Alert,
	ListItemIcon,
	IconButton,
	Button,
	CircularProgress,
} from '@mui/material';
import {Visibility, VisibilityOff, AddOutlined} from '@mui/icons-material';
import {GroupSelection} from '../../components/root';
import {GroupContext} from '../../utils/group';

export const AdminInputValue = ({res, loading, onAction, payload, onChange, children}) => {
	const [showPass, setShowPass] = useState(false),
	{data: dataGroups} = useContext(GroupContext),
	[showListGroup, setShowListGroup] = useState(false),
	getGroupSelection = useMemo(() => {
		if(dataGroups.length === 0)
			return null;
		const filtering = dataGroups.filter(el => el.isChecked);
		return filtering.length > 0 ? filtering[0] : null;
	}, [dataGroups]);

	return (
		<Grid container flexDirection='column' spacing={2} marginTop={1}>
			{showListGroup
				?	<GroupSelection
						single
						open={showListGroup}
						close={() => setShowListGroup(false)}/>
				:	null}
			<Grid item>
				<TextField
					value={payload.full_name}
					onChange={onChange}
					name='full_name'
					label='Full Name'
					placeholder='eg: budi'
					variant='standard'/>

			</Grid>
			<Grid item>
				<TextField
					value={payload.email}
					onChange={onChange}
					name='email'
					label='Email'
					placeholder='eg: info@example.com'
					variant='standard'/>
			</Grid>
			<Grid item>
				<TextField
					value={payload.username}
					onChange={onChange}
					name='username'
					placeholder='eg: budiextrada'
					label='Username'
					variant='standard'/>
			</Grid>
			
			{children}

			<Grid item>

				<TextField
						value={payload.password}
						onChange={onChange}
						name='password'
						className='input-textfield'
						variant='standard'
						label='Password'
						InputProps={{
							endAdornment: (
								<InputAdornment position="start">
									<IconButton onClick={() => setShowPass(prev => !prev)}>
										{!showPass
											?	<Visibility/> : <VisibilityOff/>}
									</IconButton>
								</InputAdornment>
							)
						}}
						placeholder='eg: bigdel'
						type={showPass ? 'text' : 'password'}/>
			</Grid>

			<Grid item>
				<ListItem button onClick={() => setShowListGroup(true)}>
					<ListItemText primary='Choose Group' secondary={getGroupSelection && getGroupSelection.id ? `${getGroupSelection.name} - Level ${getGroupSelection.level}` : payload.group_name ||'*required*'}/>
					<ListItemIcon>
						<AddOutlined color='primary'/>
					</ListItemIcon>
				</ListItem>
			</Grid>

			<Grid item>
				{loading
					?	<CircularProgress size={25}/>
					:	<Button
							variant='outlined'
							color='primary'
							size='small'
							onClick={onAction}
							startIcon={<AddOutlined/>}>Save Admin</Button>}
			</Grid>
			{res.message
				?	<Grid item>
						<Alert severity={res.status === 'ok' ? 'success' : 'error'} variant='standard'>{res.message}</Alert>
					</Grid>
				: null}
		</Grid>
	)
}

export * from './lists';