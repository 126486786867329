import {
  Box,
  Button,
  Grid,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import {
  convertCurrency,
  getFirstLastDateAMonth,
  getQueryUrl,
} from "../../helpers/custom";
import { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  downloadDataReport,
  downloadDataReportPrev,
  getRewardSeller,
} from "../../services/financial";
import { decompress } from "compress-json";
import { BasicModal, UIAlert } from "../../components/spam/modal";
import { handleError } from "../../services/helpers";
import { utils, writeFile } from "xlsx";
import { LinearProgressWithLabel } from "../../components/spam/inputs";
import { set } from "date-fns";

export default function CalculatePage() {
  const [selectSeller, setSelectSeller] = useState("CP GOLF ISLAND");
  const [sellerCommision, setSellerCommision] = useState(4);
  const [tiktokCommision, setTiktokCommision] = useState(6.5);
  const [tiktokCom1MonthPrev, setTiktokCom1MonthPrev] = useState(6.5);
  const [tiktokCom2MonthPrev, setTiktokCom2MonthPrev] = useState(6.5);
  const [ppCashRegular, setPpCashRegular] = useState(25);
  const [ppCashPromo, setPpCashPromo] = useState(4);
  const [ppCashVip, setPpCashVip] = useState(7.5);
  const [listData, setListData] = useState([]);
  const [res, setRes] = useState({ status: "", message: "" });
  const [totalData, setTotalData] = useState(0);
  const [totalPrevData, setTotalPrevData] = useState(0);
  const [progress, setProgress] = useState(0);
  const [loading, setLoading] = useState(false);

  const { search } = useLocation();
  const urlParams = new URLSearchParams(window.location.search);
  const dateString = urlParams.get("date");
  const date = new Date(dateString);
  const currentMonth = date.getMonth();
  const prevMonth1 = currentMonth === 0 ? 11 : currentMonth - 1;
  const prevMonth2 = prevMonth1 === 0 ? 11 : prevMonth1 - 1;
  const navigate = useNavigate();

  const query = useMemo(() => {
    if (!search) return null;
    return getQueryUrl(search);
  }, [search]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      // Proses pemanggilan getListReward hanya jika sudah dilakukan submit
    }, 500);

    return () => clearTimeout(timeout);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  const getListReward = async (seller, date) => {
    try {
      setLoading(true);
      const results = await getRewardSeller(
        seller,
        date,
        sellerCommision,
        tiktokCommision,
        tiktokCom1MonthPrev,
        tiktokCom2MonthPrev,
        ppCashRegular,
        ppCashPromo,
        ppCashVip
      );
      console.log(results);
      setTotalData(results.totalData);
      setTotalPrevData(results.totalPrevData);
      setListData(results.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const handleChange = (e) => {
    e.preventDefault();
    if (!selectSeller) setListData([]);
    setSelectSeller(e.target?.value);
  };

  const handleCommisionChange = (setter) => (e) => {
    setter(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    getListReward(selectSeller, query.date);
  };

  const handleDownloadCashback = async (e) => {
    e.preventDefault();
    try {
      const rows = listData.map((row, index) => {
        return {
          Description: row.serviceName,
          "Shipment Fee":
            index === 3
              ? `${convertCurrency(row.feeRegular)}\n${convertCurrency(
                  row.feePromo
                )}\n${convertCurrency(row.feeVIP)}`
              : convertCurrency(row.fee),
          "Shipment Fee After Disc TTD":
            index === 3
              ? `${convertCurrency(row.afterDiscTTDRegular)}\n${convertCurrency(
                  row.afterDiscTTDPromo
                )}\n${convertCurrency(row.afterDiscTTDVIP)}`
              : convertCurrency(row.afterDiscTTD),
          "Shipment Fee After Disc TTD (RTS)":
            index === 3
              ? `${convertCurrency(
                  row.afterDiscTTDRtsRegular
                )}\n${convertCurrency(
                  row.afterDiscTTDRtsPromo
                )}\n${convertCurrency(row.afterDiscTTDRtsVIP)}`
              : convertCurrency(row.afterDiscTTDRts),
          "Shipment Fee After Disc Not TTD":
            index === 3
              ? `${convertCurrency(
                  row.afterDiscNotTTDRegular
                )}\n${convertCurrency(
                  row.afterDiscNotTTDPromo
                )}\n${convertCurrency(row.afterDiscNotTTDVIP)}`
              : convertCurrency(row.afterDiscNotTTD),
          DISCOUNT:
            index === 3
              ? `${convertCurrency(row.discountRegular)}\n${convertCurrency(
                  row.discountPromo
                )}\n${convertCurrency(row.discountVIP)}`
              : convertCurrency(row.discount),
          "Shipment Fee TTD (Prev. Period)":
            index === 3
              ? `${convertCurrency(
                  row.shipmentFeePrevRegular
                )}\n${convertCurrency(
                  row.shipmentFeePrevPromo
                )}\n${convertCurrency(row.shipmentFeePrevVIP)}`
              : index === 0
              ? `${new Date(0, prevMonth2).toLocaleString("id-ID", {
                  month: "long",
                })}: ${convertCurrency(row.shipmentFeePrev2)}\n${new Date(
                  0,
                  prevMonth1
                ).toLocaleString("id-ID", {
                  month: "long",
                })}: ${convertCurrency(row.shipmentFeePrev1)}`
              : convertCurrency(row.shipmentFeePrev),
          "%Commission":
            index === 3
              ? `Regular ${row.commisionRegular}%\nPromo ${row.commisionPromo}%\nVIP ${row.commisionVIP}%`
              : index === 0
              ? `${new Date(0, prevMonth2).toLocaleString("id-ID", {
                  month: "long",
                })}: ${row.commisionPrev2} %\n${new Date(
                  0,
                  prevMonth1
                ).toLocaleString("id-ID", { month: "long" })}: ${
                  row.commisionPrev1
                } %`
              : `${row.commision}%`,
          "Total Commission":
            index === 3
              ? `${convertCurrency(
                  row.totalCommisionRegular
                )} (Regular)\n${convertCurrency(
                  row.totalCommisionPromo
                )} (Promo)\n${convertCurrency(row.totalCommisionVIP)} (VIP)`
              : index === 0
              ? convertCurrency(row.totalShipmentFeePrev)
              : convertCurrency(row.totalCommision),
        };
      });

      let wscols = [
        { width: 20 },
        { width: 20 },
        { width: 30 },
        { width: 30 },
        { width: 30 },
        { width: 15 },
        { width: 30 },
        { width: 15 },
        { width: 20 },
      ];

      const ws = utils.json_to_sheet(rows);
      ws["!cols"] = wscols;
      const wb = utils.book_new();
      utils.book_append_sheet(wb, ws, "Data");
      writeFile(wb, "cashback.xlsx");

      setRes({ status: "ok", message: "Successfully downloaded cashback" });
    } catch (error) {
      console.log(error);
      const message = await handleError(error);
      setRes({ status: "error", message: message });
      console.log(message);
    }
  };

  const handleDownloadReport = async (e) => {
    e.preventDefault();
    try {
      setProgress(0);
      setLoading(true);
      const payload = {
        seller: selectSeller,
        date: decodeURIComponent(query.date),
      };
      let data = [];
      let newTotalData = totalData;
      let limitDownload = 2000;
      let page = 0;
      if (newTotalData > limitDownload) {
        let loop = Math.ceil(newTotalData / limitDownload);
        let _data = [];
        let progress = 0;
        for (let index = 0; index < loop; index++) {
          if (index === loop - 1) {
            _data = await downloadDataReport({
              query: `?limitDownload=${newTotalData}&pageDownload=${
                page * limitDownload
              }`,
              payload,
            });
          } else {
            _data = await downloadDataReport({
              query: `?limitDownload=${limitDownload}&pageDownload=${
                page * limitDownload
              }`,
              payload,
            });
          }
          if (progress < 100) {
            progress += 10;
            setProgress(progress);
          }
          page += 1;
          newTotalData -= limitDownload;
          data = data.concat(decompress(_data));
        }
      } else {
        data = await downloadDataReport({ payload });
        data = decompress(data);
      }
      let rows = data.map((e) => {
        return {
          "Nama Seller": e.seller_name,
          "No. Waybill": e.awb,
          "Biaya Kirim": convertCurrency(e.shipment_fee),
          Diskon: e.discount ? convertCurrency(e.discount_fee) : "",
          "Biaya Kirim Setelah Diskon": convertCurrency(
            e.shipment_fee_after_disc
          ),
          "Cara Pembayaran": e.payment_method,
          "Klien Pengirim":
            e.shipper_client === "undefined" ? "" : e.shipper_client,
          "Kode Promo": e.promo_code === "undefined" ? "" : e.promo_code,
          TTD: e.ttd,
          "Sumber Order": e.order_source,
        };
      });
      let wscols = [
        { width: 27 },
        { width: 15 },
        { width: 20 },
        { width: 17 },
        { width: 27 },
        { width: 17 },
        { width: 25 },
        { width: 18 },
        { width: 17 },
        { width: 20 },
      ];

      const ws = utils.json_to_sheet(rows);
      ws["!cols"] = wscols;
      const wb = utils.book_new();
      utils.book_append_sheet(wb, ws, "Data");
      writeFile(wb, "list-report-cashback.xlsx");

      setLoading(false);
      setRes({
        status: "ok",
        message: `Successfully downloaded detail cashback, with total data ${totalData}`,
      });
    } catch (error) {
      setLoading(false);
      const message = await handleError(error);
      if (Array.isArray(message)) {
        if (message.length) {
          setRes({ status: "error", message });
        }
      } else if (message === "Tidak ada koneksi") {
        setRes({ status: "error", message });
      } else {
        setRes({ status: "error", message });
      }
    }
  };

  const handleDownloadReportPrev = async (e) => {
    e.preventDefault();
    try {
      setProgress(0);
      setLoading(true);
      const payload = {
        seller: selectSeller,
        date: decodeURIComponent(query.date),
      };
      let data = [];
      let newTotalData = totalPrevData;
      let limitDownload = 100000;
      let page = 0;
      if (newTotalData > limitDownload) {
        let loop = Math.ceil(newTotalData / limitDownload);
        let _data = [];
        let progress = 0;
        for (let index = 0; index < loop; index++) {
          if (index === loop - 1) {
            _data = await downloadDataReportPrev({
              query: `?limitDownload=${newTotalData}&pageDownload=${
                page * limitDownload
              }`,
              payload,
            });
          } else {
            _data = await downloadDataReportPrev({
              query: `?limitDownload=${limitDownload}&pageDownload=${
                page * limitDownload
              }`,
              payload,
            });
          }
          if (progress < 100) {
            progress += 10;
            setProgress(progress);
          }
          page += 1;
          newTotalData -= limitDownload;
          data = data.concat(decompress(_data));
        }
      } else {
        data = await downloadDataReportPrev({ payload });
        data = decompress(data);
      }
      let rows = data.map((e) => {
        return {
          "Nama Seller": e.seller_name,
          "No. Waybill": e.awb,
          "Biaya Kirim Setelah Diskon": convertCurrency(
            e.shipment_fee_after_disc
          ),
          "Cara Pembayaran": e.payment_method,
          "Klien Pengirim":
            e.shipper_client === "undefined" ? "" : e.shipper_client,
          "Kode Promo": e.promo_code === "undefined" ? "" : e.promo_code,
          "Sumber Order": e.order_source,
        };
      });
      let wscols = [
        { width: 22 },
        { width: 15 },
        { width: 24 },
        { width: 17 },
        { width: 24 },
        { width: 19 },
        { width: 16 },
      ];

      const ws = utils.json_to_sheet(rows);
      ws["!cols"] = wscols;
      const wb = utils.book_new();
      utils.book_append_sheet(wb, ws, "Data");
      writeFile(wb, "list-report-cashback-prev.xlsx");

      setLoading(false);
      setRes({
        status: "ok",
        message: `Successfully downloaded detail cashback, with total data ${totalPrevData}`,
      });
    } catch (error) {
      setLoading(false);
      const message = await handleError(error);
      if (Array.isArray(message)) {
        if (message.length) {
          setRes({ status: "error", message });
        }
      } else if (message === "Tidak ada koneksi") {
        setRes({ status: "error", message });
      } else {
        setRes({ status: "error", message });
      }
    }
  };

  return (
    <article
      style={{ width: "100%", minHeight: "88dvh", position: "relative" }}
    >
      <Box
        sx={{
          my: 2,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Select
          value={selectSeller}
          onChange={handleChange}
          defaultValue={"select seller"}
          displayEmpty
          inputProps={{ "aria-label": "Without label" }}
          size="small"
          margin="none"
          sx={{
            borderRadius: 2,
            backgroundColor: "#ffffff",
            minWidth: "23rem",
          }}
        >
          <MenuItem disabled value="select seller">
            <em>Choose a seller</em>
          </MenuItem>
          <MenuItem value={"CP GOLF ISLAND"}>
            REPORT KOMISI - CP GOLF ISLAND
          </MenuItem>
          <MenuItem value={"CP PLUIT KARANG PERMAI"}>
            REPORT KOMISI - CP PLUIT KARANG PERMAI
          </MenuItem>
          <MenuItem value={"CP JEMBATAN TIGA"}>
            REPORT KOMISI - CP JEMBATAN TIGA
          </MenuItem>
          <MenuItem value={"CP LODAN"}>REPORT KOMISI - CP LODAN</MenuItem>
          <MenuItem value={"CP EXCLUSIVE PIK"}>
            REPORT KOMISI - CP EXCLUSIVE PIK
          </MenuItem>
          <MenuItem value={"CP INDRALOKA"}>
            REPORT KOMISI - CP INDRALOKA
          </MenuItem>
          <MenuItem value={"CP KARANG ANYAR"}>
            REPORT KOMISI - CP KARANG ANYAR
          </MenuItem>
          <MenuItem value={"CP KARYA RAYA"}>
            REPORT KOMISI - CP KARYA RAYA
          </MenuItem>
          <MenuItem value={"CP MANGGA DUA SQUARE"}>
            REPORT KOMISI - CP MANGGA DUA SQUARE
          </MenuItem>
          <MenuItem value={"CP PASAR PAGI"}>
            REPORT KOMISI - CP PASAR PAGI
          </MenuItem>
          <MenuItem value={"CP PLUIT POLOS"}>
            REPORT KOMISI - CP PLUIT POLOS
          </MenuItem>
          <MenuItem value={"CP TANAH PASIR"}>
            REPORT KOMISI - CP TANAH PASIR
          </MenuItem>
          <MenuItem value={"CP ORIONS"}>
            REPORT KOMISI - CP ORION DUSIT
          </MenuItem>
          <MenuItem value={"CP AMPERA"}>REPORT KOMISI - CP AMPERA</MenuItem>
          <MenuItem value={"CP KETING"}>REPORT KOMISI - CP KETING</MenuItem>
          <MenuItem value={"CP LAGUNA"}>REPORT KOMISI - CP LAGUNA</MenuItem>
          <MenuItem value={"DOP DHI"}>REPORT KOMISI - DOP DHI</MenuItem>
          <MenuItem value={"DOP JALAN K"}>REPORT KOMISI - DOP JALAN K</MenuItem>
          <MenuItem value={"DOP JNT SMP 122"}>
            REPORT KOMISI - DOP JNT SMP 122
          </MenuItem>
          <MenuItem value={"DOP MUARA KARANG"}>
            REPORT KOMISI - DOP MUARA KARANG
          </MenuItem>
          <MenuItem value={"DOP MULTI TELUK GONG"}>
            REPORT KOMISI - DOP MULTI TELUK GONG
          </MenuItem>
          <MenuItem value={"DOP TELUK INTAN"}>
            REPORT KOMISI - DOP TELUK INTAN
          </MenuItem>
        </Select>
      </Box>

      {/* Tambahkan inputan untuk sellerCommision dan tiktokCommision */}
      <Box
        sx={{
          my: 2,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: 2,
        }}
      >
        <TextField
          label="Seller Commision"
          type="number"
          value={sellerCommision}
          onChange={handleCommisionChange(setSellerCommision)}
        />
        <TextField
          label={`Tiktok Commision (${new Date(0, prevMonth2).toLocaleString(
            "id-ID",
            { month: "long" }
          )}):`}
          type="number"
          value={tiktokCom2MonthPrev}
          onChange={handleCommisionChange(setTiktokCom2MonthPrev)}
        />
        <TextField
          label={`Tiktok Commision (${new Date(0, prevMonth1).toLocaleString(
            "id-ID",
            { month: "long" }
          )}):`}
          type="number"
          value={tiktokCom1MonthPrev}
          onChange={handleCommisionChange(setTiktokCom1MonthPrev)}
        />
        <TextField
          label="PP_CASH Regular Commision"
          type="number"
          value={ppCashRegular}
          onChange={handleCommisionChange(setPpCashRegular)}
        />
        <TextField
          label="PP_CASH Promo Commision"
          type="number"
          value={ppCashPromo}
          onChange={handleCommisionChange(setPpCashPromo)}
        />
        <TextField
          label="PP_CASH VIP Commision"
          type="number"
          value={ppCashVip}
          onChange={handleCommisionChange(setPpCashVip)}
        />
      </Box>

      {/* Tambahkan Button Submit */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          my: 2,
        }}
      >
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          disabled={loading} // Nonaktifkan tombol saat loading
        >
          Submit
        </Button>
      </Box>

      {/* loading */}
      {loading ? (
        <BasicModal title="Loading..." open onClose={() => null}>
          <Grid
            container
            spacing={2}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Grid item>
              <LinearProgressWithLabel value={progress} />
              <Typography variant="h6" color="red" textAlign="center">
                Your request is being processed.
                <br />
                Please don't leave the page
              </Typography>
            </Grid>
          </Grid>
        </BasicModal>
      ) : null}

      {/* alert */}
      <UIAlert
        onClose={(_) => setRes({ status: "", message: "" })}
        open={res.status !== ""}
        autoHideDuration={10000}
        res={res}
      />

      {/* first date until end date */}
      <Typography variant="h5" sx={{ mb: 2 }} textAlign={"center"}>
        {getFirstLastDateAMonth(decodeURIComponent(query.date))}
      </Typography>

      <TableContainer
        sx={{
          minHeight: "50vh",
          maxHeight: "80vh",
          maxWidth: "97%",
          margin: "0 auto",
        }}
      >
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>Description</TableCell>
              <TableCell sx={{ minWidth: 130 }}>Shipment Fee</TableCell>
              <TableCell sx={{ minWidth: 140 }}>
                Shipment Fee After Disc TTD
              </TableCell>
              <TableCell sx={{ minWidth: 140 }}>
                Shipment Fee After Disc TTD (RTS)
              </TableCell>
              <TableCell sx={{ minWidth: 140 }}>
                Shipment Fee After Disc Not TTD
              </TableCell>
              <TableCell>DISCOUNT</TableCell>
              <TableCell>Shipment Fee TTD (Prev. Period)</TableCell>
              <TableCell>%Commision</TableCell>
              <TableCell>Total Commision</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {listData?.length > 0
              ? listData.map((item, index) => (
                  <TableRow hover key={index}>
                    <TableCell>
                      {index === 8 ? (
                        <span
                          style={{ fontWeight: "bold", fontStyle: "italic" }}
                        >
                          {item.serviceName}
                        </span>
                      ) : (
                        item.serviceName
                      )}
                    </TableCell>
                    <TableCell>
                      {index === 3 ? (
                        <>
                          {convertCurrency(item.feeRegular)}
                          <br />
                          {convertCurrency(item.feePromo)}
                          <br />
                          {convertCurrency(item.feeVIP)}
                        </>
                      ) : (
                        convertCurrency(item.fee)
                      )}
                    </TableCell>
                    <TableCell>
                      {index === 3 ? (
                        <>
                          {convertCurrency(item.afterDiscTTDRegular)}
                          <br />
                          {convertCurrency(item.afterDiscTTDPromo)}
                          <br />
                          {convertCurrency(item.afterDiscTTDVIP)}
                        </>
                      ) : (
                        convertCurrency(item.afterDiscTTD)
                      )}
                    </TableCell>
                    <TableCell>
                      {index === 3 ? (
                        <>
                          {convertCurrency(item.afterDiscTTDRtsRegular)}
                          <br />
                          {convertCurrency(item.afterDiscTTDRtsPromo)}
                          <br />
                          {convertCurrency(item.afterDiscTTDRtsVIP)}
                        </>
                      ) : (
                        convertCurrency(item.afterDiscTTDRts)
                      )}
                    </TableCell>
                    <TableCell>
                      {index === 3 ? (
                        <>
                          {convertCurrency(item.afterDiscNotTTDRegular)}
                          <br />
                          {convertCurrency(item.afterDiscNotTTDPromo)}
                          <br />
                          {convertCurrency(item.afterDiscNotTTDVIP)}
                        </>
                      ) : (
                        convertCurrency(item.afterDiscNotTTD)
                      )}
                    </TableCell>
                    <TableCell>
                      {index === 3 ? (
                        <>
                          {convertCurrency(item.discountRegular)}
                          <br />
                          {convertCurrency(item.discountPromo)}
                          <br />
                          {convertCurrency(item.discountVIP)}
                        </>
                      ) : (
                        convertCurrency(item.discount)
                      )}
                    </TableCell>
                    <TableCell>
                      {index === 3 ? (
                        <>
                          {convertCurrency(item.shipmentFeePrevRegular)}
                          <br />
                          {convertCurrency(item.shipmentFeePrevPromo)}
                          <br />
                          {convertCurrency(item.shipmentFeePrevVIP)}
                        </>
                      ) : index === 0 ? (
                        <>
                          {`${new Date(0, prevMonth2).toLocaleString("id-ID", {
                            month: "long",
                          })}: `}
                          {convertCurrency(item.shipmentFeePrev2)}
                          <br />
                          {`${new Date(0, prevMonth1).toLocaleString("id-ID", {
                            month: "long",
                          })}: `}
                          {convertCurrency(item.shipmentFeePrev1)}
                        </>
                      ) : (
                        convertCurrency(item.shipmentFeePrev)
                      )}
                    </TableCell>
                    <TableCell>
                      {index === 8 ? (
                        ""
                      ) : index === 3 ? (
                        <>
                          {`Regular ${item.commisionRegular}%`}
                          <br />
                          {`Promo ${item.commisionPromo}%`}
                          <br />
                          {`VIP ${item.commisionVIP}%`}
                        </>
                      ) : index === 0 ? (
                        <>
                          {`${new Date(0, prevMonth2).toLocaleString("id-ID", {
                            month: "long",
                          })}: `}
                          {`${item.commisionPrev2} %`}
                          <br />
                          {`${new Date(0, prevMonth1).toLocaleString("id-ID", {
                            month: "long",
                          })}: `}
                          {`${item.commisionPrev1} %`}
                        </>
                      ) : (
                        `${item.commision}%`
                      )}
                    </TableCell>
                    <TableCell>
                      {index === 3 ? (
                        <>
                          {convertCurrency(item.totalCommisionRegular)}
                          <br />
                          {convertCurrency(item.totalCommisionPromo)}
                          <br />
                          {convertCurrency(item.totalCommisionVIP)}
                        </>
                      ) : index === 0 ? (
                        convertCurrency(item.totalShipmentFeePrev)
                      ) : (
                        convertCurrency(item.totalCommision)
                      )}
                    </TableCell>
                  </TableRow>
                ))
              : null}
          </TableBody>
        </Table>
      </TableContainer>

      <Box
        display={"flex"}
        justifyItems={"center"}
        justifyContent={"center"}
        my={3}
        gap={3}
      >
        <Button
          variant="outlined"
          size="medium"
          onClick={handleDownloadCashback}
        >
          Download Cashback
        </Button>
        <Button
          variant="outlined"
          size="medium"
          disabled={loading}
          onClick={handleDownloadReport}
        >
          Download Report
        </Button>
        <Button
          variant="outlined"
          size="medium"
          disabled={loading}
          onClick={handleDownloadReportPrev}
        >
          Download Previous Report
        </Button>
      </Box>
    </article>
  );
}
