import {
  Grid,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Button,
  Alert,
  CircularProgress,
  // Typography,
  TablePagination,
  Stack,
  Divider,
} from "@mui/material";
import {
  // Today,
  RemoveCircleOutlineOutlined,
  ImportExportOutlined,
} from "@mui/icons-material";
import { useEffect, useState, Fragment, useContext } from "react";
import { useSearchParams } from "react-router-dom";
import { DateRangePicker } from "react-date-range";
import { useListFinancial } from "../../services/transactions";
// import { LocalizationProvider } from "@mui/x-date-pickers";
// import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import writeXlsxFile from "write-excel-file";

// import { MonthPicker } from "@mui/x-date-pickers/MonthPicker";
import { BasicModal } from "../../components/spam/modal";
// import { convertCurrency } from "../../helpers/custom";
// import { RangeDateTimePicker } from "../../components/spam/dateTime";
import { SearchHead } from "../../components/spam/inputs";
import { TablePaginationActions } from "../../components/spam/pagination";
// import { exportFileExcel } from "../../helpers/excel";
// import { Box } from "@mui/system";
import { financeCard } from "../../components/financeCard";
import { AuthContext } from "../../utils/auth";

export const FinancialIndexPage = () => {
  const { loading, onProcess, data } = useListFinancial(),
    limit = 10,
    { auth } = useContext(AuthContext),
    [searchParams, setSearchParams] = useSearchParams(),
    params = Object.fromEntries([...searchParams]),
    // [month, setMonth] = useState(new Date()),
    [open, setOpen] = useState(false),
    [openExport, setOpenExport] = useState(false),
    // [values, setValues] = useState(""),
    // [tempMonth, setTempMonth] = useState(new Date()),
    // [page, setPage] = useState(1),
    [count, setCount] = useState(0),
    [newData, setNewData] = useState({}),
    [searchData, setSearchData] = useState({}),
    [statusSearch, setStatusSearch] = useState(params.startDate ? true : false),
    [state, setState] = useState({
      startDate: params.startDate || new Date(),
      endDate: params.endDate || new Date(),
      key: "selection",
    }),
    searchQuery = params.search || "",
    pageQuery = params.page || "";

  const searchSeller = () => {
    const newObjSearch = {};
    let newobj = {};
    let page = +params.page - 1;
    let limit = 10;
    let offset = page * limit;

    if (searchQuery) {
      // setValues(searchQuery);
      Object.keys(data).forEach((e) => {
        if (e.toLocaleLowerCase().match(searchQuery.toLowerCase())) {
          newObjSearch[e] = data[e];
        }
      });

      Object.entries(newObjSearch).forEach(function ([key, item], index) {
        if (index >= offset && index < offset + limit) {
          newobj[key] = item;
        }
      });
      setCount(Object.keys(newObjSearch).length);
      setSearchData(newobj);
      setNewData(newobj);
    }
    // changePage(newObjSearch);
    // setLimit(Object.keys(newObj).length);
  };
  const changePage = () => {
    let newobj = {};
    let page = +params.page - 1;
    let limit = 10;
    let offset = page * limit;
    Object.entries(data).forEach(function ([key, item], index) {
      if (index >= offset && index < offset + limit) {
        newobj[key] = item;
      }
    });

    setNewData(newobj);
  };

  const exportData = async (extensionChoose) => {
    let dataExport = [];
    Object.keys(searchQuery ? searchData : data).forEach((key) => {
      let obj = {
        "NAMA SELLER": key,
        "TOTAL JNE": data[key].jne ? Number(data[key].jne) : 0,
        "TOTAL BERAT JNE": data[key].jne_berat
          ? Number(data[key].jne_berat)
          : 0,

        "TOTAL ONGKIR JNE": data[key].jne_price
          ? Number(data[key].jne_price)
          : 0,

        "TOTAL JNT": data[key].jnt ? Number(data[key].jnt) : 0,
        "TOTAL BERAT JNT": data[key].jnt_berat
          ? Number(data[key].jnt_berat)
          : ``,
        "TOTAL ONGKIR JNT": data[key].jnt_price
          ? Number(data[key].jnt_price)
          : 0,
        "TOTAL ANTER AJA": data[key].anteraja ? Number(data[key].anteraja) : 0,
        "TOTAL BERAT ANTER AJA": data[key].anteraja_berat
          ? Number(data[key].anteraja_berat)
          : 0,

        "TOTAL ONGKIR ANTER AJA": data[key].anteraja_price
          ? Number(data[key].anteraja_price)
          : 0,
        "TOTAL NINJA EXPRESS": data[key].ninja_express
          ? Number(data[key].ninja_express)
          : 0,
        "TOTAL BERAT NINJA EXPRESS": data[key].ninja_express_berat
          ? Number(data[key].ninja_express_berat)
          : 0,
        "TOTAL ONGKIR NINJA EXPRESS": data[key].ninja_express_price
          ? Number(data[key].ninja_express_price)
          : 0,
        "TOTAL ID EXPRESS": data[key].id_express
          ? Number(data[key].id_express)
          : 0,
        "TOTAL BERAT ID EXPRESS": data[key].id_express_berat
          ? Number(data[key].id_express_berat)
          : 0,
        "TOTAL ONGKIR ID EXPRESS": data[key].id_express_price
          ? Number(data[key].id_express_price)
          : 0,
        "TOTAL LION PARCEL": data[key].lion ? Number(data[key].lion) : 0,
        "TOTAL BERAT LION PARCEL": data[key].lion_berat
          ? Number(data[key].lion_berat)
          : 0,
        "TOTAL ONGKIR LION PARCEL": data[key].lion_price
          ? Number(data[key].lion_price)
          : 0,
        "TOTAL SICEPAT": data[key].sicepat ? Number(data[key].sicepat) : 0,
        "TOTAL BERAT SICEPAT": data[key].sicepat_berat
          ? Number(data[key].sicepat_berat)
          : 0,
        "TOTAL ONGKIR SICEPAT": data[key].sicepat_price
          ? Number(data[key].sicepat_price)
          : 0,
        "TOTAL SHOPEE": data[key].shopee ? Number(data[key].shopee) : 0,
        "TOTAL BERAT SHOPEE": data[key].shopee_berat
          ? Number(data[key].shopee_berat)
          : 0,
        "TOTAL ONGKIR SHOPEE": data[key].shopee_price
          ? Number(data[key].shopee_price)
          : 0,
        "TOTAL LEX": data[key].lex ? Number(data[key].lex) : 0,
        "TOTAL BERAT LEX": data[key].lex_berat
          ? Number(data[key].lex_berat)
          : 0,
        "TOTAL ONGKIR LEX": data[key].lex_price
          ? Number(data[key].lex_price)
          : 0,
        "TOTAL WAHANA": data[key].wahana ? Number(data[key].wahana) : 0,
        "TOTAL BERAT WAHANA": data[key].wahana_berat
          ? Number(data[key].wahana_berat)
          : 0,

        "TOTAL ONGKIR WAHANA": data[key].wahana_price
          ? Number(data[key].wahana_price)
          : 0,
        "TOTAL OTHER": data[key].other ? Number(data[key].other) : 0,
        "TOTAL BERAT OTHER": data[key].other_berat
          ? Number(data[key].other_berat)
          : 0,
        "TOTAL ONGKIR OTHER": data[key].other_price
          ? Number(data[key].other_price)
          : 0,
        "TOTAL PAKET": data[key].total ? Number(data[key].total) : 0,
        "TOTAL BERAT PAKET": data[key]["total_berat"]
          ? Number(data[key]["total_berat"])
          : 0,
        "TOTAL ONGKIR PAKET": data[key]["total_price"]
          ? Number(data[key]["total_price"])
          : 0,
      };
      dataExport.push(obj);
    });
    const schema = [
      {
        column: "NAMA SELLER",
        type: String,
        value: (data) => data["NAMA SELLER"],
      },
      {
        column: "TOTAL JNE",
        type: Number,
        value: (data) => data["TOTAL JNE"],
      },
      {
        column: "TOTAL BERAT JNE",
        type: Number,
        value: (data) => data["TOTAL BERAT JNE"],
      },
      {
        column: "TOTAL ONGKIR JNE",
        type: Number,
        value: (data) => data["TOTAL ONGKIR JNE"],
      },
      {
        column: "TOTAL JNT",
        type: Number,
        value: (data) => data["TOTAL JNT"],
      },
      {
        column: "TOTAL BERAT JNT",
        type: Number,
        value: (data) => data["TOTAL BERAT JNT"],
      },
      {
        column: "TOTAL ONGKIR JNT",
        type: Number,
        value: (data) => data["TOTAL ONGKIR JNT"],
      },
      {
        column: "TOTAL ANTER AJA",
        type: Number,
        value: (data) => data["TOTAL ANTER AJA"],
      },
      {
        column: "TOTAL BERAT ANTER AJA",
        type: Number,
        value: (data) => data["TOTAL BERAT ANTER AJA"],
      },
      {
        column: "TOTAL ONGKIR ANTER AJA",
        type: Number,
        value: (data) => data["TOTAL ONGKIR ANTER AJA"],
      },
      {
        column: "TOTAL NINJA EXPRESS",
        type: Number,
        value: (data) => data["TOTAL NINJA EXPRESS"],
      },
      {
        column: "TOTAL BERAT NINJA EXPRESS",
        type: Number,
        value: (data) => data["TOTAL BERAT NINJA EXPRESS"],
      },
      {
        column: "TOTAL ONGKIR NINJA EXPRESS",
        type: Number,
        value: (data) => data["TOTAL ONGKIR NINJA EXPRESS"],
      },
      {
        column: "TOTAL ID EXPRESS",
        type: Number,
        value: (data) => data["TOTAL ID EXPRESS"],
      },
      {
        column: "TOTAL BERAT ID EXPRESS",
        type: Number,
        value: (data) => data["TOTAL BERAT ID EXPRESS"],
      },
      {
        column: "TOTAL ONGKIR ID EXPRESS",
        type: Number,
        value: (data) => data["TOTAL ONGKIR ID EXPRESS"],
      },
      {
        column: "TOTAL LION PARCEL",
        type: Number,
        value: (data) => data["TOTAL LION PARCEL"],
      },
      {
        column: "TOTAL BERAT LION PARCEL",
        type: Number,
        value: (data) => data["TOTAL BERAT LION PARCEL"],
      },
      {
        column: "TOTAL ONGKIR LION PARCEL",
        type: Number,
        value: (data) => data["TOTAL ONGKIR LION PARCEL"],
      },
      {
        column: "TOTAL SICEPAT",
        type: Number,
        value: (data) => data["TOTAL SICEPAT"],
      },
      {
        column: "TOTAL BERAT SICEPAT",
        type: Number,
        value: (data) => data["TOTAL BERAT SICEPAT"],
      },
      {
        column: "TOTAL ONGKIR SICEPAT",
        type: Number,
        value: (data) => data["TOTAL ONGKIR SICEPAT"],
      },
      {
        column: "TOTAL SHOPEE",
        type: Number,
        value: (data) => data["TOTAL SHOPEE"],
      },
      {
        column: "TOTAL BERAT SHOPEE",
        type: Number,
        value: (data) => data["TOTAL BERAT SHOPEE"],
      },
      {
        column: "TOTAL ONGKIR SHOPEE",
        type: Number,
        value: (data) => data["TOTAL ONGKIR SHOPEE"],
      },
      {
        column: "TOTAL LEX",
        type: Number,
        value: (data) => data["TOTAL LEX"],
      },
      {
        column: "TOTAL BERAT LEX",
        type: Number,
        value: (data) => data["TOTAL BERAT LEX"],
      },
      {
        column: "TOTAL ONGKIR LEX",
        type: Number,
        value: (data) => data["TOTAL ONGKIR LEX"],
      },
      {
        column: "TOTAL WAHANA",
        type: Number,
        value: (data) => data["TOTAL WAHANA"],
      },
      {
        column: "TOTAL BERAT WAHANA",
        type: Number,
        value: (data) => data["TOTAL BERAT WAHANA"],
      },
      {
        column: "TOTAL ONGKIR WAHANA",
        type: Number,
        value: (data) => data["TOTAL ONGKIR WAHANA"],
      },
      {
        column: "TOTAL OTHER",
        type: Number,
        value: (data) => data["TOTAL OTHER"],
      },
      {
        column: "TOTAL BERAT OTHER",
        type: Number,
        value: (data) => data["TOTAL BERAT OTHER"],
      },
      {
        column: "TOTAL ONGKIR OTHER",
        type: Number,
        value: (data) => data["TOTAL ONGKIR OTHER"],
      },
      {
        column: "TOTAL PAKET",
        type: Number,
        value: (data) => data["TOTAL PAKET"],
      },
      {
        column: "TOTAL ONGKIR PAKET",
        type: Number,
        value: (data) => data["TOTAL ONGKIR PAKET"],
      },
      {
        column: "TOTAL BERAT PAKET",
        type: Number,
        value: (data) => data["TOTAL BERAT PAKET"],
      },
    ];
    await writeXlsxFile(dataExport, {
      schema,
      fileName: `Shipping Cost Report ${new Date(
        state.startDate
      ).toLocaleDateString("id-ID")} s/d ${new Date(
        state.endDate
      ).toLocaleDateString("id-ID")}.xlsx`,
    });

    // Object.keys(searchQuery ? searchData : data).map((key) => {
    //   let obj = {
    //     ["NAMA SELLER"]: key,
    //     ["TOTAL JNE"]: data[key].jne || 0,
    //     ["TOTAL ONGKIR JNE"]: data[key].jne_price ? data[key].jne_price : 0,
    //     ["TOTAL JNT"]: data[key].jnt || 0,
    //     ["TOTAL ONGKIR JNT"]: data[key].jnt_price ? data[key].jnt_price : 0,
    //     ["TOTAL ANTER AJA"]: data[key].anteraja || 0,
    //     ["TOTAL ONGKIR ANTER AJA"]: data[key].anteraja_price
    //       ? data[key].anteraja_price
    //       : 0,
    //     ["TOTAL NINJA EXPRESS"]: data[key].ninja_express || 0,
    //     ["TOTAL ONGKIR NINJA EXPRESS"]: data[key].ninja_express_price
    //       ? data[key].ninja_express_price
    //       : 0,
    //     ["TOTAL ID EXPRESS"]: data[key].id_express || 0,
    //     ["TOTAL ONGKIR ID EXPRESS"]: data[key].id_express_price
    //       ? data[key].id_express_price
    //       : 0,
    //     ["TOTAL LION PARCEL"]: data[key].lion || 0,
    //     ["TOTAL ONGKIR LION PARCEL"]: data[key].lion_price
    //       ? data[key].lion_price
    //       : 0,
    //     ["TOTAL SICEPAT"]: data[key].sicepat || 0,
    //     ["TOTAL ONGKIR SICEPAT"]: data[key].sicepat_price
    //       ? data[key].sicepat_price
    //       : 0,
    //     ["TOTAL SHOPEE"]: data[key].shopee || 0,
    //     ["TOTAL ONGKIR SHOPEE"]: data[key].shopee_price
    //       ? data[key].shopee_price
    //       : 0,
    //     ["TOTAL LEX"]: data[key].lex || 0,
    //     ["TOTAL ONGKIR LEX"]: data[key].lex_price ? data[key].lex_price : 0,
    //     ["TOTAL WAHANA"]: data[key].wahana || 0,
    //     ["TOTAL ONGKIR WAHANA"]: data[key].wahana_price
    //       ? data[key].wahana_price
    //       : 0,
    //     ["TOTAL OTHER"]: data[key].other || 0,
    //     ["TOTAL ONGKIR OTHER"]: data[key].other_price
    //       ? data[key].other_price
    //       : 0,
    //     ["TOTAL PAKET"]: data[key].total,
    //     ["TOTAL ONGKIR PAKET"]: data[key]["total_price"]
    //       ? data[key]["total_price"]
    //       : 0,
    //   };
    //   dataExport.push(obj);
    // });
    // exportFileExcel(
    //   dataExport,
    //   `Shipping Cost Report ${new Date(state.startDate).toLocaleDateString(
    //     "id-ID"
    //   )} s/d ${new Date(state.endDate).toLocaleDateString("id-ID")}`,
    //   extensionChoose
    // );
    setOpenExport(false);
  };

  const searchDate = () => {
    const obj = params;
    obj.startDate = state.startDate.toISOString();
    obj.endDate = state.endDate.toISOString();
    setStatusSearch(true);
    setSearchParams(obj);
    setOpen(false);
  };

  // useEffect(() => {
  //   if (query && query.search) setValues(query.search);
  //   else setValues("");
  // }, [query]);
  useEffect(() => {
    onProcess(state, statusSearch);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusSearch]);

  useEffect(() => {
    setCount(Object.keys(data).length);
    if (searchQuery) {
      searchSeller();
    } else if (!searchQuery && pageQuery) {
      changePage();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, searchParams]);

  return (
    <Fragment>
      {open ? (
        <BasicModal title="Select Range Date" open={open} onClose={setOpen}>
          <DateRangePicker
            onChange={(item) => {
              setState(item.selection);
            }}
            showSelectionPreview={true}
            moveRangeOnFirstSelection={false}
            months={2}
            ranges={[state]}
            direction="horizontal"
          />

          <Grid item width="100%" display="flex" flexDirection="row-reverse">
            <Button
              onClick={() => {
                searchDate();
              }}
              title="Filter"
              variant="outlined"
              color="success"
              size="small"
            >
              Search
            </Button>
            <Button
              onClick={() => {
                setOpen(false);
                setState({
                  startDate: new Date(),
                  endDate: new Date(),
                  key: "selection",
                });
              }}
              style={{ marginRight: 15 }}
              title="Cancel"
              variant="text"
              size="small"
            >
              Cancel
            </Button>
          </Grid>
        </BasicModal>
      ) : null}

      {openExport ? (
        <BasicModal
          title="Choose Extension *jika format xlsx atau xls error, coba download dengan csv"
          open
          onClose={(_) => setOpenExport(false)}
        >
          <Stack direction="row" spacing={1} alignItems="center">
            {/* <Button
              variant="outlined"
              color="primary"
              size="small"
              onClick={(_) => exportData(".csv")}
            >
              .CSV
            </Button>
            <Button
              variant="outlined"
              color="primary"
              size="small"
              onClick={(_) => exportData(".xls")}
            >
              .XLS
            </Button> */}
            <Button
              variant="outlined"
              color="primary"
              size="small"
              onClick={(_) => exportData(".xlsx")}
            >
              .XLSX
            </Button>
          </Stack>
        </BasicModal>
      ) : null}

      <Grid
        item
        width="100%"
        justifyContent="space-between"
        display="flex"
        sx={{ mb: 2 }}
      >
        <Grid item display="flex">
          {statusSearch ? (
            <Button
              size="small"
              endIcon={<RemoveCircleOutlineOutlined />}
              variant="outlined"
              style={{ marginLeft: 5 }}
              color="error"
              onClick={() => {
                const obj = params;
                delete obj.startDate;
                delete obj.endDate;
                setSearchParams(obj);
                setStatusSearch(false);
                setState({
                  startDate: new Date(),
                  endDate: new Date(),
                  key: "selection",
                });
              }}
              title="Remove Filter Date"
            >
              {new Date(state.startDate).toLocaleDateString("id-ID", {
                timeZone: "Asia/Jakarta",
              })}{" "}
              -{" "}
              {new Date(state.endDate).toLocaleDateString("id-ID", {
                timeZone: "Asia/Jakarta",
              })}
            </Button>
          ) : (
            <Button
              size="small"
              variant="outlined"
              style={{ marginLeft: 5 }}
              onClick={() => setOpen(true)}
              title="Filter date"
            >
              Choose Date Range
            </Button>
          )}
          <Button
            title="Export Excel"
            style={{ marginLeft: 5 }}
            endIcon={<ImportExportOutlined />}
            size="small"
            variant="outlined"
            color="secondary"
            onClick={(_) => setOpenExport(true)}
          >
            Export Data
          </Button>
        </Grid>

        <SearchHead
          values={searchQuery}
          setCount={setCount}
          placeholder="Search here"
          onEnter={(variable) => {
            // push({ ...query, search: variable });
            const obj = params;
            obj.search = variable;
            obj.page = 1;
            if (!variable) {
              // setValues("");
              const obj = params;
              delete obj.search;
              setSearchParams(obj);
            }
            setSearchParams(obj);
            searchSeller(variable);
          }}
        />
      </Grid>

      <Grid item />

      <Divider />

      <TableContainer
        style={{
          minHeight: "50vh",
          maxWidth: 1500,
          maxHeight: limit > 10 ? "70vh" : "70vh",
        }}
      >
        <Table size="small" style={{ width: "100%" }} stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell
                sx={{
                  position: "sticky",
                  left: 0,
                  background: "white",
                  zIndex: 3,
                }}
              >
                SELLER
              </TableCell>
              <TableCell align="center">JNE</TableCell>
              <TableCell align="center">JNT</TableCell>
              <TableCell align="center">ANTER AJA</TableCell>
              <TableCell align="center">NINJA</TableCell>
              <TableCell align="center">ID</TableCell>
              <TableCell align="center">LION</TableCell>
              <TableCell align="center">SI CEPAT</TableCell>
              <TableCell align="center">SHOPEE</TableCell>
              <TableCell align="center">LEX</TableCell>
              <TableCell align="center">WAHANA</TableCell>
              <TableCell align="center">OTHER</TableCell>
              <TableCell align="center">TOTAL PAKET</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.keys(newData).length >= 1
              ? Object.keys(newData).map((key, index) => (
                  <TableRow key={index} hover>
                    <TableCell
                      sx={{
                        position: "sticky",
                        left: 0,
                        background: "white",
                        borderRight: 1,
                      }}
                    >
                      {key}
                    </TableCell>
                    <TableCell align="center">
                      {financeCard(
                        newData[key]["jne"],
                        newData[key]["jne_berat"],
                        newData[key]["jne_price"],
                        auth.group_name
                      )}
                    </TableCell>
                    <TableCell align="center">
                      {financeCard(
                        newData[key]["jnt"],
                        newData[key]["jnt_berat"],
                        newData[key]["jnt_price"],
                        auth.group_name
                      )}
                    </TableCell>
                    <TableCell align="center">
                      {financeCard(
                        newData[key]["anteraja"],
                        newData[key]["anteraja_berat"],
                        newData[key]["anteraja_price"],
                        auth.group_name
                      )}
                    </TableCell>
                    <TableCell align="center">
                      {financeCard(
                        newData[key]["ninja_express"],
                        newData[key]["ninja_express_berat"],
                        newData[key]["ninja_express_price"],
                        auth.group_name
                      )}
                    </TableCell>
                    <TableCell align="center">
                      {financeCard(
                        newData[key]["id_express"],
                        newData[key]["id_express_berat"],
                        newData[key]["id_express_price"],
                        auth.group_name
                      )}
                    </TableCell>
                    <TableCell align="center">
                      {financeCard(
                        newData[key]["lion"],
                        newData[key]["lion_berat"],
                        newData[key]["lion_price"],
                        auth.group_name
                      )}
                    </TableCell>
                    <TableCell align="center">
                      {financeCard(
                        newData[key]["sicepat"],
                        newData[key]["sicepat_berat"],
                        newData[key]["sicepat_price"],
                        auth.group_name
                      )}
                    </TableCell>
                    <TableCell align="center">
                      {financeCard(
                        newData[key]["shopee"],
                        newData[key]["shopee_berat"],
                        newData[key]["shopee_price"],
                        auth.group_name
                      )}
                    </TableCell>
                    <TableCell align="center">
                      {financeCard(
                        newData[key]["lex"],
                        newData[key]["lex_berat"],
                        newData[key]["lex_price"],
                        auth.group_name
                      )}
                    </TableCell>
                    <TableCell align="center">
                      {financeCard(
                        newData[key]["wahana"],
                        newData[key]["wahana_berat"],
                        newData[key]["wahana_price"],
                        auth.group_name
                      )}
                    </TableCell>
                    <TableCell align="center">
                      {financeCard(
                        newData[key]["other"],
                        newData[key]["other_berat"],
                        newData[key]["other_price"],
                        auth.group_name
                      )}
                    </TableCell>
                    <TableCell align="center">
                      {financeCard(
                        newData[key]["total"],
                        newData[key]["total_berat"].toFixed(2),
                        newData[key]["total_price"],
                        auth.group_name
                      )}
                    </TableCell>
                  </TableRow>
                ))
              : null}
          </TableBody>
        </Table>

        {loading ? (
          <div className="center">
            <CircularProgress size={25} />
          </div>
        ) : !Object.keys(newData).length ? (
          <Alert variant="standard" severity="warning">
            No Data
          </Alert>
        ) : null}
      </TableContainer>
      <TablePagination
        component="div"
        count={count}
        onPageChange={(_, newPage) => {
          const obj = params;
          obj.page = `${newPage + 1}`;
          setSearchParams(obj);
        }}
        page={+params.page - 1}
        rowsPerPage={limit}
        rowsPerPageOptions={[10]}
        ActionsComponent={TablePaginationActions}
      />
    </Fragment>
  );
};
