import {Fragment, useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {
	Button,
	TableContainer,
	Table,
	Grid,
	TableBody,
	Alert,
	TableCell,
	TableRow,
	Checkbox,
	CircularProgress,
	IconButton,
	TablePagination,
	ListItem,
	ListItemText,
	Typography,
	TextField,
} from '@mui/material';
import {MultilineChartOutlined, EditOutlined, DeleteOutline} from '@mui/icons-material';
import {EnchancedTableHead, TablePaginationActions} from '../../components/spam/pagination';
import {BasicModal} from '../../components/spam/modal';
import {convertDataToQuery, convertDate} from '../../helpers/custom';
import {SearchHead} from '../../components/spam/inputs';
import {useRootLists, useDelete, useEdit} from '../../services/lists';
import {EditAdmin} from './edit/admin';


const tableHead = {
	admin: [
		{id: 1, original: '', label: 'No'},
		{id: 2, original: 'create_date', label: 'Create Date'},
		{id: 3, original: 'update_date', label: 'Last Update'},
		{id: 4, original: 'full_name', label: 'Full Name'},
		{id: 5, original: 'username', label: 'Username'},
		{id: 6, original: 'email', label: 'Email'},
		{id: 7, original: '', label: 'Group Name'},
		{id: 7, original: '', label: 'Group Level'},
		{id: 8, original: '', label: 'Superior'},
		{id: 9, original: '', label: 'Action'},
	],
	group: [
		{id: 1, original: '', label: 'No'},
		{id: 2, original: 'create_date', label: 'Create Date'},
		{id: 3, original: 'update_date', label: 'Last Update'},
		{id: 4, original: 'name', label: 'Group Name'},
		{id: 5, original: 'level', label: 'Group Level'},
		{id: 6, original: '', label: 'Superior Name'},
		{id: 7, original: '', label: 'Superior Level'},
		{id: 8, original: '', label: 'Action'},
	],
	privileges: [
		{id: 1, original: '', label: 'No'},
		{id: 2, original: 'create_date', label: 'Create Date'},
		{id: 3, original: 'update_date', label: 'Last Update'},
		{id: 4, original: '', label: 'Module Name'},
		{id: 5, original: '', label: 'Group Name'},
		{id: 6, original: '', label: 'Create Data'},
		{id: 7, original: '', label: 'Read Data'},
		{id: 8, original: '', label: 'Update Data'},
		{id: 9, original: '', label: 'Delete Data'},
		{id: 10, original: '', label: 'Action'},
	],
	modules: [
		{id: 1, original: '', label: 'No'},
		{id: 2, original: 'create_date', label: 'Create Date'},
		{id: 3, original: 'update_date', label: 'Last Update'},
		{id: 4, original: 'identity', label: 'Module Name'},
		{id: 5, original: '', label: 'Approval'},
		{id: 6, original: '', label: 'Count Approval'},
		{id: 7, original: '', label: 'Action'},
	]
}

const DeleteItem = ({close, open, data, onRefresh}) => {
	const {module: moduleName} = useParams(),
		{onProcess, res, loading} = useDelete();

	useEffect(() => {
		if(res.status === 'ok') {
			onRefresh();
			close(false);
		}
	}, [onRefresh, res.status, close])

	return (
		<BasicModal title='are you sure you want to delete this data?' onClose={close} open={open}>
			<Grid item display='flex' flexDirection='column'>
				{moduleName === 'privileges'
					?	<ListItem style={{flexDirection: 'column', display: 'flex'}}>
							<ListItemText primary='Module name' secondary={data.module_name || '-'}/>
							<ListItemText primary='Group name' secondary={data.group_name || '-'}/>
						</ListItem>
					:	moduleName === 'modules'
						?	<ListItem style={{flexDirection: 'column', display: 'flex'}}>
								<ListItemText primary='Module Name' secondary={data.identity || '-'}/>
								<ListItemText primary='Count Approval' secondary={data.count_approval || '-'}/>
							</ListItem>
						:	moduleName === 'admin'
							?	<ListItem style={{flexDirection: 'column', display: 'flex'}}>
									<ListItemText primary='Email' secondary={data.email || '-'}/>
								</ListItem>
							: moduleName === 'group'
								?	<ListItem style={{textAlign: 'left', alignItems: 'flex-start', flexDirection: 'column', display: 'flex'}}>
										<ListItemText primary='Group Name' secondary={data.name || '-'}/>
										<ListItemText primary='Group Level' secondary={data.level || '-'}/>
										<ListItemText primary='Superior Name' secondary={data.parent_name || '-'}/>
									</ListItem>
								:	null}
			</Grid>
			<Grid item flexDirection='row' display='flex' style={{width: '100%', marginTop: 20}} justifyContent='space-around'>
				{loading
					?	<CircularProgress size={25}/>
					:	<Fragment>
							<Button variant='outlined' color='info' onClick={close}>Cancel</Button>
							<Button variant='outlined' color='error' onClick={() => onProcess(data.id, true)}>Sure..</Button>
						</Fragment>}
			</Grid>

			{res.message
				?	<Grid item>
						<Alert severity={res.status === 'ok' ? 'success' : 'error'}>{res.message}</Alert>
					</Grid> : null}

			<Typography variant='caption' color='red' style={{marginTop: 20}}>*nb: if you delete data, then all data relations will also be deleted</Typography>
		</BasicModal>
	)
}

export const HomeListPage = () => {
	const {onProcess, loading, res, data, onPush, parseQuery, updateAll, updateOne, offset, limit, moduleName, selectItems} = useRootLists(),
		push = useNavigate(),
		[dataSelect, setDataSelect] = useState({data: null, action: ''}),
		[values, setValues] = useState('');

	function handleRequestSort (_, property) {
		if(!property)
			return;
		let query = {...parseQuery};
		query.orderBy = property;
		query.sortBy = parseQuery.orderBy !== property ? 'desc' : parseQuery.sortBy === 'asc' ? 'desc' : 'asc';

		onPush({page: 1, query});
	}

	useEffect(() => {
		onProcess();
	}, [onProcess])

	useEffect(() => {
		if(parseQuery.search !== undefined)
			setValues(parseQuery.search);

		else setValues('');
	}, [parseQuery])

	useEffect(() => {
		document.title = `BD - ${moduleName}`
	}, [moduleName]);
	
	return (
		<Fragment>
			{dataSelect && dataSelect.data
				?	(dataSelect.action === 'delete'
					?	<DeleteItem
							onRefresh={onProcess}
							data={dataSelect.data}
							open={dataSelect.data.id && dataSelect.action === 'delete'}
							close={() => setDataSelect({data: null, action: ''})}/>
					: dataSelect.action === 'edit'
						?	moduleName === 'admin'
							?	<EditAdmin
									open={dataSelect.data.id && dataSelect.action === 'edit'}
									close={() => setDataSelect({data: null, action: ''})}
									callback={dataCallback => updateOne({identity: dataCallback.id, payload: dataCallback})}
									defaultValue={dataSelect.data}/>
							:	null
						: null)
				:	null}
			<Grid item width='100%' justifyContent='space-between' display='flex'>
				<Button onClick={() => push(`/root/${moduleName}/create`, {state: {from: `/root/${moduleName}/${offset}/${limit}${parseQuery ? convertDataToQuery(parseQuery) : ''}`}})} variant='outlined' color='success'>
					Create {moduleName}
				</Button>

				<SearchHead
					values={values}
					onEnter={variable => onPush({page: 1, query: {...parseQuery, search: variable}})}/>
			</Grid>

			<TableContainer style={{minHeight: '50vh', maxHeight: '70vh'}}>
				<Table style={{width: '100%'}} stickyHeader>
					<EnchancedTableHead
						numSelected={selectItems.length}
						order={parseQuery.sortBy || 'desc'}
						orderBy={parseQuery.orderBy || 'create_date'}
						onSelectAllClick={({target: {checked = false}}) => updateAll({key: 'isChecked', value: checked})}
						onRequestSort={handleRequestSort}
						rowCount={data.data.length}
						headCells={tableHead[moduleName]}/>
					<TableBody>
						{data.data.length > 0 && !loading
							?	data.data.map((option, index) => {
								const {date, time} = convertDate(option.create_date),
									{date: _lastDate, time: _lastTime} = convertDate(option.update_date);
								return (
									<TableRow key={index} hover>
										<TableCell padding='checkbox'>
											<Checkbox
												color='primary'
												onChange={({target: {checked}}) => updateOne({identity: option.id, key: 'isChecked', value: checked})}
												checked={option.isChecked || false}
												inputProps={{'aria-labelledby': `enchanced-table-checkbox-${option.id}`}}/>
										</TableCell>
										<TableCell>{index + 1}</TableCell>
										<TableCell>
											{date}<br/>
											{time}
										</TableCell>
										<TableCell>
											{_lastDate}<br/>
											{_lastTime}
										</TableCell>
										{moduleName === 'admin'
											?	<AdminCell option={option} setDataSelect={setDataSelect}/>
											: moduleName === 'group'
												?	<GroupCell option={option} setDataSelect={setDataSelect}/>
												:	moduleName === 'privileges'
													?	<PrivilegeCell option={option} setDataSelect={setDataSelect}/>
													:	moduleName === 'modules'
														?	<ModuleCell option={option} setDataSelect={setDataSelect}/>
														:	null}
									</TableRow>
								)
							})
							:	null}
					</TableBody>
				</Table>
				{loading
					?	<div className="center">
							<CircularProgress size={25}/>
						</div>
					:	res.message
						?	<Alert variant='standard' severity='error'>{res.message}</Alert>
						:	data.data.length === 0
							?	<Alert variant='standard' severity='warning'>No Data</Alert>
							:	null}
			</TableContainer>

			<TablePagination
				rowsPerPageOptions={[5, 10, 15, 25]}
				component='div'
				count={data.count}
				rowsPerPage={parseInt(limit || '10', 10)}
				page={data.count === 0 ? 0 : parseInt(offset || '1', 10) - 1}
				onPageChange={(_, newPage) => onPush({page: newPage + 1})}
				onRowsPerPageChange={({target: {value = '10'}}) => onPush({page: 1, perPage: parseInt(value, 10)})}
				ActionsComponent={TablePaginationActions}/>
		</Fragment>
	)
}

const ModuleCell = ({option, setDataSelect}) => {
	const [showEdit, setShowEdit] = useState(false),
		[payload, setPayload] = useState({
			identity: option.identity || '',
			approval: option.approval || false,
			count_approval: option.count_approval || '',
		}),
		{loading, onProcess, res} = useEdit(),
		backDefault = _ => {
			setPayload({
				identity: option.identity || '',
				approval: option.approval || false,
				count_approval: option.count_approval || '',
			})
			setShowEdit(false);
		},
		onUpdate = () => {
			let _payload = {};
			if(payload.identity && option.identity !== payload.identity)
				_payload.identity = payload.identity;

			if(option.approval !== undefined && option.approval !== payload.approval)
				_payload.approval = payload.approval;

			const parsingInt = parseInt(payload.count_approval || '0', 10);
			if(parsingInt !== option.count_approval)
				_payload.count_approval = parsingInt;

			onProcess(option.id, _payload, () => setShowEdit(false))
		}
	
	return (
		<Fragment>
			<TableCell>
				{showEdit
					?	<TextField
							value={payload.identity}
							onChange={({target: {value}}) => setPayload(prev => ({...prev, identity: value}))}
							variant='standard'
							size='small'
							placeholder='eg: identity *required'/>
					:	<Fragment>
							{payload.identity || '-'}
							<IconButton
								title='Relation Privilege'
								href={`/root/privileges/1/10?orderBy=create_date&sortBy=desc&module_id=${option.id}`}
								target='_blank'>
								<MultilineChartOutlined/>
							</IconButton>
						</Fragment>}
			</TableCell>
			<TableCell>
				<Checkbox
					disabled={!showEdit}
					checked={payload.approval || false}
					color={payload.approval ? 'success' : 'error'}
					onChange={({target: {checked}}) => setPayload(prev => ({...prev, approval: checked, count_approval: checked ? '1' : ''}))}/>
			</TableCell>
			<TableCell>
				{!showEdit
					?	payload.count_approval || '-'
					:	<TextField
							size='small'
							variant='standard'
							disabled={!payload.approval}
							placeholder='eg: 1 *required if need approval*'
							value={payload.count_approval}
							onChange={({target: {value}}) => setPayload(prev => ({...prev, count_approval: value && !isNaN(Number(value)) ? value : ''}))}/>}
			</TableCell>
			<TableCell>
				{showEdit
					?	(loading
						?	<CircularProgress size={25}/>
						:	<Fragment>
								<Button size='small' variant='text' onClick={backDefault} color='info'>Cancel</Button>
								<Button size='small' style={{marginLeft: 5}} variant='outlined' color='primary' onClick={onUpdate}>Update</Button>
							</Fragment>)
					:	<Fragment>
							<IconButton
								color='primary'
								title='Update Privilege'
								size='small'
								onClick={() => setShowEdit(true)}>
								<EditOutlined/>
							</IconButton>
							<IconButton
								onClick={() => setDataSelect({data: {...option, ...payload}, action: 'delete'})}
								size='small'
								color='error'
								title='Delete Privilege'>
								<DeleteOutline/>
							</IconButton>
						</Fragment>}
				{res.message
					?	<Fragment>
							<br/>
							<Typography variant='caption' color={res.status === 'ok' ? 'green' : 'red'}>{res.message}</Typography>
						</Fragment> : null}
			</TableCell>
		</Fragment>

	)
}

const AdminCell = ({option, setDataSelect}) => (
	<Fragment>
		<TableCell>{option.full_name || '-'}</TableCell>
		<TableCell>{option.username || '-'}</TableCell>
		<TableCell>{option.email || '-'}</TableCell>
		<TableCell>
			{option.group_name || '-'}
		
			<IconButton
				title='Relation Privilage'
				href={`/root/privileges/1/10?orderBy=create_date&sortBy=desc&group_id=${option.group_id}`}
				target='_blank'>
				<MultilineChartOutlined/>
			</IconButton>
		</TableCell>
		<TableCell>
			{option.level || '-'}
		</TableCell>
		<TableCell>{option.parent_group_name || '-'}</TableCell>
		<TableCell>
			{/* <IconButton
				color='secondary'
				title='Admin Privilege'>
				<FolderSpecial/>
			</IconButton> */}
			<IconButton
				color='primary'
				title='Update Admin'
				onClick={() => setDataSelect({data: option, action: 'edit'})}>
				<EditOutlined/>
			</IconButton>
			<IconButton
				onClick={() => setDataSelect({data: option, action: 'delete'})}
				color='error'
				title='Delete Admin'>
				<DeleteOutline/>
			</IconButton>
		</TableCell>
	</Fragment>
)


const PrivilegeCell = ({option, setDataSelect}) => {
	const [showEdit, setShowEdit] = useState(false),
		[payload, setPayload] = useState({
			create: option.create || false,
			read: option.read || false,
			update: option.update || false,
			delete: option.delete || false,
		}),
		{loading, onProcess, res} = useEdit(),
		onChange = (key) => ({target: {checked}}) =>
			setPayload(prev => ({...prev, [key]: checked})),
		backDefault = _ => {
			setPayload({
				create: option.create || false,
				read: option.read || false,
				update: option.update || false,
				delete: option.delete || false,
			})
			setShowEdit(false);
		}

	return (
		<Fragment>
			<TableCell>
				{option.module_name || '-'}

				<IconButton
					title='Relation Privilege'
					href={`/root/privileges/1/10?orderBy=create_date&sortBy=desc&module_id=${option.module_id}`}
					target='_blank'>
					<MultilineChartOutlined/>
				</IconButton>
			</TableCell>
			<TableCell>
				{option.group_name || '-'}

				<IconButton
					title='Relation Admin'
					href={`/root/admin/1/10?orderBy=create_date&sortBy=desc&group_id=${option.group_id}`}
					target='_blank'>
					<MultilineChartOutlined/>
				</IconButton>
			</TableCell>
			<TableCell>
				<Checkbox
					disabled={!showEdit}
					checked={payload.create || false}
					color={payload.create ? 'success' : 'error'}
					onChange={onChange('create')}/>
			</TableCell>
			<TableCell>
				<Checkbox
					disabled={!showEdit}
					checked={payload.read || false}
					color={payload.read ? 'success' : 'error'}
					onChange={onChange('read')}/>
			</TableCell>
			<TableCell>
				<Checkbox
					disabled={!showEdit}
					checked={payload.update || false}
					color={payload.update ? 'success' : 'error'}
					onChange={onChange('update')}/>
			</TableCell>
			<TableCell>
				<Checkbox
					disabled={!showEdit}
					checked={payload.delete || false}
					color={payload.delete ? 'success' : 'error'}
					onChange={onChange('delete')}/>
			</TableCell>
			<TableCell>
				{showEdit
					?	(loading
						?	<CircularProgress size={25}/>
						:	<Fragment>
								<Button size='small' variant='text' onClick={backDefault} color='info'>Cancel</Button>
								<Button size='small' style={{marginLeft: 5}} variant='outlined' color='primary' onClick={() => onProcess(option.id, payload, () => setShowEdit(false))}>Update</Button>
							</Fragment>)
					:	<Fragment>
							<IconButton
								color='primary'
								title='Update Privilege'
								onClick={() => setShowEdit(true)}
								size='small'>
								<EditOutlined/>
							</IconButton>
							<IconButton
								color='error'
								size='small'
								title='Delete Privilege'
								onClick={() => setDataSelect({data: option, action: 'delete'})}>
								<DeleteOutline/>
							</IconButton>
						</Fragment>}
				{res.message
					?	<Fragment>
							<br/>
							<Typography variant='caption' color={res.status === 'ok' ? 'green' : 'red'}>{res.message}</Typography>
						</Fragment> : null}
			</TableCell>
		</Fragment>
	)
}

const GroupCell = ({option, setDataSelect}) => (
	<Fragment>
		<TableCell>
			{option.name || '-'}

			<IconButton
				title='Relation Admin'
				href={`/root/admin/1/10?orderBy=create_date&sortBy=desc&group_id=${option.id}`}
				target='_blank'>
				<MultilineChartOutlined/>
			</IconButton>
		</TableCell>
		<TableCell>
			{option.level || '-'}

			<IconButton
				title='Relation Privileges'
				href={`/root/privileges/1/10?orderBy=create_date&sortBy=desc&group_id=${option.id}`}
				target='_blank'>
				<MultilineChartOutlined/>
			</IconButton>
		</TableCell>
		<TableCell>{option.parent_name || '-'}</TableCell>
		<TableCell>{option.parent_level || '-'}</TableCell>
		<TableCell>
			{/* <IconButton
				color='secondary'
				title='Add to Admin'>
				<GroupAddOutlined/>
			</IconButton>
			<IconButton
				color='primary'
				title='Update Group'>
				<EditOutlined/>
			</IconButton> */}
			<IconButton
				color='error'
				onClick={() => setDataSelect({data: option, action: 'delete'})}
				title='Delete Group'>
				<DeleteOutline/>
			</IconButton>
		</TableCell>
	</Fragment>
)