import writeXlsxFile from "write-excel-file";

export const exportFileExcel = (
  items = [],
  fileName = "ReportTransaction",
  extension = ".csv"
) => {
  const replacer = (key, value) => (value === null ? "" : value),
    _header = Object.keys(items[0]).map((el) => ({
      original: el,
      view: el.replace(/_/g, " "),
    })),
    header = _header.filter((el) => el.original !== "dccb");

  let csv = items.map((item) =>
    header
      .map((fieldName) => JSON.stringify(item[fieldName.original], replacer))
      .join(",")
  );
  csv.unshift(header.map((el) => el.view).join(","));
  let joinCsv = csv.join("\r\n"),
    downloadLink = document.createElement("a"),
    blob = new Blob(["\ufeff", joinCsv]),
    url = URL.createObjectURL(blob),
    __fileName = (() => {
      const _fileName = `${fileName}-${new Date()}`;

      return `${_fileName}${extension}`;
    })();
  downloadLink.href = url;

  downloadLink.download = __fileName;
  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);
};

export const exportFileExcelNew = async (
  items = [],
  fileName = "ReportTransaction",
  extension = ".xlsx"
) => {
  const schema = [
    {
      column: "Tanggal Pengiriman",
      type: String,
      value: (data) => data["tanggal_pengiriman"],
    },
    {
      column: "Nama Pengirim",
      type: String,
      value: (data) => data["nama_pengirim"],
    },
    {
      column: "Nama Toko",
      type: String,
      value: (data) => data["nama_toko"],
    },
    {
      column: "Nama Penerima",
      type: String,
      value: (data) => data["nama_penerima"],
    },
    {
      column: "Nomor Penerima",
      type: String,
      value: (data) => data["kontak_penerima"],
    },
    {
      column: "Nama Barang",
      type: String,
      value: (data) => data["nama_barang"],
    },
    {
      column: "Berat",
      type: String,
      value: (data) => data["berat"],
    },
    {
      column: "Service",
      type: String,
      value: (data) => data["service"],
    },
    {
      column: "Expedisi",
      type: String,
      value: (data) => data["three_PL"],
    },
    {
      column: "Ongkir",
      type: Number,
      value: (data) => Number(data["ongkir"]),
    },
    {
      column: "Awb",
      type: String,
      value: (data) => data["awb"],
    },
    {
      column: "E-Commerce",
      type: String,
      value: (data) => {
        const awbPrefix = data["awb"].substring(0, 2);
        switch (awbPrefix) {
          case "JX":
            return "TIKTOK";
          case "JP":
            return "SHOPEE";
          case "JD":
            return "REGULAR";
          case "JZ":
            return "LAZADA";
          case "JT":
            return "TOKOPEDIA";
          case "JB":
            return "BUKALAPAK";
          case "TK":
            return "TOKOPEDIA JNT";
          case "TL":
            return "TOKOPEDIA JNE";
          case "CM":
            return "SHOPEE JNE";
          case "JO":
            return "AKULAKU";
          case "JJ":
            return "BLIBLI";
          default:
            return awbPrefix === "13" ? "VIP" : "";
        }
      },
    },
  ];

  await writeXlsxFile(items, {
    schema,
    fileName: `${fileName}.xlsx`,
  });
};
