import {useState, useContext, useMemo, useEffect} from 'react';
import {
	Grid,
	TextField,
	ListItem,
	ListItemText,
	Alert,
	ListItemIcon,
	Button,
	CircularProgress,
	Typography,
	Checkbox
} from '@mui/material';
import {SaveAltOutlined, AddOutlined} from '@mui/icons-material';
import {GroupSelection} from '../../../components/root';
import {OptionPrivilegeCreate} from '../../../components/spam/optionPrivilegesCreate';
import {GroupContext} from '../../../utils/group';
import {useCreate} from '../../../services/modules';
import {useCreate as createPrivilege} from '../../../services/privileges';

export const ModuleCreate = () => {
	const [identity, setIdentity] = useState(''),
		{data: dataGroups, updateOne, updateAll: allGroup} = useContext(GroupContext),
		{onProcess: processCreatePrivilege} = createPrivilege(),
		{onProcess, loading} = useCreate(),
		[res, setRes] = useState({status: '', message: ''}),
		[approval, setApproval] = useState(true),
		[count, setCount] = useState(1),
		[showGroupList, setShowGroupList] = useState(false),
		[privileges, setPrivileges] = useState([]),
		selectionsGroups = useMemo(() => {
			if(dataGroups.length === 0)
				return [];
			return dataGroups.filter(el => el.isChecked);
		}, [dataGroups]),
		loadPrivileges = useMemo(() => {
			if(privileges.length === 0)
				return false;
			return privileges.filter(el => el.loading).length > 0;
		}, [privileges])



	const updatePrivileges = ({group_id, payload = undefined, key, value}) => {

		if(group_id === 'all')
			return setPrivileges(prev => prev.map(el => ({...el, [key]: value})));
		setPrivileges(prev => prev.map(el => {
			if(group_id === el.group_id) {
				if(payload !== undefined)
					return {...el, ...payload};
				return {...el, [key]: value};
			}
			return el;
		}))
	}



	function addOthers () {
		setIdentity('');
		setCount(1);
		allGroup({key: 'isChecked', value: false});
		setRes({status: '', message: ''});
	}

	function onCreateData () {
		onProcess({identity, approval, count_approval: parseInt(count, 10)}, async (err, _respModule) => {
			if(err)
				return setRes({status: 'error', message: err});
			
			const module_id = _respModule.id;

			if(!module_id)
				return setRes({status: 'error', message: 'invalid response from server'});

			if(privileges.length > 0) {
				await updatePrivileges({group_id: 'all', key: 'loading', value: true});

				for(const privilege of privileges) {
					const payload = {
						group_id: privilege.group_id,
						module_id,
						create: privilege.create,
						read: privilege.read,
						update: privilege.update,
						delete: privilege.delete,
					};
					await processCreatePrivilege(payload, async (err,  _) => {
						if(err) {
							await updatePrivileges({group_id: payload.group_id, payload: {message: err, loading: false}});
							return;
						}

						await updatePrivileges({group_id: payload.group_id, key: 'loading', value: false});
					})

				}

				setRes({status: 'ok', message: `Success create module, and success create privilege`});
				return;
			}

			setRes({status: 'ok', message: 'Success create module'})
		})
	}
	
	useEffect(() => {
		if(selectionsGroups.length > 0) {
			setPrivileges(selectionsGroups.map(el => ({
				module_id: null,
				group_id: el.id,
				group_name: el.name,
				create: true,
				read: true,
				update: true,
				delete: true,
				loading: false,
				message: '',
			})))
		}
		else
			setPrivileges([]);
	}, [selectionsGroups]);


	useEffect(() => {
		return () =>
			allGroup({key: 'isChecked', value: false});
	}, [allGroup])

	return (
		<Grid container flexDirection='column' spacing={2} marginTop={1}>
			{showGroupList
				?	<GroupSelection
						open={showGroupList}
						close={() => setShowGroupList(false)}/>
				:	null}
			<Grid item>
				<TextField
					value={identity}
					onChange={({target: {value}}) => setIdentity(value)}
					label='Module Name'
					placeholder='eg: Cashback'
					variant='standard'/>
			</Grid>
			<Grid item>
				<Typography variant='caption'>Approval</Typography>
				<br/>
				<Checkbox
					checked={approval}
					onChange={({target: {checked}}) => setApproval(checked)}
					variant='standard'
					size='small'
					label='Approval ?'/>

				&nbsp;
				&nbsp;
				&nbsp;

				{approval
					?	<TextField
							value={count}
							onChange={({target: {value}}) => {
								if(!value)
									return setCount('');
								if(!isNaN(Number(value)))
									setCount(value);
							}}
							label='Count Approval'
							placeholder='eg: 1'
							variant='standard'/> : null}
			</Grid>


			<Grid item>
				<ListItem button onClick={() => setShowGroupList(true)}>
					<ListItemText primary='Choose Group' secondary='*Many choices possible*'/>
					<ListItemIcon>
						<AddOutlined color='primary'/>
					</ListItemIcon>
				</ListItem>
			</Grid>

			{privileges.length > 0
				?	<OptionPrivilegeCreate
						privileges={privileges}
						moduleName={identity}
						updatePrivileges={({privilege, key, value}) => updatePrivileges({group_id: privilege.group_id, key, value})}
						deleteOne={privilege => updateOne(privilege.group_id, {key: 'isChecked', value: false})}/> : null}

			<Grid item>
				{res.status === 'ok'
					?	<Button
							variant='outlined'
							size='small'
							color='success'
							startIcon={<AddOutlined/>}
							onClick={addOthers}>Add Other</Button>
					:	loading || loadPrivileges
						?	<CircularProgress size={25}/>
						:	<Button
								size='small'
								variant='outlined'
								color='primary'
								onClick={onCreateData}
								startIcon={<SaveAltOutlined/>}>Save Module</Button>}

			</Grid>

			{res.message
				?	<Grid item>
						<Alert severity={res.status === 'ok' ? 'success' : 'error'} variant='standard'>{res.message}</Alert>
					</Grid>
				: null}
		</Grid>
	)
}