import { useState, useContext, useCallback, useMemo } from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import x from "axios";
import gtw from "../apis/gtw";
import { AuthContext } from "../utils/auth";
import { getQueryUrl, convertDataToQuery } from "../helpers/custom";
import { handleError, useCancelRequest } from "./helpers";

export function useLogin() {
  const [loading, setLoading] = useState(false),
    { login } = useContext(AuthContext),
    [request, setRequest] = useState(null),
    [data, setData] = useState({ request: "", password: "" }),
    [res, setRes] = useState({ status: "", message: "" }),
    onProcess = async function (e) {
      if (loading) return;
      if (e.preventDefault) e.preventDefault();
      setLoading(true);
      setRes({ status: "", message: "" });
      const cancel = await (async function () {
        try {
          const _req = x.CancelToken.source();
          setRequest(_req);
          const { data: _data } = await gtw({
            method: "post",
            url: `/user/login`,
            data,
            cancelToken: _req.token,
          });
          if (_data) {
            if (!e.ignoreSaveToken) login(_data);
            if (e.callback) return e.callback(true);
          }
        } catch (e) {
          const message = handleError(e);
          if (message.cancel) return true;
          setRes({ status: "error", message });
        }
        if (e.callback) e.callback(false);
      })();
      if (cancel) return;
      setLoading(false);
    };
  const onChange = ({ target: { value, name } }) =>
    setData((prev) => ({ ...prev, [name]: value }));
  useCancelRequest(request);
  return { loading, res, onProcess, data, onChange, setData };
}

export function useCreate() {
  const [loading, setLoading] = useState(false),
    [request, setRequest] = useState(null),
    { headers } = useContext(AuthContext),
    onProcess = async function (data, callback = (err, dataAdmin) => null) {
      setLoading(true);
      const cancel = await (async function () {
        try {
          const _req = x.CancelToken.source();
          setRequest(_req);
          const { data: _data } = await gtw({
            method: "post",
            url: `/root/admin`,
            data,
            headers,
            cancelToken: _req.token,
          });

          if (_data && _data.id) return callback(null, _data);

          callback("server si not responding", null);
        } catch (e) {
          const message = handleError(e);
          if (message.cancel) return true;
          callback(message, null);
        }
      })();
      if (cancel) return;
      setLoading(false);
    };
  useCancelRequest(request);
  return { onProcess, loading };
}

export const useGetAdminId = () => {
  const [loading, setLoading] = useState(false),
    [request, setRequest] = useState(null),
    [res, setRes] = useState({ status: "", message: "" }),
    { headers, auth } = useContext(AuthContext),
    [data, setData] = useState(null),
    onProcess = useCallback(
      async (internalId) => {
        setLoading(true);
        setRes({ status: "", message: "" });
        setData(null);
        const cancel = await (async () => {
          try {
            if (auth.id === parseInt(internalId, 10))
              return setData({
                full_name: auth.full_name,
                group_name: auth.group_name,
                group_level: auth.group_level,
              });
            const _req = x.CancelToken.source();
            setRequest(_req);
            const { data: _data } = await gtw({
              method: "get",
              url: `/user/${internalId}`,
              headers,
              cancelToken: _req.token,
            });
            setData(_data);
          } catch (e) {
            const message = handleError(e);
            if (message.cancel) return true;
            setRes({ status: "error", message });
          }
        })();
        if (cancel) return;
        setLoading(false);
      },
      [headers, auth]
    );

  useCancelRequest(request);

  return { onProcess, loading, res, data };
};

export function useMyPrivilegeLists() {
  const [loading, setLoading] = useState(false),
    [request, setRequest] = useState(null),
    [res, setRes] = useState({ status: "", message: "" }),
    { headers } = useContext(AuthContext),
    { offset, limit } = useParams(),
    { search } = useLocation(),
    push = useNavigate(),
    [data, setData] = useState({ count: 0, data: [] }),
    onProcess = useCallback(
      async function () {
        setLoading(true);
        setRes({ status: "", message: "" });
        const cancel = await (async function () {
          try {
            const _req = x.CancelToken.source(),
              _page = parseInt(offset || "1", 10) - 1;
            setRequest(_req);
            const { data: _data } = await gtw({
              method: "get",
              url: `/user/privileges/${_page}/${limit}${search}`,
              cancelToken: _req.token,
              headers,
            });
            if (_data.data.length === 0)
              return setData((prev) => ({ ...prev, data: [] }));
            setData({
              ..._data,
              data: _data.data.map((el) => ({ ...el, isChecked: false })),
            });
          } catch (e) {
            const message = handleError(e);
            if (message.cancel) return true;
            setRes({ status: "error", message });
          }
        })();
        if (cancel) return;
        setLoading(false);
      },
      [headers, offset, limit, search]
    ),
    parseQuery = useMemo(() => getQueryUrl(search), [search]),
    updateOne = ({ identity, payload = undefined, key, value }) =>
      setData((prev) => ({
        ...prev,
        data: prev.data.map((option) => {
          if (option.id === identity) {
            if (payload !== undefined) return { ...option, ...payload };
            return { ...option, [key]: value };
          }
          return option;
        }),
      })),
    updateAll = ({ key, value }) =>
      setData((prev) => ({
        ...prev,
        data: prev.data.map((option) => ({ ...option, [key]: value })),
      })),
    onPush = ({ page, perPage, query }) =>
      push(
        `/my/privileges/${page || offset}/${perPage || limit}${
          query !== undefined ? convertDataToQuery(query) : search
        }`
      ),
    selectItems = useMemo(() => {
      if (data.data.length === 0) return [];
      return data.data.filter((admin) => admin.isChecked);
    }, [data.data]);

  useCancelRequest(request);

  return {
    onProcess,
    loading,
    data,
    res,
    onPush,
    parseQuery,
    updateOne,
    updateAll,
    offset,
    limit,
    selectItems,
  };
}
