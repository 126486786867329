import {useState, useContext, useCallback, useMemo, useRef} from 'react';
import {useParams, useLocation, useNavigate} from 'react-router-dom';
import x from 'axios';
import gtw from '../apis/gtw';
import {AuthContext} from '../utils/auth';
import {handleError, useCancelRequest, useCancelTimeout} from './helpers';
import {getQueryUrl, convertDataToQuery} from '../helpers/custom';
import {GroupContext} from '../utils/group';
import {ModuleContext} from '../utils/modules';

export function useRootLists () {
	const [loading, setLoading] = useState(false),
		[request, setRequest] = useState(null),
		[res, setRes] = useState({status: '', message: ''}),
		{headers} = useContext(AuthContext),
		{module, offset, limit} = useParams(),
		{search} = useLocation(),
		push = useNavigate(),
		[data, setData] = useState({count: 0, data: []}),
		onProcess = useCallback(async function () {
			setLoading(true);
			setRes({status: '', message: ''});
			const cancel = await (async function () {
				try {
					const _req = x.CancelToken.source(),
						_page = offset - 1;
					setRequest(_req);
					const {data: _data} = await gtw({method: 'get', url: `/root/${module}/${_page}/${limit}${search}`, cancelToken: _req.token, headers});
					if(_data.data.length === 0)
						return setData(prev => ({...prev, data: []}));
					setData({..._data, data: _data.data.map(el => ({...el, isChecked: false}))});
				}catch(e) {
					const message = handleError(e);
					if(message.cancel)
						return true;
					setRes({status: 'error', message});
				}
			})()
			if(cancel)
				return;
			setLoading(false);
		}, [headers, offset, limit, search, module]),
		parseQuery = useMemo(() => getQueryUrl(search), [search]),
		updateOne = ({identity, payload = undefined, key, value}) => setData(prev => ({...prev, data: prev.data.map(option => {
			if(option.id === identity) {
				if(payload !== undefined)
					return {...option, ...payload};
				return {...option, [key]: value};
			}
			return option;
		})})),
		updateAll = ({key, value}) => setData(prev => ({...prev, data: prev.data.map(option => ({...option, [key]: value}))})),
		onPush = ({page, perPage, query}) => push(`/root/${module}/${page || offset}/${perPage || limit}${query !== undefined ? convertDataToQuery(query) : search}`),
		selectItems = useMemo(() => {
			if(data.data.length === 0)
				return [];
			return data.data.filter(admin => admin.isChecked);
		}, [data.data]);

	useCancelRequest(request);

	return {onProcess, loading, data, res, onPush, parseQuery, updateOne, updateAll, offset, limit, moduleName: module, selectItems};
}

export const useDelete = () => {
	const [loading, setLoading] = useState(false),
		[request, setRequest] = useState(null),
		{headers} = useContext(AuthContext),
		{clear: clearGroup} = useContext(GroupContext),
		[res, setRes] = useState({status: '', message: ''}),
		{clear: clearModule} = useContext(ModuleContext),
		{module: moduleName} = useParams(),
		onProcess = async (id, clearing = false) => {
			setLoading(true);
			setRes({status: '', message: ''});
			const cancel = await (async function () {
				try {
					if(!id)
						throw new Error('invalid identity');
					const _req = x.CancelToken.source();
					setRequest(_req);
					const {data} = await gtw({method: 'delete', url: `/root/${moduleName}/${id}`, headers, cancelToken: _req.token});

					if(data) {
						setRes({status: 'ok', message: `${moduleName} with identity ${id} successfully deleted`});

						if(clearing) {
							clearGroup();
							clearModule();
						}
						return;
					}
					setRes({status:'error', message: 'server is not responding, please try again'});
				}catch(e) {
					const message = handleError(e);
					if(message.cancel)
						return true;
					setRes({status: 'error', message});
				}
			})();
			if(cancel)
				return;
			setLoading(false);
		}
	useCancelRequest(request);
	return {onProcess, loading, res}
}

export const useEdit = () => {
	const [loading, setLoading] = useState(false),
		[request, setRequest] = useState(null),
		[res, setRes] = useState({status: '', message: ''}),
		{headers} = useContext(AuthContext),
		_timeout = useRef(),
		{module: moduleName} = useParams(),
		onProcess = async (id, payload, callback = data => null) => {
			setLoading(true);
			setRes({status: '', message: ''});
			const cancel = await (async () => {
				try {
					if(!id)
						throw new Error('missing parameters');

					if(Object.keys(payload).length === 0)
						throw new Error('you don\'t change anything')
					const _req = x.CancelToken.source();
					setRequest(_req);
					const {data} = await gtw({method: 'put', url: `/root/${moduleName}/${id}`, data: payload, headers, cancelToken: _req.token});

					if(data && data.id) {
						setRes({status: 'ok', message: `success update ${moduleName} data`});
						_timeout.current = setTimeout(() => setRes({status: '', message: ''}), 5000)
						return callback(data);
					}

					setRes({status: 'error', message: 'server is not responding'});
				}catch(e) {
					const message = handleError(e);
					if(message.cancel)
						return true;
					setRes({status: 'error', message});
				}
				_timeout.current = setTimeout(() => setRes({status: '', message: ''}), 5000)
			})();
			if(cancel)
				return;
			setLoading(false);
		};
	useCancelRequest(request);
	useCancelTimeout(_timeout);
	return {onProcess, loading, res}
}