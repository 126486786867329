import { useState, useContext, Fragment, useEffect } from "react";
import { useLocation, Outlet, useNavigate } from "react-router-dom";
import {
  styled,
  createTheme,
  Box,
  ThemeProvider,
  Grid,
  Toolbar,
  Menu,
  Container,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  Typography,
  IconButton,
  CssBaseline,
  AppBar as MuiAppBar,
  Drawer as MuiDrawer,
  ListItemButton,
  Tooltip,
  Collapse,
} from "@mui/material";
import {
  Menu as MenuIcon,
  AttachMoneyOutlined,
  Videocam,
  // PedalBikeOutlined,
  // ScheduleOutlined,
  SupervisedUserCircleSharp,
  UploadFileOutlined,
  GifBoxOutlined,
  ArrowRight,
  PeopleAltOutlined,
  ExitToApp,
  AccountCircle,
  ChevronLeft,
  // Money,
  Dashboard,
  AdminPanelSettings,
  ViewModule,
  GroupAddOutlined,
  AccessibilityNewOutlined,
  // PriceChange,
  AccountBalanceWallet,
  ExpandLess,
  ExpandMore,
  Leaderboard,
  Compare,
  Percent,
  Receipt,
  PriceCheck,
  Key,
  WorkspacePremiumOutlined,
  Campaign,
  CoPresent,
  SupportAgent,
  ContentPasteSearch,
  LocalShipping,
  Warehouse,
  ChecklistRtl,
  CardMembership,
  Inventory,
  WorkHistory,
} from "@mui/icons-material";
import { AuthContext } from "../utils/auth";
import { ThisMonth, yesterday } from "../helpers/custom";

const currentDate = new Date();
const startOfMonth = new Date(
  currentDate.getFullYear(),
  currentDate.getMonth(),
  1
);
const formattedStartOfMonth = startOfMonth.toISOString();
// const formattedCurrentDate = currentDate.toISOString();

const drawerWidth = 250,
  AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== "open",
  })(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
  })),
  Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== "open" })(
    ({ theme, open }) => ({
      "& .MuiDrawer-paper": {
        position: "relative",
        whiteSpace: "nowrap",
        width: drawerWidth,
        transition: theme.transitions.create("width", {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        }),
        boxSizing: "border-box",
        ...(!open && {
          overflowX: "hidden",
          transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
          width: theme.spacing(7),
          [theme.breakpoints.up("sm")]: {
            width: theme.spacing(9),
          },
        }),
      },
    })
  ),
  mdTheme = createTheme();

export const PrivateLayoutPage = () => {
  const [open, setOpen] = useState(true),
    [openSub, setOpenSub] = useState(false),
    [openSubRoot, setOpenSubRoot] = useState(false),
    defaultQuery = "?orderBy=create_date&sort_by=desc",
    [anchorEl, setAnchorEl] = useState(null),
    toggleDraw = () => setOpen((prev) => !prev),
    { pathname } = useLocation(),
    { auth, logout } = useContext(AuthContext),
    // [setPickupDate] = useState({ c_from: 0, c_to: 0 }),
    push = useNavigate(),
    isMenuOpen = Boolean(anchorEl),
    onChangePage =
      (isRoot = false, namePage, options = "") =>
      (e) => {
        push(isRoot ? `/root/${namePage}${options}` : `/${namePage}${options}`);
      };
  useEffect(() => {
    let c_from = new Date(),
      c_to = new Date();
    c_from.setHours(0, 0, 0, 0);
    c_to.setHours(23, 59, 59, 59);

    c_from = Math.floor(+c_from / 1000);
    c_to = Math.floor(+c_to / 1000);
    // setPickupDate({ c_from, c_to });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderOption = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMenuOpen}
      onClose={(_) => setAnchorEl(null)}
      id="primary-menu-account"
    >
      <List>
        {/* <ListItem button onClick={() => push('/my/profile', {state: {from: true}})}>
						<ListItemIcon><Person/></ListItemIcon>
						<ListItemText>Profile</ListItemText>
					</ListItem> */}
        {auth.group_name !== "root" ? (
          <ListItem
            button
            onClick={() => {
              push("/my/privileges/1/10?orderBy=create_date&sortBy=desc", {
                state: { from: true },
              });
              setAnchorEl(null);
            }}
          >
            <ListItemIcon>
              <SupervisedUserCircleSharp />
            </ListItemIcon>
            <ListItemText
              primary="My Prvilege"
              secondary={`${auth.group_name || "-"}( ${
                auth.group_level || "-"
              } )`}
            />
          </ListItem>
        ) : null}
        <ListItem button onClick={logout}>
          <ListItemIcon>
            <ExitToApp />
          </ListItemIcon>
          <ListItemText>Logout</ListItemText>
        </ListItem>
      </List>
    </Menu>
  );
  return (
    <ThemeProvider theme={mdTheme}>
      {renderOption}
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar position="absolute" open={open}>
          <Toolbar sx={{ pr: "24px" }}>
            <IconButton
              edge="start"
              color="inherit"
              onClick={toggleDraw}
              sx={{ marginRight: "36px", ...(open ? { display: "none" } : {}) }}
            >
              <MenuIcon />
            </IconButton>

            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              sx={{ flexGrow: 1 }}
              style={{ textTransform: "capitalize" }}
            >
              {pathname.includes("dashboard")
                ? "dashboard"
                : /root\/admin/i.test(pathname)
                ? "Admin"
                : /root\/group/i.test(pathname)
                ? "Group"
                : /root\/privileges/i.test(pathname)
                ? "Privileges"
                : /root\/modules/i.test(pathname)
                ? "Module"
                : /s-cshback/i.test(pathname)
                ? "Cashback"
                : /alias/i.test(pathname)
                ? "Seller Alias"
                : pathname.replace(/\//g, " ")}
            </Typography>

            <IconButton
              color="inherit"
              onClick={({ currentTarget }) => setAnchorEl(currentTarget)}
              aria-controls="primary-menu-account"
            >
              <AccountCircle />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={open}>
          <Toolbar
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              px: [1],
            }}
          >
            <IconButton onClick={toggleDraw}>
              <ChevronLeft />
            </IconButton>
          </Toolbar>
          <Divider />
          <List>
            <div>
              <Tooltip
                placement="right"
                title="total transaksi harian perekspedisi"
              >
                <ListItem
                  selected={/dashboard/i.test(pathname)}
                  button
                  onClick={onChangePage(
                    false,
                    "dashboard",
                    `?date=${new Date().toLocaleString("en-US", {
                      month: "long",
                    })}-${new Date().getFullYear()}`
                  )}
                >
                  <ListItemIcon>
                    <Dashboard />
                  </ListItemIcon>
                  <ListItemText primary="Dashboard" />
                </ListItem>
              </Tooltip>
              {auth.group_name === "bigpos" || auth.username === "root" ? (
                <>
                  <Tooltip
                    placement="right"
                    title="Management Easy Drop dan Big POS, manipulasi dan validasi data"
                  >
                    <ListItem
                      button
                      onClick={() => {
                        setOpenSub(openSub === "pos" ? false : "pos");
                        setOpenSubRoot(false);
                      }}
                    >
                      <ListItemIcon>
                        <Warehouse />
                      </ListItemIcon>
                      <ListItemText primary="Big P.O.S" />
                      {openSub === "pos" ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>
                  </Tooltip>
                  <Collapse in={openSub === "pos"} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      <ListItem
                        sx={{ pl: 4 }}
                        selected={pathname === "/easy-drop"}
                        button
                        onClick={onChangePage(
                          false,
                          `easy-drop?page=0&perPage=10`
                        )}
                      >
                        <ListItemIcon>
                          <ChecklistRtl />
                        </ListItemIcon>
                        <ListItemText primary="List Data Scan" />
                      </ListItem>
                      <ListItem
                        sx={{ pl: 4 }}
                        selected={/easy-drop\/bagging/i.test(pathname)}
                        button
                        onClick={() => {
                          const thisMonth = ThisMonth();
                          push(
                            `easy-drop/bagging?limit=10&page=1&minDate=${thisMonth.startDate}&maxDate=${thisMonth.endDate}`
                          );
                        }}
                      >
                        <ListItemIcon>
                          <Inventory />
                        </ListItemIcon>
                        <ListItemText primary="List Bagging" />
                      </ListItem>
                      <ListItem
                        sx={{ pl: 4 }}
                        selected={/easy-drop\/logging/i.test(pathname)}
                        button
                        onClick={onChangePage(
                          false,
                          `easy-drop/logging?page=0&perPage=10`
                        )}
                      >
                        <ListItemIcon>
                          <WorkHistory />
                        </ListItemIcon>
                        <ListItemText primary="Logging" />
                      </ListItem>
                    </List>
                  </Collapse>
                </>
              ) : null}
              {auth.group_name === "root" ||
              auth.group_name === "finance" ||
              auth.username === "root" ? (
                <div>
                  <Tooltip
                    placement="right"
                    title="Kebutuhan Finansial. Seperti total transaksi perbulan, cashback, invoice dan komisi sales"
                  >
                    <ListItem
                      button
                      onClick={() => {
                        setOpenSub(openSub === "finance" ? false : "finance");
                        setOpenSubRoot(false);
                      }}
                    >
                      <ListItemIcon>
                        <AccountBalanceWallet />
                      </ListItemIcon>
                      <ListItemText primary="Finance" />
                      {openSub === "finance" ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>
                  </Tooltip>

                  <Collapse
                    in={openSub === "finance"}
                    timeout="auto"
                    unmountOnExit
                  >
                    <List component="div" disablePadding>
                      <ListItem
                        sx={{ pl: 4 }}
                        selected={pathname === "/financial"}
                        button
                        onClick={onChangePage(
                          false,
                          `financial?page=1&perPage=10&startDate=${encodeURIComponent(
                            formattedStartOfMonth
                          )}&endDate=${encodeURIComponent(currentDate)}`
                        )}
                      >
                        <ListItemIcon>
                          <Leaderboard />
                        </ListItemIcon>
                        <ListItemText primary="Dashboard" />
                      </ListItem>
                      <ListItem
                        sx={{ pl: 4 }}
                        selected={/financial\/comparation/i.test(pathname)}
                        button
                        onClick={onChangePage(
                          false,
                          "financial/comparation?page=1&perPage=10&status=sudah_compare"
                        )}
                      >
                        <ListItemIcon>
                          <Compare />
                        </ListItemIcon>
                        <ListItemText primary="Compare" />
                      </ListItem>

                      <ListItem
                        sx={{ pl: 4 }}
                        selected={/financial\/upload-jnt/i.test(pathname)}
                        button
                        onClick={onChangePage(
                          false,
                          `financial/upload-jnt?date=${new Date()}`
                        )}
                      >
                        <ListItemIcon>
                          <CardMembership />
                        </ListItemIcon>
                        <ListItemText primary="Calculate Bonus" />
                      </ListItem>

                      <ListItem
                        sx={{ pl: 4 }}
                        selected={/financial\/cashback/i.test(pathname)}
                        button
                        onClick={onChangePage(false, "financial/cashback")}
                      >
                        <ListItemIcon>
                          <Percent />
                        </ListItemIcon>
                        <ListItemText primary="Saldo" />
                      </ListItem>

                      <ListItem
                        sx={{ pl: 4 }}
                        selected={/financial\/invoice/i.test(pathname)}
                        button
                        onClick={onChangePage(false, "financial/invoice")}
                      >
                        <ListItemIcon>
                          <Receipt />
                        </ListItemIcon>
                        <ListItemText primary="Invoice" />
                      </ListItem>
                      <ListItem
                        sx={{ pl: 4 }}
                        selected={/financial\/commission/i.test(pathname)}
                        button
                        onClick={onChangePage(
                          false,
                          `financial/commission?date=${new Date()}`
                        )}
                      >
                        <ListItemIcon>
                          <PriceCheck />
                        </ListItemIcon>
                        <ListItemText primary="Commission" />
                      </ListItem>
                    </List>
                  </Collapse>
                </div>
              ) : null}

              {auth.group_name !== "bigpos" ? (
                <div>
                  <Tooltip placement="right" title="Kebutuhan Customer Service">
                    <ListItem
                      button
                      onClick={() => {
                        setOpenSub(openSub === "cs" ? false : "cs");
                        setOpenSubRoot(false);
                      }}
                    >
                      <ListItemIcon>
                        <SupportAgent />
                      </ListItemIcon>
                      <ListItemText primary="Customer Service" />
                      {openSub === "cs" ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>
                  </Tooltip>

                  <Collapse in={openSub === "cs"} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      <ListItem
                        sx={{ pl: 4 }}
                        button
                        selected={/\/difference/i.test(pathname)}
                        onClick={onChangePage(
                          false,
                          `difference?page=1&perPage=10&date=${yesterday()}`
                        )}
                      >
                        <ListItemIcon>
                          <ContentPasteSearch />
                        </ListItemIcon>
                        <ListItemText primary="Selisih Surat Jalan" />
                      </ListItem>

                      <ListItem
                        sx={{ pl: 4 }}
                        button
                        selected={/\/ticket/i.test(pathname)}
                        onClick={onChangePage(
                          false,
                          "tickets?page=0&perPage=10"
                        )}
                      >
                        <ListItemIcon>
                          <Campaign />
                        </ListItemIcon>
                        <ListItemText primary="Complaint Tickets" />
                      </ListItem>
                    </List>
                  </Collapse>
                </div>
              ) : null}
              {/* <Tooltip
                placement="right"
                title="Data delivery dan report harian sprinter"
              >
                <ListItem
                  button
                  selected={/\/delivery/i.test(pathname)}
                  onClick={onChangePage(false, "delivery?page=0&perPage=10")}
                >
                  <ListItemIcon>
                    <DeliveryDining />
                  </ListItemIcon>
                  <ListItemText primary="Delivery" />
                </ListItem>
              </Tooltip> */}

              {auth.group_name !== "bigpos" ? (
                <Tooltip
                  placement="right"
                  title="Data Seller yang register ke dalam sistem"
                >
                  <ListItem
                    button
                    selected={
                      /\/seller/i.test(pathname) &&
                      !/\/bigdelivery/i.test(pathname)
                    }
                    onClick={onChangePage(false, "seller?page=0&perPage=10")}
                  >
                    <ListItemIcon>
                      <PeopleAltOutlined />
                    </ListItemIcon>
                    <ListItemText primary="Seller" />
                  </ListItem>
                </Tooltip>
              ) : null}

              {auth.group_name !== "bigpos" ? (
                <Tooltip placement="right" title="Data Seller bigdel Apps">
                  <ListItem
                    button
                    selected={
                      /\/bigdelivery/i.test(pathname) &&
                      !/\/dropper/i.test(pathname)
                    }
                    onClick={onChangePage(
                      false,
                      "seller/bigdelivery/app?page=0&perPage=10"
                    )}
                  >
                    <ListItemIcon>
                      <CoPresent />
                    </ListItemIcon>
                    <ListItemText primary="Seller Bigdel App" />
                  </ListItem>
                </Tooltip>
              ) : null}

              {auth.group_name !== "bigpos" ? (
                <Tooltip placement="right" title="Data Dropper bigdel Apps">
                  <ListItem
                    button
                    selected={/\/dropper/i.test(pathname)}
                    onClick={onChangePage(
                      false,
                      "seller/bigdelivery/dropper?page=0&perPage=10"
                    )}
                  >
                    <ListItemIcon>
                      <LocalShipping />
                    </ListItemIcon>
                    <ListItemText primary="Dropper Bigdel App" />
                  </ListItem>
                </Tooltip>
              ) : null}

              {auth.group_name !== "bigpos" ? (
                <Tooltip placement="right" title="Data Cashback per Seller">
                  <ListItem
                    button
                    selected={/s-cshback/i.test(pathname)}
                    onClick={onChangePage(false, "s-cshback?page=0&perPage=10")}
                  >
                    {/* testing */}
                    <ListItemIcon>
                      <AttachMoneyOutlined />
                    </ListItemIcon>
                    <ListItemText primary="Saldo" />
                  </ListItem>
                </Tooltip>
              ) : null}

              {auth.group_name !== "bigpos" ? (
                <Tooltip placement="right" title="Cari DOP berdasarkan AWB">
                  <ListItem button onClick={onChangePage(false, "search-awb")}>
                    {/* testing */}
                    <ListItemIcon>
                      <Videocam />
                    </ListItemIcon>
                    <ListItemText primary="Search AWB" />
                  </ListItem>
                </Tooltip>
              ) : null}

              <Divider />

              {auth.group_name !== "bigpos" ? (
                <ListItem
                  selected={/transaction/i.test(pathname)}
                  component="div"
                  disablePadding
                >
                  <Tooltip
                    placement="right"
                    title="Data Transaction yang sudah di upload"
                  >
                    <ListItemButton
                      sx={{ height: 56 }}
                      onClick={onChangePage(
                        false,
                        "transactions?page=0&perPage=10&threePl=all"
                      )}
                    >
                      <ListItemIcon>
                        <GifBoxOutlined />
                      </ListItemIcon>
                      <ListItemText
                        primary="Transactions"
                        primaryTypographyProps={{
                          fontWeight: "medium",
                          variant: "body2",
                        }}
                      />
                    </ListItemButton>
                  </Tooltip>
                  <Tooltip placement="right" title="Instant to Upload Page">
                    <IconButton
                      size="large"
                      onClick={onChangePage(false, "transactions/upload")}
                      sx={{
                        "& svg": {
                          transition: "0.2s",
                          transform: "translateX(0) rotate(0)",
                        },
                        "&:hover, &:focus": {
                          bgcolor: "unset",
                          "& svg:first-of-type": {
                            transform: "translateX(-4px) rotate(-20deg)",
                          },
                          "& svg:last-of-type": {
                            right: 0,
                            opacity: 1,
                          },
                        },
                        "&:after": {
                          content: '""',
                          position: "absolute",
                          height: "80%",
                          display: "block",
                          left: 0,
                          width: "1px",
                          bgcolor: "divider",
                        },
                      }}
                    >
                      <UploadFileOutlined />
                      <ArrowRight
                        sx={{ position: "absolute", right: 4, opacity: 0 }}
                      />
                    </IconButton>
                  </Tooltip>
                </ListItem>
              ) : null}
            </div>
          </List>
          {auth.group_name !== "executor" || auth.group_name !== "bigpos" ? (
            <Fragment>
              <Divider />
              <List>
                <div>
                  {/* <ListSubheader inset>Root User</ListSubheader> */}

                  {auth.group_name === "root" || auth.username === "root" ? (
                    <div>
                      <Tooltip
                        placement="right"
                        title="Akses Khusus untuk Super Admin (root)"
                      >
                        <ListItem
                          button
                          onClick={() => {
                            setOpenSubRoot(
                              openSubRoot === "root" ? false : "root"
                            );
                            setOpenSub(false);
                          }}
                        >
                          <ListItemIcon>
                            <Key />
                          </ListItemIcon>
                          <ListItemText primary="Root" />
                          {openSubRoot === "root" ? (
                            <ExpandLess />
                          ) : (
                            <ExpandMore />
                          )}
                        </ListItem>
                      </Tooltip>

                      <Collapse
                        in={openSubRoot === "root"}
                        timeout="auto"
                        unmountOnExit
                      >
                        <List component="div" disablePadding>
                          {[
                            {
                              original: "group",
                              regex: /root\/group/i,
                              icon: <GroupAddOutlined />,
                            },
                            {
                              original: "admin",
                              regex: /root\/admin/i,
                              icon: <AdminPanelSettings />,
                            },
                            {
                              original: "modules",
                              regex: /root\/modules/i,
                              icon: <ViewModule />,
                            },
                            {
                              original: "privileges",
                              regex: /root\/privileges/i,
                              icon: <AccessibilityNewOutlined />,
                            },
                            {
                              original: "lottery",
                              regex: /root\/lottery/i,
                              icon: <WorkspacePremiumOutlined />,
                            },
                            // {
                            //   original: "whatsapp",
                            //   regex: /root\/whatsapp/i,
                            //   icon: <WhatsApp />,
                            // },
                          ].map((menu) => (
                            <ListItem
                              selected={menu.regex.test(pathname)}
                              key={menu.original}
                              button
                              onClick={
                                menu.original === "whatsapp" ||
                                menu.original === "lottery"
                                  ? onChangePage(true, menu.original)
                                  : onChangePage(
                                      true,
                                      `${menu.original}/1/10${defaultQuery}`
                                    )
                              }
                            >
                              <ListItemIcon>{menu.icon}</ListItemIcon>
                              <ListItem style={{ textTransform: "capitalize" }}>
                                {menu.original}
                              </ListItem>
                            </ListItem>
                          ))}
                        </List>
                      </Collapse>
                    </div>
                  ) : null}
                </div>
              </List>
            </Fragment>
          ) : null}
        </Drawer>
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: "100vh",
            overflow: "auto",
          }}
        >
          <Toolbar />
          <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
            <Grid container spacing={3} style={{ backgroundColor: "white" }}>
              <Outlet />
            </Grid>
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
};
