import {createContext, useReducer, useCallback} from 'react';

let initialState = {
	data: null,
	requestId: '',
}

const RequestContext = createContext({
	...initialState,
	clear: _ => {},
	add: ({data, requestId}) => {},
})

function requestReducer (state, action) {
	switch (action.type) {
		case 'ADD_REQUEST':
			return {...state, ...action.payload};
		case 'CLEAR_REQUEST':
			return {...state, ...initialState};
		default:
			return state;
	}
};

function RequestProvider (props) {
	const [state, dispatch] = useReducer(requestReducer, {...initialState, add: ({data, requestId}) => null, clear: _ => null}),
		add = useCallback((payload = {data: null, requestId: ''}) => dispatch({type: 'ADD_REQUEST', payload}), [dispatch]),
		clear = useCallback(_ => dispatch({type: 'CLEAR_REQUEST', payload: null}), [dispatch]);

	return (
		<RequestContext.Provider
			value={{...state, add, clear}}
			{...props}/>
	)
}

export {RequestProvider, RequestContext};