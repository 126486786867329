import { useState, Fragment, useEffect } from "react";
import { useSearchParams } from "react-router-dom";

import {
  Typography,
  Box,
  Button,
  Grid,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Divider,
  // FormControl,
  // InputLabel,
  // Select,
  // MenuItem,
  IconButton,
  Modal,
  Zoom,
  Card,
  CardContent,
  CircularProgress,
  Alert,
} from "@mui/material";
import {
  InfoOutlined,
  Close,
  // Today,
  ImportExportOutlined,
  RemoveCircleOutlineOutlined,
} from "@mui/icons-material";
// import { SearchHead } from "../../components/spam/inputs";
import { RangeDateTimePicker } from "../../components/spam/dateTime";
import { useInvoice } from "../../services/financial";
import { convertCurrency } from "../../helpers/custom";

export const FinancialInvoicePage = () => {
  const [showDateFilter, setShowDateFilter] = useState(false);
  const [sellerName, setSellerName] = useState("");
  const [detailPerDate, setDetailPerDate] = useState([]);
  const [date, setDate] = useState("");

  const [openModal, setOpenModal] = useState(false);
  const [openModal2, setOpenModal2] = useState(false);

  const [dateView, setDateView] = useState({ minDate: "", maxDate: "" });
  const [searchParams] = useSearchParams();

  const params = Object.fromEntries([...searchParams]);

  const {
    getList,
    downloadAllInvoiceDetail,
    downloadAllInvoice,
    dataInvoice,
    getDetail,
    loading,
    dataInvoiceDetail,
    donwnloadPerDate,
    query,
    push,
  } = useInvoice();
  const detailHandler = async (item) => {
    setOpenModal(true);
    await getDetail(item);
    setSellerName(item);
  };

  useEffect(() => {
    getList();
    if (params.minDate && params.maxDate) {
      setDateView({ minDate: params.minDate, maxDate: params.maxDate });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  return (
    <Fragment>
      <Modal
        open={openModal2}
        sx={{
          width: "100%",
          justifyContent: "center",
          display: "flex",
          height: "80%",
          mt: 10,
        }}
      >
        <Zoom in={openModal2}>
          <Grid>
            <Grid
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                backgroundColor: "white",
                height: 50,
              }}
            >
              <Button
                size="small"
                onClick={() => {
                  setOpenModal(true);

                  setOpenModal2(false);
                }}
              >
                <Close />
              </Button>
            </Grid>
            <Card
              sx={{
                width: 1000,
                overflow: "auto",
                height: "100%",
                borderRadius: 0,
              }}
            >
              <CardContent>
                <Typography variant="h5" sx={{ mb: 2 }}>
                  Detail Invoice Tanggal : {date}
                </Typography>
                <Button
                  component="label"
                  // startIcon={<Update />}
                  variant="outlined"
                  size="small"
                  color="secondary"
                  sx={{
                    height: "100%",
                  }}
                  onClick={() =>
                    donwnloadPerDate({ sellerName, detailPerDate })
                  }
                >
                  Download Report {date}
                </Button>
                <TableContainer style={{ minHeight: "100%" }}>
                  <Table size="small" style={{ width: "100%" }} stickyHeader>
                    <TableHead>
                      <TableRow>
                        <TableCell>No</TableCell>
                        <TableCell align="center">ThreePL</TableCell>
                        <TableCell align="center">SERVICE</TableCell>
                        <TableCell align="center">AIRWILLBAY</TableCell>
                        <TableCell align="center">INVOICE</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {detailPerDate.length
                        ? detailPerDate.map((e, i) => (
                            <TableRow key={i}>
                              <TableCell>{i + 1}</TableCell>
                              <TableCell align="center">{e.threePL}</TableCell>
                              <TableCell align="center">{e.service}</TableCell>
                              <TableCell align="center">{e.awb}</TableCell>
                              <TableCell align="center">
                                {convertCurrency(+e.total)}
                              </TableCell>
                            </TableRow>
                          ))
                        : null}
                    </TableBody>
                  </Table>
                </TableContainer>
              </CardContent>
            </Card>
          </Grid>
        </Zoom>
      </Modal>
      <Modal
        open={openModal}
        sx={{
          width: "100%",
          justifyContent: "center",
          display: "flex",
          height: "80%",
          mt: 10,
        }}
      >
        <Zoom in={openModal}>
          <Grid>
            <Grid
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                backgroundColor: "white",
                height: 50,
              }}
            >
              <Button
                size="small"
                onClick={() => {
                  setOpenModal(false);
                }}
              >
                <Close />
              </Button>
            </Grid>
            <Card
              sx={{
                width: 1000,
                overflow: "auto",
                height: "100%",
                borderRadius: 0,
              }}
            >
              <CardContent>
                <Typography variant="h5" sx={{ mb: 2 }}>
                  Detail Invoice Seller : {sellerName.toUpperCase()}
                </Typography>
                <Button
                  component="label"
                  // startIcon={<Update />}
                  variant="outlined"
                  size="small"
                  color="secondary"
                  sx={{
                    height: "100%",
                  }}
                  onClick={() => downloadAllInvoiceDetail(sellerName)}
                >
                  Download Invoice
                </Button>
                <TableContainer style={{ minHeight: "100%" }}>
                  <Table size="small" style={{ width: "100%" }} stickyHeader>
                    <TableHead>
                      <TableRow>
                        <TableCell>NO</TableCell>
                        <TableCell>Tanggal</TableCell>
                        <TableCell>TOTAL</TableCell>
                        <TableCell>ACTION</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {Object.keys(dataInvoiceDetail).length
                        ? Object.keys(dataInvoiceDetail).map((e, i) => (
                            <TableRow key={i}>
                              <TableCell>{i + 1}</TableCell>
                              <TableCell>
                                {new Date(e).toLocaleDateString("id-ID")}
                              </TableCell>
                              <TableCell>
                                {convertCurrency(+dataInvoiceDetail[e].total)}
                              </TableCell>
                              <TableCell>
                                <IconButton
                                  color="primary"
                                  onClick={() => {
                                    // detailHandler(item);
                                    setDate(
                                      new Date(e).toLocaleDateString("id-ID")
                                    );
                                    setDetailPerDate(
                                      dataInvoiceDetail[e].detail
                                    );
                                    setOpenModal2(true);
                                    setOpenModal(false);
                                  }}
                                >
                                  <InfoOutlined />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          ))
                        : null}
                    </TableBody>
                  </Table>
                  {loading ? (
                    <div className="center">
                      <CircularProgress size={25} disableShrink thickness={4} />
                    </div>
                  ) : !Object.keys(dataInvoiceDetail).length ? (
                    <Alert variant="standard" severity="warning">
                      No Data
                    </Alert>
                  ) : null}
                </TableContainer>
              </CardContent>
            </Card>
          </Grid>
        </Zoom>
      </Modal>
      <Grid
        item
        width="100%"
        justifyContent="space-between"
        display="flex"
        flexDirection="column"
      >
        <Box
          sx={{
            mb: 2,
            display: "flex",
            alignItems: "center",
          }}
        >
          <Box sx={{ height: "100%" }}>
            {showDateFilter ? (
              <RangeDateTimePicker
                onFilter={({ startDate, endDate }) =>
                  (_) => {
                    push({
                      ...query,
                      minDate: startDate,
                      maxDate: endDate,
                    });
                    setShowDateFilter(false);
                  }}
                open
                close={setShowDateFilter}
              />
            ) : null}

            {dateView.minDate && dateView.maxDate ? (
              <Button
                size="small"
                endIcon={<RemoveCircleOutlineOutlined />}
                variant="outlined"
                sx={{ mr: 2 }}
                color="error"
                onClick={() => {
                  delete query.minDate;
                  delete query.maxDate;
                  setDateView({ minDate: "", maxDate: "" });
                  push({
                    ...query,
                  });
                }}
                title="Remove Filter Date"
              >
                {new Date(dateView.minDate).toLocaleDateString("en-US", {
                  timeZone: "Asia/Jakarta",
                })}{" "}
                -{" "}
                {new Date(dateView.maxDate).toLocaleDateString("en-US", {
                  timeZone: "Asia/Jakarta",
                })}
              </Button>
            ) : (
              <Button
                size="small"
                variant="outlined"
                sx={{ mr: 2 }}
                onClick={() => setShowDateFilter(true)}
                title="Filter date"
              >
                Choose Date Range
              </Button>
            )}
          </Box>
          <Box>
            {/* <SearchHead
              values={values}
              placeholder="Search Nama Seller"
              onEnter={(variable) =>
                push({ ...query, page: 0, search: variable })
              }
            /> */}
            {Object.keys(dataInvoice).length ? (
              <Button
                title="Export Excel"
                style={{ marginLeft: 5 }}
                endIcon={<ImportExportOutlined />}
                size="small"
                variant="outlined"
                color="secondary"
                onClick={(_) => downloadAllInvoice()}
              >
                Export Data
              </Button>
            ) : null}
          </Box>
        </Box>
        <Divider sx={{ ml: -3 }} />
        <Box sx={{ mb: 3, mt: 2 }}>
          {/* <FormControl sx={{ minWidth: 200 }} size="small">
            <InputLabel>Nama Seller</InputLabel>
            <Select
              // value={age}
              // onChange={handleChange}
              label="Nama Seller"
              sx={{ borderColor: "red" }}
            >
              <MenuItem value={10}>Ten</MenuItem>
              <MenuItem value={20}>Twenty</MenuItem>
              <MenuItem value={30}>Thirty</MenuItem>
            </Select>
          </FormControl> */}
        </Box>
      </Grid>
      <TableContainer style={{ minHeight: "50vh", maxHeight: "80vh" }}>
        <Table size="small" style={{ width: "100%" }} stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>NO</TableCell>
              <TableCell>NAMA SELLER</TableCell>
              <TableCell>JNE</TableCell>
              <TableCell>JNT</TableCell>
              <TableCell>ANTER AJA</TableCell>
              <TableCell>NINJA</TableCell>
              <TableCell>ID</TableCell>
              <TableCell>LION</TableCell>
              <TableCell>SI CEPAT</TableCell>
              <TableCell>SHOPEE</TableCell>
              <TableCell>LEX</TableCell>
              <TableCell>WAHANA</TableCell>
              <TableCell>OTHER</TableCell>
              <TableCell>TOTAL INVOICE</TableCell>
              <TableCell>ACTION</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.keys(dataInvoice).length
              ? Object.keys(dataInvoice).map((item, i) => (
                  <TableRow key={i}>
                    <TableCell>{i + 1}</TableCell>
                    <TableCell>{item}</TableCell>
                    <TableCell>
                      {dataInvoice[item]["jne"]
                        ? convertCurrency(+dataInvoice[item]["jne"])
                        : convertCurrency(0)}
                    </TableCell>
                    <TableCell>
                      {dataInvoice[item].jnt
                        ? convertCurrency(+dataInvoice[item].jnt)
                        : convertCurrency(0)}
                    </TableCell>
                    <TableCell>
                      {dataInvoice[item].anteraja
                        ? convertCurrency(+dataInvoice[item].anteraja)
                        : convertCurrency(0)}
                    </TableCell>
                    <TableCell>
                      {dataInvoice[item]["ninja express"]
                        ? convertCurrency(+dataInvoice[item]["ninja express"])
                        : convertCurrency(0)}
                    </TableCell>
                    <TableCell>
                      {dataInvoice[item]["id express"]
                        ? convertCurrency(+dataInvoice[item]["id express"])
                        : convertCurrency(0)}
                    </TableCell>
                    <TableCell>
                      {dataInvoice[item].lion
                        ? convertCurrency(+dataInvoice[item].lion)
                        : convertCurrency(0)}
                    </TableCell>
                    <TableCell>
                      {dataInvoice[item].sicepat
                        ? convertCurrency(+dataInvoice[item].sicepat)
                        : convertCurrency(0)}
                    </TableCell>
                    <TableCell>
                      {dataInvoice[item].shopee
                        ? convertCurrency(+dataInvoice[item].shopee)
                        : convertCurrency(0)}
                    </TableCell>
                    <TableCell>
                      {dataInvoice[item].lex
                        ? convertCurrency(+dataInvoice[item].lex)
                        : convertCurrency(0)}
                    </TableCell>
                    <TableCell>
                      {dataInvoice[item].wahana
                        ? convertCurrency(+dataInvoice[item].wahana)
                        : convertCurrency(0)}
                    </TableCell>
                    <TableCell>
                      {dataInvoice[item].other
                        ? convertCurrency(+dataInvoice[item].other)
                        : convertCurrency(0)}
                    </TableCell>
                    <TableCell>
                      {dataInvoice[item].total
                        ? convertCurrency(+dataInvoice[item].total)
                        : convertCurrency(0)}
                    </TableCell>
                    <TableCell>
                      <IconButton
                        color="primary"
                        onClick={() => {
                          setSellerName("");
                          detailHandler(item);
                        }}
                      >
                        <InfoOutlined />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))
              : null}
          </TableBody>
        </Table>
        {loading ? (
          <div className="center">
            <CircularProgress size={25} />
          </div>
        ) : Object.keys(dataInvoice).length < 1 ? (
          <Alert variant="standard" severity="warning">
            No Data
          </Alert>
        ) : null}
      </TableContainer>

      {/* <TablePagination
        rowsPerPageOptions={[5, 10, 15, 25, 50, 100, 200, 500]}
        component="div"
        // count={data.count}
        count={data.count}
        rowsPerPage={parseInt(query?.perPage || "10", 10)}
        page={data.count === 0 ? 0 : parseInt(query?.page || "0", 10)}
        onPageChange={(_, newPage) =>
          push(
            query
              ? { ...query, page: newPage }
              : { page: newPage, perPage: "10" }
          )
        }
        onRowsPerPageChange={({ target: { value = "10" } }) =>
          push(
            query
              ? { ...query, page: 0, perPage: parseInt(value, 10) }
              : { page: 0, perPage: parseInt(value, 10) }
          )
        }
        ActionsComponent={TablePaginationActions}
      /> */}
    </Fragment>
  );
};
