import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Outlet } from "react-router-dom";
import { PrivateRoute, PublicRoute } from "./components";
import {
  LoginPage,
  PrivateLayoutPage,
  DashboardPage,
  RootPage,
  HomeListPage,
  HomeCreate,
  UserSellerPage,
  CashbackPage,
  SellerCashbackPage,
  SellerCashbackDetailPage,
  DetailUserSellerPage,
  AliasSellerPage,
  TransactionPage,
  TransactionUpload,
  MyOutlet,
  MyPrivilegePage,
  SellerRegisterMassal,
  SellerBigdiPage,
  TicketPage,
  DifferenceTransactionPage,
  // DriverPage,
  // DriverCreatePage,
  // PickupPage,
  // PickupCreatePage,
  // FinancialPage,
  // FinancialIndexPage,
  // FinancialComparationPage,
  // FinancialCashbackPage,
  // FinancialCommisionPage,
  // FinancialInvoicePage,
} from "./containers";

import { FinancialIndexPage } from "./containers/financials";
import CalculatePage from "./containers/financials/calculate";
import { FinancialComparationPage } from "./containers/financials/comparation";
import { FinancialComparationUploadPage } from "./containers/financials/comparationUpload";
import { FinancialCommissionPage } from "./containers/financials/commission";
import { FinancialCashbackPage } from "./containers/financials/cashback";
import { FinancialInvoicePage } from "./containers/financials/invoice";

import { SellerBigdiStorePage } from "./containers/seller-bigdiapp/store";
// import { FinancialCashbackInvoicePage } from "./containers/financials/cashbackInvoice";

import { LotteryPage } from "./containers/lottery";

import "./app.scss";
import { WhatsAppPage } from "./containers/root/WhatsAppPage";
import { DropperBigdiPage } from "./containers/dropper";
import { RegisterDropper } from "./containers/dropper/createDropper";
import DeliveryPage from "./containers/delivery";
import UploadReportPage from "./containers/delivery/uploadReport";
import EasyDropPage from "./containers/easy-drop";
import UploadJNTPage from "./containers/financials/uploadjnt";
import BaggingPage from "./containers/easy-drop/bagging";
import LoggingDOPPage from "./containers/easy-drop/loggingDOP";
import SearchAwb from "./containers/searchAwb/searchAwb";

const NotFound = () => {
  return (
    <div
      style={{
        width: "100%",
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      404 Not Found
    </div>
  );
};

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<PublicRoute component={LoginPage} />} />

        <Route path="/" element={<PrivateRoute />}>
          <Route path="" element={<PrivateLayoutPage />}>
            <Route path="dashboard" element={<DashboardPage />} />
            {/* <Route path="driver" element={<Outlet />}>
              <Route path="" element={<DriverPage />} />
              <Route path="create" element={<DriverCreatePage />} />
              </Route>
              <Route path="pickup" element={<Outlet />}>
              <Route path="" element={<PickupPage />} />
              <Route path="create" element={<PickupCreatePage />} />
            </Route> */}
            <Route path="easy-drop" element={<Outlet />}>
              <Route path="" element={<EasyDropPage />} />
              <Route path="bagging" element={<BaggingPage />} />
              <Route path="logging" element={<LoggingDOPPage />} />
            </Route>
            <Route path="delivery" element={<Outlet />}>
              <Route path="" element={<DeliveryPage />} />
              <Route path="upload" element={<UploadReportPage />} />
            </Route>
            <Route path="seller" element={<Outlet />}>
              <Route path="" element={<UserSellerPage />} />
              <Route
                path="register/massal"
                element={<SellerRegisterMassal />}
              />
              <Route path=":sellerId" element={<Outlet />}>
                <Route path="" element={<DetailUserSellerPage />} />
                <Route path="alias" element={<AliasSellerPage />} />
              </Route>
              <Route path="bigdelivery/app" element={<Outlet />}>
                <Route path="" element={<SellerBigdiPage />} />
                <Route path="store" element={<SellerBigdiStorePage />} />
              </Route>
              <Route path="bigdelivery/dropper" element={<Outlet />}>
                <Route path="" element={<DropperBigdiPage />} />
                <Route path="create" element={<RegisterDropper />} />
              </Route>
            </Route>
            <Route path="tickets" element={<TicketPage />} />
            <Route path="difference" element={<DifferenceTransactionPage />} />
            <Route path="transactions" element={<Outlet />}>
              <Route path="" element={<TransactionPage />} />
              <Route path="upload" element={<TransactionUpload />} />
            </Route>
            <Route path="search-awb" element={<Outlet />}>
              <Route path="" element={<SearchAwb />} />
            </Route>
            <Route path="financial" element={<Outlet />}>
              <Route path="" element={<FinancialIndexPage />} />
              <Route path="upload-jnt" element={<UploadJNTPage />} />
              <Route path="Calculate" element={<CalculatePage />} />
              <Route path="comparation" element={<FinancialComparationPage />}>
                <Route
                  path="upload"
                  element={<FinancialComparationUploadPage />}
                />
              </Route>
              {/* <Route
                path="cashback&invoice"
                element={<FinancialCashbackInvoicePage />}
              /> */}
              <Route path="cashback" element={<FinancialCashbackPage />} />
              <Route path="invoice" element={<FinancialInvoicePage />} />
              <Route path="commission" element={<FinancialCommissionPage />} />
            </Route>
            <Route path="s-cshback" element={<Outlet />}>
              <Route path="" element={<SellerCashbackPage />} />
              <Route
                path=":cashbackId"
                element={<SellerCashbackDetailPage />}
              />
            </Route>
            <Route path="my" element={<MyOutlet />}>
              <Route
                path="privileges/:offset/:limit"
                element={<MyPrivilegePage />}
              />
            </Route>
            <Route path="cashback" element={<CashbackPage />} />

            <Route path="root" element={<RootPage />}>
              <Route path="whatsapp" element={<WhatsAppPage />} />
              <Route path="lottery" element={<LotteryPage />} />

              <Route path=":module" element={<Outlet />}>
                <Route path="create" element={<HomeCreate />} />
                <Route path=":offset/:limit" element={<HomeListPage />} />
              </Route>
            </Route>

            <Route path="*" element={<NotFound />} />
          </Route>
        </Route>
      </Routes>
    </Router>
  );
};

export default App;
